<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <fieldset class="section-legend-border  col-md-12 col-lg-12" #FoodByNutrition>
        <legend class="section-legend-border">Food By Nutrition</legend>
      <div class="form-row">
        <div class="form-group col-md-8">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.NutritionName}}
          </ng-template>
          <label for="typeahead-basic">Search for a NutritionMaster:</label>
          <input id="typeahead-basic" type="text" class="form-control"
            name="nutritionMasterModel.NutritionMasterAutoComplete"
            [(ngModel)]="nutritionMasterModel.NutritionMasterAutoComplete" [ngbTypeahead]="search"
            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)" />
        </div>
      </div>
      <div class="form-row">

        <div class="form-group col-md-2">
          <label for="disabledTextInput">Nutrition Limit Value</label>
          <input type="number" [(ngModel)]="value" name="value" placeholder="Nutrition Limit Value" class="form-control">
        </div>
        <div class="form-group col-md-2">
          <label for="voucherType">Operation</label>
          <select class="form-control" name="value" [(ngModel)]="operation">
            <option *ngFor="let item of operationList" [value]="item.Value">
              {{item.Name}}
            </option>
          </select>
        </div>
        <div class="col-md-4 col-sm-12 inline-custom-button">
          <button type="button" class="btn btn-primary btn-block" (click)="fetchFoods()">Fetch Foods</button>
        </div>

      </div>
      </fieldset>
      <fieldset class="section-legend-border  col-md-12 col-lg-12" #NutritionMaster>
        <legend class="section-legend-border">Nutrition Master</legend>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Nutrition Master Id</label>
          <input type="text" [(ngModel)]="nutritionMasterModel.NutritionMasterId"
            name="nutritionMasterModel.NutritionMasterId" placeholder="id" class="form-control">
        </div>
        <div class="form-group col-md-5">
          <label for="disabledTextInput">NutritionMaster Name</label>
          <input type="text" [(ngModel)]="nutritionMasterModel.NutritionName" name="nutritionMasterModel.NutritionName"
            placeholder="NutritionMaster Name" class="form-control">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Filter Nutrition Name</label>
          <input type="text" [(ngModel)]="nutritionMasterModel.FilterNutritionName"
            name="nutritionMasterModel.FilterNutritionName" placeholder="Filter Nutrition Name" class="form-control">
        </div>

      </div>
      <div class="form-row">

        <div class="form-group col-md-8">
          <label for="disabledTextInput">Notes</label>
          <textarea type="text" [(ngModel)]="nutritionMasterModel.Notes" name="nutritionMasterModel.Notes"
            placeholder="NutritionMaster Name" class="form-control" rows="5"></textarea>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-4 col-sm-12">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
      </div>
      </fieldset>
    </form>
  </div>
  <div class="col-sm-12">
    {{result}}
  </div>
</div>

<fieldset class="section-legend-border  col-md-12 col-lg-12" #FoodList1 *ngIf="FoodList.length>0">
  <legend class="section-legend-border">Food List</legend>
<div class="row" id="topic-example" >
  <div class="col-sm-12 example-section">
    <div class="">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ProductName</th>
            <th scope="col">Protien</th>
            <th scope="col">Oxalate</th>
            <th scope="col">Sodium</th>
            <th scope="col">Potassium</th>
            <th scope="col">Sugar</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of FoodList">
            <td>{{item.ProductName}}</td>
            <td>{{item.Protien}}</td>
            <td>{{item.Oxalate}}</td>
            <td>{{item.Sodium}}</td>
            <td>{{item.Potassium}}</td>
            <td>{{item.Sugar}}</td>
        </tbody>
      </table>
    </div>
  </div>
</div>
</fieldset>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #NutritionMasterList1 *ngIf="NutritionMasterList.length>0">
  <legend class="section-legend-border">Nutrition Master List</legend>
<div class="row" id="topic-example">
  <div class="col-sm-12 example-section">
    <div class="">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">NutritionMasterId</th>
            <th scope="col">NutritionName</th>
            <th scope="col">FilterNutritionName</th>
            <th scope="col">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of NutritionMasterList">
            <td>{{item.NutritionMasterId}}</td>
            <td>{{item.NutritionName}}</td>
            <td>{{item.FilterNutritionName}}</td>
            <td>{{item.Notes}}</td>
        </tbody>
      </table>
    </div>
  </div>
</div>
</fieldset>