export class FixedDeposit {
    public FixedDepositID:number;
    public InterestRate:number;
    public Amount:number; 
    MaturityAmount:number;
    BankFDID:string;
    public DepositeDateDisplay:any;
    DepositeDate:Date;

    public MaturityDateDisplay:any;
    MaturityDate:Date;
    Narration:string;
}
