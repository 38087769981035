import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemTrackerCurrentLocationModel } from 'src/app/models/ItemTracker/item-tracker-current-location-model';
import { ItemTrackerService } from 'src/app/services/item-tracker.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-list-items-by-current-location',
  templateUrl: './list-items-by-current-location.component.html',
  styleUrls: ['./list-items-by-current-location.component.css']
})
export class ListItemsByCurrentLocationComponent implements OnInit {
  //http://localhost:4401/itemtracker/itemlistbylocation?locationname=Right_Wall_Steel_Shelf_Left_To_Gas_First_Shelf
  locationName = '';
  public qrwidth=150;
  public itemTrackerCurrentLocationList: ItemTrackerCurrentLocationModel[];
  constructor(private route: ActivatedRoute, private itemTrackerService: ItemTrackerService,private titleService:Title) {this.titleService.setTitle("Item List By Current Location"); }

  ngOnInit(): void {
    this.locationName = this.route.snapshot.queryParamMap.get('locationname');
    this.itemTrackerService.GetAllItemsByLocationUniqueName( this.locationName).subscribe(response => {
      this.itemTrackerCurrentLocationList = response;
      this.itemTrackerCurrentLocationList.forEach(element => {
        element.LocationNameForQrCode = element.MainLocationUniqueName + "|" + element.SubLocationUniqueName;
      });
    });
  }
}

