export class VitalInformationMeasurementModel {
    public MeasurementID :number;
    public BloodPressure_SystolicmHg :number;
    public BloodPressure_DiastolicmmHg :number;
    public BloodSugar_Fasting :number;
    public BloodSugar_AfterEating :number;
    public BloodSugar_Pulse :number;
    public UserID? :number;
    public IsAvailableToPublic? :boolean;
    public Created? :Date;
    public Updated? :Date;
    public  DisplayCreated:string; 
    public DateDisplay:any;
    public TimeDisplay:any;
}
