<fieldset class="section-legend-border col-md-12 col-lg-12" #HabitType >
  <legend class="section-legend-border">Habit Type</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <div class="form-row">
        <div class="form-group col-md-4">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.HabitTypeName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Habit Type:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="habitTypeModel.HabitTypeAutoComplete" 
          [(ngModel)]="habitTypeModel.HabitTypeAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
          [resultTemplate]="rt" (selectItem)="selectedItem($event)"
          />
        </div>
      </div>
      <div class="form-row"> 
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Habit Type </label>
          <input type="text" [(ngModel)]="habitTypeModel.HabitTypeId" name="habitTypeModel.HabitTypeId" placeholder="Habit Type Id" class="form-control">
        </div>
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Habit Type Name</label>
          <input type="text" [(ngModel)]="habitTypeModel.HabitTypeName" name="habitTypeModel.HabitTypeName" placeholder="Habit Type Name" class="form-control">
        </div>

      </div>
      <div class="form-row">
        <div class="col-md-1"></div>
        <div class="form-group col-md-2 col-sm-12">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
      </div>
    </form> 
  </div>
  <div class="col-sm-12">
    {{result}}
  </div>
  </div>  
</fieldset>
  <!-- <pre>Model: {{ habitTypeModel.HabitTypeAutoComplete | json }}</pre> -->