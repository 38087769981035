import { SensorDataSensorModel } from "./sensor-data-sensor-model";

export class SensorDataSensorFeatureTypeModel {
    public  SensorFeatureTypeID :number;
    public  SensorFeatureTypeName :string;
    public SensorFeatureTypeUniqueName :string;
    public  SensorID :number;
    public  UserID :number;
    public  IsAvailableToPublic :boolean;

     //For template
     public SensorDataSensorFeatureTypeAutoComplete:any;
     public SensorDataSensorFeatureTypelist:SensorDataSensorFeatureTypeModel[];
     public Sensor: SensorDataSensorModel;
}
