import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
//import { HabitTrackerService } from 'src/app/service/habit-tracker.service';
import { HabitTypeModel } from 'src/app/models/HabitTracker/HabitType/habit-type-model';
import { HabitTrackerService } from 'src/app/services/habit-tracker.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-habit-type',
  templateUrl: './habit-type.component.html',
  styleUrls: ['./habit-type.component.css']
})
export class HabitTypeComponent implements OnInit {
  public result='';

  public habitTypeModel:HabitTypeModel;

model: any;
searching = false;
searchFailed = false;

  constructor(private habitTrackerService: HabitTrackerService,private titleService:Title) {  this.titleService.setTitle("Habit Type");}

  ngOnInit() {

    
this.habitTypeModel = new HabitTypeModel();
this.habitTypeModel.HabitTypelist = [];

    // this.habitTrackerService.GetHabitCategoryAllOrById(0).subscribe(response=>
    //   {
    //     console.log(response);
    //   }
    // );
  }

  
  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]):
      (this.habitTrackerService.GetHabitTypeByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )



 formatter = (x: { HabitName: string }) => x.HabitName;

 selectedItem(item){
  //this.clickedItem=item.item;
  console.log(item);
  this.habitTypeModel.HabitTypeId = item.item.HabitTypeId;
  this.habitTypeModel.HabitTypeName = item.item.HabitTypeName;
}
onSubmit() {
  var me = this.habitTypeModel;

  this.habitTrackerService.Insertorupdatehabittype(me).subscribe(
    response => {
      console.log(response);
      this.result = response;
      this.habitTypeModel = new HabitTypeModel();
    }
  );
}
}
