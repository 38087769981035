<fieldset class="section-legend-border col-md-12 col-lg-12" #ManageSubject >
  <legend class="section-legend-border">Manage Subject</legend>
<div class="row">
    <div class="col-sm-12">
<form (ngSubmit)="onSubmit()" #loginForm="ngForm">
<div class="form-row">
    <div class="form-group col-md-6">
        <ng-template #rtSubject let-r="result" let-t="term">
            {{ r.SubjectName}}
        </ng-template>
        <label for="typeahead-basic">Search for a Subject:</label>
        <input id="typeahead-basic" type="text" class="form-control"
            name="subjectModel.SubjectAutoComplete"
            [(ngModel)]="subjectModel.SubjectAutoComplete" [ngbTypeahead]="searchSubject"
            [inputFormatter]="formatterSubject" [resultTemplate]="rtSubject"
            (selectItem)="selectedItemSubject($event)" />
    </div>
</div>
<div class="form-row">
    <div class="form-group col-md-4">
        <label for="disabledTextInput">Subject Id</label>
        <input type="text" [(ngModel)]="subjectModel.SubjectID" name="subjectModel.SubjectID"
            placeholder="id" class="form-control">
    </div>
    <div class="form-group col-md-4">
        <label for="disabledTextInput">Subject Name</label>
        <input type="text" [(ngModel)]="subjectModel.SubjectName" name="subjectModel.SubjectName"
            placeholder="Subject Name" class="form-control">
    </div>
</div>
<div class="form-row">
    <div class="form-group col-md-1"></div>
    <div class="form-group col-md-4 col-sm-12">
        <button type="submit" class="btn btn-primary btn-block">Submit</button>
    </div>
</div>

</form>
</div>
<div class="col-sm-12">
    {{result}}
  </div>
</div>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #SubjectList1 >
  <legend class="section-legend-border">Subject List</legend>
<div class="row" id="subject-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <table class="table">
          <thead>
            <tr>
             <th scope="col">Subject ID</th>
              <th scope="col">Subject Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of subjectList">
              <th scope="row">{{item.SubjectName}}</th>
              <td>{{item.SubjectID}}</td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
</fieldset>
