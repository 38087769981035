<fieldset class="section-legend-border col-md-12 col-lg-12" #ManageQuestionAnswer >
    <legend class="section-legend-border">Manage Question Answer</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Question >
                <legend class="section-legend-border">Question</legend>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-12 col-lg-12">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.QuestionAutoComplete}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a question:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="questionanswermodel.QuestionAnswerAutoComplete" [(ngModel)]="questionanswermodel.QuestionAnswerAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                    <label for="disabledTextInput">Question Id</label>
                    <input type="text" [(ngModel)]="questionanswermodel.QuestionAnswerID" name="questionanswermodel.QuestionAnswerID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-sm-9 col-md-9 col-lg-9">
                    <label for="disabledTextInput">Question</label>
                    <input type="text" [(ngModel)]="questionanswermodel.Question" name="questionanswermodel.Question" placeholder="question" class="form-control">
                </div>
               
            </div>
            </fieldset>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Answer >
                <legend class="section-legend-border">Answer</legend>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-12 col-lg-12">
                    <label for="disabledTextInput">Answer </label>
                    <!-- <input type="text" [(ngModel)]="questionanswermodel.Question" name="questionanswermodel.Question" placeholder="Question" class="form-control"> -->
                    <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="questionanswermodel.Answer" name="questionanswermodel.Answer"></angular-editor>
                </div>
            </div>
            </fieldset>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Subject >
                <legend class="section-legend-border">Subject</legend>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-12 col-lg-12">
                    <ng-template #rtSubject let-r="result" let-t="term">
                        {{ r.SubjectName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Subject:</label>
                    <input id="typeahead-basic" type="text" class="form-control"
                        name="questionanswermodel.SubjectAutoComplete"
                        [(ngModel)]="questionanswermodel.SubjectAutoComplete" [ngbTypeahead]="searchSubject"
                        [inputFormatter]="formatterSubject" [resultTemplate]="rtSubject"
                        (selectItem)="selectedItemSubject($event)" />
                </div>
            </div>
    
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-3 col-lg-3">
                    <label for="disabledTextInput">Subject Id</label>
                    <input type="text" [(ngModel)]="questionanswermodel.SubjectID" name="questionanswermodel.SubjectID"
                        disabled placeholder="id" class="form-control">
                </div>
                <div class="form-group col-sm-12 col-md-9 col-lg-9">
                    <label for="disabledTextInput">Subject Name</label>
                    <input type="text" [(ngModel)]="questionanswermodel.SubjectName" name="questionanswermodel.SubjectName"
                        disabled placeholder="Subject Name" class="form-control">
                </div>
            </div>
            </fieldset>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Topic >
                <legend class="section-legend-border">Topic</legend>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-12 col-lg-12">
                    <ng-template #rtTopic let-r="result" let-t="term">
                        {{ r.TopicName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Topic:</label>
                    <input id="typeahead-basic" type="text" class="form-control"
                        name="questionanswermodel.TopicAutoComplete"
                        [(ngModel)]="questionanswermodel.TopicAutoComplete" [ngbTypeahead]="searchTopic"
                        [inputFormatter]="formatterTopic" [resultTemplate]="rtTopic"
                        (selectItem)="selectedItemTopic($event)" />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-3 col-lg-3">
                    <label for="disabledTextInput">Topic Id</label>
                    <input type="text" [(ngModel)]="questionanswermodel.TopicID" name="questionanswermodel.TopicID"
                        placeholder="id" class="form-control">
                </div>
                <div class="form-group col-sm-12 col-md-9 col-lg-9">
                    <label for="disabledTextInput">Topic Name</label>
                    <input type="text" [(ngModel)]="questionanswermodel.TopicName" name="questionanswermodel.TopicName"
                        placeholder="Topic Name" class="form-control">
                </div>
            </div>
            </fieldset>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
                <div class="form-group  col-sm-12 col-md-4 col-lg-4">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
<div class="form-row">
    <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
    <div class="form-group  col-sm-12 col-md-4 col-lg-4">
        <button class="btn btn-primary btn-block" id="ResetForm" type="button" (click)="resetForm()">Reset</button>
    </div>
    <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
</div>
</fieldset>

<div class="row" id="subject-list">
    <span  (click)="filterTopic(0)"> 
       0 | ALL
    </span>
    <span *ngFor="let item of subjectList" (click)="filterTopic(item.SubjectID)">
      {{item.SubjectID}} | {{item.SubjectName}}
    </span>
  </div>
  <div class="row" id="topic-list">
    <span  (click)="filterQuestionByTopic(0)"> 
       0 | ALL
    </span>
    <span *ngFor="let item of topicList" (click)="filterQuestionByTopic(item.TopicID)">
      {{item.TopicID}} | {{item.TopicName}}
    </span>
  </div>
  <fieldset class="section-legend-border col-md-12 col-lg-12" #QuestionAnswerList1 >
    <legend class="section-legend-border">Question Answer List</legend>
<div class="row" id="questionn-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Question</th>
              <th scope="col">Topic Name</th>
              <th scope="col">Subject Name</th>
              <th scope="col">QuestionAnswer ID</th>
           
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of questionAnswerList">
              <th scope="row" (click)="getQuestionById(item.QuestionAnswerID)" class="question-col">{{item.Question}}</th>
              <td>{{item.TopicName}}</td>
              <td>{{item.SubjectName}}</td>
              <td>{{item.QuestionAnswerID}}</td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </fieldset>