import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { MealTypeModel } from 'src/app/models/FoodLogger/MealType/meal-type-model';
import { FoodLoggerService } from 'src/app/services/food-logger.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-meal-type',
  templateUrl: './meal-type.component.html',
  styleUrls: ['./meal-type.component.css']
})
export class MealTypeComponent implements OnInit {
  public result='';
  mealTypeModel:MealTypeModel;
  model: any;
searching = false;
searchFailed = false;
  constructor(private foodLoggerservice:FoodLoggerService,private titleService:Title) { this.titleService.setTitle("Meal Type");}

  ngOnInit() {
    this.mealTypeModel = new MealTypeModel();
    this.mealTypeModel.MealTypelist = [];
  }
  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]):
      (this.foodLoggerservice.GetAllMealTypeByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
  formatter = (x: { Name: string }) => x.Name;

  selectedItem(item){
    //this.clickedItem=item.item;
    console.log(item);
    this.mealTypeModel.MealTypeId = item.item.MealTypeId;
    this.mealTypeModel.Name = item.item.Name;
   // var accountApiModel = new BrandModel();
    // this.accountModel.AccountID = item.item.AccountID;
    // this.accountModel.AccountCode = item.item.AccountCode;
    // this.accountModel.AccountName = item.item.AccountName;

    // this.accountModel.AccountMasterID = item.item.AccountMasterID;
    // this.accountModel.AccountMasterName = item.item.AccountMasterName;
    // this.accountModel.CashBankOther = item.item.CashBankOther;
    // this.accountModel.AccountStatus = item.item.AccountStatus;
    // this.accountModel.Receipt = item.item.Receipt;
    // this.accountModel.Active = item.item.Active;
    // this.accountModel.AccountAutoID = item.item.AccountAutoID;
  }
  onSubmit() {
    var me = this.mealTypeModel;
    this.foodLoggerservice.InsertOrUpdateMealType(this.mealTypeModel).subscribe(
response=>{
  this.result = response;
},
err=>{this.result = err.message;}

    );
  }

}
