import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorComponent } from './sensor/sensor.component';
import { SensorFeatureTypeComponent } from './sensor-feature-type/sensor-feature-type.component';
import { SensorLocationComponent } from './sensor-location/sensor-location.component';
import { SensorSubLocationComponent } from './sensor-sub-location/sensor-sub-location.component';
import { SensorMappingComponent } from './sensor-mapping/sensor-mapping.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ASensorDataMasterComponent } from './a-sensor-data-master/a-sensor-data-master.component';
import { WaterTankComponent } from './water-tank/water-tank.component';



@NgModule({
  declarations: [SensorComponent, SensorFeatureTypeComponent, SensorLocationComponent, SensorSubLocationComponent, SensorMappingComponent, ASensorDataMasterComponent, WaterTankComponent],
  imports: [
    CommonModule,RouterModule,FormsModule
    ,NgbModule
  ]
})
export class SensorDataModule { }
