import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ItemTrackerCurrentLocationModel } from 'src/app/models/ItemTracker/item-tracker-current-location-model';
import { ItemTrackerLocationModel } from 'src/app/models/ItemTracker/item-tracker-location-model';
import { ConfigLoaderService } from 'src/app/services/config-loader.service';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { ItemTrackerService } from 'src/app/services/item-tracker.service';
import { ElementFlags } from 'typescript';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-item-current-location',
  templateUrl: './item-current-location.component.html',
  styleUrls: ['./item-current-location.component.css']
})
export class ItemCurrentLocationComponent implements OnInit {

  public result = '';
  currentLocationModel: ItemTrackerCurrentLocationModel;
  model: any;
  searching = false;
  searchFailed = false;
  public itemTrackerCurrentLocationList:ItemTrackerCurrentLocationModel [];
  locationModel: ItemTrackerLocationModel;
  ProductAutoComplete:any;
  public qrwidth=150;
  public baseFileRef = "";
  public FileBasePathFold3 ="";
  public FileBasePathAsus6Z ="";
  public FileBasePathCommandCenter ="";
  public FileBasePathLenovoLaptop ="";
  public ProductRelativePath="/Scanned/Products/";
  public currentLocationResponse = '';
  constructor(private itemTrackerService: ItemTrackerService,private expenseManagerService: ExpenseManagerService,private configLoaderService: ConfigLoaderService,private titleService:Title) { 

    this.baseFileRef = this.configLoaderService.ItemTrackerBasePath;
    //this.baseFileRef = "https://13jun21.myzingonline.in/FileUploads/ItemTracker/";
    this.FileBasePathFold3 = this.configLoaderService.FileBasePathFold3;
    this.FileBasePathAsus6Z = this.configLoaderService.FileBasePathAsus6Z;
    this.FileBasePathCommandCenter = this.configLoaderService.FileBasePathCommandCenter;
    this.FileBasePathLenovoLaptop = this.configLoaderService.FileBasePathLenovoLaptop;
    this.titleService.setTitle("Manage Item Current Location");
  }

  ngOnInit() {
    this.locationModel = new ItemTrackerLocationModel();
    this.currentLocationModel = new ItemTrackerCurrentLocationModel();
    this.currentLocationModel.ItemTrackerCurrentLocationlist = [];
    this.itemTrackerService.GetAllCurrentLocation().subscribe(response =>{
      this.itemTrackerCurrentLocationList = response;
      this.itemTrackerCurrentLocationList.forEach(element => {
        element.LocationNameForQrCode = element.MainLocationUniqueName+"|"+element.SubLocationUniqueName;
      });
  });
  }


  // Current Location
  searchCurrentLocation = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.itemTrackerService.GetCurrentLocationByProductName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatterCurrentLocation = (x: { ProductName: string }) => x.ProductName;

selectedItemCurrentLocation(item) {
  //this.clickedItem=item.item;
  this.currentLocationModel = item.item;
  console.log(item);
  this.GetItemCurrentLocation(item.item.ProductName);
  //this.currentLocationModel.BrandId = item.item.BrandId;
  //this.currentLocationModel.Name = item.item.Name;
  //this.currentLocationModel.IsFood = item.item.IsFood;

}

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.itemTrackerService.GetLocationByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { LocationName: string }) => x.LocationName ;

  selectedItem(item) {
    //this.clickedItem=item.item;
    this.currentLocationModel.LocationID = item.item.LocationID;
    this.currentLocationModel.LocationName = item.item.LocationName +'|'+item.item.MainLocationName;
    //this.currentLocationModel.MainLocationID = item.item.MainLocationID;
    //this.currentLocationModel.MainLocationName = item.item.MainLocationName;
   
    console.log(item);
    //this.currentLocationModel.BrandId = item.item.BrandId;
    //this.currentLocationModel.Name = item.item.Name;
    //this.currentLocationModel.IsFood = item.item.IsFood;

  }
  //main location
  searchMainLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.itemTrackerService.GetMainLocationByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterMainLocation = (x: { LocationName: string }) => x.LocationName;

  selectedItemMainLocation(item) {
    //this.clickedItem=item.item;
    this.currentLocationModel.MainLocationID = item.item.LocationID;
    this.currentLocationModel.MainLocationName = item.item.LocationName;
    console.log(item);
    //this.locationModel.BrandId = item.item.BrandId;
    //this.locationModel.Name = item.item.Name;
    //this.locationModel.IsFood = item.item.IsFood;

  }



  //Product
  searchProduct = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.itemTrackerService.GetProductFromStockMapping(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )



formatterProduct = (x: { ProductName: string }) => x.ProductName;
selectedItemProduct(item) {
this.currentLocationModel.ItemID = item.item.ItemID;
this.currentLocationModel.ProductName = item.item.ProductName;
}

  onSubmit() {
    var me = this.currentLocationModel;
   // var meobj = JSON.parse(JSON.stringify(this.currentLocationModel));
    this.currentLocationModel.ItemTrackerCurrentLocationAutoComplete=null;
    this.currentLocationModel.ItemTrackerCurrentLocationProductAutoComplete=null;
    this.itemTrackerService.InsertOrUpdateCurrentLocation(this.currentLocationModel).subscribe(response => {
      this.result = response;
      this.itemTrackerService.GetAllCurrentLocation().subscribe(response =>{
        this.itemTrackerCurrentLocationList = response;
    });
    },
      err => {
        this.result = err.message;
      }
    );
    // var vendorApimodel = new VendorApiModel();
    // vendorApimodel.vendorAutoID = me.VendorAutoID;
    // vendorApimodel.vendorID = me.VendorID;
    // vendorApimodel.vendorName = me.VendorName;
    // this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
    //   response => {
    //     console.log(response);
    //     this.vendorModel = new VendorModel();
    //   }
    // );
  }
  GetItemCurrentLocation(productSearchName){
this.itemTrackerService.GetItemCurrentLocation(productSearchName).subscribe(response=>{
 this.currentLocationResponse = (<any>response).response;
});
  }

}
