import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ItemTrackerMasterComponent } from './item-tracker-master/item-tracker-master.component';
import { ItemLocationComponent } from './item-location/item-location.component';
import { ItemCurrentLocationComponent } from './item-current-location/item-current-location.component';
import { ItemStockMapperComponent } from './item-stock-mapper/item-stock-mapper.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ListItemsByCurrentLocationComponent } from './list-items-by-current-location/list-items-by-current-location.component';
import { ItemCurrentLocationDragDropComponent } from './item-current-location-drag-drop/item-current-location-drag-drop.component';
import { DragDropModule } from '@angular/cdk/drag-drop';



@NgModule({
  declarations: [ItemTrackerMasterComponent, ItemLocationComponent, ItemCurrentLocationComponent, ItemStockMapperComponent, ListItemsByCurrentLocationComponent, ItemCurrentLocationDragDropComponent],
  imports: [
    CommonModule,AgGridModule,NgbModule,RouterModule,FormsModule,BrowserAnimationsModule,QRCodeModule,DragDropModule
  ]
})
export class ItemTrackerModule { }
