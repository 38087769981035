import { Component, OnInit } from '@angular/core';
import { TestModel } from '../models/test-model';
import { TestTableService } from '../services/test-table.service';

@Component({
  selector: 'app-test-table',
  templateUrl: './test-table.component.html',
  styleUrls: ['./test-table.component.css']
})
export class TestTableComponent implements OnInit {
  public result = '';
  public testTable: TestModel;
  constructor(private testTableService: TestTableService) { }

  ngOnInit() {
    this.testTable = new TestModel();
  }
  onSubmit() {

    //   this.testTable.TestId=0;
    //   this.testTable.TestName="Angular Update Ex";
    var me = this.testTableService.saveExpense(this.testTable).subscribe(
      response => {
        this.result = response;
        console.log(response);
      });
  }
  GetTesttableData(event) {
    //just added console.log which will display the event details in browser on click of the button.
    //alert("Button is clicked");
    var me = this.testTableService.GetDataById(this.testTable.TestId).subscribe(
      response => {
        this.result = response;
          console.log(response);
      });
    console.log(event);
 }
}
