export class FitbitBodyModel {
    weight: Weight[];
}
    export class Weight {
        bmi: number;
        date: string;
        fat: number;
        logId: any;
        source: string;
        time: string;
        weight: number;
    }
 

