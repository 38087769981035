import { Component, OnInit } from '@angular/core';
import { QuestionAnswerModel } from 'src/app/models/Interview/QuestionAnswer/question-answer-model';
import { InterviewService } from 'src/app/services/interview.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SubjectModel } from 'src/app/models/Interview/Subject/subject-model';
import { TopicModel } from 'src/app/models/Interview/Topic/topic-model';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.css']
})
//https://www.npmjs.com/package/@kolkov/angular-editor
export class QuestionAnswerComponent implements OnInit {
  public result = '';
  model: any;
  searching = false;
  searchFailed = false;
  public htmlContent;
  questionanswermodel: QuestionAnswerModel;
  public questionAnswerList:QuestionAnswerModel [];
  public questionAnswerUntochedList:QuestionAnswerModel [];
  public questionAnswerTopicUntochedList:QuestionAnswerModel [];
  public subjectList: SubjectModel[];
  public topicList: TopicModel[];
  constructor(private interviewService: InterviewService,private titleService:Title) { this.titleService.setTitle("Manage Question & Answer");}

  ngOnInit(): void {
    this.questionanswermodel = new QuestionAnswerModel();
    this.questionanswermodel.QuestionAnswerlist = [];

    this.questionAnswerList=[];
    this.interviewService.GetAllQuestionAnswer().subscribe(response =>{
        this.questionAnswerList = response;
        this.questionAnswerUntochedList=response;
    });
    this.interviewService.GetAllSubject().subscribe(response => {
      this.subjectList = response;

    });
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.interviewService.GetQuestionAnswersByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { Name: string }) => x.Name;

  selectedItem(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.questionanswermodel = item.item;

    
  }


   //Topic Section
   searchTopic = (text$: Observable<string>) =>
   text$.pipe(
     debounceTime(300),
     distinctUntilChanged(),
     tap(() => this.searching = true),
     switchMap(term => term.length < 2 ? of([]):
       (this.interviewService.GetTopicsByNameSubjectId(term,this.questionanswermodel.SubjectID).pipe(
         tap(() => this.searchFailed = false),
         catchError(() => {
           this.searchFailed = true;
           return of([]);
         })))
     ),
     tap(() => this.searching = false)
   )
   formatterTopic = (x: { Name: string }) => x.Name;
 
   selectedItemTopic(item){
     //this.clickedItem=item.item;
     console.log(item);
     this.questionanswermodel.TopicID = item.item.TopicID;
     this.questionanswermodel.TopicName = item.item.TopicName;
    
   }
 //Subject Section
 searchSubject = (text$: Observable<string>) =>
 text$.pipe(
   debounceTime(300),
   distinctUntilChanged(),
   tap(() => this.searching = true),
   switchMap(term => term.length < 2 ? of([]):
     (this.interviewService.GetSubjectsByName(term).pipe(
       tap(() => this.searchFailed = false),
       catchError(() => {
         this.searchFailed = true;
         return of([]);
       })))
   ),
   tap(() => this.searching = false)
 )
 formatterSubject = (x: { Name: string }) => x.Name;

 selectedItemSubject(item){
   //this.clickedItem=item.item;
   console.log(item);
   this.questionanswermodel.SubjectID = item.item.SubjectID;
   this.questionanswermodel.SubjectName = item.item.SubjectName;

 }
  onSubmit() {
    var me = this.questionanswermodel;
    var apiModel = new QuestionAnswerModel();
    apiModel.QuestionAnswerID =  this.questionanswermodel.QuestionAnswerID;
    apiModel.Question = this.questionanswermodel.Question;
    apiModel.Answer =this.questionanswermodel.Answer;
    apiModel.TopicID = this.questionanswermodel.TopicID;
    this.interviewService.InsertOrUpdateQuestionAnswer(apiModel).subscribe(response => {
      this.result = response;
      this.interviewService.GetAllQuestionAnswer().subscribe(response =>{
        this.questionAnswerList = response;
        this.resetForm();
    });
    },
      err => {
        this.result = err.message;
        
      }
    );
  }
  filterTopic(subjectID) {
    console.log(subjectID);
    this.questionAnswerList = this.questionAnswerUntochedList;
    if (subjectID > 0) {
      this.questionAnswerList = this.questionAnswerList.filter(
        function (e) {
          return e.SubjectID == subjectID;
        }
      );
    }
    this.questionAnswerTopicUntochedList = this.questionAnswerList;
    this.interviewService.GetAllTopic().subscribe(response => {
      this.topicList = response.filter(
        function (e) {
          return e.SubjectID == subjectID;
        }
      );
    });
  }
  filterQuestionByTopic(topicId) {
    this.questionAnswerList = this.questionAnswerTopicUntochedList;
    if (topicId > 0) {
      this.questionAnswerList = this.questionAnswerList.filter(
        function (e) {
          return e.TopicID == topicId;
        }
      );
    }

  }
  getQuestionById(questionID){
    this.interviewService.GetQuestionAnswerById(questionID).subscribe(response=>{
      this.questionanswermodel = response;

    });

  }
  resetForm(){
    this.questionanswermodel = new QuestionAnswerModel();
  }
}
