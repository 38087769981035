import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { HabitCategoryModel } from '../models/HabitTracker/HabitCategory/habit-category-model';
import { HabitModel } from '../models/HabitTracker/Habit/habit-model';
import { HabitTypeModel } from '../models/HabitTracker/HabitType/habit-type-model';
import { HabitMappingModel } from '../models/HabitTracker/HabitMapping/habit-mapping-model';
import { HabitLoggerModel } from '../models/HabitTracker/HabitLogger/habit-logger-model';
import { HabitLogModel } from '../models/HabitTracker/habit-log-model';
import { HabitDataQueryModel } from '../models/HabitTracker/HabitLogger/habit-data-query-model';
import { ConfigLoaderService } from './config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class HabitTrackerService {
  //private baseUrl ="http://localhost:2718";
  private baseUrl = "http://admin-api.myzingonline.com";
  private insertorupdatehabitcategoryUrl = '/api/habit/insertorupdatehabitcategory';
  private gethabitcategoryallorbyidyUrl = '/api/habit/gethabitcategoryallorbyid?habitCategoryId=';
  private gethabitcategorybynameUrl = '/api/habit/gethabitcategorybyname?habitCategoryname=';

  private insertorupdatehabitUrl = '/api/habit/insertorupdatehabit';
  private gethabitallorbyidUrl = '/api/habit/gethabitallorbyid?habitId=';
  private gethabitbynameUrl = '/api/habit/gethabitbyname?habitName=';

  private insertorupdatehabittypeUrl = '/api/habit/insertorupdatehabittype';
  private gethabittypeallorbyidUrl = '/api/habit/gethabittypeallorbyid?habitTypeId=';
  private gethabittypebynameUrl = '/api/habit/gethabittypebyname?getHabitType=';

  private insertorupdatehabitmappingUrl = '/api/habit/insertorupdatehabitmapping';
  private gethabitmappingallorbyidUrl = '/api/habit/gethabitmappingallorbyid?habitMappingID=';
  private gethabitmappingbynameUrl = '/api/habit/gethabitmappingbyname?getHabitName=';

  private insertorupdatehabitloggerUrl = '/api/habit/insertorupdatehabitlogger';
  private gethabitdataUrl = '/api/habit/gethabitdata?date=';

  private getHabitLogsByTypeAndDateAndWeekNoUrl = '/api/habit/gethabitdata?date={0}&habitTypeId={1}&weekNo={2}';
  private postHabitLogsByTypeAndDateAndWeekNoUrl = '/api/habit/gethabitdatapost';
  private getAllHabitDataOrHabitDataByYearUrl = '';

  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) {
    this.baseUrl = this.configLoaderService.ApiBasePath;
    this.insertorupdatehabitcategoryUrl = this.baseUrl + '/api/habit/insertorupdatehabitcategory';
    this.gethabitcategoryallorbyidyUrl = this.baseUrl + '/api/habit/gethabitcategoryallorbyid?habitCategoryId=';
    this.gethabitcategorybynameUrl = this.baseUrl + '/api/habit/gethabitcategorybyname?habitCategoryname=';
    this.insertorupdatehabitUrl = this.baseUrl + '/api/habit/insertorupdatehabit';
    this.gethabitallorbyidUrl = this.baseUrl + '/api/habit/gethabitallorbyid?habitId=';
    this.gethabitbynameUrl = this.baseUrl + '/api/habit/gethabitbyname?habitName=';
    this.insertorupdatehabittypeUrl = this.baseUrl + '/api/habit/insertorupdatehabittype';
    this.gethabittypeallorbyidUrl = this.baseUrl + '/api/habit/gethabittypeallorbyid?habitTypeId=';
    this.gethabittypebynameUrl = this.baseUrl + '/api/habit/gethabittypebyname?getHabitType=';
    this.insertorupdatehabitmappingUrl = this.baseUrl + '/api/habit/insertorupdatehabitmapping';
    this.gethabitmappingallorbyidUrl = this.baseUrl + '/api/habit/gethabitmappingallorbyid?habitMappingID=';
    this.gethabitmappingbynameUrl = this.baseUrl + '/api/habit/gethabitmappingbyname?getHabitName=';
    this.insertorupdatehabitloggerUrl = this.baseUrl + '/api/habit/insertorupdatehabitlogger';
    this.gethabitdataUrl = this.baseUrl + '/api/habit/gethabitdata?date=';
    this.getHabitLogsByTypeAndDateAndWeekNoUrl = this.baseUrl + '/api/habit/gethabitdata?date={0}&habitTypeId={1}&weekNo={2}';
    this.postHabitLogsByTypeAndDateAndWeekNoUrl = this.baseUrl + '/api/habit/gethabitdatapost';

    this.getAllHabitDataOrHabitDataByYearUrl = this.baseUrl + '/api/habit/getallhabitdataorhabitdatabyyear?year=';

  }

  // Insert habit category
  Insertorupdatehabitcategory(vendorApiModel: HabitCategoryModel): Observable<any> {
    var response = this.http.post<any>(this.insertorupdatehabitcategoryUrl, vendorApiModel, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  GetHabitCategoryAllOrById(HabitCategoryId: number): Observable<HabitCategoryModel[]> {
    var response = this.http.get<any>(this.gethabitcategoryallorbyidyUrl + HabitCategoryId, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetHabitCategoryByName(name: string): Observable<HabitCategoryModel[]> {

    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders().set("jwt", token);
    var response = this.http.get<HabitCategoryModel[]>(this.gethabitcategorybynameUrl + name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  // Update habit

  Insertorupdatehabit(habitModel: HabitModel): Observable<any> {
    var response = this.http.post<any>(this.insertorupdatehabitUrl, habitModel, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  Gethabitallorbyid(habitId: number): Observable<HabitModel[]> {
    var response = this.http.get<any>(this.gethabitallorbyidUrl + habitId, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetHabitByName(name: string): Observable<HabitModel[]> {


    var response = this.http.get<HabitModel[]>(this.gethabitbynameUrl + name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  //Update HabitType
  Insertorupdatehabittype(habitTypeModel: HabitTypeModel): Observable<any> {
    var response = this.http.post<any>(this.insertorupdatehabittypeUrl, habitTypeModel, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  Gethabittypeallorbyid(habitTypeId: number): Observable<HabitTypeModel[]> {
    var response = this.http.get<any>(this.gethabittypeallorbyidUrl + habitTypeId, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetHabitTypeByName(name: string): Observable<HabitTypeModel[]> {
    var response = this.http.get<HabitTypeModel[]>(this.gethabittypebynameUrl + name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  // habit mapping
  Insertorupdatehabitmapping(habitMappingModel: HabitMappingModel): Observable<any> {
    var response = this.http.post<any>(this.insertorupdatehabitmappingUrl, habitMappingModel, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  Gethabitmappingallorbyid(habitMappingID: number): Observable<HabitMappingModel[]> {
    var response = this.http.get<any>(this.gethabitmappingallorbyidUrl + habitMappingID, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetHabitMappingByName(name: string): Observable<HabitMappingModel[]> {
    var response = this.http.get<HabitMappingModel[]>(this.gethabitmappingbynameUrl + name, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }

  //Habit logger
  Insertorupdatehabitlogger(habitLoggerModel: HabitLoggerModel): Observable<any> {
    habitLoggerModel.CreatedDisplay = habitLoggerModel.Created.toDateString();
    var response = this.http.post<any>(this.insertorupdatehabitloggerUrl, habitLoggerModel, { headers: this.getHeader() }).pipe(
      retry(2),
      tap(_ => console.log(response))
    );
    return response;
  }

  GetHabitLogsByTypeAndDateAndWeekNo(date: Date, habitTypeId: number, weekNo: number): Observable<HabitLogModel[]> {
    let params = new HttpParams();
    params.append("date", date.toDateString());
    params.append("habitTypeId", habitTypeId.toString());
    params.append("weekNo", weekNo.toString());
    // var response = this.http.get<HabitLogModel[]>(this.getHabitLogsByTypeAndDateAndWeekNoUrl,{headers:this.getHeader(),params:params}).pipe(
    //   tap(_ => console.log(response))
    // );
    // var response = this.http.get<HabitLogModel[]>(this.getHabitLogsByTypeAndDateAndWeekNoUrl+
    //   '?date='+date.toString()+'&habitTypeId='+habitTypeId.toString()+'&weekNo='+weekNo.toString(),{headers:this.getHeader()}).pipe(
    //   tap(_ => console.log(response))
    // );
    // this.getHabitLogsByTypeAndDateAndWeekNoUrl = this.getHabitLogsByTypeAndDateAndWeekNoUrl
    // .replace('{0}',date.toISOString()).replace('{1}',habitTypeId.toString()).replace('{2}',weekNo.toString());
    var newUrl = this.getHabitLogsByTypeAndDateAndWeekNoUrl;

    //newUrl= newUrl.replace('{0}',date.toISOString());
    newUrl = newUrl.replace('{0}', date.toDateString());
    newUrl = newUrl.replace('{1}', habitTypeId.toString());
    newUrl = newUrl.replace('{2}', weekNo.toString());

    var response = this.http.get<HabitLogModel[]>(newUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetHabitLogsByTypeAndDateAndWeekNoPost(date: Date, habitTypeId: number, weekNo: number): Observable<HabitLogModel[]> {
    let params = new HttpParams();
    params.append("date", date.toDateString());
    params.append("habitTypeId", habitTypeId.toString());
    params.append("weekNo", weekNo.toString());
    // var response = this.http.get<HabitLogModel[]>(this.getHabitLogsByTypeAndDateAndWeekNoUrl,{headers:this.getHeader(),params:params}).pipe(
    //   tap(_ => console.log(response))
    // );
    // var response = this.http.get<HabitLogModel[]>(this.getHabitLogsByTypeAndDateAndWeekNoUrl+
    //   '?date='+date.toString()+'&habitTypeId='+habitTypeId.toString()+'&weekNo='+weekNo.toString(),{headers:this.getHeader()}).pipe(
    //   tap(_ => console.log(response))
    // );
    // this.getHabitLogsByTypeAndDateAndWeekNoUrl = this.getHabitLogsByTypeAndDateAndWeekNoUrl
    // .replace('{0}',date.toISOString()).replace('{1}',habitTypeId.toString()).replace('{2}',weekNo.toString());
    var newUrl = this.getHabitLogsByTypeAndDateAndWeekNoUrl;

    //newUrl= newUrl.replace('{0}',date.toISOString());
    newUrl = newUrl.replace('{0}', date.toDateString());
    newUrl = newUrl.replace('{1}', habitTypeId.toString());
    newUrl = newUrl.replace('{2}', weekNo.toString());

    var habitqueryModel = new HabitDataQueryModel();
    habitqueryModel.Date = date;
    habitqueryModel.DateToDisplay = date.toDateString();
    habitqueryModel.HabitTypeID = habitTypeId;
    habitqueryModel.WeekNo = weekNo;

    var response = this.http.post<any>(this.postHabitLogsByTypeAndDateAndWeekNoUrl, habitqueryModel, { headers: this.getHeader() }).pipe(
      retry(2),
      tap(_ => console.log(response))
    );

    // var response = this.http.get<HabitLogModel[]>(newUrl,{headers:this.getHeader()}).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetAllHabitDataOrHabitDataByYear(year: number): Observable<HabitLogModel[]> {
    var response = this.http.get<any>(this.getAllHabitDataOrHabitDataByYearUrl+year, { headers: this.getHeader() }).pipe(
      retry(2),
      tap(_ => console.log(response))
    );
    return response;
  }
  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }  
}
