import { Component, OnInit } from '@angular/core';
import { RouterLinkInfoService } from 'src/app/services/router-link-info.service';

@Component({
  selector: 'app-amaster-sensor',
  templateUrl: './amaster-sensor.component.html',
  styleUrls: ['./amaster-sensor.component.css']
})
export class AMasterSensorComponent implements OnInit {

  constructor(private routerinfoservice:RouterLinkInfoService) { }

  ngOnInit() {
  }
  onActivate(componentRef){
    try{
      var currentUrl = window.location.pathname;
      // var me =  componentRef.getPageName();
      // this.CData =me;
      $('#breadItem').text(this.routerinfoservice.getPageName(currentUrl));
    }
    catch(ex){
  console.log('Not set',ex);
    }
}
}
