export class ItemTrackerLocationModel {
    public LocationID: number;
    public LocationName: string;
    public LocationUniqueName: string;
    public MainLocationID: number;
    public UserID: number;
    public IsAvailableToPublic: boolean;
    public Created: Date;
    public Updated: Date;

    public ItemTrackerLocationAutoComplete:any;
    public ItemTrackerLocationlist:ItemTrackerLocationModel[];

    public ItemTrackerMainLocationAutoComplete:any;
    public ItemTrackerMainLocationlist:ItemTrackerLocationModel[];
    public MainLocationName:string;

    
}
