import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ItemTrackerLocationModel } from 'src/app/models/ItemTracker/item-tracker-location-model';
import { ItemTrackerService } from 'src/app/services/item-tracker.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-item-location',
  templateUrl: './item-location.component.html',
  styleUrls: ['./item-location.component.css']
})
export class ItemLocationComponent implements OnInit {

  public result = '';
  locationModel: ItemTrackerLocationModel;
  model: any;
  searching = false;
  searchFailed = false;
  public itemTrackerLocationList:ItemTrackerLocationModel [];
  constructor(private itemTrackerService: ItemTrackerService,private titleService:Title) {  this.titleService.setTitle("Manage Location");}

  ngOnInit() {
    this.locationModel = new ItemTrackerLocationModel();
    this.locationModel.ItemTrackerLocationlist = [];
    this.itemTrackerService.GetAllLocation().subscribe(response =>{
      this.itemTrackerLocationList = response;
  });
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.itemTrackerService.GetLocationByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { LocationName: string,LocationUniqueName:string }) => x.LocationName;

  selectedItem(item) {
    //this.clickedItem=item.item;
    this.locationModel = item.item;
    console.log(item);
    //this.locationModel.BrandId = item.item.BrandId;
    //this.locationModel.Name = item.item.Name;
    //this.locationModel.IsFood = item.item.IsFood;

  }

  //main location
  searchMainLocation = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.itemTrackerService.GetLocationByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterMainLocation = (x: { LocationName: string }) => x.LocationName;

  selectedItemMainLocation(item) {
    //this.clickedItem=item.item;
    this.locationModel.MainLocationID = item.item.MainLocationID;
    this.locationModel.MainLocationName = item.item.MainLocationName;
    console.log(item);
    //this.locationModel.BrandId = item.item.BrandId;
    //this.locationModel.Name = item.item.Name;
    //this.locationModel.IsFood = item.item.IsFood;

  }
  onSubmit() {
    var me = this.locationModel;
   // var meobj = JSON.parse(JSON.stringify(this.locationModel));
    this.locationModel.ItemTrackerLocationAutoComplete=null;
    this.itemTrackerService.InsertOrUpdateLocation(this.locationModel).subscribe(response => {
      this.result = response;
      this.itemTrackerService.GetAllLocation().subscribe(response =>{
        this.itemTrackerLocationList = response;
    });
    },
      err => {
        this.result = err.message;
      }
    );
    // var vendorApimodel = new VendorApiModel();
    // vendorApimodel.vendorAutoID = me.VendorAutoID;
    // vendorApimodel.vendorID = me.VendorID;
    // vendorApimodel.vendorName = me.VendorName;
    // this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
    //   response => {
    //     console.log(response);
    //     this.vendorModel = new VendorModel();
    //   }
    // );
  }
}
