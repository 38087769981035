
  <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
    <!-- <div class="form-row">
      <div class="form-group col-md-12">
        <h2>{{foodModel.Name | uppercase}} Details</h2>
      </div>
    </div> -->
    <div class="form-row" >
      <div class="form-group col-md-2">
        <label for="disabledTextInput">Id</label>
        <input type="text" [(ngModel)]="foodModel.FoodId" name="food.FoodId" placeholder="id" class="form-control" disabled/>
        <!-- <span>id: </span>{{foodModel.FoodId}} -->
      </div>
      <div class="form-group col-md-4 col-lg-4">
        <label for="disabledTextInput">Name</label>
        <input [(ngModel)]="foodModel.Name" name="foodModel.Name" placeholder="name" class="form-control" disabled/>
      </div>
    </div>
    <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #AddFile >
      <legend class="section-legend-border">Add File</legend>
    <div class="form-row">
      <div class="form-group col-md-2">
        <button type="button" (click)="addFile()">Add file</button>
      </div>
      <div class="form-group col-md-6">
      <fieldset #cmp  #group="ngModelGroup" ngModelGroup="UploadFiles-{{index}}" 
      *ngFor="let foodImage of foodModel.FoodImageModels; let index = index">
      <div class="inputFilesSection">
      <input type="file" name="Name" [(ngModel)]="foodImage.Name" required>
    </div>
      </fieldset>
    </div>
    </div>
    </fieldset>

    <!-- <button type="submit">Submit</button> -->
  </form>
  <div>Is image saved {{isImageSaved}}</div>

