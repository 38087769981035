import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { AccountApiModel } from 'src/app/models/ExpenseManager/Accounts/account-api-model';
import { AccountModel } from 'src/app/models/ExpenseManager/Accounts/account-model';
import { FileModel } from 'src/app/models/ExpenseManager/File/file-model';
import { ConfigLoaderService } from 'src/app/services/config-loader.service';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {

  public fileModel: FileModel;
  accountModel: AccountModel;
  model: any;
  searching = false;
  searchFailed = false;
  result='';
baseFileRef="http://readyshare.routerlogin.net/shares/USB_Storage";
  constructor(private expenseManagerService: ExpenseManagerService,private configLoaderService: ConfigLoaderService,private titleService:Title) { 
    this.baseFileRef=this.configLoaderService.FileBasePath;
    this.titleService.setTitle("File Account Mapper");
  } 

  ngOnInit() {
    this.fileModel = new FileModel();
    this.fileModel.Filelist = [];
this.accountModel = new AccountModel();
    
    this.expenseManagerService.GetFileAllOrByID(0).subscribe(response =>{
      this.fileModel.Filelist = response;
  }); 
  }
  searchFile = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetFilesByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )



  formatterFile = (x: { FileName: string,AccountCode:string }) => x.FileName;

  selectedItemFile(item) {
    //this.clickedItem=item.item;
    console.log(item);
    //this.fileModel.FileAutoID = item.item.FileAutoID;
    this.fileModel.FileID = item.item.FileID;
    this.fileModel.FileName = item.item.FileName;
    this.fileModel = item.item;
  }


  /*Account */
search = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]) :
    (this.expenseManagerService.GetAccountByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)
formatter = (x: { AccountName: string }) => x.AccountName;

selectedItem(item) {
//this.clickedItem=item.item;
console.log(item);
var accountApiModel = new AccountApiModel();
this.accountModel.AccountID = item.item.AccountID;
this.fileModel.AccountCode = item.item.AccountCode;
this.accountModel.AccountName = item.item.AccountName;

this.accountModel.AccountMasterID = item.item.AccountMasterID;
this.accountModel.AccountMasterName = item.item.AccountMasterName;
this.accountModel.CashBankOther = item.item.CashBankOther;
this.accountModel.AccountStatus = item.item.AccountStatus;
this.accountModel.Receipt = item.item.Receipt;
this.accountModel.Active = item.item.Active;
this.accountModel.AccountAutoID = item.item.AccountAutoID;
}
  onSubmit() {
    var me = this.fileModel;
    var apimodel = new FileModel();
    apimodel.FileID = me.FileID;
    apimodel.AccountCode = me.AccountCode;
    apimodel.RelativeFilePath = me.RelativeFilePath.replace(/\\/g, "/");
    apimodel.FullPath = me.FullPath;;
    apimodel.FileName = apimodel.RelativeFilePath.substring(apimodel.RelativeFilePath.lastIndexOf('/')+1);
    var me1 = apimodel.RelativeFilePath.replace(/\//g, ":"); 
    this.expenseManagerService.InsertOrUpdateFile(apimodel).subscribe(
      response => {
        console.log(response);
        this.fileModel = new FileModel();
        this.expenseManagerService.GetFileAllOrByID(0).subscribe(response =>{
          this.result="Success";
          this.fileModel.Filelist = response;
      }); 

      }
    );
  }
  //Converting \\ slashes to // for http protocol
  relativePathChange(){
    this.fileModel.RelativeFilePath =  this.fileModel.RelativeFilePath.replace(/\\/g, "/");
    this.fileModel.FileName = this.fileModel.RelativeFilePath.substring(this.fileModel.RelativeFilePath.lastIndexOf('/')+1);
  }
  getPageName(): string {
    console.log("works");
    return 'File';
  }

}
