import { Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-canvas-tracing',
  templateUrl: './canvas-tracing.component.html',
  styleUrls: ['./canvas-tracing.component.css']
})
export class CanvasTracingComponent implements OnInit,AfterViewInit  {
  @ViewChild('myCanvas') c: ElementRef<HTMLCanvasElement>;

  cx: CanvasRenderingContext2D;
  mousedown = false;
  oldx = null;
  oldy = null;
  pixels = null;
  letterpixels = null;
  constructor() { }
  ngAfterViewInit(): void {

    this.cx = this.c.nativeElement.getContext('2d');
    this.setupCanvas('ㅊ');

    //Touch
    this.c.nativeElement.addEventListener('touchstart', (event)=>{
      this.mousedown = true;
      event.preventDefault();
    });
    this.c.nativeElement.addEventListener('touchend', (event)=>{
      this.mousedown = false;
      this.pixelthreshold();
      event.preventDefault();
    });
    this.c.nativeElement.addEventListener('touchmove', (event)=>{
      var rect = (<any>event.target).getBoundingClientRect();
      var x = event.targetTouches[0].pageX - rect.left;
      var y = event.targetTouches[0].pageY - rect.top;
    //   //var x = event.clientX;
    //   //var y = event.clientY; 
    //   var x = event.targetTouches;
    //   var y = event.offsetY;
    if (this.mousedown) {
      this.paint(x, y);
    }
    });

    //Mouse
    // this.c.nativeElement.addEventListener('mousedown', (event)=>{
    //   this.mousedown = true;
    //   event.preventDefault();
    // });
    // this.c.nativeElement.addEventListener('mouseup', (event)=>{
    //   this.mousedown = false;
    //   this.pixelthreshold();
    //   event.preventDefault();
    // });
    // this.c.nativeElement.addEventListener('mousemove', (event)=>{
    //   //var x = event.clientX;
    //   //var y = event.clientY; 
    //   var x = event.offsetX;
    //   var y = event.offsetY;
    // if (this.mousedown) {
    //   this.paint(x, y);
    // }
    // });

    //Simple
    // this.c.nativeElement.addEventListener('mousemove', (event)=>{
    //   var x = event.clientX-255;
    //   var y = event.clientY-255;
    //   var x = event.offsetX;
    //   var y = event.offsetY;
    //   this.paint(x, y);
    // });
  }

  ngOnInit(): void {

  }
  setupCanvas(letter) {
    this.c.nativeElement.height = 280;
    this.c.nativeElement.width = 280;
    this.cx.lineWidth = 20;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = 'rgb(0, 0, 50)';
    this.cx.font = 'bold 150px helvetica';
   // this.cx.font = 'bold 200px Custom-Kr';
    this.cx.fillStyle = 'rgb(255, 0, 0)';
    this.cx.textBaseline = 'middle';
    //this.drawletter('ㅊ');
    this.drawletter(letter);
    this.pixels = this.cx.getImageData(0, 0, this.c.nativeElement.width, this.c.nativeElement.height);
    this.letterpixels = this.getpixelamount(255, 0, 0);
  }
  // setupCanvas() {
  //   this.c.nativeElement.height = 480;
  //   this.c.nativeElement.width = 320;
  //   this.cx.lineWidth = 20;
  //   this.cx.lineCap = 'round';
  //   this.cx.strokeStyle = 'rgb(0, 0, 50)';
  // }
  drawletter(letter) {
    var centerx = (this.c.nativeElement.width - this.cx.measureText(letter).width) / 2;
    var centery = this.c.nativeElement.height / 2;
    this.cx.fillText(letter, centerx, centery);
  };
  showerror(error) {
    this.mousedown = false;
    alert(error);
  };
 
  getpixelamount(r, g, b) {
    var pixels = this.cx.getImageData(0, 0, this.c.nativeElement.width, this.c.nativeElement.height);
    var all = pixels.data.length;
    var amount = 0;
    for (var i = 0; i < all; i += 4) {
      if (pixels.data[i] === r &&
          pixels.data[i + 1] === g &&
          pixels.data[i + 2] === b) {
        amount++;
      }
    }
    return amount;
  };
   paint(x, y) {
    var colour = this.getpixelcolour(x, y);
    if (colour.a === 0) {
      this.showerror('you are outside');
    } else {
      this.cx.beginPath();
      if (this.oldx > 0 && this.oldy > 0) {
        //cx.moveTo(oldx, oldy);
      }
      this.cx.lineTo(x, y);
      this.cx.stroke();
      this.cx.closePath();
      this.oldx = x;
      this.oldy = y;
    }
  };
  // paint(x, y) {
  //   this.cx.beginPath();
  //   this.cx.moveTo(x, y);
  //   this.cx.lineTo(x, y);
  //   this.cx.stroke();
  //   this.cx.closePath();
  // }
  getpixelcolour(x, y) {
    var index = ((y * (this.pixels.width * 4)) + (x * 4));
    return {
      r: this.pixels.data[index],
      g: this.pixels.data[index + 1],
      b: this.pixels.data[index + 2],
      a: this.pixels.data[index + 3]
    };
  }
  pixelthreshold() {
    if (this.getpixelamount(0, 0, 50) / this.letterpixels > 0.53) {//.35
     alert('you got it!');
    }
  };
 
  changeCharachter(){
    //https://www.loc.gov/marc/specifications/specchareacc/KoreanHangul.html
    var chars=['ㄱ','ㄴ','ㄷ','ㄹ','ㅁ','ㅂ','ㅅ','ㅇ'];
    const randomElement = chars[Math.floor(Math.random() * chars.length)];
   // this.cx.clearRect(0, 0, this.c.nativeElement.width, this.c.nativeElement.height);
   /// this.drawletter('모');
   this.setupCanvas(randomElement);
  }

}
