import { DatePipe } from '@angular/common';
//import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ExerciseModel, ExerciseViewModel } from 'src/app/models/FitBit/exercise-model';
import { FitBitService } from 'src/app/services/fit-bit.service';

@Component({
  selector: 'app-fit-bit-activity',
  templateUrl: './fit-bit-activity.component.html',
  styleUrls: ['./fit-bit-activity.component.css']
})
export class FitBitActivityComponent implements OnInit, OnChanges {
  exerciseModel: ExerciseModel;
  exerciseViewModel: ExerciseViewModel[];
  weightExercise: any;
  @Input() DateFromOutside: string;
  constructor(private fitbitService: FitBitService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.exerciseViewModel = [];
    var currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() - 1);
    var previousDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    if (this.DateFromOutside && this.DateFromOutside.length > 0) {
      previousDate = this.DateFromOutside;
    }

    //this.fetchData(previousDate);

  }
  fetchData(previousDate) {
   
    this.fitbitService.GetFitbitExerciseData(previousDate).subscribe(response => {
      this.exerciseViewModel = [];
      this.exerciseModel = response;
      console.log(this.exerciseModel);

      this.exerciseModel.activities.forEach(element => {
        var model = new ExerciseViewModel();
        model.ExerciseName = element.name;
        model.Calories = element.calories;
        model.Duration = Math.round(element.duration / 60000);
    
        if (element.activityParentName === 'Weights') {
          model.FafaClassIcon = "fas fa-dumbbell";
        }
        else if (element.activityParentName === 'Kickboxing') {
          model.FafaClassIcon = "fas fa-hand-rock";
        }
        else if (element.activityParentName === 'Tennis') {
          model.FafaClassIcon = "fas fa-table-tennis";
        }
        else if (element.activityParentName === 'Martial Arts') {
          model.FafaClassIcon = "fas fa-skating";
        }
        else if (element.activityParentName === 'Treadmill') {
          model.FafaClassIcon = "fas fa-running";
        }
        else if (element.activityParentName === 'Walk') {
          
          model.FafaClassIcon = "fas fa-walking";
        }
        else if (element.activityParentName === 'Boxing') {
          model.FafaClassIcon = "fas fa-mitten";
        }
        var filtermodels = this.exerciseViewModel.filter(
          function (e) {
            return e.ExerciseName === element.name;
          }
        );
        var found=false;
        for(var exModel of this.exerciseViewModel){
          if(exModel.ExerciseName == element.name){
            exModel.Calories+= model.Calories;
            exModel.Duration +=model.Duration;
            found=true;break;
          }
        }
        if(!found){
          this.exerciseViewModel.push(model);
        }
      });
      var weightFilterResult = this.exerciseModel.activities.filter(function (item) {
        return item.activityParentName === 'Weights';
      });

      this.weightExercise = weightFilterResult[0];
      if (this.weightExercise) {
        this.weightExercise.duration = Math.round(this.weightExercise.duration / 60000);
        console.log(this.weightExercise);
      }

    });
  }
  ngOnChanges() {
    // create header using child_id
    this.fetchData(this.DateFromOutside);

  }

}
