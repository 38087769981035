import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ConfigLoaderService } from './config-loader.service';
import { TestModel } from '../models/test-model';
@Injectable({
  providedIn: 'root'
})
export class TestTableService implements OnInit {
  private baseUrl ="http://admin-api.myzingonline.com";
  private getDataByIdUrl = '/api/test/getdatabyid?id=';
  private saveTestTableUrl = '/api/test/savetesttable';

  constructor(private http: HttpClient,private configLoaderService: ConfigLoaderService) { 
    this.baseUrl = this.configLoaderService.ApiBasePath;
  }
  
  ngOnInit(){
  
    
      }
      GetDataById(TestTableID: number): Observable<any> {
        var response = this.http.get<any>(this.baseUrl+"/"+this.getDataByIdUrl+TestTableID,{headers:this.getHeader()}).pipe(
          tap(_ => console.log(response))
        );
        return response;
      } 
      saveExpense(testModel: TestModel): Observable<any> {
        var response = this.http.post<any>(this.baseUrl+"/"+this.saveTestTableUrl, testModel,{headers:this.getHeader()}).pipe(
          tap(_ => console.log(response))
        );
        return response;
      }
      getHeader(){
       // var token = sessionStorage.getItem('jwtToken');
       var token = 'jwtToken';
        let headers = new HttpHeaders().set("jwt", token);
        return headers;
      }  
}
