<p>Url Ex. itemlistbylocation?locationname=Gaming_Wall_Almirah_Last_First_Shelf</p>
<p>list-items-by-current-location works!</p>

<div class="row" id="Item-Current-List">
    <div class="col-sm-12 example-section">
      <div class="">
        <p class="section-heading-text">Qr code Size For ex 150</p>
        <p>   <input type="text" [(ngModel)]="qrwidth" name="qrwidth" placeholder="Qr code Size" class="form-control"></p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Item Current Loc ID</th>
              <th scope="col">Item ID</th>
              <th scope="col">Prd Name</th>
              <th scope="col">Location ID</th>
              <th scope="col">Location Name</th>
              <th scope="col">Main Location ID</th> 
              <th scope="col">Main Location Name</th>
              <th scope="col">Location UniqueName</th>
              <th scope="col">LocationNameForQrCode</th>
              <th scope="col">Product UniqueName For Qr Code</th>
              <th scope="col">Product UniqueName </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itemTrackerCurrentLocationList">
              <th scope="row">{{item.ItemCurrentLocationID}}</th>
              <td>{{item.ItemID}}</td>
              <td>{{item.ProductName}}</td>
              <td >{{item.LocationID}}</td>
              <td >{{item.LocationName}}</td>
              <td>{{item.MainLocationID}}</td> 
              <td>{{item.MainLocationName}}</td>
              <td>
                <qr-code [value]="item.LocationNameForQrCode" [size]="qrwidth"></qr-code>
              </td>
              <td>{{item.LocationNameForQrCode}}</td>
              <td>
                <qr-code [value]="item.ProductUniqueName" [size]="qrwidth"></qr-code>
              </td>
              <td>
                {{item.ProductUniqueName}}
              </td>
          </tbody>
        </table>
      </div>
    </div>
    </div>