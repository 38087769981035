import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { Week } from 'src/app/models/HabitTracker/Week';
import { HabitLogModel } from 'src/app/models/HabitTracker/habit-log-model';
//import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
//import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
//import { Color, Label, BaseChartDirective, SingleDataSet } from 'ng2-charts';
//import { HabitTrackerService } from 'src/app/service/habit-tracker.service';
import { HabitTypeModel } from 'src/app/models/HabitTracker/HabitType/habit-type-model';
import { HabitLoggerModel } from 'src/app/models/HabitTracker/HabitLogger/habit-logger-model';
import { HabitTrackerService } from 'src/app/services/habit-tracker.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationPostModel } from 'src/app/models/Notification/notification-post-model';
import { ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-loghabit',
  templateUrl: './loghabit.component.html',
  styleUrls: ['./loghabit.component.css']
})
export class LoghabitComponent implements OnInit {
  public resultLogger = '';
  public LogDateDisplay: any;
  public LogTimeDisplay: any;
  public days: Week[];
  public habitLogs: HabitLogModel[];
  public habitLogsClone: HabitLogModel[];
  public habitTypes: HabitTypeModel[];
  public habitLogsFromService: HabitLogModel[];
  public searchTerm = '';

  constructor(private habitTrackerService: HabitTrackerService, private elementRef: ElementRef
    , private notificationService: NotificationService, private route: ActivatedRoute,private titleService:Title
  ) {  this.titleService.setTitle("Log Habit");}

  ngOnInit() {

    this.days = [];
    this.habitLogs = [];
    this.habitTypes = [];
    this.habitLogsFromService = [];

    this.habitLogsClone = [];
    // Search


    var currentDate = new Date();
    //setting calender current date and time
    this.LogDateDisplay = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.LogTimeDisplay = { hour: currentDate.getHours(), minute: currentDate.getMinutes() };

    //getting all the habit types like daily weekly etc
    this.habitTrackerService.Gethabittypeallorbyid(0).subscribe(response => {
      for (var i = 0; i < response.length; i++) {
        var habittypeModel = new HabitTypeModel();
        if (i === 0) {
          habittypeModel.Checked = true;
        }
        else {
          habittypeModel.Checked = false;
        }

        habittypeModel.HabitTypeId = response[i].HabitTypeId;
        habittypeModel.HabitTypeName = response[i].HabitTypeName;
        this.habitTypes.push(habittypeModel)
      }
      // this.habitTypes = response;
      // this.habitTypes[0].Checked=true;
      // console.log(response);
      var selectedHabitTypeid = this.habitTypes.filter(it => it.Checked == true)[0].HabitTypeId;
      this.getAllHabitByHabitTypeId(selectedHabitTypeid);

      //this.getAllHabitByHabitTypeId(1);

    },
      err => {
        console.log(err);
        this.resultLogger = err.message;
      }
    );

   


  }
  getAllHabitByHabitTypeId(habitTypeId) {

    this.habitLogs = [];
    // Get all the habit master data
    this.habitTrackerService.Gethabitmappingallorbyid(0).subscribe(response => {
      for (var i = 0; i < response.length; i++) {
        var habitModel = new HabitLogModel();

        habitModel.HabitId = response[i].HabitId;
        habitModel.HabitName = response[i].HabitName;;
        habitModel.NumberOfTimes = 0;
        habitModel.NumberOfMinutes = 0;
        habitModel.NumberOfHours = 0;
        habitModel.HabitTypeId = response[i].HabitTypeId;
        habitModel.HabitTypeName = response[i].HabitTypeName;
        habitModel.ToolTipToDo = "Hour: " + response[i].NumberOfHours + " Min: " + response[i].NumberOfMinutes
          + " Times: " + response[i].NumberOfTimes;
        ;
        habitModel.HabitNameDisplay = habitModel.HabitName.length > 10 ? habitModel.HabitName.substr(0, 10) + '..' : habitModel.HabitName;


        //habitModel.Completed = Math.floor(Math.random()*(100-1)+1)
        this.habitLogs.push(habitModel);
      }
      this.habitLogs = this.habitLogs.filter(it => it.HabitTypeId == habitTypeId);
      console.log(response);

      //Get progress for habit log
      var newDate = new Date(this.LogDateDisplay.year, this.LogDateDisplay.month - 1
        , this.LogDateDisplay.day,
        this.LogTimeDisplay.hour, this.LogTimeDisplay.minute, 0, 0);
      var currentWeekNo = 1;

      try { currentWeekNo = this.days.filter(it => it.Checked == true)[0].Value; } catch (err) { }



      this.getHabitLoggedDataFromService(newDate, habitTypeId, currentWeekNo);

    },
      err => {
        console.log(err);
        this.resultLogger = err.message;
      }
    );
  }

  onHabitTypeClick(event) {
    this.days = [];
    var newDate = new Date(this.LogDateDisplay.year, this.LogDateDisplay.month - 1
      , this.LogDateDisplay.day,
      this.LogTimeDisplay.hour, this.LogTimeDisplay.minute, 0, 0);
    //var currentDate = new Date();
    console.log(event.target.getAttribute('data-id'))
    var currentHabitTypeId = event.target.getAttribute('data-id');
    var objIndex = this.habitTypes.findIndex((obj => obj.HabitTypeId == currentHabitTypeId))
    this.habitTypes[objIndex].Checked = true;
    for (var i = 0; i < this.habitTypes.length; i++) {
      if (this.habitTypes[i].HabitTypeId !== parseInt(currentHabitTypeId)) {
        this.habitTypes[i].Checked = false
      }
    }

    //this.getAllHabitByHabitTypeId(currentHabitTypeId);
    if (event.target.innerText.trim() === "Daily") {
      // var date = new Date();

      // var month = date.getMonth() + 1;
      // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDate();
      // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      // for (var i = firstDay; i <= lastDay; i++) {
      //   var day = new Day();
      //   day.DisplayText = i + "";
      //   day.Value = i;

      //   this.days.push(day);
      // }

      // this.transactionModel.VoucherDate = newDate;
      // this.getHabitLoggedDataFromService(newDate,currentHabitTypeId,1);
    }
    else if (event.target.innerText.trim() === "Weekly") {
      this.days = [];
      var date = new Date();
      // var date = new Date("2020-05-11");
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var noOfWeek = this.getNumber(year, month);


      for (var i = 1; i <= noOfWeek; i++) {
        var day = new Week();
        if (this.getCurrentWeekNo(date) == i) {
          day.Checked = true;
        }
        //day.DisplayText = "W"+i + "-"+this.getCurrentWeekNo(date);
        day.DisplayText = "W" + i;
        day.Value = i;
        this.days.push(day);
      }
    }
    // Getting current habit data with type

    // var currentWeekNo = this.days.filter(it => it.Checked==true)[0].Value;

    this.getAllHabitByHabitTypeId(currentHabitTypeId);

    //this.getHabitLoggedDataFromService(newDate,currentHabitTypeId,currentWeekNo);
  }
  onWeekClick(event) {


    var currentWeekId = event.target.getAttribute('data-id');

    var objIndex = this.days.findIndex((obj => obj.Value == currentWeekId))
    this.days[objIndex].Checked = true;
    for (var i = 0; i < this.days.length; i++) {
      if (this.days[i].Value !== parseInt(currentWeekId)) {
        this.days[i].Checked = false
      }
    }

    // this.habitLogs = [];
    // for (var i = 1; i <= 4; i++) {
    //   var habitModel = new HabitLogModel();
    //   habitModel.HabitId = i;
    //   habitModel.HabitName = "DailyHabit-" + i;
    //   //habitModel.Completed = Math.floor(Math.random()*(100-1)+1)
    //   this.habitLogs.push(habitModel);
    // }
    var newDate = new Date(this.LogDateDisplay.year, this.LogDateDisplay.month - 1
      , this.LogDateDisplay.day,
      this.LogTimeDisplay.hour, this.LogTimeDisplay.minute, 0, 0);
    var selectedHabitTypeid = this.habitTypes.filter(it => it.Checked == true)[0].HabitTypeId;
    this.getHabitLoggedDataFromService(newDate, selectedHabitTypeid, currentWeekId);

  }
  /**
 * Returns count of weeks for year and month
 *
 * @param {Number} year - full year (2016)
 * @param {Number} month_number - month_number is in the range 1..12
 * @returns {number}
 */
  getNumber(year, month_number) {
    var firstOfMonth = new Date(year, month_number - 1, 1);
    var day = firstOfMonth.getDay() || 6;
    day = day === 1 ? 0 : day;
    if (day) { day-- }
    var diff = 7 - day;
    var lastOfMonth = new Date(year, month_number, 0);
    var lastDate = lastOfMonth.getDate();
    if (lastOfMonth.getDay() === 1) {
      diff--;
    }
    var result = Math.ceil((lastDate - diff) / 7);
    return result + 1;
  }

  getCurrentWeekNo(date) {
    // let adjustedDate = date.getDate()+date.getDay();
    //   let prefixes = ['0','1', '2', '3', '4', '5'];
    //   return (parseInt(prefixes[0 | adjustedDate / 7])+1);
    //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    //return Math.ceil((date.getDate() + firstDay)/7);
    return Math.ceil(date.getDate() / 7);

  }

  // get habit data
  getHabitLoggedDataFromService(logDate, habittypeId, weekNo) {
    var currentDate = new Date();

    //get habit data
    this.habitTrackerService.GetHabitLogsByTypeAndDateAndWeekNoPost(logDate, habittypeId, weekNo).subscribe(response => {
      this.habitLogsFromService = [];
      this.habitLogsFromService = response;
      //this.habitTypes = response;

      for (var i = 0; i < this.habitLogs.length; i++) {
        this.habitLogs[i].Completed = 0;
        for (var j = 0; j < this.habitLogsFromService.length; j++) {
          if (this.habitLogs[i].HabitId == this.habitLogsFromService[j].HabitId) {
            this.habitLogs[i] = this.habitLogsFromService[j];
            this.habitLogs[i].ToolTipToDo = "Hour: " + response[j].NumberOfHours + " Min: " + response[j].NumberOfMinutes
              + " Times: " + response[j].NumberOfTimes;
            // this.habitLogs[i].ToolTipDoneSoFar="Total Mins To do"+response[i].TotalMinsToDo+" <br /> Min:"+response[i].NumberOfMinutesDone+" Hour:"+response[i].NumberOfHoursDone
            // +" Total Mins Done:"+response[i].TotalMinsDoneSoFar;
            this.habitLogs[i].NumberOfMinutes = 0;
            this.habitLogs[i].NumberOfHours = 0;
            this.habitLogs[i].NumberOfTimes = 0;
            this.habitLogs[i].HabitNameDisplay = this.habitLogs[i].HabitName.length > 10 ? this.habitLogs[i].HabitName.substr(0, 10) + '..' : this.habitLogs[i].HabitName;
          }
        }
      }
      //Try to sort
      this.habitLogs = this.habitLogs.sort((t1, t2) => {
        const name1 = t1.HabitName.toLowerCase();
        const name2 = t2.HabitName.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
      console.log(response);
      this.habitLogsClone = this.habitLogs;

      // Getting notifications
      this.notificationService.nextMessage("Second Message");
      var postModel = new NotificationPostModel();
      postModel.IsReaded = true;
      postModel.ProjectID = 1;
      this.notificationService.GetAllNotificationOrByApplicationID_Sub(postModel);

      // Searching on click of notification
      this.searchTerm = this.route.snapshot.queryParamMap.get('habitName');
      if (this.searchTerm != null && this.searchTerm.length > 0) {
        this.searchByHabitName();
      }
    }

    );


  }
  AddHabitLog($event, data) {
    var model = new HabitLoggerModel();
    var newDate = new Date(this.LogDateDisplay.year, this.LogDateDisplay.month - 1
      , this.LogDateDisplay.day,
      this.LogTimeDisplay.hour, this.LogTimeDisplay.minute, 0, 0);
    model.Created = newDate;
    model.HabitId = data.HabitId;
    model.NumberOfHours = data.NumberOfHours;
    model.NumberOfMinutes = data.NumberOfMinutes;
    model.NumberOfTimes = data.NumberOfTimes;
    this.habitTrackerService.Insertorupdatehabitlogger(model).subscribe(response => {
      console.log(Response);
    },
      err => {
        console.log(err);
        this.resultLogger = err.message;
      }
    );
    console.log(data);

    //Get current selected habit type and week number;
    var selectedHabitTypeid = this.habitTypes.filter(it => it.Checked == true)[0].HabitTypeId;
    var currentWeekNo = 1;
    try { currentWeekNo = this.days.filter(it => it.Checked == true)[0].Value; } catch (err) { }

    //this.getHabitLoggedDataFromService(newDate, selectedHabitTypeid, currentWeekNo);
    this.getAllHabitByHabitTypeId(selectedHabitTypeid);
  }
  onSubmit() {
    var newDate = new Date(this.LogDateDisplay.year, this.LogDateDisplay.VoucherDateDisplay.month - 1
      , this.LogDateDisplay.VoucherDateDisplay.day,
      this.LogTimeDisplay.VoucherTimeDisplay.hour, this.LogTimeDisplay.VoucherTimeDisplay.minute, 0, 0);
    this.LogTimeDisplay.VoucherDate = newDate;

  }
  filterIscompleted(value) {
    this.habitLogs = this.habitLogsClone;
    var newModels = [];
    console.log(this.habitLogs);
    if (value == 'Re') {
      newModels = this.habitLogsClone;

    }
    else if (value == 'R') {
      newModels = this.habitLogs.filter(function (e) {
        return e.HabitName.indexOf(value) >=0;
      });
    }
    else if (value == 'asc') {
      newModels = this.habitLogs.sort(function (a, b) {
        return a.Completed - b.Completed;
      });
    }
    else if (value == 'dsc') {
      newModels = this.habitLogs.sort(function (a, b) {
        return b.Completed - a.Completed;
      });
    }
    else if (value == 'nasc') {
      newModels = this.habitLogs.sort((t1, t2) => {
        const name1 = t1.HabitName.toLowerCase();
        const name2 = t2.HabitName.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
    }
    else if (value == 'ndsc') {
      newModels = this.habitLogs.sort((t1, t2) => {
        const name1 = t1.HabitName.toLowerCase();
        const name2 = t2.HabitName.toLowerCase();
        if (name1 > name2) { return -1; }
        if (name1 < name2) { return 1; }
        return 0;
      });
    }
    else if (value == 0 || value == 100) {
      newModels = this.habitLogs.filter(function (e) {
        return e.Completed == value;
      });
    }
    else {
      newModels = this.habitLogs.filter(function (e) {
        return e.Completed >= value;
      });
    }
    this.habitLogs = newModels;

  }
  searchByHabitName() {
    this.habitLogs = this.habitLogsClone;
    var newModels = [];
    let term = this.searchTerm;

    newModels = this.habitLogs.filter(function (tag) {
      return tag.HabitName.indexOf(term) >= 0;
    });
    this.habitLogs = newModels;

  }
  onModelChange(event) {
 console.log(event);
    var newDate = new Date(this.LogDateDisplay.year, this.LogDateDisplay.month - 1
      , this.LogDateDisplay.day,
      this.LogTimeDisplay.hour, this.LogTimeDisplay.minute, 0, 0);
      var currentHabitTypeId =1//Hardcode 1 for daily
      var objIndex = this.habitTypes.findIndex((obj => obj.HabitTypeId == currentHabitTypeId))
      this.getAllHabitByHabitTypeId(currentHabitTypeId);
  }

}
