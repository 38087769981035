import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

import { SensorDataPostModel } from 'src/app/models/Sensor/sensor-data-post-model';
import { SensorService } from 'src/app/services/sensor.service';
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
@Component({
  selector: 'app-sensor-widget',
  templateUrl: './sensor-widget.component.html',
  styleUrls: ['./sensor-widget.component.css']
})
export class SensorWidgetComponent implements OnInit {
  locationNameForApi='computer%20room';
  public sensorDataType = '';
  public dataName = '';
  public title = '';
  public width = 300;
  public height = 300;
  public yAxisLabel = '';
  constructor(private sensorervice1: SensorService) { }

  async ngOnInit() {



    this.dataName = localStorage.getItem('dataName');
    this.sensorDataType = localStorage.getItem('sensorDataType');
    this.title = localStorage.getItem('title');
    this.width = parseInt(localStorage.getItem('width'));
    this.height = parseInt(localStorage.getItem('height'));
    this.yAxisLabel = localStorage.getItem('yAxisLabel');
    console.log(this.sensorDataType + " - SensorDataType")
    const this1 = this;
    Highcharts.setOptions({
      time: {
        timezone: "Asia/Kolkata",
      }
    });
    let model = new SensorDataPostModel();
    model.LocationName = "computer room";
    model.SensorType = localStorage.getItem('sensorDataType');
    model.LogDate = new Date();
    var dataPromise = [];
    var mePromise = await this1.sensorervice1.GetSensorDataByDay(model);
    for (var i = 0; i < mePromise.SensorAllDayValue.length; i++) {
      dataPromise.push([
        (new Date((mePromise.SensorAllDayValue[i].LastUpdated))).getTime(),
        mePromise.SensorAllDayValue[i].SensorValue
      ]);
    }
    //Dynamic Content
    //var container = document.getElementById("dynamicContainerr");
    // var container = document.getElementById("dynamicComponent"); 
    // var container = document.getElementsByClassName("dynamicContainerclass")[0];
    var classItems = document.querySelectorAll(".dynamicContainerclass:last-child")
    var container = classItems[classItems.length - 1];
    //var container = document.getElementById(divIdMain);
    var divCard = document.createElement('div');
    let divIdMain = "div" + this.getUniqueId();
    divCard.id = divIdMain;
    if (this.sensorDataType === 'temperature') {
      divCard.innerHTML = '<div class="card-body"><div class="card-body-icon"><i class="fas fa-fw fa-thermometer-half"></i></div>' +
        ' <div class="mr-5">' + this.dataName + '</div></div>'
        ;
    }
    else if (this.sensorDataType === 'humidity') {
      divCard.innerHTML = '<div class="card-body"><div class="card-body-icon"><i class="fas fa-fw fa-tint"></i></div>' +
        ' <div class="mr-5">' + this.dataName + '</div></div>'
        ;
    }

    divCard.className = 'card text-white bg-primary o-hidden h-100';
    divCard.setAttribute('style', 'float:left;margin-right:12px;margin-bottom:12px');
    container.appendChild(divCard);
    let e = document.createElement("div");
    let divId = "div" + this.getUniqueId();
    e.setAttribute("id", divId);
    e.setAttribute('style', 'float:left');
    e.setAttribute("class", "meow ");
    // e.innerHTML = '<div class="card-body-icon"><i class="fas fa-fw fa-comments"></i></div>';
    // e.appendChild(divCard);
    var containerMain = document.getElementById(divIdMain);
    containerMain.appendChild(e);
    //document.body.appendChild(e);
    //if (this.param1 === 'temp') 
    //{
    var options: any = {
      chart: {
        events: {
          load: function () {
            // set up the updating of the chart each second
            var series = this.series[0];
            var paramFor = series.options.sensorDataType;
            setInterval(function () {
              this1.sensorervice1.getSensorDataByType('computer room',paramFor).subscribe(sensorData => {
                var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                  // //                    // var x = (new Date()).getTime(), // current time
                  y = sensorData.SensorValue.SensorValue;
                series.addPoint([x, y], true, true);
              });
              // var x = (new Date()).getTime(), // current time
              //     y = Math.round(Math.random() * 100);
              // series.addPoint([x, y], true, true);
            }, 1000);
          }
        },
        // type: 'column',
        // margin:90,
        reflow: true,
        backgroundColor: '#007bff',
        color: "#f00"
      },
      style: {
        fontFamily: 'monospace',
        color: "green"
      },

      time: {
        useUTC: false
      },

      rangeSelector: {
        buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
        }, {
          count: 5,
          type: 'minute',
          text: '5M'
        }, {
          type: 'all',
          text: 'All'
        }],
        inputEnabled: true,
        selected: 0
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   formatter: function() {
        //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
        //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
        //   }
        // }
        tickColor: 'blue',
        labels: {
          style: {
            color: 'white',
            font: '11px Trebuchet MS, Verdana, sans-serif'
          }
        },
      },
      yAxis: {
        tickColor: 'white',
        title: {
          style: {
            color: 'white'
          },
          text: this.yAxisLabel
        },
        labels: {
          itemStyle: {
            // fontSize:'35px',
            // font: '35pt Trebuchet MS, Verdana, sans-serif',
            color: 'white'
          },

          style: {
            color: 'white',
            font: '11px Trebuchet MS, Verdana, sans-serif'
          }

        }
      },
      legend: {
        itemStyle: {
          // fontSize:'35px',
          // font: '35pt Trebuchet MS, Verdana, sans-serif',
          color: 'white'
        },
        itemHoverStyle: {
          color: '#FFF'
        },
        color: 'white',
        // backgroundColor: '#FCFFC5'
      },
      title: {
        //text: 'Room Temperature' + this.title
        //text:this.title,
        text: '',
        style: {
          fontFamily: 'monospace',
          color: "white"
        }
      },

      exporting: {
        enabled: false
      },

      series: [{
        //name: 'Temperature',
        color: 'white',
        name: this.dataName,
        sensorDataType: this.sensorDataType,
        data: (
          function () {
            return dataPromise;
          }())

        // (function () {
        //   let model = new SensorDataPostModel();
        //   model.LocationName="computer room";
        //   model.SensorType = sessionStorage.getItem('sensorDataType'); 
        //   model.LogDate = new Date();
        //   var data = [];
        //   var meResonse = this1.sensorervice1.GetSensorDataByDay(model).subscribe(
        //     response=>{
        //       console.log(response);
        //       for(var i=0;i<=response.SensorAllDayValue.length;i++){
        //         data.push([
        //           response.SensorAllDayValue[i].LastUpdated,
        //           response.SensorAllDayValue[i].SensorValue
        //         ]);
        //       }
        //     }
        //    // return push;

        //   );
        //   // generate an array of random data
        //   var data = [],
        //     time = (new Date()).getTime(),
        //     i;

        //   for (i = -50; i <= 0; i += 1) {
        //     data.push([
        //       time + i * 1000,
        //       Math.round(Math.random() * 100)
        //     ]);
        //   }
        //   // data.push([time],Math.round(Math.random() * 100))
        //   return data;
        // }())
      },
      ]
    }

    //var meChart = Highcharts.chart('container', options);
    var meChart = Highcharts.chart(divId, options);
    // meChart.setSize(300,300,true);
    meChart.setSize(this.width, this.height, true);
    //meChart.reflow('resize');
    // Use the Time object
    console.log(
      'Current time in New York',
      //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
      //meChart.time.dateFormat('%d( %H:%M)', Date.now())
      meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())

    );
  }
  getUniqueId(): number {
    return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
  }

}
