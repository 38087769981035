export class FoodLoggerNutritionMasterModel {
    public  NutritionMasterId:number;
    public  NutritionName:string;
    public  FilterNutritionName:string;
    public  Notes:string;
   // public  UserID:number;
    //public  IsAvailableToPublic:boolean;
   // public  Created:Date;
   // public  Updated:Date;

   public NutritionMasterAutoComplete:any;
    public NutritionMasterlist:FoodLoggerNutritionMasterModel[];
}
