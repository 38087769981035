export class TransactionGridModel {
    Id:number;
    RowID:number;
    TranID:number;
    VoucherDate:Date;
    VoucherID:number;
    VoucherType:string;
    ProductID:number;
    ProductName:string;
    AccCode:string;
    DrAmount:number;
    CrAmount:number;
    PayMode:string;
    TranType:string;
     Quantity:number;
     Measurement:Number;
     MeasurementId:number;
     Narration:string;

}
