<fieldset class="section-legend-border  col-md-12 col-lg-12" #SensorSubLocation >
    <legend class="section-legend-border">Sensor Sub Location</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #SubLocation >
                <legend class="section-legend-border">Sub Location</legend>
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.SubLocationName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sub location:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSubLocationModel.SensorDataSubLocationAutoComplete" [(ngModel)]="sensorDataSubLocationModel.SensorDataSubLocationAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
           
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor sub location Id</label>
                    <input type="text" [(ngModel)]="sensorDataSubLocationModel.SubLocationID" name="sensorDataSubLocationModel.SubLocationID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Sub location Name</label>
                    <input type="text" [(ngModel)]="sensorDataSubLocationModel.SubLocationName" name="sensorDataSubLocationModel.SubLocationName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor Sub location Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataSubLocationModel.SubLocationUniqueName" name="sensorDataSubLocationModel.SubLocationUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            </fieldset>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Location >
                <legend class="section-legend-border"> Location</legend>
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rtLocation let-r="result" let-t="term">
                        {{ r.LocationName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sensor Location:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSubLocationModel.Location.SensorDataLocationAutoComplete" [(ngModel)]="sensorDataSubLocationModel.Location.SensorDataLocationAutoComplete" [ngbTypeahead]="searchLocation" [inputFormatter]="formatterLocation" [resultTemplate]="rtLocation" (selectItem)="selectedItemLocation($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Location id</label>
                    <input type="text" [(ngModel)]="sensorDataSubLocationModel.Location.LocationID" name="sensorDataSubLocationModel.Location.LocationID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Location Name</label>
                    <input type="text" [(ngModel)]="sensorDataSubLocationModel.Location.LocationName" name="sensorDataSubLocationModel.Location.LocationName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor Location Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataSubLocationModel.Location.LocationUniqueName" name="sensorDataSubLocationModel.Location.LocationUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            </fieldset>
            <div class="form-row">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-4 col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>