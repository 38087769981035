import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SmartBagComboMasterModel } from 'src/app/models/SmartBag/smart-bag-combo-master-model';
import { SmartBagCurrentComboModel } from 'src/app/models/SmartBag/smart-bag-current-combo-model';
import { SmartBagService } from 'src/app/services/smart-bag.service';

@Component({
  selector: 'app-master-combo',
  templateUrl: './master-combo.component.html',
  styleUrls: ['./master-combo.component.css']
})
export class MasterComboComponent implements OnInit {
  public result = '';
  smartBagComboMasterModel: SmartBagComboMasterModel;
  smartBagCurrentComboModel: SmartBagCurrentComboModel;
  model: any;
  searching = false;
  searchFailed = false;
  comboBagSimulationList = [];
  public smartBagComboMasterList: SmartBagComboMasterModel[];
  constructor(private smartBagServiceservice: SmartBagService, private titleService: Title) { this.titleService.setTitle("Manage Combo Master"); }

  ngOnInit() {
    this.smartBagCurrentComboModel = new SmartBagCurrentComboModel();
    this.smartBagComboMasterModel = new SmartBagComboMasterModel();
    this.smartBagComboMasterModel.ComboMasterlist = [];
    this.smartBagServiceservice.GetComboMasterAllOrByID(0).subscribe(response => {
      this.smartBagComboMasterList = response;
    });
    this.smartBagServiceservice.GetCurrentComboAllOrByID(1).subscribe(response => {
      this.smartBagCurrentComboModel = response[0];
    });
    this.comboBagSimulationList = [];
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.smartBagServiceservice.GetComboByname(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { ComboMasterName: string }) => x.ComboMasterName;

  selectedItem(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.smartBagComboMasterModel.ComboMasterID = item.item.ComboMasterID;
    this.smartBagComboMasterModel.ComboMasterName = item.item.ComboMasterName;
    this.smartBagComboMasterModel.ComboCommand = item.item.ComboCommand;

  }
  onSubmit() {
    var me = this.smartBagComboMasterModel;
    this.smartBagServiceservice.InsertOrUpdateComboMaster(this.smartBagComboMasterModel).subscribe(response => {
      this.result = response+':'+formatDate(Date.now(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
      this.smartBagComboMasterModel= new SmartBagComboMasterModel();
      this.smartBagServiceservice.GetComboMasterAllOrByID(0).subscribe(response => {
        this.smartBagComboMasterList = response;
      });
    },
      err => {
        this.result = err.message;
      }
    );
    // var vendorApimodel = new VendorApiModel();
    // vendorApimodel.vendorAutoID = me.VendorAutoID;
    // vendorApimodel.vendorID = me.VendorID;
    // vendorApimodel.vendorName = me.VendorName;
    // this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
    //   response => {
    //     console.log(response);
    //     this.vendorModel = new VendorModel();
    //   }
    // );
  }
  onCurrentComboSubmit() {
    var me = this.smartBagCurrentComboModel;
    this.smartBagServiceservice.UpdateCurrentCombo(this.smartBagCurrentComboModel).subscribe(response => {
      this.result = response+':'+formatDate(Date.now(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    },
      err => {
        this.result = err.message;
      }
    );

  }
  selectedComboMaster(ComboMasterID) {

    var newModels = this.smartBagComboMasterList.filter(function (cMaster) {
      return cMaster.ComboMasterID === ComboMasterID;
    })[0];
    this.smartBagComboMasterModel = newModels;
    this.smartBagCurrentComboModel = new SmartBagCurrentComboModel();
    //Currently haave only 1 selected combo.
    this.smartBagCurrentComboModel.CurrentComboID = 1;
    this.smartBagCurrentComboModel.ComboMasterID = newModels.ComboMasterID;
    this.smartBagCurrentComboModel.ComboMasterName = newModels.ComboMasterName;
    this.smartBagCurrentComboModel.ComboCommand = newModels.ComboCommand;
    this.smartBagCurrentComboModel.DelayDurationInMS = 1000; 
    this.smartBagCurrentComboModel.NoOfTimes = 10;
  }

  addcombo(selectedCombo) {
    this.comboBagSimulationList.push(selectedCombo);
  }
  clearCombo() {
    this.comboBagSimulationList = [];
  }
  AddToInput(inputChoice) {
    if (inputChoice === "CurrentCombo") {
      this.smartBagCurrentComboModel.ComboCommand = this.comboBagSimulationList.join(",");
    }
    else if (inputChoice === "ComboMaster") {
      this.smartBagComboMasterModel.ComboCommand = this.comboBagSimulationList.join(",");
    }
    else if (inputChoice === "CurrentComboFromCM") {
      this.smartBagCurrentComboModel.ComboMasterID = this.smartBagComboMasterModel.ComboMasterID;
      this.smartBagCurrentComboModel.ComboMasterName = this.smartBagComboMasterModel.ComboMasterName;
      this.smartBagCurrentComboModel.ComboCommand = this.smartBagComboMasterModel.ComboCommand;
    }
  }
}
