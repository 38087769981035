<fieldset class="section-legend-border col-md-12 col-lg-12" #SensorMapping >
    <legend class="section-legend-border">Sensor Mapping</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rtSensor let-r="result" let-t="term">
                        {{ r.SensorMappingName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sensor mapping: Current mapping id :{{sensorDataSensorMappingModel.SensorMappingID}}</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSensorMappingModel.SensorDataSensorMappingAutoComplete" [(ngModel)]="sensorDataSensorMappingModel.SensorDataSensorMappingAutoComplete" [ngbTypeahead]="searchSensor" [inputFormatter]="formatterSensor" [resultTemplate]="rtSensor" (selectItem)="selectedItemSensor($event)"
                    />
                </div>
            </div>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Sensor>
                <legend class="section-legend-border">Sensor</legend> 
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.SensorName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sensor:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSensorMappingModel.Sensor.SensorDataSensorAutoComplete" [(ngModel)]="sensorDataSensorMappingModel.Sensor.SensorDataSensorAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Id</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.Sensor.SensorID" name="sensorDataSensorMappingModel.Sensor.SensorID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.Sensor.SensorName" name="sensorDataSensorMappingModel.Sensor.SensorName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.Sensor.SensorUniqueName" name="sensorDataSensorMappingModel.Sensor.SensorUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            </fieldset>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #FeatureType>
                <legend class="section-legend-border">Feature Type</legend> 
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rtFeatureType let-r="result" let-t="term">
                        {{ r.SensorFeatureTypeName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sensor FeatureType:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSensorMappingModel.FeatureType.SensorDataSensorFeatureTypeAutoComplete" [(ngModel)]="sensorDataSensorMappingModel.FeatureType.SensorDataSensorFeatureTypeAutoComplete" [ngbTypeahead]="searchFeatureType" [inputFormatter]="formatterFeatureType" [resultTemplate]="rtFeatureType" (selectItem)="selectedItemFeatureType($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor FeatureType Id</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeID" name="sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor FeatureType Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeName" name="sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor FeatureType Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeUniqueName" name="sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            </fieldset>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #SubLocation>
                <legend class="section-legend-border">Sub Location</legend> 
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rtSubLocation let-r="result" let-t="term">
                        {{ r.SubLocationName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sub location:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSensorMappingModel.SubLocation.SensorDataSubLocationAutoComplete" [(ngModel)]="sensorDataSensorMappingModel.SubLocation.SensorDataSubLocationAutoComplete" [ngbTypeahead]="searchSubLocation" [inputFormatter]="formatterSubLocation" [resultTemplate]="rtSubLocation" (selectItem)="selectedItemSubLocation($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor sub location Id</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.SubLocation.SubLocationID" name="sensorDataSensorMappingModel.SubLocation.SubLocationID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Sub location Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.SubLocation.SubLocationName" name="sensorDataSensorMappingModel.SubLocation.SubLocationName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor Sub location Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorMappingModel.SubLocation.SubLocationUniqueName" name="sensorDataSensorMappingModel.SubLocation.SubLocationUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            </fieldset>
            <div class="form-row">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-4 col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>