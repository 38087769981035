import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NumberValueAccessor } from '@angular/forms';
import { ItemTrackerCurrentLocationModel } from 'src/app/models/ItemTracker/item-tracker-current-location-model';
import { ItemTrackerService } from 'src/app/services/item-tracker.service';
import { GetAllItemsForStockModel } from 'src/app/models/ItemTracker/get-all-items-for-stock-model';
import { filter } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import {Title} from "@angular/platform-browser";
export interface DragAndDropDemoList {
  projectName: string;
  products: Product[];
}

export interface Product {
  originalProjectName: string;
  productName: string;
}

export class DragAndDropItemCurrentList {
  MainLocationId: number;
  MainLocationName: string;
  MainLocationIdStr: string;
  LocationIdStr: string;
  MainLocationFilter: string;
  SubLocationFilter: string;
  ProductItems: ItemTrackerCurrentLocationModel[];

}
export class MainFilterLocation {
  FilterLocation: FilterLocation[];
}
export class FilterLocation {
  MainLocationId: number;
  MainLocationName: string;
  IsSelected: boolean;
  FilterSubLocation: FilterSubLocation[];
}
export class FilterSubLocation {
  SubLocationId: number;
  SubLocationName: string;
  IsSelected: boolean;
}
//https://stackblitz.com/edit/angular-odppkg-z1rjjd?file=src%2Fapp%2Fcdk-drag-drop-connected-sorting-example.html
//npm install --save @angular/material @angular/cdk @angular/animations

@Component({
  selector: 'app-item-current-location-drag-drop',
  templateUrl: './item-current-location-drag-drop.component.html',
  styleUrls: ['./item-current-location-drag-drop.component.css']
})
export class ItemCurrentLocationDragDropComponent implements OnInit {
  projectLists: DragAndDropDemoList[] = [];
  ItemCurrentList: DragAndDropItemCurrentList[] = [];
  ItemUnTouchedCurrentList: DragAndDropItemCurrentList[] = [];
  public itemTrackerCurrentLocationList: ItemTrackerCurrentLocationModel[];
  public GetAllItemsForStockModelList: GetAllItemsForStockModel[];
  public itemsForSaving: ItemTrackerCurrentLocationModel[];
  StockeItemForDb: ItemTrackerCurrentLocationModel[];
  MainFilterLocation: MainFilterLocation;

  public result = '';
  constructor(private itemTrackerService: ItemTrackerService,private titleService:Title) {
    this.titleService.setTitle("Drag Drop Manage Item Current Location ");

    this.itemsForSaving = [];
    //For filter
    this.MainFilterLocation = new MainFilterLocation();
    this.MainFilterLocation.FilterLocation = [];
    this.itemTrackerService.GetAllCurrentLocation().subscribe(response => {
      this.itemTrackerCurrentLocationList = response;
      this.itemTrackerCurrentLocationList.forEach(element => {
        element.LocationNameForQrCode = element.MainLocationUniqueName + "|" + element.SubLocationUniqueName;
      });

      this.itemTrackerService.GetAllItemsForStock().subscribe(response => {
        this.GetAllItemsForStockModelList = response;
        var listItem = new DragAndDropItemCurrentList();
        listItem.MainLocationId = 0;
        listItem.MainLocationName = "Not Mapped";
        listItem.MainLocationFilter = "Not Mapped";
        listItem.LocationIdStr='0';
        listItem.SubLocationFilter='Not Mapped';
        listItem.ProductItems = [];
        var productDontMapped = [];
        var uniqueCurrentLocProductIds = [...new Map(this.itemTrackerCurrentLocationList.map(item =>
          [item['ItemID'], item])).values()];
        var myArrayFiltered = this.GetAllItemsForStockModelList.filter((el) => {
          return uniqueCurrentLocProductIds.some((f) => {
            return f.ItemID !== el.ID;
          });
        });
        // var result23 = this.GetAllItemsForStockModelList.filter(ad => 
        //   uniqueCurrentLocProductIds.every(fd => fd.ItemID == ad.ID));
        var onlyInA = (this.GetAllItemsForStockModelList
          .filter(this.comparer(uniqueCurrentLocProductIds)))
          .filter(x => x.ProductSearchName.length > 2)
          ;
        onlyInA.forEach(stockResponse => {

          var currentLocationModel = new ItemTrackerCurrentLocationModel();
          //currentLocationModel.ItemID = stockResponse.ProductId;
          currentLocationModel.ItemID = stockResponse.ID;
          currentLocationModel.ProductName = stockResponse.ProductSearchName;
          listItem.ProductItems.push(currentLocationModel);

        });
        this.ItemCurrentList.push(listItem);
        this.ItemUnTouchedCurrentList.push(listItem);
        //for filter
        var filterLocation = new FilterLocation();
        filterLocation.MainLocationId = listItem.MainLocationId;
        filterLocation.MainLocationName = listItem.MainLocationName;
        filterLocation.IsSelected = true;
        filterLocation.FilterSubLocation = [];
        var sublocationFilter = new FilterSubLocation();
              sublocationFilter.SubLocationId = 0;
              sublocationFilter.SubLocationName = "Not mapped";
              sublocationFilter.IsSelected = false;
              filterLocation.FilterSubLocation.push(sublocationFilter);
        this.MainFilterLocation.FilterLocation.push(filterLocation);
        // var uniqueMainLocationId =[...new Map(this.itemTrackerCurrentLocationList.map(item =>
        //   [item['MainLocationID'], item])).values()];
        var uniqueMainLocationId = [...new Map(this.itemTrackerCurrentLocationList.map(item =>
          [item['LocationID'], item])).values()];

        //for Filter
        var uniqueMainLocationIdFilter = [...new Map(this.itemTrackerCurrentLocationList.map(item =>
          [item['MainLocationID'], item])).values()];
        uniqueMainLocationIdFilter.forEach(element => {
          var mainFilterLocation = new MainFilterLocation();
          mainFilterLocation.FilterLocation = [];
          var filterLocation = new FilterLocation();
          filterLocation.MainLocationId = element.MainLocationID;
          filterLocation.MainLocationName = element.MainLocationName;
          filterLocation.IsSelected = true;
          filterLocation.FilterSubLocation = [];

          this.MainFilterLocation.FilterLocation.push(filterLocation);
        });
        // Create unique location which have items in it.
        uniqueMainLocationId.forEach(element => {
          var listItem = new DragAndDropItemCurrentList();
          listItem.MainLocationId = element.MainLocationID;
          listItem.MainLocationIdStr = element.MainLocationID.toString();
          listItem.LocationIdStr = element.LocationID.toString();
          listItem.MainLocationFilter = element.MainLocationName;
          listItem.SubLocationFilter = element.SubLocationUniqueName.replace(/_/g, " ");
          listItem.MainLocationName = element.MainLocationID + '-' + element.MainLocationName + ' :' + element.LocationID + ' - ' + element.SubLocationUniqueName.replace(/_/g, " ");
          listItem.ProductItems = [];
          this.itemTrackerCurrentLocationList.forEach(responseList => {
            if (responseList.LocationID == element.LocationID) {
              listItem.ProductItems.push(responseList);
            }
          });
          //For filter
          this.MainFilterLocation.FilterLocation.forEach(mainFilterElement => {
            if (element.MainLocationID == mainFilterElement.MainLocationId) {
              var sublocationFilter = new FilterSubLocation();
              sublocationFilter.SubLocationId = element.LocationID;
              sublocationFilter.SubLocationName = element.SubLocationUniqueName;
              sublocationFilter.IsSelected = false;
              mainFilterElement.FilterSubLocation.push(sublocationFilter);
            }
          });

          // Main List of location
          this.ItemCurrentList.push(listItem);
          this.ItemUnTouchedCurrentList.push(listItem);
        });

      });



    });

    // #Region: Building MOCK objects
    this.projectLists = [{
      projectName: 'ProjectA',
      products: [
        { originalProjectName: 'ProjectA', productName: 'Drill A1' },
        { originalProjectName: 'ProjectA', productName: 'Saw A1' },
        { originalProjectName: 'ProjectA', productName: 'Container A1' },
        { originalProjectName: 'ProjectA', productName: 'Drill A2' }
      ]
    },
    {
      projectName: 'ProjectB',
      products: [
        { originalProjectName: 'ProjectB', productName: 'Scrap' },
        { originalProjectName: 'ProjectB', productName: 'Drill A3' },
        { originalProjectName: 'ProjectB', productName: 'Drill A4' },
        { originalProjectName: 'ProjectB', productName: 'Drill C1' },
        { originalProjectName: 'ProjectB', productName: 'Container A1' }
      ]
    },
    {
      projectName: 'ProjectC',
      products: [
        { originalProjectName: 'ProjectC', productName: 'Electric Motor A1' },
        { originalProjectName: 'ProjectC', productName: 'Pump A1' },
      ]
    }
    ];
    // #EndRegion
  }
  drop(event: CdkDragDrop<Product[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      var currentMovedObject = event.previousContainer.data[event.previousIndex] as any;
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      //currentMovedObject.LocationID   =  (event.container.data as any[])[0].LocationID;     
      //currentMovedObject.MainLocationID   =  (event.container.data as any[])[0].MainLocationID;   
      currentMovedObject.MainLocationID = Number(event.container.element.nativeElement.dataset.mainlocationidstr);
      currentMovedObject.LocationID = Number(event.container.element.nativeElement.dataset.locationidstr);
      this.itemsForSaving.push(currentMovedObject as ItemTrackerCurrentLocationModel);
      this.itemTrackerService.InsertOrUpdateCurrentLocation(this.itemsForSaving[0]).subscribe(response => {
        this.result = response;
        this.itemTrackerService.GetAllCurrentLocation().subscribe(response => {
          this.itemTrackerCurrentLocationList = response;
        });
      },
        err => {
          this.result = err.message;
        }
      );
    }
  }

  get workTask(): string {
    // this could be an emit function passing data back to the backend, instead of a display function
    let result = '';
    this.projectLists.forEach(list => {
      list.products.forEach(product => {
        if (product.originalProjectName !== list.projectName)
          result = `${result}Move ${product.productName} from ${product.originalProjectName} to ${list.projectName}\n`;
      })
    });
    return result;
  }

  ngOnInit(): void {
    this.result = "Initial load"
  }

  comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.ItemID === current.ID
      }).length == 0;
    }
  }
  filterLocation(locationType, locationName) {
    var models: DragAndDropItemCurrentList[] = [];;
    this.ItemCurrentList = this.ItemUnTouchedCurrentList;
    if (locationType === 'mainlocation') {
      models = this.ItemCurrentList.filter(
        function (e) {

          return (e.MainLocationFilter == locationName) || e.MainLocationFilter == 'Not Mapped';
        }
      );
    }
    this.ItemCurrentList = models;
  }
  onSubmit() {
    var me = this.MainFilterLocation;
    var models: DragAndDropItemCurrentList[] = [];
    models = this.ItemUnTouchedCurrentList;
    var mainFilter = this.MainFilterLocation.FilterLocation.filter(function (e) {
      return (e.IsSelected == true)
    });
    var selectedSubFilters: FilterSubLocation[] = [];
    this.MainFilterLocation.FilterLocation.forEach(element => {

      var subFilter = element.FilterSubLocation.filter(function (e) {
        return (e.IsSelected == true)
      });
      selectedSubFilters = selectedSubFilters.concat(subFilter);
    });


    var myArrayFiltered = models.filter((el) => {
      return mainFilter.some((f) => {
        return f.MainLocationId === el.MainLocationId;
      });
    });
    if (selectedSubFilters.length > 0) {
      myArrayFiltered = models.filter((el) => {
        return selectedSubFilters.some((f) => {
          return f.SubLocationId === Number(el.LocationIdStr);
        });
      }
      );
    }



    // this.MainFilterLocation.FilterLocation.forEach(mainElement=>{
    //   if(mainElement.IsSelected){
    //     models = models.filter(
    //       function (e) {

    //         return (e.MainLocationFilter == mainElement.MainLocationName);
    //       }
    //     );

    //   }
    // });
    this.ItemCurrentList = myArrayFiltered;
  }
}
