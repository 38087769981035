import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HabitLogModel } from 'src/app/models/HabitTracker/habit-log-model';
import { HabitModel } from 'src/app/models/HabitTracker/Habit/habit-model';
import { HabitMappingModel } from 'src/app/models/HabitTracker/HabitMapping/habit-mapping-model';
import { HabitReportModel } from 'src/app/models/HabitTracker/HabitReport/habit-report-model';
import { HabitTrackerService } from 'src/app/services/habit-tracker.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-habit-report',
  templateUrl: './habit-report.component.html',
  styleUrls: ['./habit-report.component.css']
})
export class HabitReportComponent implements OnInit {
  habitReportModels: HabitReportModel[];
  habitLogModelList: HabitLogModel[];
  allHabitData:HabitMappingModel[];
  allMonthDates = [];
  public habitLogs: HabitLogModel[];
  public habitLogsClone: HabitLogModel[];
  public LogDateDisplay: any;
  public LogTimeDisplay: any;
  constructor(private habitTrackerService: HabitTrackerService, private route: ActivatedRoute,private titleService:Title) { 
    this.titleService.setTitle("Habit Report");
  }

  ngOnInit(): void {
    this.habitLogModelList = [];
    const date = new Date();
    this.LogDateDisplay = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    this.LogTimeDisplay = { hour: date.getHours(), minute: date.getMinutes() };

    this.getHabitDataByDate(date);
   
  }
  getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);
    const dates = [];
    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }
  filterIscompleted(value) {
    this.habitLogs = this.habitLogsClone;
    var newModels = [];
    console.log(this.habitLogs);
    if (value == 'Re') {
      newModels = this.habitLogsClone;

    }
    else if (value == 'R') {
      newModels = this.habitLogs.filter(function (e) {
        return e.HabitName.indexOf(value) >=0;
      });
    }
    else if (value == 'asc') {
      newModels = this.habitLogs.sort(function (a, b) {
        return a.Completed - b.Completed;
      });
    }
    else if (value == 'dsc') {
      newModels = this.habitLogs.sort(function (a, b) {
        return b.Completed - a.Completed;
      });
    }
    else if (value == 'nasc') {
      newModels = this.habitLogs.sort((t1, t2) => {
        const name1 = t1.HabitName.toLowerCase();
        const name2 = t2.HabitName.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
    }
    else if (value == 'ndsc') {
      newModels = this.habitLogs.sort((t1, t2) => {
        const name1 = t1.HabitName.toLowerCase();
        const name2 = t2.HabitName.toLowerCase();
        if (name1 > name2) { return -1; }
        if (name1 < name2) { return 1; }
        return 0;
      });
    }
    else if (value == 'htasc') {
      newModels = this.habitLogs.sort((t1, t2) => {
        const name1 = t1.HabitTypeName.toLowerCase();
        const name2 = t2.HabitTypeName.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });
    }
    else if (value == 'htdsc') {
      newModels = this.habitLogs.sort((t1, t2) => {
        const name1 = t1.HabitTypeName.toLowerCase();
        const name2 = t2.HabitTypeName.toLowerCase();
        if (name1 > name2) { return -1; }
        if (name1 < name2) { return 1; }
        return 0;
      });
    }
    else if (value == 0 || value == 100) {
      newModels = this.habitLogs.filter(function (e) {
        return e.Completed == value;
      });
    }
    else {
      newModels = this.habitLogs.filter(function (e) {
        return e.Completed >= value;
      });
    }
    this.habitLogs = newModels;

  }	
  onModelChange(event) {
    console.log(event);
    var newDate = new Date(this.LogDateDisplay.year, this.LogDateDisplay.month - 1
      , this.LogDateDisplay.day,
      this.LogTimeDisplay.hour, this.LogTimeDisplay.minute, 0, 0);
    // var newDate = moment({ y: 2017, M: 0, d: 4, h: 15, m: 10, s: 0, ms: 0 });
    this.getHabitDataByDate(newDate);

  }

  getHabitDataByDate(date){
    this.allMonthDates = this.getAllDaysInMonth(date.getFullYear(), date.getMonth());//2022,1
    this.habitReportModels=[];
    this.habitLogsClone = [];
    this.allMonthDates.forEach(element => {
      var model = new HabitReportModel();
      model.CalenderDate = element;
      this.habitReportModels.push(model);
    });
    //Get all habit data by yr
    this.habitLogModelList = [];
    this.habitTrackerService.Gethabitmappingallorbyid(0).subscribe(habitData=>{
this.habitLogs=[];
      //Adding progress to model
      for (var i = 0; i < habitData.length; i++) {
        var habitModel = new HabitLogModel();

        habitModel.HabitId = habitData[i].HabitId;
        habitModel.HabitName = habitData[i].HabitName;;
        habitModel.NumberOfTimes = 0;
        habitModel.NumberOfMinutes = 0;
        habitModel.NumberOfHours = 0;
        habitModel.HabitTypeId = habitData[i].HabitTypeId;
        habitModel.HabitTypeName = habitData[i].HabitTypeName;
        habitModel.ToolTipToDo = "Hour: " + habitData[i].NumberOfHours + " Min: " + habitData[i].NumberOfMinutes
          + " Times: " + habitData[i].NumberOfTimes;
        ;
        habitModel.HabitNameDisplay = habitModel.HabitName.length > 10 ? habitModel.HabitName.substr(0, 10) + '..' : habitModel.HabitName;


        //habitModel.Completed = Math.floor(Math.random()*(100-1)+1)
        this.habitLogs.push(habitModel);
      }


      this.allHabitData=habitData;
      this.habitTrackerService.GetAllHabitDataOrHabitDataByYear(date.getFullYear()).subscribe(response=>{
        this.habitLogModelList = response;
        //this.habitReportModels.forEach(result=>{
          this.habitLogs.forEach(result=>{
          
          //result.HabitLogModel=[];
          this.habitLogModelList.forEach(element=>{
            if(result.HabitId == element.HabitId){
              result.Completed = element.Completed; 
            }
            else{
              result.Completed=0;
            }
            element.LogDate = new Date(element.LogDate);
       
          });
        });


      });

    this.habitLogsClone = this.habitLogs;
    });

  }

}
