import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AVitalInformationMasterComponent } from './a-vital-information-master/a-vital-information-master.component';
import { Component, OnInit } from '@angular/core';
import { RouterLinkInfoService } from 'src/app/services/router-link-info.service';
import { VitalInformationMeasurementComponent } from './vital-information-measurement/vital-information-measurement.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [AVitalInformationMasterComponent, VitalInformationMeasurementComponent],
  imports: [
    CommonModule,AgGridModule,NgbModule,RouterModule,FormsModule
  ],
})
export class VitalInformationModule implements OnInit {
  constructor(private routerinfoservice: RouterLinkInfoService) { }

  ngOnInit() {
  }
  onActivate(componentRef) {
    try {
      var currentUrl = window.location.pathname;
      // var me =  componentRef.getPageName();
      // this.CData =me;
      $('#breadItem').text(this.routerinfoservice.getPageName(currentUrl));
    }
    catch (ex) {
      console.log('Not set');
    }
  }
 }
