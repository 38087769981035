import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class CustomvalidationService {

  constructor(private userService: UserService) { }
  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: UntypedFormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }


  userNameValidator(userControl: AbstractControl) {
    return new Promise(resolve => {
      setTimeout(() => {
        this.userService.IsUserNameExist(userControl.value).subscribe(result => {
          var response = result;
          if (response === true) {
            resolve({ userNameNotAvailable: true })
          }
          else {
            resolve(null);
          }
        },
        (err) => {
          resolve(null);
        }
        );
      }, 1000);
    });
  }
  EmailValidator(userControl: AbstractControl) {
    return new Promise(resolve => {
      setTimeout(() => {
        this.userService.IsEmailExist(userControl.value).subscribe(result => {
          var response = result;
          if (response === true) {
            resolve({ emailNotAvailable: true })
          }
          else {
            resolve(null);
          }
        },
        (err) => {
          resolve(null);
        }
        );
      }, 1000);
    });
  }
  userNameValidator1(userControl: AbstractControl) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.validateUserName(userControl.value)) {
          resolve({ userNameNotAvailable: true });
        } else {
          resolve(null);
        }
      }, 1000);
    });
  }

  validateUserName(userName: string) {
    const UserList = ['ankit', 'admin', 'user', 'superuser'];
    var me = ''
    this.userService.login("test", "1234").subscribe(result => {
      me = result.UserName;

    });
    //return (UserList.indexOf(userName) > -1);
    if (me === userName) {
      return 1;
    }
    else {
      return -1;
    }
    //return (me === userName > -1);
  }
}
