<p>
  dash-board works!
</p>
<div class="d-flex flex-wrap justify-content-center" id="dash-board-btn-region">
  <div class="dash-board-btn-section">
    <button class="btn btn-primary btn-block"
      (click)="addComponent(sensorWidgetComponent,'temperature','Temperature','Temperature Data',200,200,'In Degree Celcius')">Add
      Temperature</button>

  </div>
  <div class="dash-board-btn-section">
    <button class="btn btn-primary btn-block"
      (click)="addComponent(sensorWidgetComponent,'humidity','Humidity','Humidity Data',200,200,'In % ')">Add Humidity</button>

  </div>
  <div class="dash-board-btn-section">
    <button class="btn btn-primary btn-block" (click)="removeComponent(sensorWidgetComponent)">Remove Foo</button>
  </div>
  <!-- <div id="dynamicComponent"  cdkDropList (cdkDropListDropped)="drop($event)">
  
  <ng-template #container>

  </ng-template>
</div> -->
</div>
<div >
 
  <div id="dynamicComponent" cdkDropList (cdkDropListDropped)="drop($event)" class="d-flex flex-wrap justify-content-center">
    <ng-template #container>

    </ng-template>
  </div>
</div>