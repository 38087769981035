<fieldset class="section-legend-border col-md-12 col-lg-12" #Habit >
  <legend class="section-legend-border">Habit</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <div class="form-row">
        <div class="form-group col-md-6">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.HabitName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Habit:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="habitModel.HabitAutoComplete" 
          [(ngModel)]="habitModel.HabitAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
          [resultTemplate]="rt" (selectItem)="selectedItem($event)"
          />
        </div>
      </div>
      <div class="form-row"> 
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Habit Id</label>
          <input type="text" [(ngModel)]="habitModel.HabitId" name="habitModel.HabitId" placeholder="Habit Id" class="form-control">
        </div>
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Habit Name</label>
          <input type="text" [(ngModel)]="habitModel.HabitName" name="habitModel.HabitName" placeholder="Habit Name" class="form-control">
        </div>
   
    

      </div>
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #HabitCategory >
        <legend class="section-legend-border">Habit Category</legend>
      <div class="form-row"> 
        <div class="form-group col-md-6">
          <ng-template #rtCategory let-r="result" let-t="term">
            {{ r.HabitCategoryName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Habit category:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="habitModel.HabitCategoryAutoComplete" 
          [(ngModel)]="habitModel.HabitCategoryAutoComplete" [ngbTypeahead]="searchHabitcategory" [inputFormatter]="formatterCategory"
          [resultTemplate]="rtCategory" (selectItem)="selectedItemHabitCategory($event)"
          />
        </div>
      </div>
      <div class="form-row"> 
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Habit Category Id</label>
          <input type="text" [(ngModel)]="habitModel.HabitCategoryId" name="habitModel.HabitCategoryId" placeholder="Habit Category Id" class="form-control">
        </div>
    
        <div class="form-group col-md-3">
          <label for="disabledTextInput">Habit Category Name</label>
          <input type="text" [(ngModel)]="habitModel.HabitCategoryName" name="habitModel.HabitCategoryName" placeholder="Habit Category Name" class="form-control">
        </div>
      </div>
      </fieldset>
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
      </div>
    </form> 
  </div>
  <div class="col-sm-12">
    {{result}}
  </div>
  </div>  
  </fieldset>
  <!-- <pre>Model: {{ habitModel.HabitAutoComplete | json }}</pre>
  <pre>Model: {{ habitModel.HabitCategoryAutoComplete | json }}</pre> -->