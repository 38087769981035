import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SensorDataLocationModel } from 'src/app/models/Sensor/sensor-data-location-model';
import { SensorDataSubLocationModel } from 'src/app/models/Sensor/sensor-data-sub-location-model';
import { SensorService } from 'src/app/services/sensor.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-sensor-sub-location',
  templateUrl: './sensor-sub-location.component.html',
  styleUrls: ['./sensor-sub-location.component.css']
})
export class SensorSubLocationComponent implements OnInit {

 
  public result = '';
sensorDataSubLocationModel: SensorDataSubLocationModel;
model: any;
searching = false;
searchFailed = false;
constructor(private sensorService: SensorService,private titleService:Title) { this.titleService.setTitle("Manage Sensor Sub Location"); }

ngOnInit() {
  this.sensorDataSubLocationModel = new SensorDataSubLocationModel();
  this.sensorDataSubLocationModel.SensorDataSubLocationlist = [];

  this.sensorDataSubLocationModel.Location = new SensorDataLocationModel();
  this.sensorDataSubLocationModel.Location.SensorDataLocationlist = [];
}
search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.sensorService.GetSensorSubLocationByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatter = (x: { Name: string }) => x.Name;

selectedItem(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataSubLocationModel = item.item;
  this.sensorDataSubLocationModel.SubLocationID = item.item.SubLocationID;
  this.sensorDataSubLocationModel.SubLocationName = item.item.SubLocationName; 
  this.sensorDataSubLocationModel.SubLocationUniqueName = item.item.SubLocationUniqueName;
 
}

//Sensor Auto Complete
searchLocation = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.sensorService.GetSensorLocationByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatterLocation = (x: { Name: string }) => x.Name;

selectedItemLocation(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataSubLocationModel.Location = item.item;
  this.sensorDataSubLocationModel.Location.LocationID = item.item.LocationID;
  this.sensorDataSubLocationModel.Location.LocationName = item.item.LocationName; 
  this.sensorDataSubLocationModel.Location.LocationUniqueName = item.item.LocationUniqueName;
 }
onSubmit() {
  var me = this.sensorDataSubLocationModel;
  this.sensorService.InsertOrUpdateSensorSubLocation(this.sensorDataSubLocationModel).subscribe(response => {
    this.result = response;
  },
    err => {
      this.result = err.message;
    }
  );
  
}

}
