export class SensorDataLocationModel {
    public  LocationID :number;
    public  LocationName :string;
    public  LocationUniqueName :string;
    public  UserID :number;
    public  IsAvailableToPublic :boolean;

        //For template
        public SensorDataLocationAutoComplete:any;
        public SensorDataLocationlist:SensorDataLocationModel[];
}
