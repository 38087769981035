import { SubjectModel } from "../Subject/subject-model";

export class TopicModel {
    public TopicID:number; 
    public TopicName:number; 
    
    public SubjectID:number; 
    public SubjectName:number; 

    
    public SubjectAutoComplete:any;
    public Subjectlist:SubjectModel[];
    public TopicAutoComplete:any;
    public Topiclist:TopicModel[];

    public  TopicSubjectAutoComplete:string;
}
