export class OpeningBalanceModel {
    public OpeningBalanceID: number;
    public AccountID: number;
    public AccountCode: String;
    public Amount: number;
    public Year: number;
    public UserID: number;
    public IsAvailableToPublic: boolean;
    public Comments: String;

    public OpeningBalanceAutoComplete:any;
   public OpeningBalancelist:OpeningBalanceModel[];
}
