
export class AccountBalanceModel {
    public AccountCode:string;
    public Year:number;
    public BalAmount:number;
    public  Month:number;
    public  MonthName:string;
    public AccountBalanceAutoComplete:any;

    public  AccountMasterID:number;
    public  AccountMasterName:string;

     /*For sum */
     public IsCheckedForTotal:boolean;
}
