export class RecipeDetailsModel {
    public RecipeDetailsID: number ;
	public RecipeID: number ; 
	public ProductName: string ;
	public ProductID: number ;
	public ServingSize_MeasurementId: number ; 
	public ServingSizeValue: number ;
	public UserID: number ; 
	public IsAvailableToPublic: number ; 
    public Narration:string;
	public CalculatedPrice:number;
}
