import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { FoodLoggerNutritionMasterModel } from 'src/app/models/FoodLogger/NutritionFact/food-logger-nutrition-master-model';
import { GetAllFoodByNutritionNameAndLimitModel } from 'src/app/models/FoodLogger/NutritionFact/get-all-food-by-nutrition-name-and-limit-model';
import { FoodLoggerService } from 'src/app/services/food-logger.service';

@Component({
  selector: 'app-food-by-nutrution',
  templateUrl: './food-by-nutrution.component.html',
  styleUrls: ['./food-by-nutrution.component.css']
})
export class FoodByNutrutionComponent implements OnInit {
  nutritionName = 'protien';
  value = 18;
  operation = 'gt';
  result = '';
  nutritionMasterModel: FoodLoggerNutritionMasterModel;
  model: any;
  searching = false;
  searchFailed = false;
  public NutritionMasterList: FoodLoggerNutritionMasterModel[];
  public FoodList: GetAllFoodByNutritionNameAndLimitModel[];
  public operationList=[];
  constructor(private foodLoggerservice: FoodLoggerService, private titleService: Title) {
    this.titleService.setTitle("Food by nutrition");

    this.FoodList=[];
    this.NutritionMasterList=[];

     this.operationList=[];
    this.operationList.push({"Name":'Greater than >=',"Value":'gt'});
    this.operationList.push({"Name":'Less Than <=',"Value":'lt'});
  }

  ngOnInit(): void {
    this.nutritionMasterModel = new FoodLoggerNutritionMasterModel();
    this.nutritionMasterModel.NutritionMasterlist = [];
    this.foodLoggerservice.GetAllNutrition().subscribe(response => {
      this.NutritionMasterList = response;
    });

  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.foodLoggerservice.GetNutritionsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { NutritionName: string }) => x.NutritionName;

  selectedItem(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.nutritionMasterModel.NutritionMasterId = item.item.NutritionMasterId;
    this.nutritionMasterModel.NutritionName = item.item.NutritionName;
    this.nutritionMasterModel.FilterNutritionName = item.item.FilterNutritionName;
    this.nutritionMasterModel.Notes = item.item.Notes;

  }
  onSubmit() {
    var me = this.nutritionMasterModel;
    this.foodLoggerservice.InsertOrUpdateNutritionMaster(this.nutritionMasterModel).subscribe(response => {
      this.result = response;
      this.foodLoggerservice.GetAllNutrition().subscribe(response => {
        this.NutritionMasterList = response;
      });
    },
      err => {
        this.result = err.message;
      }
    );
  }
  fetchFoods() {
    this.foodLoggerservice.GetAllFoodByNutritionNameAndLimit(this.nutritionMasterModel.FilterNutritionName, this.value, this.operation).subscribe(response => {
      this.FoodList = response;
    },
      err => { this.result = err.message; }

    );
  }

}
