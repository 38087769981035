import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { VendorModel } from 'src/app/models/ExpenseManager/Vendor/vendor-model';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { VendorApiModel } from 'src/app/models/ExpenseManager/Vendor/vendor-api-model';
import { VitalInformationService } from 'src/app/services/vital-information.service';
import { VitalInformationMeasurementModel } from 'src/app/models/VitalInformation/vital-information-measurement-model';
import * as Highcharts from 'highcharts';
import { SensorDataPostModel } from 'src/app/models/Sensor/sensor-data-post-model';
import { SensorService } from 'src/app/services/sensor.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/Login/user';
import {Title} from "@angular/platform-browser";
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
@Component({
  selector: 'app-vital-information-measurement',
  templateUrl: './vital-information-measurement.component.html',
  styleUrls: ['./vital-information-measurement.component.css']
})
export class VitalInformationMeasurementComponent implements OnInit {
  public vitalInformationMeasurementModel: VitalInformationMeasurementModel;

  model: any;
  searching = false;
  searchFailed = false;
  isEdit = false;
  public currentDate;
  public userList: User[];
  public userId: number;
  constructor(private vitalInformationService: VitalInformationService, private sensorervice1: SensorService, private userService: UserService,private titleService:Title) {
    this.vitalInformationMeasurementModel = new VitalInformationMeasurementModel();
    this.userList = [];
    this.titleService.setTitle("Vital Information Measurement");
  }

  async ngOnInit() {
    this.currentDate = new Date();
    this.vitalInformationMeasurementModel.DateDisplay = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
    this.vitalInformationMeasurementModel.TimeDisplay = { hour: this.currentDate.getHours(), minute: this.currentDate.getMinutes() };

    const this1 = this;
    let model = new SensorDataPostModel();
    model.LocationName = "computer room";
    model.SensorType = "temperature";
    model.LogDate = new Date();
    var dataPromiseDiastolicmmHg = [];
    var dataPromiseSystolicmHg = [];
    var dataPromiseHeartBeat = [];
    var dataPromiseBloodSugarFasting = [];
    var dataPromiseBloodSugarAfterEating = [];
    var getVitalInfo = await this.vitalInformationService.GetAllVitalInformationMeasurementPromise();
    var mePromise = await this1.sensorervice1.GetSensorDataByDay(model);
    for (var i = 0; i < getVitalInfo.length; i++) {
      if (Number(getVitalInfo[i].BloodPressure_DiastolicmmHg) > 0)
        dataPromiseDiastolicmmHg.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodPressure_DiastolicmmHg
        ]);
      if (Number(getVitalInfo[i].BloodPressure_SystolicmHg) > 0)
        dataPromiseSystolicmHg.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodPressure_SystolicmHg
        ]);
      if (Number(getVitalInfo[i].BloodSugar_Pulse) > 0)
        dataPromiseHeartBeat.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodSugar_Pulse
        ]);
      if (Number(getVitalInfo[i].BloodSugar_Fasting) > 0)
        dataPromiseBloodSugarFasting.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodSugar_Fasting
        ]);
      if (Number(getVitalInfo[i].BloodSugar_AfterEating) > 0)
        dataPromiseBloodSugarAfterEating.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodSugar_AfterEating
        ]);

    }
    // for (var i = 0; i < mePromise.SensorAllDayValue.length; i++) {
    //   dataPromiseTemperature.push([
    //     (new Date((mePromise.SensorAllDayValue[i].LastUpdated))).getTime(),
    //     mePromise.SensorAllDayValue[i].SensorValue
    //   ]);
    // }


    this.drawBloodPressureDiastolicmmHg(dataPromiseDiastolicmmHg);
    this.drawBloodPressureSystolicmHgChart(dataPromiseSystolicmHg);
    this.drawHeartBeatChart(dataPromiseHeartBeat);
    this.drawBloodBloodSugarFasting(dataPromiseBloodSugarFasting);
    this.drawBloodBloodSugarAfterEating(dataPromiseBloodSugarAfterEating);

    this.userService.GetAllUsers().subscribe(response => {
      this.userList = response;


    })

  }
  drawBloodBloodSugarFasting(dataPromiseBloodSugarFasting) {
    // Systologic
    var options: any = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          minWidth: 600,
          scrollPositionX: 1
        },
        events: {
          load: function () {
            // set up the updating of the chart each second
            var series = this.series[0];
            // setInterval(function () {
            //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
            //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
            //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
            //     // //                    // var x = (new Date()).getTime(), // current time
            //       y = sensorData.SensorValue.SensorValue;
            //       series.addPoint([x, y], true, true);
            //   });
            //     // var x = (new Date()).getTime(), // current time
            //     //     y = Math.round(Math.random() * 100);
            //     // series.addPoint([x, y], true, true);
            // }, 1000);
          }
        }
      },

      time: {
        useUTC: false
      },

      rangeSelector: {
        buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
        }, {
          count: 5,
          type: 'minute',
          text: '5M'
        }, {
          type: 'all',
          text: 'All'
        }],
        inputEnabled: true,
        selected: 0
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function () {
            //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
            //return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
            return Highcharts.time.dateFormat('%d-%b/%y', this.value);
          }
        }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'Fasting'
        },
        exporting: {
          enabled: false
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [{ // Light air
          from: 80,
          to: 100,
          color: '#5fa632',
          label: {
            text: 'Normal',
            style: {
              color: 'white'
            }
          }
        }, { // Light breeze
          from: 101,
          to: 125,
          color: 'Yellow',
          label: {
            text: 'Impaired glucose',
            style: {
              color: 'black'
            }
          }
        }, { // Gentle breeze
          from: 126,
          to: 160,
          color: 'Red',
          label: {
            text: 'Diabetic',
            style: {
              color: 'Black'
            }
          }
        }]

      },
      title: {
        text: 'Blood Glucose Chart (Fasting)'
      },

      exporting: {
        enabled: false
      },

      series: [{
        name: 'Fasting',
        data: (
          function () {
            return dataPromiseBloodSugarFasting;
          }())

      },


      ]
    }

    var meChart1 = Highcharts.chart('containerBloodSugarFasting', options);

  }
  drawBloodBloodSugarAfterEating(dataPromiseBloodSugarAfterEating) {
    // Systologic
    var options: any = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          minWidth: 600,
          scrollPositionX: 1
        },
        events: {
          load: function () {
            // set up the updating of the chart each second
            var series = this.series[0];
            // setInterval(function () {
            //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
            //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
            //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
            //     // //                    // var x = (new Date()).getTime(), // current time
            //       y = sensorData.SensorValue.SensorValue;
            //       series.addPoint([x, y], true, true);
            //   });
            //     // var x = (new Date()).getTime(), // current time
            //     //     y = Math.round(Math.random() * 100);
            //     // series.addPoint([x, y], true, true);
            // }, 1000);
          }
        }
      },

      time: {
        useUTC: false
      },

      rangeSelector: {
        buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
        }, {
          count: 5,
          type: 'minute',
          text: '5M'
        }, {
          type: 'all',
          text: 'All'
        }],
        inputEnabled: true,
        selected: 0
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function () {
            //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
            //return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
            return Highcharts.time.dateFormat('%d-%b/%y', this.value);
          }
        }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'After Eating'
        },
        exporting: {
          enabled: false
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [{ // Light air
          from: 120,
          to: 140,
          color: 'Green',
          label: {
            text: 'Normal (120 -140)',
            style: {
              color: 'white'
            }
          }
        }, { // Gentle breeze
          from: 140,
          to: 200,
          color: 'Yellow',
          label: {
            text: 'Impaired Glucose (140-200)',
            style: {
              color: 'Black'
            }
          }
        }, { // Moderate breeze
          from: 200,
          to: 260,
          color: 'Red',
          label: {
            text: 'Diabetic',
            style: {
              color: '#606060'
            }
          }
        }]

      },
      title: {
        text: 'Blood Glucose Chart (After Eating)'
      },

      exporting: {
        enabled: false
      },

      series: [{
        name: 'After Eating',
        data: (
          function () {
            return dataPromiseBloodSugarAfterEating;
          }())

      },


      ]
    }

    var meChart1 = Highcharts.chart('containerBloodSugarAfterEating', options);

  }
  drawBloodPressureDiastolicmmHg(dataPromiseDiastolicmmHg) {
    //Diastolic 
    var options: any = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          minWidth: 600,
          scrollPositionX: 1
        },
        events: {
          load: function () {
            // set up the updating of the chart each second
            var series = this.series[0];
            // setInterval(function () {
            //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
            //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
            //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
            //     // //                    // var x = (new Date()).getTime(), // current time
            //       y = sensorData.SensorValue.SensorValue;
            //       series.addPoint([x, y], true, true);
            //   });
            //     // var x = (new Date()).getTime(), // current time
            //     //     y = Math.round(Math.random() * 100);
            //     // series.addPoint([x, y], true, true);
            // }, 1000);
          }
        }
      },

      time: {
        useUTC: false
      },

      rangeSelector: {
        buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
        }, {
          count: 5,
          type: 'minute',
          text: '5M'
        }, {
          type: 'all',
          text: 'All'
        }],
        inputEnabled: true,
        selected: 0
      },
      xAxis: {
        reversed: false,
        type: 'datetime',
        labels: {
          formatter: function () {
            //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
            //return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
            return Highcharts.time.dateFormat('%d-%b/%y', this.value);
          }
        }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'In mm Hg Diastolic (Low Number)'
        },
        exporting: {
          enabled: false
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [{ // Light air
          from: 0,
          to: 80,
          color: 'Green',
          label: {
            text: 'Normal / Elevated',
            style: {
              color: 'white'
            }
          }
        }, { // Light breeze
          from: 80,
          to: 89,
          color: 'Orange',
          label: {
            text: 'High Blood Pressure (Hyper Tension Stage 1)',
            style: {
              color: 'white'
            }
          }
        }, { // Gentle breeze
          from: 90,
          to: 120,
          color: '#FF7F7F',
          label: {
            text: 'High Blood Pressure (Hyper Tension Stage 2)',
            style: {
              color: 'Black'
            }
          }
        }, { // Moderate breeze
          from: 120,
          to: 160,
          color: 'Red',
          label: {
            text: 'Hypertensive crisis (Consult your doctor immediatly)',
            style: {
              color: 'white'
            }
          }
        }]
        //         plotLines: [{
        //           color: 'green',
        //           width: 2,
        //           value: 120,
        //           dashStyle: 'ShortDash',
        //           label: {
        //               text: 'Normal Systologic / Stage 2 Diastolic',
        //           },
        //       },
        //       {
        //         color: 'yellow',
        //         width: 2,
        //         value: 129,
        //         dashStyle: 'ShortDash',
        //         label: {
        //             text: 'Elevated Systologic',
        //         },
        //     },
        //     {
        //       color: 'orange',
        //       width: 2,
        //       value: 139,
        //       dashStyle: 'ShortDash',
        //       label: {
        //           text: 'Stage 1 Systologic',
        //       },
        //   },
        //   {
        //   color: 'red',
        //   width: 2,
        //   value: 180,
        //   dashStyle: 'ShortDash',
        //   label: {
        //       text: 'Crysis 2 Systologic',
        //   },
        // },
        //       {
        //         color: 'green',
        //           width: 2,
        //           value: 80,
        //           dashStyle: 'longdash',
        //           label: {
        //               text: 'Normal Diastolic',
        //           }
        //     },

        //   {
        //     color: 'Orange',
        //       width: 2,
        //       value: 89,
        //       dashStyle: 'longdash',
        //       label: {
        //           text: 'Stage 1 Diastolic High Blood Pressure (Hyper Tenstion S) 80-89',
        //       }
        // },
        // {
        //   color: 'red',
        //     width: 2,
        //     value: 118,
        //     dashStyle: 'longdash',
        //     label: {
        //         text: '',
        //     }
        // }
        //     ]
      },
      title: {
        text: 'Blood Pressure Diastolic (In Low Number)'
      },

      exporting: {
        enabled: false
      },

      series: [{
        name: 'Diastolic mmHg',
        data: (
          function () {
            return dataPromiseDiastolicmmHg;
          }())
        // data: (function () {
        //     // generate an array of random data
        //     var data = [],
        //         time = (new Date()).getTime(),
        //         i;

        //     for (i = -50; i <= 0; i += 1) {
        //         data.push([
        //             time + i * 1000,
        //             Math.round(Math.random() * 100)
        //         ]);
        //     }
        //    // data.push([time],Math.round(Math.random() * 100))
        //     return data;
        // }())
      },
        // {
        //   name: 'SystolicmHg',
        //   data: (
        //     function () {
        //       return dataPromiseSystolicmHg;
        //     }())

        // },
        // {
        //   name: 'HeartBeat',
        //   data: (
        //     function () {
        //       return dataPromiseHeartBeat;
        //     }())

        // },


      ]
    }

    var meChart = Highcharts.chart('containerBloodPressureDiastolicmmHg', options);

  }
  drawBloodPressureSystolicmHgChart(dataPromiseSystolicmHg) {
    // Systologic
    var options: any = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          minWidth: 600,
          scrollPositionX: 1
        },
        events: {
          load: function () {
            // set up the updating of the chart each second
            var series = this.series[0];
            // setInterval(function () {
            //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
            //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
            //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
            //     // //                    // var x = (new Date()).getTime(), // current time
            //       y = sensorData.SensorValue.SensorValue;
            //       series.addPoint([x, y], true, true);
            //   });
            //     // var x = (new Date()).getTime(), // current time
            //     //     y = Math.round(Math.random() * 100);
            //     // series.addPoint([x, y], true, true);
            // }, 1000);
          }
        }
      },

      time: {
        useUTC: false
      },

      rangeSelector: {
        buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
        }, {
          count: 5,
          type: 'minute',
          text: '5M'
        }, {
          type: 'all',
          text: 'All'
        }],
        inputEnabled: true,
        selected: 0
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function () {
            //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
            //return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
            return Highcharts.time.dateFormat('%d-%b/%y', this.value);
          }
        }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: ' In mm Hg (Upper Number)'
        },
        exporting: {
          enabled: false
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [{ // Light air
          from: 0,
          to: 120,
          color: 'Green',
          label: {
            text: 'Normal',
            style: {
              color: 'white'
            }
          }
        }

          , { // Light breeze
          from: 120,
          to: 129,
          color: 'Yellow',
          label: {
            text: 'Elevated',
            style: {
              color: 'black'
            }
          }
        },
        { // Light breeze
          from: 130,
          to: 139,
          color: 'Orange',
          label: {
            text: 'High Blood Pressure (Hyper Tension Stage 1)',
            style: {
              color: 'white'
            }
          }
        },
        { // Gentle breeze
          from: 140,
          to: 180,
          color: '#FF7F7F',
          label: {
            text: 'High Blood Pressure (Hyper Tension Stage 2)',
            style: {
              color: 'Black'
            }
          }
        }, { // Moderate breeze
          from: 180,
          to: 240,
          color: 'Red',
          label: {
            text: 'Hypertensive crisis (Consult your doctor immediatly)',
            style: {
              color: 'white'
            }
          }
        }]

      },
      title: {
        text: 'Blood Pressure Systolic (In Higher Number)'
      },

      exporting: {
        enabled: false
      },

      series: [{
        name: 'SystolicmmHg',
        data: (
          function () {
            return dataPromiseSystolicmHg;
          }())

      },


      ]
    }

    var meChart1 = Highcharts.chart('containerBloodPressureSystolicmHg', options);

  }


  drawHeartBeatChart(dataPromiseHeartBeat) {
    //Hearbeat
    var options: any = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          minWidth: 600,
          scrollPositionX: 1
        },
        events: {
          load: function () {
            // set up the updating of the chart each second
            var series = this.series[0];
            // setInterval(function () {
            //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
            //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
            //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
            //     // //                    // var x = (new Date()).getTime(), // current time
            //       y = sensorData.SensorValue.SensorValue;
            //       series.addPoint([x, y], true, true);
            //   });
            //     // var x = (new Date()).getTime(), // current time
            //     //     y = Math.round(Math.random() * 100);
            //     // series.addPoint([x, y], true, true);
            // }, 1000);
          }
        }
      },

      time: {
        useUTC: false
      },

      rangeSelector: {
        buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
        }, {
          count: 5,
          type: 'minute',
          text: '5M'
        }, {
          type: 'all',
          text: 'All'
        }],
        inputEnabled: true,
        selected: 0
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function () {
            //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
            //return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
            return Highcharts.time.dateFormat('%d-%b/%y', this.value);
          }
        }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'In Beats Per Min'
        },
        exporting: {
          enabled: false
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [{ // Light air
          from: 0,
          to: 120,
          color: '#5fa632',
          label: {
            text: 'Normal Systologic / Stage 2 Diastolic',
            style: {
              color: 'white'
            }
          }
        }, { // Light breeze
          from: 0,
          to: 80,
          color: '#436031',
          label: {
            text: 'Normal Diastolic',
            style: {
              color: 'white'
            }
          }
        }, { // Gentle breeze
          from: 120,
          to: 129,
          color: 'Yellow',
          label: {
            text: 'Elevated  Systologic',
            style: {
              color: 'Black'
            }
          }
        }, { // Moderate breeze
          from: 80,
          to: 89,
          color: 'Orange',
          label: {
            text: 'High Blood Diastolic',
            style: {
              color: '#606060'
            }
          }
        }, { // Fresh breeze
          from: 8,
          to: 11,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'Fresh breeze',
            style: {
              color: '#606060'
            }
          }
        }, { // Strong breeze
          from: 11,
          to: 14,
          color: 'rgba(0, 0, 0, 0)',
          label: {
            text: 'Strong breeze',
            style: {
              color: '#606060'
            }
          }
        }, { // High wind
          from: 14,
          to: 15,
          color: 'rgba(68, 170, 213, 0.1)',
          label: {
            text: 'High wind',
            style: {
              color: '#606060'
            }
          }
        }]

      },
      title: {
        text: 'HeartBeat'
      },

      exporting: {
        enabled: false
      },

      series: [{
        name: 'HeartBeat In BPM',
        data: (
          function () {
            return dataPromiseHeartBeat;
          }())

      },


      ]
    }

    var HeartBeatChart = Highcharts.chart('containerBloodPressureHeartBeat', options);


  }

  onSubmit() {
    var newDate = new Date(this.vitalInformationMeasurementModel.DateDisplay.year, this.vitalInformationMeasurementModel.DateDisplay.month - 1
      , this.vitalInformationMeasurementModel.DateDisplay.day,
      this.vitalInformationMeasurementModel.TimeDisplay.hour, this.vitalInformationMeasurementModel.TimeDisplay.minute, 0, 0);
    // var newDate = moment({ y: 2017, M: 0, d: 4, h: 15, m: 10, s: 0, ms: 0 });
    this.vitalInformationMeasurementModel.Created = newDate;
    // // var me = this.vendorModel;
    // // var vendorApimodel = new VendorApiModel();
    // // vendorApimodel.vendorAutoID = me.VendorAutoID;
    // // vendorApimodel.vendorID = me.VendorID;
    // // vendorApimodel.vendorName = me.VendorName;
    this.vitalInformationService.InsertOrUpdateVitalInformationMeasurement(this.vitalInformationMeasurementModel).subscribe(
      response => {
        console.log(response);
        this.vitalInformationMeasurementModel = new VitalInformationMeasurementModel();
        this.currentDate = new Date();
        this.vitalInformationMeasurementModel.DateDisplay = { year: this.currentDate.getFullYear(), month: this.currentDate.getMonth() + 1, day: this.currentDate.getDate() };
        this.vitalInformationMeasurementModel.TimeDisplay = { hour: this.currentDate.getHours(), minute: this.currentDate.getMinutes() };
      }
    );
  }
  onModelChange(event) {
    if (!this.isEdit) {



      console.log(event);
      var newDate = new Date(this.vitalInformationMeasurementModel.DateDisplay.year, this.vitalInformationMeasurementModel.DateDisplay.month - 1
        , this.vitalInformationMeasurementModel.DateDisplay.day,
        this.vitalInformationMeasurementModel.TimeDisplay.hour, this.vitalInformationMeasurementModel.TimeDisplay.minute, 0, 0);
      // var newDate = moment({ y: 2017, M: 0, d: 4, h: 15, m: 10, s: 0, ms: 0 });
      this.vitalInformationMeasurementModel.Created = newDate;
      this.vitalInformationService.GetVitalInformationMeasurementByDate(newDate).subscribe(response => {

        if (response.length > 0) {

          this.vitalInformationMeasurementModel = response[0];

          var newDate = new Date(this.vitalInformationMeasurementModel.Created);

          this.vitalInformationMeasurementModel.DateDisplay = { year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: newDate.getDate() };
          this.vitalInformationMeasurementModel.TimeDisplay = { hour: newDate.getHours(), minute: newDate.getMinutes() };

          // var newDate = new Date(this.vitalInformationMeasurementModel.DateDisplay.year, this.vitalInformationMeasurementModel.DateDisplay.month - 1
          //   , this.vitalInformationMeasurementModel.DateDisplay.day,
          //   this.vitalInformationMeasurementModel.TimeDisplay.hour, this.vitalInformationMeasurementModel.TimeDisplay.minute, 0, 0);
          //   this.vitalInformationMeasurementModel.DateDisplay = { year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: this.currentDate.getDate() };
          //   this.vitalInformationMeasurementModel.TimeDisplay = { hour: newDate.getHours(), minute: newDate.getMinutes() };
        }

      },
        error => {
          console.log(console.error);


        });
    }
    // this.getFoodLogByDate(this.foodLogModel.Created);
  }
setDataMyway(selectedValue){
  var me = this.userId;
  var you = selectedValue;
}
 async setDataByUserId(){
  let me = this.vitalInformationMeasurementModel.UserID;
  if (!this.isEdit) {
    var dataPromiseDiastolicmmHg = [];
    var dataPromiseSystolicmHg = [];
    var dataPromiseHeartBeat = [];
    var dataPromiseBloodSugarFasting = [];
    var dataPromiseBloodSugarAfterEating = [];
    var getVitalInfo = await this.vitalInformationService.GetAllVitalInformationMeasurementPromise();

    getVitalInfo = getVitalInfo.filter(
      function (e) {
        return e.UserID === Number(me);
      }
    );
    for (var i = 0; i < getVitalInfo.length; i++) {
      if (Number(getVitalInfo[i].BloodPressure_DiastolicmmHg) > 0)
        dataPromiseDiastolicmmHg.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodPressure_DiastolicmmHg
        ]);
      if (Number(getVitalInfo[i].BloodPressure_SystolicmHg) > 0)
        dataPromiseSystolicmHg.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodPressure_SystolicmHg
        ]);
      if (Number(getVitalInfo[i].BloodSugar_Pulse) > 0)
        dataPromiseHeartBeat.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodSugar_Pulse
        ]);
      if (Number(getVitalInfo[i].BloodSugar_Fasting) > 0)
        dataPromiseBloodSugarFasting.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodSugar_Fasting
        ]);
      if (Number(getVitalInfo[i].BloodSugar_AfterEating) > 0)
        dataPromiseBloodSugarAfterEating.push([
          (new Date((getVitalInfo[i].Created))).getTime(),
          getVitalInfo[i].BloodSugar_AfterEating
        ]);

    }
    // for (var i = 0; i < mePromise.SensorAllDayValue.length; i++) {
    //   dataPromiseTemperature.push([
    //     (new Date((mePromise.SensorAllDayValue[i].LastUpdated))).getTime(),
    //     mePromise.SensorAllDayValue[i].SensorValue
    //   ]);
    // }


    this.drawBloodPressureDiastolicmmHg(dataPromiseDiastolicmmHg);
    this.drawBloodPressureSystolicmHgChart(dataPromiseSystolicmHg);
    this.drawHeartBeatChart(dataPromiseHeartBeat);
    this.drawBloodBloodSugarFasting(dataPromiseBloodSugarFasting);
    this.drawBloodBloodSugarAfterEating(dataPromiseBloodSugarAfterEating);

    // this.exependitureModelList = this.incomeExpenditureModelList.filter(
    //   function (e) {
    //     return e.IncomeExpenditure === "Expenditure" && e.Year === year;
    //   }
    // );
  }
  }
}
