<fieldset class="section-legend-border  col-md-12 col-lg-12" #FoodLog>
  <legend class="section-legend-border">Food Log</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #logFoodForm="ngForm">

      <div class="col-sm-12 col-md-12 col-12">
      
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="exampleInputPassword1">Food Log Date</label>
              <div class="input-group">

                <input class="form-control" placeholder="yyyy-mm-dd" name="transactionModel.DateDisplay"
                  [(ngModel)]="foodLogModel.DateDisplay" ngbDatepicker #d="ngbDatepicker"
                  (ngModelChange)="onModelChange($event)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>
            </div>

            <div class="form-group col-md-3">
              <div id="time-picker-section">
                <ngb-timepicker name="foodLogModel.TimeDisplay" [(ngModel)]="foodLogModel.TimeDisplay">
                </ngb-timepicker>
              </div>
              <!-- <label for="exampleInputPassword1">Voucher Time</label> -->
            </div>
            <div class="col-md-2">
              <div class="water-content">
                <app-fit-bit-water [DateFromOutside]="DateFromOutside"></app-fit-bit-water>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="exampleFormControlSelect1">Meal Type</label>
              <select class="form-control" id="MealTypeId" name="foodLogModel.MealTypeModel.MealTypeId"
                [(ngModel)]="foodLogModel.MealTypeModel.MealTypeId" #MealType="ngModel"
                (change)='onOptionsSelected(MealType.value)'>
                <option [ngValue]="undefined" disabled selected> Select Meal </option>
                <option *ngFor="let item of mealTypeModel" [value]="item.MealTypeId">
                  {{item.Name}}
                </option>
              </select>
              <div *ngIf="!MealType.valid"> {{MealType.errors.Required}} </div>
            </div>
          </div>
          <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #RecentFoods *ngIf="recentLogFoods.length>0">
            <legend class="section-legend-border">Recent Foods</legend>
            <div class="form-row" *ngIf="recentLogFoods.length>0">
              <div class="form-group col-sm-12 col-md-12 col-lg-12 recent-food-section">
                <div>
                </div>
                <span *ngFor="let item of recentLogFoods"
                  (click)="selectedRecentLogFoods(item.ProductID,item.ProductName)">
                  {{item.ProductID}} | {{item.ProductName}}
                </span>
              </div>
            </div>
          </fieldset>
          <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #RecentRecipe *ngIf="recentLogReciepe.length>0">
            <legend class="section-legend-border">Recent Recipe</legend>
            <div class="form-row" *ngIf="recentLogReciepe.length>0">
              <div>
              </div>
              <div class="form-group col-sm-12 col-md-12 col-lg-12 recent-reciepe-section">
                <span *ngFor="let item of recentLogReciepe" (click)="selectedRecentLogReciepe(item.RecipeID)">
                  {{item.RecipeID}} | {{item.RecipeName}}
                </span>
              </div>
            </div>
          </fieldset>

       
        <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #SearchFoodRecipe>
          <legend class="section-legend-border">Search Food / Recipe</legend>
          <div class="form-row">
            <div class="form-group col-md-12">
              <ng-template #rt let-r="result" let-t="term">
                <!-- {{ r.Name}} -->
                {{ r.ProductName}}
              </ng-template>
              <label for="typeahead-basic">Search for a Food:</label>
              <input id="typeahead-basic" type="text" class="form-control"
                name="foodLogModel.FoodModel.FoodAutoComplete" [(ngModel)]="foodLogModel.FoodModel.FoodAutoComplete"
                [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt"
                (selectItem)="selectedItem($event)" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <ng-template #rtRecipe let-r="result" let-t="term">
                <!-- {{ r.Name}} -->
                {{ r.RecipeName}}
              </ng-template>
              <label for="typeahead-basic">Search for a Recipe:</label>
              <input id="typeahead-basic" type="text" class="form-control" name="recipeModel.RecipeList"
                [(ngModel)]="recipeModel.RecipeList" [ngbTypeahead]="searchRecipe" [inputFormatter]="formatterRecipe"
                [resultTemplate]="rtRecipe" (selectItem)="selectedItemRecipe($event)" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="disabledTextInput">Food Id</label>
              <input type="text" [(ngModel)]="foodLogModel.FoodModel.FoodId" name="foodLogModel.FoodModel.FoodId"
                placeholder="id" class="form-control" #FoodId="ngModel">
              <div *ngIf="!FoodId.valid"> {{FoodId.errors.Required}} </div>
            </div>
            <div class="form-group col-md-8">
              <label for="disabledTextInput">Food Name</label>
              <input type="text" [(ngModel)]="foodLogModel.FoodModel.Name" name="foodLogModel.FoodModel.Name" disabled
                placeholder="Food Name" class="form-control" #Name="ngModel">

            </div>
          </div>
        </fieldset>
        <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #Serving>
          <legend class="section-legend-border">Serving</legend>
          <div class="form-row">
           
            <div class="form-group col-md-2">
              <label for="disabledTextInput">Serving Size</label>
              <input type="text" [(ngModel)]="this.nutritionFactModel.ServingSize"
                name="this.nutritionFactModel.ServingSize" placeholder="Serving Size" class="form-control"
                #ServingSize="ngModel" disabled>
              <!-- <div *ngIf="!ServingQuantity.valid"> {{ServingQuantity.errors| json}} </div> -->
              <!-- <div *ngIf="!Quantity.valid"> {{Quantity.errors.Required}} </div> -->
            </div>
            <div class="form-group  col-md-2" style="margin-left: 29px;margin-top:40px;">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isServingSize"
                [(ngModel)]="isServingSize">
              <label class="form-check-label" for="exampleCheck1">Use Serving Size </label>
              <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
            </div>
            <div class="form-group col-md-6">
              <label for="disabledTextInput">Quantity (In Gm or Ml) For DB</label>
              <input type="text" [(ngModel)]="foodLogModel.Quantity" name="foodLogModel.Quantity" placeholder="Quantity"
                class="form-control">
            </div>
            <div class="form-group col-md-2">
              <label for="disabledTextInput">Serving Qty (In Gm or Ml) For Facts</label>
              <input type="text" [(ngModel)]="foodLogModel.ServingQuantity" name="foodLogModel.ServingQuantity"
                placeholder="Serving Qty" class="form-control" (ngModelChange)="SevingQuanityChange($event)"
                #Quantity="ngModel">
              <!-- <div *ngIf="!ServingQuantity.valid"> {{ServingQuantity.errors| json}} </div> -->
              <div *ngIf="!Quantity.valid"> {{Quantity.errors.Required}} </div>
            </div>
          </div>
        </fieldset>
        <div class="form-row">
          <div class="col-sm-12">
            <div class="col-sm-12 col-md-12 col-12">
              <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #NutritionFacts>
                <legend class="section-legend-border">Nutrition Facts</legend>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <hr>
                    <div class="form-row">
                      <div class="col-md-4">
                        <div id="dvNutritionFact">
                          <div id="NutritionFact">
                            <div class="nutpanel">
                              <!-- <table style="table-layout:fixed"> -->
                                <table>
                                <colgroup>
                                  <col width="16">
                                  <col width="*">
                                  <col width="50">
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td class="" colspan="3">
                                      <!-- @*Serving Size: 1 pack (12 pieces)*@ -->
                                      <span> Serving Size: {{this.nutritionFactModel.ServingSize}}
                                      </span>
                                      <br />
                                      Nutrition Fact Base value: {{this.nutritionFactModel.ServingSizeValue}}
                                      {{this.nutritionFactModel.ServingSize_Measurement}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="sep" colspan="3">&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td class="strong small" style="font-weight:bold;" colspan="3">Current Amount Per
                                      Serving
                                      {{foodLogModel.Quantity}}
                                      {{this.nutritionFactModel.ServingSize_Measurement}}(Rs.
                                      {{this.priceModel.CalculatedAmount|number:'1.2-2'}})</td>
                                  </tr>
                                  <tr>
                                    <td class="borderTop" colspan="3">
                                      <div style="float:right">Calories from Fat 63</div>
                                      <b>Calories</b> {{this.nutritionFactModel.Calories|number:'1.2-2'}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="sep" colspan="3">&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td class="strong small" colspan="3" align="right">% Daily Values*</td>
                                  </tr>
                                  <tr>
                                    <td class="borderTop" colspan="2">
                                      <b>Total Fat</b>
                                    </td>
                                    <td class="borderTop" align="right"><b>
                                        {{this.nutritionFactModel.TotalFat|number:'1.2-2'}}</b>{{this.nutritionFactModel.TotalFat_Measurement}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                    <td class="borderTop">
                                      Saturated Fat
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.SaturatedFat}}</b>{{this.nutritionFactModel.SaturatedFat_Measurement}}
                                    </td>
                                  </tr>
        
        
                                  <tr>
                                    <td class=" borderTop" colspan="2">
                                      <b>Cholesterol</b>
        
                                    </td>
                                    <td class="borderTop" align="right"><b>{{this.nutritionFactModel.Cholesterol}}</b>
                                      {{this.nutritionFactModel.Cholesterol_Measurement}}</td>
                                  </tr>
                                  <tr>
                                    <td class=" borderTop" colspan="2">
                                      <b>Sodium</b>
                                    </td>
                                    <td class="borderTop" align="right"><b>
                                        {{this.nutritionFactModel.Sodium}}</b>{{this.nutritionFactModel.Sodium_Measurement}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class=" borderTop" colspan="2">
                                      <b>Total Carbohydrate</b>
                                    </td>
                                    <td class="borderTop" align="right"><b>
                                        {{this.nutritionFactModel.TotalCabohydrate|number:'1.2-2'}}</b>
                                      {{this.nutritionFactModel.TotalCabohydrate_Measurement}}</td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                    <td class="borderTop">
                                      Dietary Fiber
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.DietryFiber}}</b>{{this.nutritionFactModel.DietryFiber_Measurement}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                    <td class="borderTop">
                                      Sugars
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Sugar|number:'1.2-2'}}</b>{{this.nutritionFactModel.Sugar_Measurement}}
                                    </td>
                                  </tr>
        
                                  <tr>
                                    <td class=" borderTop" colspan="2">
                                      <b>Protein</b> <br><span (click)="showText()" class="custom-link"
                                        style="text-decoration: underline;">
                                        {{ isReadMore ? 'Read More': 'Read Less' }}
                                      </span>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Protien|number:'1.2-2'}}</b>{{this.nutritionFactModel.Protien_Measurement}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="sep" colspan="3">&nbsp;</td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b>Vitamin A</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminA}}</b>{{this.nutritionFactModel.VitaminA_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b>Vitam B1 (Thiamin)</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminB1_Thiamin}}</b>{{this.nutritionFactModel.VitaminB1_Thiamin_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> VitaminB2_Riboflavin</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminB2_Riboflavin}}</b>{{this.nutritionFactModel.VitaminB2_Riboflavin_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Vitamin B3 (Niacin)</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminB3_Niacin}}</b>{{this.nutritionFactModel.VitaminB3_Niacin_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Vitamin B5 (Pantothenic Acid)</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminB5_PantothenicAcid}}</b>{{this.nutritionFactModel.VitaminB5_PantothenicAcid_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> VitaminB6</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminB6}}</b>{{this.nutritionFactModel.VitaminB6_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> VitaminB12</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminB12}}</b>{{this.nutritionFactModel.VitaminB12_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> VitaminC</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminC}}</b>{{this.nutritionFactModel.VitaminC_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> VitaminD</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminD}}</b>{{this.nutritionFactModel.VitaminD_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> VitaminE</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminE}}</b>{{this.nutritionFactModel.VitaminE_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> VitaminK</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.VitaminK}}</b>{{this.nutritionFactModel.VitaminK_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class="sep" colspan="3">&nbsp;</td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Folate</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Folate}}</b>{{this.nutritionFactModel.Folate_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Iron</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Iron}}</b>{{this.nutritionFactModel.Iron_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Calcium</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Calcium}}</b>{{this.nutritionFactModel.Calcium_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Phosphorus</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Phosphorus}}</b>{{this.nutritionFactModel.Phosphorus_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Mangnese</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Mangnese}}</b>{{this.nutritionFactModel.Mangnese_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Iodin</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Iodin}}</b>{{this.nutritionFactModel.Iodin_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Biotin</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Biotin}}</b>{{this.nutritionFactModel.Biotin_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Zinc</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Zinc}}</b>{{this.nutritionFactModel.Zinc_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Copper</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Copper}}</b>{{this.nutritionFactModel.Copper_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Selenium</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Selenium}}</b>{{this.nutritionFactModel.Selenium_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Omega3</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Omega3}}</b>{{this.nutritionFactModel.Omega3_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Cobalamin</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Cobalamin}}</b>{{this.nutritionFactModel.Cobalamin_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Oxalate</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Oxalate}}</b>{{this.nutritionFactModel.Oxalate_Measurement}}
                                    </td>
                                  </tr>
                                  <tr [ngClass]="{'limitTextHeight': isReadMore}">
                                    <td class=" borderTop" colspan="2">
                                      <b> Water</b>
                                    </td>
                                    <td class="borderTop" align="right">
                                      <b>{{this.nutritionFactModel.Water}}</b>{{this.nutritionFactModel.Water_Measurement}}
                                    </td>
                                  </tr>
                                  <!--     <tr>
                                  <td colspan="3">
                                      <table class="generic" style="margin:0px">
                                          <tbody>
                                             
                                              <tr>
                                                <td width="50%">
                                                  Vitam B3(Nia)
                                                 
                                                </td>
                                                <td width="50%">
                                                  Vitam B5(Pan)
                                                 
                                                </td>
                                            </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                             <tr>
                                  <td colspan="3" class="borderTop">
                                      <table class="generic" style="margin:0px">
                                          <tbody>
                                              <tr>
                                                  <td width="50%">
                                                      Calcium
                                                      2%
                                                  </td>
                                                  <td width="50%">
                                                      Iron
                                                      -
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                              <tr>
                                  <td class="borderTop" colspan="3">
                                      <table style="border-collapse:collapse">
                                          <colgroup>
                                              <col width="5">
                                              <col width="*">
                                          </colgroup>
                                          <tbody>
                                              <tr valign="top">
                                                  <td class="footer">*&nbsp;</td>
                                                  <td class="footer">Percent Daily Values are based on a 2000 calorie diet. Your daily values may be higher or lower depending on your calorie needs.</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr> -->
                                </tbody>
                              </table>
                            </div>
                          </div>
        
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="">Food Sugar Grade</div>
                        <div class="food-grade">{{this.foodSugarGrade}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #FoodImage *ngIf="foodImageModel.length>0">
                  <legend class="section-legend-border">Food Image</legend>
                  <div class="row">
                      <div class="col-sm-12">
                          <div *ngFor="let foodImageMo of foodImageModel" class="image-section">
                              {{foodImageMo.Name}}
                              <img [src]='foodImageMo.ImageByteData'  [id]="foodImageMo.Name" />
                          </div>
                      </div>
                  </div>
               </fieldset>
              </fieldset>
            </div>
          </div>
        </div>
        <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #RecipeDetails
        *ngIf="recipeModel.RecipeDetails.length>0">
        <legend class="section-legend-border">Recipe Details</legend>
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-12" *ngIf="recipeModel.RecipeDetails.length>0">
            <hr>
            <h3>Recipe Details {{recipeModel.RecipeID}} </h3>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="disabledTextInput">Recipe Name</label>
                <input type="text" [(ngModel)]="recipeModel.RecipeName" name="recipeModel.RecipeName"
                  placeholder="Recipe Name" class="form-control" #RecipeName="ngModel">
                <div *ngIf="!RecipeName.valid"> {{RecipeName.errors.Required}} </div>
              </div>
              <div class="form-group col-md-6">
                <label for="disabledTextInput">Serving Quantity</label>
                <input type="text" [(ngModel)]="recipeModel.RecipeServingSize" name="recipeModel.RecipeServingSize"
                  placeholder="Serving Quantity" class="form-control" (ngModelChange)="RecipeQuanityChange($event)"
                  #RecipeServingSize="ngModel">
                <div *ngIf="!RecipeServingSize.valid"> {{RecipeServingSize.errors.Required}} </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-12">
                <table style="width:'100%'">
                  <tr class="details">
                    <th>RecipeID</th>
                    <th>Prod Name</th>
                    <th>Prod ID</th>
                    <th>Serv Size</th>
                    <th> Price</th>
                  </tr>
                  <ng-container *ngFor="let recipeD of recipeModel.RecipeDetails">

                    <tr class="details">
                      <td><span style="color: red;" (click)=removeRecipeItem(recipeD.RecipeDetailsID)
                          class="custom-link fa fa-trash">
                        </span> {{recipeD.RecipeDetailsID}}</td>
                      <td>{{recipeD.ProductName}}</td>
                      <td>{{recipeD.ProductID}}</td>
                      <td>{{recipeD.ServingSizeValue}}</td>
                      <td>{{recipeD.CalculatedPrice|number:'1.2-2'}}</td>
                    </tr>

                  </ng-container>
                </table>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-12">
                <label for="disabledTextInput">Recipe Narration</label>
                <!-- <input type="text" [(ngModel)]="productModel.SummaryNotes" name="productModel.SummaryNotes" placeholder="Summary Notes" class="form-control"> -->
                <textarea type="text" [(ngModel)]="recipeModel.Narration" name="recipeModel.Narration"
                  placeholder="Recipe Narration" class="form-control" rows="5"  #Narration="ngModel"></textarea>
                  <div *ngIf="!Narration.valid"> {{Narration.errors.MaxLength}} </div>
                  {{recipeModel.Narration?.length}}
              </div>
            </div>
          </div>
        </div>

        
      </fieldset>
        <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #Save>
          <legend class="section-legend-border">Save</legend>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12">
              <textarea type="text" [(ngModel)]="foodLogMasterModel.SummaryNotes" name="foodLogMasterModel.SummaryNotes"
                placeholder="Summary Notes" class="form-control" rows="5"> </textarea>
            </div>
            <div class="form-group col-md-12">
              <button type="submit" class="btn btn-primary btn-block">Add</button>
            </div>
            <div class="form-group col-md-6">
              <button type="button" class="btn btn-primary btn-block" (click)=addToRecipe()>Add to Recipe</button>

            </div>
            <div class="form-group col-md-6">
              <button type="button" class="btn btn-primary btn-block" (click)=saveRecipe()>Save Recipe</button>

            </div>
            <div class="col-sm-12">
              {{result}}
            </div>
          </div>
        </fieldset>
       
      </div>
    </form>

  </div>
</div>
   </fieldset>

<div class="form-row">
  <div class="col-sm-12">
    <div class="col-sm-12">
    <!-- <h4>FoodLog</h4> -->
    <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #FoodLog>
      <legend class="section-legend-border ">Food Log</legend>
      <hr />
      <div id="foodSection">
        <div id="foodDiary">
          <table>
            <thead>
              <tr>
                <th>Food Name</th>
                <th>Fat</th>
                <th>Carbs</th>
                <th>Calcium</th>
                <th>Prot</th>
                <th>Cals</th>
                <th>Sugar</th>
                <th>Price</th>
                <th>Sodium</th>
                <th>Phosphorus</th>
                <th>Oxalate</th>
                <th>Water</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of mealTypeModel">

                <tr>
                  <td class="meal-heading" colspan="12">{{item.Name}}</td>
                </tr>
                <ng-container *ngFor="let log of foodLogsModel">
                  <ng-container *ngIf="item.Name == log.MealTypeModel.Name">
                    <tr *ngIf="log.RecipeSerialNo == 1">
                      <td class="meal-heading" colspan="12">{{log.RecipeID}} - {{log.RecipeName}}</td>
                    </tr>
                  </ng-container>
                  <tr class="details" *ngIf="item.Name == log.MealTypeModel.Name">
                    <td>
                      <span class="diary-remove" (click)=removeFoodLog(log.FoodLogId)>X</span>
                      {{log.FoodModel.Name}} ({{log.Quantity}}
                      {{log.FoodModel.NutritionFactModel.ServingSize_Measurement}})
                      {{log.Created| date:'shortTime'}} Price: {{log.FoodModel.NutritionFactModel.CalculatedPrice}}
                    </td>
                    <td>{{log.FoodModel.NutritionFactModel.TotalFat|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.TotalCabohydrate|number:'1.2-2'}}</td>

                    <td>{{log.FoodModel.NutritionFactModel.Calcium|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.Protien|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.Calories|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.Sugar|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.CalculatedPrice|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.Sodium|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.Phosphorus|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.Oxalate|number:'1.2-2'}}</td>
                    <td>{{log.FoodModel.NutritionFactModel.Water|number:'1.2-2'}}</td>
                  </tr>

                </ng-container>
                <tr class="details details-Total">
                  <td>Meal Total
                    <!-- <span (click)=addToRecipe(item.MealTypeId)>addToRecipe </span> -->

                  </td>
                  <ng-container *ngFor="let logProgress of item.FoodLogProgress">
                    <td>{{logProgress.MealTotal|number:'1.2-2'}} </td>

                  </ng-container>
                </tr>
              </ng-container>
              <tr>
                <td>
                  Grand Total
                </td>
                <td let data of *ngFor="let data of foodLogProgress">
                  {{data.NutritionDoneSoFar| number:'1.0-0'}}/{{data.NutritionTotalLimit}}
                </td>
              </tr>

            </tbody>
          </table>

        </div>
      </div>
    </fieldset>
  </div>
  <div class="col-sm-12">
    <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #Progress>
      <legend class="section-legend-border">Progress</legend>
    <div class="Fact-Section-Graph">
      <div class="food-log-progress-box" *ngFor="let data of foodLogProgress">
        <ng-template #donSoFar>
          <div class="">
            <div> <span class="tool-tip-heading">Total Progress: </span> <span class="nutrition-log-status">
                {{data.NutritionDoneSoFar| number:'1.0-0'}}/{{data.NutritionTotalLimit}} </span> </div>
          </div>
        </ng-template>
        <div class="fact-name">
          {{data.NutritionName}} <br />

        </div>
        <div class="foodlog-circle-row col-circle">
          <div class="circle-section" [ngbTooltip]="donSoFar" triggers="click:blur" data-html="true" placement="top">
            <!-- <div class="c100 p50 small"> -->
            <div [className]="'c100 small p'+data.NutritionCompletePercentage">
              <span class="foodlog-percentage-text">{{data.NutritionActualPercentage | number:'1.0-0'}}%</span>
              <div class="slice">
                <div class="bar"></div>
                <div class="fill"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </fieldset>
    
    </div>
  </div>
</div>
<!-- <div class="row" id="fitbit-widget-section">
    <div class="col-sm-8 col-md-8 col-lg-8 fitbit-widget-section-item">
      <app-fit-bit-activity [DateFromOutside]="DateFromOutside"></app-fit-bit-activity>
    </div>
    <div class="col-sm-5 col-md-5 col-lg-4 fitbit-widget-section-item">
      <app-fit-bit-body ></app-fit-bit-body>
    </div>
    <div class="col-sm-8 col-md-8 col-lg-8 fitbit-widget-section-item">
      <app-fit-bit-sleep-tracker [DateFromOutside]="DateFromOutside"></app-fit-bit-sleep-tracker>
    </div>
  </div> -->
<div class="row" id="fitbit-widget-section">
  <fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #FitbitSleepData>
    <legend class="section-legend-border">Fitbit Sleep Data</legend>
  <div class="col-md-12"> <app-fit-bit-sleep-tracker [DateFromOutside]="DateFromOutside"></app-fit-bit-sleep-tracker>
  </div>
</fieldset>
<fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #BodyMeasurement>
  <legend class="section-legend-border">Body Measurement</legend>
  <div class="col-md-12"> <app-fit-bit-body></app-fit-bit-body> </div>
</fieldset>
<fieldset class="section-legend-border sub-section-legend  col-md-12 col-lg-12" #Activities>
  <legend class="section-legend-border">Activities</legend>
  <div class="col-md-12">
    <app-fit-bit-activity [DateFromOutside]="DateFromOutside"></app-fit-bit-activity>
  </div>
</fieldset>

</div>
<div class="row">

</div>
<div class="col-sm-12">
  {{result}}
</div>




<!-- <div class="stacked-bar-graph">
  <span style="width:50%" class="bar-1">50%</span>
  <div style="display: inline-block;"> hello
  </div>-->
<!-- <span style="width:15%" class="bar-2">20%</span>
  <span style="width:30%" class="bar-3">30%</span> -->
<!-- </div> -->