<div class="card card-login mx-auto mt-1">
  <div class="card-header">Reset Password</div>
  <div class="card-body">
    <div class="text-center mb-4">
      <h4>Forgot your password?</h4>
      <p>Enter your email address and we will send you instructions on how to reset your password.</p>
    </div>
    <form (ngSubmit)="resetPasswordForm.form.valid && onSubmit()" #resetPasswordForm="ngForm"
    [appMatchPassword]="['model.UserPassword', 'model.UserConfirmPassword']" novalidate
    >
      <div class="form-group">
        <div class="form-label-group">
          <input type="text" [(ngModel)]="model.OTP" name="model.OTP" id="OTP" class="form-control"
                placeholder="OTP" required="required" #OTP="ngModel">
          <label for="OTP">Enter OTP</label>
        </div>
        <span class="text-danger"
        *ngIf="(OTP.touched || resetPasswordForm.submitted) && OTP.errors?.required">
        OTP is required
      </span>
      </div>
      <div class="form-group">
        <div class="form-label-group">
          <input type="text" [(ngModel)]="model.Email" name="model.Email" id="Email" class="form-control"
                placeholder="Email address" required="required" #email="ngModel">
          <label for="Email">Enter email address</label>
        </div>
        <span class="text-danger"
        *ngIf="(email.touched || resetPasswordForm.submitted) && email.errors?.required">
        Email is required
       </span>
      </div>
      <div class="form-group">
        <div class="form-label-group">
          <input type="text" [(ngModel)]="model.UserPassword" name="model.UserPassword" id="UserPassword" class="form-control"
                placeholder="User Password" required="required" #userpassword="ngModel">
          <label for="UserPassword">User Password</label>
        </div>
        <span class="text-danger"
        *ngIf="(userpassword.touched || resetPasswordForm.submitted) && userpassword.errors?.required">
        User Password is required
       </span>
      </div>
      <div class="form-group">
        <div class="form-label-group">
          <input type="text" [(ngModel)]="model.UserConfirmPassword" name="model.UserConfirmPassword" id="UserConfirmPassword" class="form-control"
                placeholder="User Password" required="required" #userconfirmpassword="ngModel">
          <label for="UserConfirmPassword">Confirm Password</label>
        </div>
        <span class="text-danger"
        *ngIf="(userconfirmpassword.touched || resetPasswordForm.submitted) && userconfirmpassword.errors?.required">
        Confirm  Password is required
       </span>
        <span class="text-danger" *ngIf="(userconfirmpassword.touched) && userconfirmpassword.errors?.passwordMismatch">
          Passwords doesnot match
        </span>

      </div>
      <button type="submit" class="btn btn-primary btn-block">Reset Password</button>
    </form>
    <div class="text-center">
      <a class="d-block small mt-3" routerLink='/register'>Register an Account</a>
      <a class="d-block small" routerLink='/login'>Login Page</a>
    </div>
  </div>
</div>
<div class="col-sm-12">
  {{result|json}}
</div>