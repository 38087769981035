import { VendorApiModel } from "../Vendor/vendor-api-model";
import { TransactionApiModel } from "./transaction-api-model";
import { TransactionGridModel } from "./transaction-grid-model";

export class TransactionMasterModel {
    public  VoucherID:number;
    public  SummaryNotes:String;
    public  UserID:Number;
    public IsAvailableToPublic:boolean ;
    public  Created:Date ;
    public  Updated:Date ;
    public  VendorID:number;
    public  VendorName:string;
    public VoucherDateDisplay:any;
    public  ExpenseTTransModel:TransactionApiModel[];
    public VendorAutoComplete:any;
    public Vendorlist:VendorApiModel[];

}
