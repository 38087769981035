<fieldset class="section-legend-border col-md-12 col-lg-12" #MealType >
    <legend class="section-legend-border">Meal Type</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.Name}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Meal Type:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="mealTypeModel.MealTypeAutoComplete" [(ngModel)]="mealTypeModel.MealTypeAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Meal Type Id</label>
                    <input type="text" [(ngModel)]="mealTypeModel.MealTypeId" name="mealTypeModel.MealTypeId" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-4">
                    <label for="disabledTextInput">Meal Type Name</label>
                    <input type="text" [(ngModel)]="mealTypeModel.Name" name="mealTypeModel.Name" placeholder="Meal Type Name" class="form-control">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-4 col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>