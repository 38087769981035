import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Observable, of } from 'rxjs';
import { saveAs as importedSaveAs } from "file-saver";
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { FoodLoggerService } from 'src/app/services/food-logger.service';
import { FoodModel } from 'src/app/models/FoodLogger/Food/food-model';
import { BrandModel } from 'src/app/models/FoodLogger/Brand/brand-model';
import { NutritionFactModel } from 'src/app/models/FoodLogger/NutritionFact/nutrition-fact-model';
import { MeasurementModel } from 'src/app/models/FoodLogger/Measurement/measurement-model';
import { FoodImageComponent } from '../food-image/food-image.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FoodImageModel } from 'src/app/models/FoodLogger/FoodImage/food-image-model';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { ProductApiModel } from 'src/app/models/ExpenseManager/Product/product-api-model';
import { RecipeModel } from 'src/app/models/FoodLogger/Recipe/recipe-model';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.css']
})
export class FoodComponent implements OnInit {
  public result = '';
  foodModel: FoodModel;
  model: any;
  searching = false;
  searchFailed = false;
  thumbnail: any;
  public foodImageModel: FoodImageModel[];
  public productModels: ProductApiModel[];
  public recipeModels: RecipeModel[];
  public isShowFoodList=false;
  public isShowRecipeList=false;
  public isValue: number = 0;
  @ViewChild('appfoodimage', { static: true }) componentFileFoodImage: FoodImageComponent;
  constructor(private foodLoggerservice: FoodLoggerService, private expenseService: ExpenseManagerService, private sanitizer: DomSanitizer,private titleService:Title) { this.titleService.setTitle("Create Food");}

  ngOnInit() {
    this.foodModel = new FoodModel();
    this.foodModel.FoodId = 0;
    this.foodModel.Foodlist = [];
    this.foodModel.BrandModel = new BrandModel();
    this.foodModel.BrandModel.Brandlist = [];

    this.foodModel.NutritionFactModel = new NutritionFactModel();
    this.foodModel.MeasurementModel = [];
    this.foodLoggerservice.GetAllMeasurement().subscribe(response => {
      this.foodModel.MeasurementModel = response;

      this.foodImageModel = [];
      this.recipeModels = [];
    }

      , err => { });
    this.foodLoggerservice.GetProductWithoutNutritionFacts().subscribe(response => {
      this.productModels = response;
    });

    this.foodLoggerservice.GetAllRecipe().subscribe(response => {
      this.recipeModels = response;
    });
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        // (this.foodLoggerservice.GetFoodsByName(term).pipe(
        (this.expenseService.GetFoodProductsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatter = (x: { Name: string }) => x.Name;

  selectedItem(item) {
    //this.clickedItem=item.item;
    console.log(item);
    // this.foodModel.FoodId = item.item.FoodId;
    // this.foodModel.Name = item.item.Name;
    // this.foodModel.BrandModel.BrandId = item.item.BrandModel.BrandId;
    // this.foodModel.BrandModel.Name = item.item.BrandModel.Name;

    this.foodModel.FoodId = item.item.ProductID;
    this.foodModel.Name = item.item.ProductName;
    this.foodModel.BrandModel.BrandId = item.item.BrandId;
    this.foodModel.NutritionFactModel = new NutritionFactModel();
    //this.foodModel.BrandModel.Name = item.item.BrandModel.Name;
    this.foodLoggerservice.GetFoodWithNutritionFactById(this.foodModel.FoodId).subscribe(response => {
      console.log(response);
      this.foodModel.NutritionFactModel = response.NutritionFactModel;
      if (response.NutritionFactModel === null) {
        this.foodModel.NutritionFactModel = new NutritionFactModel();
      }
      //Working fine .net api
      this.foodLoggerservice.GetAllFoodFiles(this.foodModel.FoodId)
        .subscribe((baseImage: any) => {

          //alert(JSON.stringify(data.image));
          let objectURL = 'data:image/jpeg;base64,' + baseImage.Image;
          // this.foodImageModel = baseImage.Image as FoodImageModel[];
          //this.foodImageModel =[];
          let imagesFood = [];
          baseImage.Image.forEach(element => {
            let foodImgModel = new FoodImageModel();
            foodImgModel.FoodImageId = element.FoodImageId;
            foodImgModel.Name = element.Name;
            foodImgModel.Path = element.Path;
            foodImgModel.ImageByteData = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + element.ImageByteData);
            this.foodImageModel.push(foodImgModel);
            imagesFood.push(this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + element));
          });
          //this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(imagesFood);
          this.thumbnail = imagesFood;
        });


    },
      err => {
        console.log(err);
        this.result = err.message;

      }

    )

  }

  //selected  item clone
  selectedItemClone(item) {
    //this.clickedItem=item.item;
    console.log(item);
    // this.foodModel.FoodId = item.item.FoodId;
    // this.foodModel.Name = item.item.Name;
    // this.foodModel.BrandModel.BrandId = item.item.BrandModel.BrandId;
    // this.foodModel.BrandModel.Name = item.item.BrandModel.Name;

    //this.foodModel.FoodId = item.item.ProductID;
    //this.foodModel.Name = item.item.ProductName;
    //this.foodModel.BrandModel.BrandId = item.item.BrandId;
    this.foodModel.NutritionFactModel = new NutritionFactModel();
    //this.foodModel.BrandModel.Name = item.item.BrandModel.Name;
    this.foodLoggerservice.GetFoodWithNutritionFactById(item.item.ProductID).subscribe(response => {
      console.log(response);
      this.foodModel.NutritionFactModel = response.NutritionFactModel;
      if (response.NutritionFactModel === null) {
        this.foodModel.NutritionFactModel = new NutritionFactModel();
      }
    },
      err => {
        console.log(err);
        this.result = err.message;

      }

    )

  }

  //Brand Section
  searchBrand = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.foodLoggerservice.GetBrandsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterBrand = (x: { Name: string }) => x.Name;

  selectedItemBrand(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.foodModel.BrandModel.BrandId = item.item.BrandId;
    this.foodModel.BrandModel.Name = item.item.Name;

    // var accountApiModel = new BrandModel();
    // this.accountModel.AccountID = item.item.AccountID;
    // this.accountModel.AccountCode = item.item.AccountCode;
    // this.accountModel.AccountName = item.item.AccountName;

    // this.accountModel.AccountMasterID = item.item.AccountMasterID;
    // this.accountModel.AccountMasterName = item.item.AccountMasterName;
    // this.accountModel.CashBankOther = item.item.CashBankOther;
    // this.accountModel.AccountStatus = item.item.AccountStatus;
    // this.accountModel.Receipt = item.item.Receipt;
    // this.accountModel.Active = item.item.Active;
    // this.accountModel.AccountAutoID = item.item.AccountAutoID;
  }
  onSubmit() {
    var me = this.foodModel;
    this.foodLoggerservice.SaveFoodWithNutritionFact(this.foodModel).subscribe(response => {
      console.log(response);
      this.result = response;
      if (this.foodModel.FoodId <= 0)
        this.foodModel.FoodId = Number(this.result);
      this.saveFiles(this.result);
    },
      err => {
        console.log(err);
        this.result = err.message;
      }

    )
    // var vendorApimodel = new VendorApiModel();
    // vendorApimodel.vendorAutoID = me.VendorAutoID;
    // vendorApimodel.vendorID = me.VendorID;
    // vendorApimodel.vendorName = me.VendorName;
    // this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
    //   response => {
    //     console.log(response);
    //     this.vendorModel = new VendorModel();
    //   }
    // );
  }

  saveFiles(foodId) {
    var me = this.foodModel;
    // this.components.forEach(element =>
    //   console.log(element.nativeElement.elements.Name)
    //   //input.append("file[]", element.nativeElement.elements.state.files)
    // );

    //adding files to form data
    var arrCompnonet = this.componentFileFoodImage.components.toArray();
    //arrCompnonet[0].nativeElement.elements.Name.files[0].name
    var uploadedFiles = [];
    for (var i = 0; i < arrCompnonet.length; i++) {
      uploadedFiles.push(arrCompnonet[i].nativeElement.elements.Name);
    }
    let input = new FormData();
    for (var i = 0; i < uploadedFiles.length; i++) {
      input.append("file[" + i + "]", uploadedFiles[i].files[0], uploadedFiles[i].files[0]['name']);
    }

    // this.foodLoggerservice.SaveFoodFilesCore(input,foodId).subscribe(
    //   data => {
    //   var me = data;
    //     console.log(me);
    //   }
    // );

    this.foodLoggerservice.SaveFoodFiles(input, foodId).subscribe(
      data => {
        var me = data;
        console.log(me);
        this.result += " Food Image id:" + me.Data[0].FoodImageId + " Path: " + me.Data[0].ImagePath;
      },
      err => {
        console.log(err);
        this.result = err.message;
      }
    );
  }
  DownLoadFiles() {
    // //const ImageName = data.ImageName;  
    // //var image = ImageName.slice(0, -4);  
    // this.foodLoggerservice.downloadImageApi("Screenshot_3.rar").subscribe((data) => {  
    //   console.log(data);
    //     importedSaveAs(data, "Screenshot_3.rar");  
    // });  
    //const ImageName = data.ImageName;  
    //var image = ImageName.slice(0, -4);  
    this.foodLoggerservice.downloadallfoodfilesbyidzipFormat(this.foodModel.FoodId).subscribe((data) => {
      console.log(data);
      importedSaveAs(data, this.foodModel.FoodId + ".rar");
    },
      err => {
        console.log(err);
        this.result = err.message;
      });

    // //Working on .net core
    // //const ImageName = data.ImageName;  
    // //var image = ImageName.slice(0, -4);  
    // this.foodLoggerservice.downloadImage("Screenshot_1.png").subscribe((data) => {  
    //   console.log(data);
    //     importedSaveAs(data, "Screenshot_1.png");  
    // });  
  }

  DeleteFoodPic(imageId) {
    console.log(imageId);
    this.foodLoggerservice.DeleteFoodImage(imageId, this.foodModel.FoodId).subscribe((data) => {
      console.log(data);
    },
      err => {
        console.log(err);
        this.result = err.message;
      });
  }
  showHideFoodList() {
    this.isShowFoodList = !this.isShowFoodList;
  }
  showHideRecipeList() {
    this.isShowRecipeList = !this.isShowRecipeList;
  }
  toggle(num) { 
    this.isValue = num; 
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
}
}

