import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ExerciseModel } from 'src/app/models/FitBit/exercise-model';
import { FitbitBodyModel } from 'src/app/models/FitBit/fitbit-body-model';
import { FitbitFoodModel } from 'src/app/models/FitBit/fitbit-food-model';
import { HeartModel } from 'src/app/models/FitBit/heart-model';
import { SleepModel, SleepViewModel } from 'src/app/models/FitBit/sleep-model';
import { FitBitService } from 'src/app/services/fit-bit.service';

@Component({
  selector: 'app-fit-bit-sleep-tracker',
  templateUrl: './fit-bit-sleep-tracker.component.html',
  styleUrls: ['./fit-bit-sleep-tracker.component.css']
})
export class FitBitSleepTrackerComponent implements OnInit, OnChanges {
  sleepModel: SleepModel;
  heartModel: HeartModel;
  exerciseModel: ExerciseModel;
  fitbitBodyModel: FitbitBodyModel;
  fitbitFoodModel: FitbitFoodModel;
  sleepViewData: SleepViewModel[];
  totalSleep: string;
  totalTimeInBed: string;
  sleepStart: string;
  sleepEnd: string;
  public result = '';
  @Input() DateFromOutside: string;
  constructor(private fitbitService: FitBitService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.sleepViewData = [];
    //Getting previousDay date for sleep log
    var currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() - 1);
    var previousDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    if (this.DateFromOutside && this.DateFromOutside.length > 0) {
      previousDate = this.DateFromOutside;
    }
    //this.fetchData(previousDate);
  }
  fetchData(previousDate) {
    var fitbitResp;
    this.fitbitService.GetFitbitFoodData(previousDate).subscribe(
      response => {
        fitbitResp = response;
        this.fitbitFoodModel = fitbitResp;
        console.log(this.fitbitFoodModel);
      },
      err => { this.result = err.message; }

    );
    this.fitbitService.GetFitbitSleepData(previousDate).subscribe(
      response => {
        fitbitResp = response;
        this.sleepModel = fitbitResp;
        var mainSleep = this.sleepModel.sleep.filter(function (item) {
          return item.isMainSleep === true;
        });
        if (mainSleep.length > 0) {


          this.totalSleep = mainSleep[0].minutesAsleep > 60 ? Math.round((mainSleep[0].minutesAsleep) / 60) + " Hr " + (mainSleep[0].minutesAsleep) % 60 + " mins" : (mainSleep[0].minutesAsleep) + "  mins";
          this.totalTimeInBed = mainSleep[0].timeInBed > 60 ? Math.round((mainSleep[0].timeInBed) / 60) + " Hr " + (mainSleep[0].timeInBed) % 60 + " mins" : (mainSleep[0].timeInBed) + "  mins";
          // this.sleepStart = this.datepipe.transform(mainSleep[0].startTime, 'yyyy-MM-dd h:mm a');
          //this.sleepEnd = this.datepipe.transform(mainSleep[0].endTime, 'yyyy-MM-dd h:mm a');
          this.sleepStart = this.datepipe.transform(mainSleep[0].startTime, 'h:mm a');
          this.sleepEnd = this.datepipe.transform(mainSleep[0].endTime, 'h:mm a');
          //setting sleep data
          var viewModel = new SleepViewModel();
          viewModel.SleepCategory = "Awake";
          if(mainSleep[0].levels.summary.wake){
            viewModel.SleepInMinutes = Number(mainSleep[0].levels.summary.wake.minutes);
            viewModel.SleepInPercentage = Math.round(((viewModel.SleepInMinutes) / mainSleep[0].timeInBed) * 100);
            viewModel.SleepDataDisplay = viewModel.SleepInMinutes > 60 ? Math.round((viewModel.SleepInMinutes) / 60) + " Hr " + (viewModel.SleepInMinutes) % 60 + " mins" : (viewModel.SleepInMinutes) + "  mins";
            viewModel.SleepDataClass = "awake";
            this.sleepViewData.push(viewModel);
          }
          

          var viewModel = new SleepViewModel();
          viewModel.SleepCategory = "Rem";
          if(mainSleep[0].levels.summary.rem){
          viewModel.SleepInMinutes = Number(mainSleep[0].levels.summary.rem.minutes);
          viewModel.SleepInPercentage = Math.round(((viewModel.SleepInMinutes) / mainSleep[0].timeInBed) * 100);
          viewModel.SleepDataDisplay = viewModel.SleepInMinutes > 60 ? Math.round((viewModel.SleepInMinutes) / 60) + " Hr " + (viewModel.SleepInMinutes) % 60 + " mins" : (viewModel.SleepInMinutes) + "  mins";
          viewModel.SleepDataClass = "rem";
          this.sleepViewData.push(viewModel);
          }

          var viewModel = new SleepViewModel();
          if(mainSleep[0].levels.summary.light){
          viewModel.SleepCategory = "Light";
          viewModel.SleepInMinutes = Number(mainSleep[0].levels.summary.light.minutes);
          viewModel.SleepInPercentage = Math.round(((viewModel.SleepInMinutes) / mainSleep[0].timeInBed) * 100);
          viewModel.SleepDataDisplay = viewModel.SleepInMinutes > 60 ? Math.round((viewModel.SleepInMinutes) / 60) + " Hr " + (viewModel.SleepInMinutes) % 60 + " mins" : (viewModel.SleepInMinutes) + "  mins";
          viewModel.SleepDataClass = "light";
          this.sleepViewData.push(viewModel);
          }

          var viewModel = new SleepViewModel();
          if(mainSleep[0].levels.summary.deep){
          viewModel.SleepCategory = "Deep";
          viewModel.SleepInMinutes = Number(mainSleep[0].levels.summary.deep.minutes);
          viewModel.SleepInPercentage = Math.round(((viewModel.SleepInMinutes) / mainSleep[0].timeInBed) * 100);
          viewModel.SleepDataDisplay = viewModel.SleepInMinutes > 60 ? Math.round((viewModel.SleepInMinutes) / 60) + " Hr " + (viewModel.SleepInMinutes) % 60 + " mins" : (viewModel.SleepInMinutes) + "  mins";
          viewModel.SleepDataClass = "deep";
          this.sleepViewData.push(viewModel);
          }
        }
        var count = this.sleepModel.sleep.length;
        var sleepData = this.sleepModel.sleep[count - 1];
        var startTime = sleepData?.startTime;
        var endTime = sleepData?.endTime;

        console.log(this.fitbitFoodModel);
      },
      err => { this.result = err.message; }

    );
  }
  ngOnChanges() {
    // create header using child_id
   this.fetchData(this.DateFromOutside);

  }

}
