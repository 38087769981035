<fieldset class="section-legend-border col-md-12 col-lg-12" #VitalInformationMeasurement >
  <legend class="section-legend-border">Vital Information Measurement</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">

      <div class="form-row">
        <div class="form-group col-md-2 chkbox">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isEdit" [(ngModel)]="isEdit">
          <label class="form-check-label" for="exampleCheck1">Is Edit </label>
        </div> 
      </div>
      <div class="form-row">
        <div class="form-group col-md-8">
          <label for="exampleInputPassword1">Food Log Date</label>
          <div class="input-group">

            <input class="form-control" placeholder="yyyy-mm-dd" name="vitalInformationMeasurementModel.DateDisplay"
              [(ngModel)]="vitalInformationMeasurementModel.DateDisplay" ngbDatepicker #d="ngbDatepicker"
              (ngModelChange)="onModelChange($event)">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </div>
       
        <div class="form-group col-md-4">
          <div id="time-picker-section">
            <ngb-timepicker name="vitalInformationMeasurementModel.TimeDisplay"
              [(ngModel)]="vitalInformationMeasurementModel.TimeDisplay">
            </ngb-timepicker>
          </div>
          <!-- <label for="exampleInputPassword1">Voucher Time</label> -->
        </div>
      </div>
        <!-- <div class="form-group col-sm-12 col-md-4 col-lg-3">
          <label for="voucherType">User Id</label>
          <select class="form-control" name="userId" [(ngModel)]="userId" (change)='setDataByUserId()'>
            <option [ngValue]="undefined" disabled selected> Select User </option>
            <option *ngFor="let item of userList" [value]="item.UserID">
              {{item.UserName}}
            </option>
          </select>
        </div> -->
        <div class="row">
        <div class="form-group col-sm-12 col-md-4 col-lg-3">
          <label for="voucherType">User Id</label>
          <select class="form-control" name="userId" [(ngModel)]="vitalInformationMeasurementModel.UserID" (change)='setDataByUserId()'>
            <option [ngValue]="undefined" disabled selected> Select User </option>
            <option *ngFor="let item of userList" [value]="item.UserID">
              {{item.UserName}}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3 col-sm-12 col-12 col-lg-3">
          <label for="disabledTextInput">Measurement ID</label>
          <input type="text" [(ngModel)]="vitalInformationMeasurementModel.MeasurementID"
            name="vitalInformationMeasurementModel.MeasurementID" placeholder="id" class="form-control">
        </div>
      </div>
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #BloodPressure >
        <legend class="section-legend-border">Blood Pressure</legend>
      <div class="row">
        <div class="form-group col-md-6 col-sm-12 col-12 col-lg-4">
          <label for="disabledTextInput">BP SystolicmHg (High in Number)</label>
          <input type="text" [(ngModel)]="vitalInformationMeasurementModel.BloodPressure_SystolicmHg"
            name="vitalInformationMeasurementModel.BloodPressure_SystolicmHg" placeholder="BloodPressure SystolicmHg"
            class="form-control">
        </div>
        <div class="form-group col-md-6 col-sm-12 col-12 col-lg-5">
          <label for="disabledTextInput">BP DiastolicmmHg (Low in Number)</label>
          <input type="text" [(ngModel)]="vitalInformationMeasurementModel.BloodPressure_DiastolicmmHg"
            name="vitalInformationMeasurementModel.BloodPressure_DiastolicmmHg"
            placeholder="BloodPressure_DiastolicmmHg" class="form-control">
        </div>
        </div>
      </fieldset>
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Sugar >
        <legend class="section-legend-border">Sugar</legend>
        <div class="row">
        <div class="form-group col-md-6 col-sm-12 col-12 col-lg-3">
          <label for="disabledTextInput">Blood Sugar Fasting</label>
          <input type="text" [(ngModel)]="vitalInformationMeasurementModel.BloodSugar_Fasting"
            name="vitalInformationMeasurementModel.BloodSugar_Fasting" placeholder="Blood Sugar Fasting"
            class="form-control">
        </div>
        <div class="form-group col-md-6 col-sm-12 col-12 col-lg-4">
          <label for="disabledTextInput">Blood Sugar After Eating</label>
          <input type="text" [(ngModel)]="vitalInformationMeasurementModel.BloodSugar_AfterEating"
            name="vitalInformationMeasurementModel.BloodSugar_AfterEating" placeholder="Blood Sugar After Fasting"
            class="form-control">
        </div>
        <div class="form-group col-md-2 col-sm-12 col-12 col-lg-2">
          <label for="disabledTextInput"> Pulse</label>
          <input type="text" [(ngModel)]="vitalInformationMeasurementModel.BloodSugar_Pulse"
            name="vitalInformationMeasurementModel.BloodSugar_Pulse" placeholder="Pulse"
            class="form-control">
        </div>
      </div>
      </fieldset>

     
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12"></div>
        <div class="form-group col-md-4 col-sm-12">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
        <div class="form-group col-md-4 col-sm-12"></div>
      </div>
    </form>
  </div>
</div>
</fieldset>


<div class="row">
  <fieldset class="section-legend-border col-md-12 col-lg-12" #BloodPressureDiastolic>
    <legend class="section-legend-border">Blood Pressure Diastolic (In Low Number)</legend>
  <div class="col-sm-12">
    <div id="containerBloodPressureDiastolicmmHg" style="height: 400px; min-width: 310px" class="round-div"></div>
  </div>
  </fieldset>
  <fieldset class="section-legend-border col-md-12 col-lg-12" #BloodPressureSystolic>
    <legend class="section-legend-border">Blood Pressure Systolic (In Higher Number)</legend>
  <div class="col-sm-12">
    <div id="containerBloodPressureSystolicmHg" style="height: 400px; min-width: 310px" class="round-div"></div>
  </div>
  </fieldset>
  <fieldset class="section-legend-border col-md-12 col-lg-12" #HeartBeat>
    <legend class="section-legend-border">HeartBeat</legend>
  <div class="col-sm-12">
    <div id="containerBloodPressureHeartBeat" style="height: 400px; min-width: 310px" class="round-div"></div>
  </div>
  </fieldset>
  <fieldset class="section-legend-border col-md-12 col-lg-12" #BloodGlucoseChartFasting>
    <legend class="section-legend-border">Blood Glucose Chart (Fasting)</legend>
  <div class="col-sm-12">
    <div id="containerBloodSugarFasting" style="height: 400px; min-width: 310px" class="round-div"></div>
  </div>
  </fieldset>
  <fieldset class="section-legend-border col-md-12 col-lg-12" #BloodGlucoseChartEating>
    <legend class="section-legend-border">Blood Glucose Chart (After Eating)</legend>
  <div class="col-sm-12">
    <div id="containerBloodSugarAfterEating" style="height: 400px; min-width: 310px" class="round-div"></div>
  </div>
  </fieldset>
</div>