<fieldset class="section-legend-border col-md-12 col-lg-12" #File >
  <legend class="section-legend-border">File</legend>
<div class="row">
    <div class="col-sm-12">
      <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      
       
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <ng-template #rt1 let-r="result" let-t="term">
              {{ r.AccountCode}} | {{ r.FileName}}
            </ng-template>
            <label for="typeahead-basic">Search for a File:</label>
            <input id="typeahead-basic" type="text" class="form-control" name="fileModel.FileAutoComplete" 
            [(ngModel)]="fileModel.FileAutoComplete" [ngbTypeahead]="searchFile" [inputFormatter]="formatterFile"
            [resultTemplate]="rt1" (selectItem)="selectedItemFile($event)"
            />
          </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
              <ng-template #rt let-r="result" let-t="term">
                {{ r.AccountName}}
              </ng-template>
              <label for="typeahead-basic">Search for a Account:</label>
              <input id="typeahead-basic" type="text" class="form-control" name="accountModel.AccountAutoComplete" 
              [(ngModel)]="accountModel.AccountAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
              [resultTemplate]="rt" (selectItem)="selectedItem($event)"
              />
            </div>
          </div>
        <div class="form-row"> 
          <div class="form-group col-sm-12 col-md-2 col-lg-2">
            <label for="disabledTextInput">File Id</label>
            <input type="text" disabled [(ngModel)]="fileModel.FileID" name="fileModel.FileID" placeholder="id" class="form-control">
          </div>
          <div class="form-group col-sm-12 col-md-4 col-lg-4">
            <label for="disabledTextInput">Account Code</label>
            <input type="text" disabled [(ngModel)]="fileModel.AccountCode" name="fileModel.AccountCode" placeholder="File Account Code " class="form-control">
          </div>
        </div>
        <div class="form-row"> 
            <div class="form-group col-sm-12 col-md-12 col-lg-12">
                <label for="disabledTextInput">Relative File Path starts with /</label>
                <input type="text" [(ngModel)]="fileModel.RelativeFilePath" name="fileModel.RelativeFilePath" placeholder="Relative File Path" class="form-control" (change)="relativePathChange()">
              </div>
        </div>
        <div class="form-row"> 
            <div class="form-group col-sm-12 col-md-2 col-lg-2">
              <label for="disabledTextInput">Full Path</label>
              <input type="text" disabled [(ngModel)]="fileModel.FullPath" name="fileModel.FullPath" placeholder="Full path" class="form-control">
            </div>
            <div class="form-group col-sm-12 col-md-6 col-lg-6">
              <label for="disabledTextInput">File Name</label>
              <input type="text" disabled [(ngModel)]="fileModel.FileName" name="fileModel.FileName" placeholder="File Name " class="form-control">
              <a href="{{baseFileRef}}{{fileModel.RelativeFilePath}}" target="_blank">{{baseFileRef}}{{fileModel.RelativeFilePath}}</a>
            </div>
           
          </div>
          <div class="form-row">
            {{result}}
          </div>
          <div class="form-row">
           <span>Note: Used in trasaction page when you click on account code and checked the <b>Show Related Files</b> Check box. (Use tasker Open File task on mobile after copy the path)</span>
          </div>
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
          <div class="form-group col-sm-12 col-md-4 col-lg-4">
            <button type="submit" class="btn btn-primary btn-block">Submit</button>
          </div>
          <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
        </div>
      </form> 
    </div>
    </div>  
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #FileList >
  <legend class="section-legend-border">File List</legend>
    <div class="row" id="topic-example">
      <div class="col-sm-12 example-section">
        <div class="">
          <p class="section-heading-text">File List</p>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">FileID</th>
                <th scope="col">AccountCode</th>
                <th scope="col">FileName</th>
                <th scope="col">RelativeFilePath</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of fileModel.Filelist">
                <th scope="row">{{item.FileID}}</th>
                <td>{{item.AccountCode}}</td>     
                 <td>{{item.FileName}}</td>
                <td>{{item.RelativeFilePath}}</td>
            </tbody>
          </table>
        </div>
      </div>
      </div>
      </fieldset>
    
