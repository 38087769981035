import { Component, OnInit } from '@angular/core';
import { GetAllItemsForStockModel } from 'src/app/models/ItemTracker/get-all-items-for-stock-model';
import { InsertOrUpdateProductsStocksMappingModel } from 'src/app/models/ItemTracker/insert-or-update-products-stocks-mapping-model';
import { ItemTrackerProductsStocksMappingModel } from 'src/app/models/ItemTracker/item-tracker-products-stocks-mapping-model';
import { ItemTrackerService } from 'src/app/services/item-tracker.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-item-stock-mapper',
  templateUrl: './item-stock-mapper.component.html',
  styleUrls: ['./item-stock-mapper.component.css']
})
export class ItemStockMapperComponent implements OnInit {
  public GetAllItemsForStockModelList:GetAllItemsForStockModel [];
  public itemSotcke:ItemTrackerProductsStocksMappingModel;
  public result = '';
  constructor(private itemTrackerService: ItemTrackerService,private titleService:Title) {  this.titleService.setTitle("Stock Mapping");}
  public qrwidth=150;
  ngOnInit(): void {
    this.itemTrackerService.GetAllItemsForStock().subscribe(response =>{
      this.GetAllItemsForStockModelList = response;
  });

  }
  onSubmit(){
    this.result = '';
    var me =  this.GetAllItemsForStockModelList;
   var models = this.GetAllItemsForStockModelList.filter(
      function (e) {
        return (e.IsOutDated == true||e.IsOutDated == false ||e.ProductSearchName.trim().length>0 || e.Comment.trim().length>0 || e.ID >0);
      }
    );
    var postModel = new InsertOrUpdateProductsStocksMappingModel();
    postModel.ItemTrackerProductsStocksMappingModels=[];
    models.forEach(element => {
      var model = new ItemTrackerProductsStocksMappingModel();
      model.ID = element.ID;
      model.IsOutDated = element.IsOutDated;
      model.ProductId = element.ProductId;
      model.ProductUniqueName = element.FinalProductUniqueName;
      model.ProductSearchName = element.ProductSearchName.trim();
      model.Comment = element.Comment;
      postModel.ItemTrackerProductsStocksMappingModels.push(model);
    });
    this.itemTrackerService.InsertOrUpdateProductsStocksMapping(postModel).subscribe(response=>{
      this.result = 'Success';

    },
    err => {
      this.result = err.message;
    }
    );
   
  }

}
