<div class="card card-login mx-auto mt-1">
    <div class="card-header">Reset Password</div>
    <div class="card-body">
      <div class="text-center mb-4">
        <h4>Forgot your password?</h4>
        <p>Enter your email address and we will send you instructions on how to reset your password.</p>
      </div>
      <form (ngSubmit)="forgetPasswordForm.form.valid && onSubmit()" #forgetPasswordForm="ngForm">
        <div class="form-group">
          <div class="form-label-group">
            <input type="text" [(ngModel)]="model.Email" name="model.Email" id="Email" class="form-control"
                  placeholder="Email address" required="required" #email="ngModel">
            <label for="Email">Enter email address</label>
          </div>
        </div>
        <button type="submit" class="btn btn-primary btn-block">Reset Password</button>
      </form>
      <div class="text-center">
        <a class="d-block small mt-3" routerLink='/register'>Register an Account</a>
        <a class="d-block small" routerLink='/login'>Login Page</a>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    {{result|json}}
  </div>