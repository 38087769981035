import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { ForgetPasswordModel } from '../models/Register/forget-password-model';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {
public result='';
public model:ForgetPasswordModel;
  constructor(private userService: UserService) { }

  ngOnInit() {
this.model = new ForgetPasswordModel();
  }
    onSubmit() {
    var me = '';
    this.model.DomainUrl="/resetpassword?Email={0}&OTP={1}";
    this.userService.ForgetPassword(this.model).subscribe(
        response => {
          this.result = response;
          console.log(response);
        },
        (err)=> {this.result = err.message}
        );
      }

}
