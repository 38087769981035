import { FoodLogModel } from "./food-log-model";

export class FoodLogMasterModel {
    public  FoodLogMasterID :number;
    public  FoodLogMId :number;
    public  SummaryNotes:String;
    public  UserID:Number;
    public IsAvailableToPublic:boolean ;
    public  Created:Date ;
    public  Updated:Date ;
    public  FoodLoggerFoodLogModels:FoodLogModel[];
    public  FoodLoggerFoodLogModel:FoodLogModel;
}
