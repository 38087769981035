export class SensorDataSensorModel {
    public  SensorID :number;
    public  SensorName :string;
    public SensorUniqueName :string;
    public  UserID :number;
    public  IsAvailableToPublic :boolean;

    //For template
    public SensorDataSensorAutoComplete:any;
    public SensorDataSensorlist:SensorDataSensorModel[];
}
