import { HabitCategoryModel } from '../HabitCategory/habit-category-model';

export class HabitModel {
   public  HabitId:number;
   public  HabitName:string;
   public  HabitCategoryId:number;
   public  HabitCategoryName:string;
    public HabitAutoComplete:any;
   public Habitlist:HabitModel[];

   public HabitCategoryAutoComplete:any;
   public HabitCategorylist:HabitCategoryModel[];
}
