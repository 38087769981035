export class AccountApiModel {
    AccountID:number;
    AccountCode:string;
    AccountName:string;
    AccountMasterID:number;
    AccountMasterName:string;
    CashBankOther:string;
    AccountStatus:string;
    Receipt:boolean;
    Active:boolean;
    AccountAutoID:number;

}
