export class HabitLogModel {
public LogDate:Date;
public HabitLoggerId:number;
public HabitId:number;
public HabitName:string;
public HabitTypeId:number;
public HabitTypeName:string;
public NumberOfTimes:number;
public TotalMinsToDo:number;
public TotalMinsDoneSoFar:number;
public NumberOfMinutes:number;
public NumberOfHours:number;
public NumberOfMinutesDone:number;
public NumberOfHoursDone:number;
public NumberOfTimesDone:number;
public Completed:number;
public LogDateDisplay:any;
public LogTimeDisplay:any;
//For View
public ToolTipDoneSoFar:string;
public ToolTipToDo:string;
public HabitNameDisplay:string;

}

