export class IncomeExpenditureModel {
    public AccountCode: string;
    public AccountName: string;
    public IncomeExpenditure: string;
    public VendorId: number;
    public VendorName: string;
    public Year: number;
    public Month: number;
    public MonthName: string;
    public BalAmount: number;

    public ClassStyled:string;

     /*For sum */
     public IsCheckedForTotal:boolean;
}
