import { Component, OnInit,Input, ViewChild, ElementRef, AfterViewInit, ViewChildren, OnDestroy } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
//import { SensorData } from '../models/sensor-data';
//import { INavigation } from '../Interfaces/inavigation';
import * as $ from "jquery"
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit,OnDestroy  {
  @ViewChild('inputEmail') myDiv: ElementRef;
  // @ViewChildren('email') vc;
  loginErrorMessage ='';
  isLoggedIn = false;
  urlParameter='';
  returnUrl='';
  //tempratureData=new SensorData();
  subscription1$: Subscription ;
  subscription2$: Subscription ;
  subscriptions: Subscription[] = []

@Input() firstName;

  constructor(private userService: UserService,private router: Router,private route: ActivatedRoute) { 
    this.urlParameter = this.route.snapshot.paramMap.get("loginaction");
    //this.urlParameter = this.route.snapshot.queryParamMap.get("loginAction");
    this.returnUrl = this.route.snapshot.paramMap.get("returnurl");

  }
  
  ngOnInit() {

    console.log('First Name '+this.firstName);
    // this.userService.getSensorData().subscribe(data =>
    //   {
    //     var me = data;
    //   },
    //    err=>{
    //     console.log(err);
    //    }
    //   );

    if(this.userService.isAuthorized()){
      this.isLoggedIn =true;
    }
   this.urlParameter = this.route.snapshot.paramMap.get("loginaction");
  //this.urlParameter = this.route.snapshot.queryParamMap.get("loginAction");
  this.returnUrl = this.route.snapshot.paramMap.get("returnurl");
if(this.urlParameter === 'logout'){
  this.Logout();
}
  }
  logForm(value: any) {
    console.log(value);
    this.Login(value.emailaddress,value.password);
  }
  Login( userName,password):void{
   
    this.subscription1$ = this.userService.loginDetails(userName,password).subscribe(token=>
      {
        localStorage.setItem('currentUser', token.UserID);
        localStorage.setItem('userName', token.UserName);  
        localStorage.setItem('role', token.Role);
        localStorage.setItem('jwtToken', token.JwtToken);
        localStorage.setItem('ExpiryDate', token.ExpiryDate);
        console.log(token);
        var me = this.returnUrl;
        $('#login-name').text(token.UserName);
        //this.router.navigate(['/home']);
        if(me === null || me==='null'){
          me='/home';
        }
        this.router.navigate([me]);
      },
      err=>{
        console.log(err);
        localStorage.removeItem('currentUser');
        this.loginErrorMessage ="Username or password incorrect";
       }
      );
      this.subscriptions.push(this.subscription1$);
    }
    Logout():void{
      localStorage.removeItem('currentUser');
      localStorage.removeItem('userName');  
      localStorage.removeItem('role');
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('ExpiryDate');
      $('#login-name').text('Not logged in');
      this.isLoggedIn =false;
    }
    ngAfterViewInit() {
      //var  me =this.myDiv;
      //console.log(this.myDiv.nativeElement.innerHTML);
      this.myDiv.nativeElement.focus();
     // $('#inputEmail').focus()
    }
    ngOnDestroy() {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
