import { SensorDataLocationModel } from "./sensor-data-location-model";

export class SensorDataSubLocationModel {
    public  SubLocationID :number;
    public  SubLocationName :string;
    public SubLocationUniqueName :string;
    public LocationID :number;
    public  UserID :number;
    public  IsAvailableToPublic :boolean;

      //For template
      public SensorDataSubLocationAutoComplete:any;
      public SensorDataSubLocationlist:SensorDataSubLocationModel[];
      public Location:SensorDataLocationModel;
}
