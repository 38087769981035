import { SensorDataLocationModel } from "./sensor-data-location-model";
import { SensorDataSensorFeatureTypeModel } from "./sensor-data-sensor-feature-type-model";
import { SensorDataSensorModel } from "./sensor-data-sensor-model";
import { SensorDataSubLocationModel } from "./sensor-data-sub-location-model";

export class SensorDataSensorMappingModel {
    public  SensorMappingID :number;
    public  SensorID :number;
    public SensorFeatureTypeID :number;
    public SubLocationID :number;
    public  UserID :number;
    public  IsAvailableToPublic :boolean;

        //For template
        public SensorDataSensorMappingAutoComplete:any;
        public SensorDataSensorMappinglist:SensorDataSensorMappingModel[];
        public Sensor: SensorDataSensorModel;
        public Location:SensorDataLocationModel;
        public SubLocation:SensorDataSubLocationModel;
        public FeatureType:SensorDataSensorFeatureTypeModel;
        


}
