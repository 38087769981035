import { Component, OnInit } from '@angular/core';
import { SetNewPasswordModel } from '../models/Register/set-new-password-model';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public result = '';
  params: Params;
public model:SetNewPasswordModel;
  constructor(private userService: UserService,private route: ActivatedRoute) { }

  ngOnInit() {
    this.model = new SetNewPasswordModel();
    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
      console.log('App params', params);
      this.model.Email = params['Email'];
      this.model.OTP = params['OTP'];
      console.log('id', this.model);
    });
  
  }
  onSubmit() {
    var me = this.model;
    this.userService.SetNewPassword(this.model).subscribe(
      response => {
        this.result = response;
        console.log(response);
      },
      (err)=> {this.result = err.message}
      );
  }
}
