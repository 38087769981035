export class NutritionFactModel {
    constructor() {
        this.Calories = 0;
        this.TotalCabohydrate = 0;
        this.Sugar = 0;
        this.SaturatedFat = 0;
        this.Cholesterol = 0;
        this.Sodium = 0;
        this.TotalCabohydrate = 0;
        this.DietryFiber = 0;
        this.Protien = 0;

        this.VitaminA = 0;
        this.VitaminB1_Thiamin = 0;
        this.VitaminB2_Riboflavin = 0;
        this.VitaminB3_Niacin = 0;
        this.VitaminB5_PantothenicAcid = 0;

        this.VitaminB6 = 0;
        this.VitaminB12 = 0;
        this.VitaminC = 0;
        this.VitaminD = 0;
        this.VitaminE = 0;
        this.VitaminK = 0;

        this.Folate = 0;
        this.Iron = 0;
        this.Calcium = 0;
        this.Phosphorus = 0;
        this.Mangnese = 0;
        this.Iodin = 0;

        this.Biotin = 0;
        this.Zinc = 0;
        this.Copper = 0;
        this.Selenium = 0;
        this.Omega3 = 0;
        this.Cobalamin = 0;
        this.Oxalate = 0;
    }

    public NutritionFactId: number;
    public FoodId: number;

    public ServingSize: number;

    public ServingSizeValue: number;

    public ServingSize_MeasurementId: number;
    public ServingSize_Measurement: string;

    public Calories: number;
    public Calories_MeasurementId: number;
    public Calories_Measurement: string;

    public TotalFat: number;
    public TotalFat_MeasurementId: number;
    public TotalFat_Measurement: string;

    public SaturatedFat: number;
    public SaturatedFat_MeasurementId: number;
    public SaturatedFat_Measurement: string;

    public PolyunsaturaredFat: number;
    public PolyunsaturaredFat_MeasurementId: number;
    public PolyunsaturaredFat_Measurement: string;

    public MonounsaturatedFat: number;
    public MonounsaturatedFat_MeasurementId: number;
    public MonounsaturatedFat_Measurement: string;

    public TansFat: number;
    public TansFat_MeasurementId: number;
    public TansFat_Measurement: string;

    public Cholesterol;
    public Cholesterol_MeasurementId;
    public Cholesterol_Measurement: string;

    public Sodium;
    public Sodium_MeasurementId;
    public Sodium_Measurement: string;

    public Potassium: number;
    public Potassium_MeasurementId: number;
    public Potassium_Measurement: string;

    public TotalCabohydrate: number;
    public TotalCabohydrate_MeasurementId: number;
    public TotalCabohydrate_Measurement: string;

    public DietryFiber: number;
    public DietryFiber_MeasurementId;
    public DietryFiber_Measurement: string;

    public Sugar: number;
    public Sugar_MeasurementId: number;
    public Sugar_Measurement: string;

    public Protien: number;
    public Protien_MeasurementId: number;
    public Protien_Measurement: string;

    public VitaminA: number;
    public VitaminA_Measurement: string;
    public VitaminA_MeasurementId: number;
    public VitaminB1_Thiamin: number;
    public VitaminB1_Thiamin_Measurement: string;

    public VitaminB2_Riboflavin: number;
    public VitaminB2_Riboflavin_Measurement: string;
    public VitaminB3_Niacin: number;
    public VitaminB3_Niacin_Measurement: string;
    public VitaminB5_PantothenicAcid: number;
    public VitaminB5_PantothenicAcid_Measurement: string;
    public VitaminB6: number;
    public VitaminB6_Measurement: string;
    public VitaminB12: number;
    public VitaminB12_Measurement: string;
    public VitaminC: number;
    public VitaminC_Measurement: string;
    public VitaminD: number;
    public VitaminD_Measurement: string;
    public VitaminE: number;
    public VitaminE_Measurement: string;
    public VitaminK: number;
    public VitaminK_Measurement: string;
    public Folate: number;
    public Folate_Measurement: string;
    public Iron: number;
    public Iron_Measurement: string;
    public Calcium: number;
    public Calcium_Measurement: string;
    public Phosphorus: number;
    public Phosphorus_Measurement: string;
    public Mangnese: number;
    public Mangnese_Measurement: string;
    public Magnesium: number;
    public Magnesium_Measurement: string;
    public Iodin: number;
    public Iodin_Measurement: string;
    public Biotin: number;
    public Biotin_Measurement: string;
    public Zinc: number;
    public Zinc_Measurement: string;
    public Copper: number;
    public Copper_Measurement: string;
    public Selenium: number;
    public Selenium_Measurement: string;
    public Omega3: number;
    public Omega3_Measurement: string;
    public Cobalamin: number;
    public Cobalamin_Measurement: string;
    public Oxalate: number;
    public Oxalate_Measurement: string;
    public Water: number;
    public Water_Measurement: string;
    public VitaminB1_Thiamin_MeasurementId: number;
    public VitaminB2_Riboflavin_MeasurementId: number;
    public VitaminB3_Niacin_MeasurementId: number;
    public VitaminB5_PantothenicAcid_MeasurementId: number;
    public VitaminB6_MeasurementId: number;
    public VitaminB12_MeasurementId: number;
    public VitaminC_MeasurementId: number;
    public VitaminD_MeasurementId: number;
    public VitaminE_MeasurementId: number;
    public VitaminK_MeasurementId: number;
    public Folate_MeasurementId: number;
    public Iron_MeasurementId: number;
    public Calcium_MeasurementId: number;
    public Phosphorus_MeasurementId: number;
    public Mangnese_MeasurementId: number;
    public Magnesium_MeasurementId: number;
    public Iodin_MeasurementId: number;
    public Biotin_MeasurementId: number;
    public Zinc_MeasurementId: number;
    public Copper_MeasurementId: number;
    public Selenium_MeasurementId: number;
    public Omega3_MeasurementId: number;
    public Cobalamin_MeasurementId: number;
    public Oxalate_MeasurementId: number;
    public Water_MeasurementId: number;


}
