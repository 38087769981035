import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customInr'
})
export class CustomInrPipe implements PipeTransform {

  transform(val: string) {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    }).format(Number(val));
  }

}
