import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { User } from '../models/Login/user';
import { ConfigLoaderService } from './config-loader.service';
import { UserLoginModel } from '../models/Login/user-login-model';
//import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { RegisterUser } from '../models/Register/register-user';
import { SetNewPasswordModel } from '../models/Register/set-new-password-model';
import { ForgetPasswordModel } from '../models/Register/forget-password-model';
//import { User } from '../models/user';
//import { SensorData, MainSensorData } from '../models/sensor-data';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  //private baseUrl ="http://localhost:2718";

  private baseUrl = "http://admin-api.myzingonline.com";
  private GetAllUsersUrl = '/api/jwt/getallusers';
  private GetUserByIdUrl = '/api/User/GetUser?userId=1';
  private SaveUserUrl = '/api/User/saveuser';  
  private IsEmailExistUrl = '/api/User/isemailexist';
  private IsUserNameExistUrl = '/api/User/isusernameexist';
  private ForgetPasswordUrl = '/api/User/forgetpassword';
  private SetNewPasswordUrl = '/api/User/setnewpassword';
  // private LoginUrl = "http://localhost:57800/jwt/getjwttoken";
  // private LoginDetailsUrl = "http://localhost:57800/jwt/getjwttokendetails";
  // private CheckAuthByTokenUrl="http://localhost:57800/api/Values/1";
  private LoginUrl = '/api/jwt/getjwttoken';
  private LoginDetailsUrl = '/api/jwt/getjwttokendetails';
  private CheckAuthByTokenUrl = "http://localhost:57800/api/Values/1";
  private GetSensorDataUrl = "http://sensor-api.myzingonline.com/api/Sensor?locationName=Computer%20room&sensorType=temperature";
  private MyUrl ="/api/jwt/My";
  public redirectUrl: string;
  private GetRefreshTokenUrl = '/api/jwt/getrefreshtoken';
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) {
    this.baseUrl = this.configLoaderService.ApiBasePath;

  }
  public get userValue(): User {
    return this.userSubject.value;
}
  /** GET heroes from the server */
  GetAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + this.GetAllUsersUrl, { headers: this.getHeader() });
    // .pipe(
    //   tap(_ => this.log('fetched heroes')),
    //   catchError(this.handleError('getHeroes', []))
    // );
  }
  getUser(): Observable<User> {
    return this.http.get<User>(this.GetUserByIdUrl)
    // .pipe(
    //   tap(_ => this.log('fetched heroes')),
    //   catchError(this.handleError('getHeroes', []))
    // );
  }
  // getSensorData():Observable<MainSensorData>{
  //   //var me = this.http.get<any>(this.GetSensorDataUrl);
  //    return this.http.get<MainSensorData>(this.GetSensorDataUrl)
  //   // return me;
  // }
  saveUser(user: RegisterUser): Observable<any> {
    var me = this.http.post<any>(this.baseUrl + this.SaveUserUrl, user, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(user))
    );
    return me;
  }
  IsEmailExist(email: string): Observable<any> {
    var response = this.http.get<any>(this.baseUrl + this.IsEmailExistUrl+"?email="+email).pipe(
      tap(_ => console.log(_))
    );
    return response;
  }
  IsUserNameExist(username: string): Observable<any> {
    var response = this.http.get<any>(this.baseUrl + this.IsUserNameExistUrl+"?userName="+username).pipe(
      tap(_ => console.log(_))
    );
    return response;
  }
  ForgetPassword(model:ForgetPasswordModel): Observable<any> {
    var response = this.http.post<any>(this.baseUrl + this.ForgetPasswordUrl,model).pipe(
      tap(_ => console.log(_))
    );
    return response;
  }
  SetNewPassword(model:SetNewPasswordModel): Observable<any> {
    var response = this.http.post<any>(this.baseUrl + this.SetNewPasswordUrl,model).pipe(
      tap(_ => console.log(_))
    );
    return response;
  }
  login(username: string, password: string): Observable<any> {
    var model = new UserLoginModel();
    model.UserName = username;
    model.Password = password;
    var me = this.http.post<any>(this.baseUrl + this.LoginUrl, model).pipe(
      tap(_ => console.log('I am login'))
    );
    return me;
  }

  loginDetails(username: string, password: string): Observable<any> {
    var model = new UserLoginModel();
    model.UserName = username;
    model.Password = password;

    var me = this.http.post<any>(this.baseUrl + this.LoginDetailsUrl, model).pipe(


      tap(_ => console.log('I am login'))
    );
    return me;
  }
  
  loginDetailsSubject(username: string, password: string): Observable<any> {
    //https://jasonwatmore.com/post/2020/05/22/angular-9-jwt-authentication-with-refresh-tokens
    var model = new UserLoginModel();
    model.UserName = username;
    model.Password = password;
    

    var me = this.http.post<any>(this.baseUrl + this.LoginDetailsUrl, model).pipe(
      map(user => {
                this.userSubject.next(user);
                this.startRefreshTokenTimer();
                return user;
            }),
      tap(_ => console.log('I am login'))
    );
    return me;
  }o
  GetRefreshToken() {
    var token = localStorage.getItem('jwtToken');
    var model = {
      Token:token
    }
    var me = this.http.post<any>(this.baseUrl + this.GetRefreshTokenUrl, model).pipe(
      map(user => {
                //this.userSubject.next(user);
                //this.startRefreshTokenTimer();
                return user;
            }),
      tap(_ => console.log('I am login'))
    );
    return me;
    // return this.http.post<any>('this.base  /users/refresh-token`, {}, { withCredentials: true })
    //     .pipe(map((user) => {
    //         this.userSubject.next(user);
    //         this.startRefreshTokenTimer();
    //         return user;
    //     }));
}
  getUserDetails(token: string): Observable<any> {
    const httpOptions = {
      //headers: { 'jwt':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyIjoiYWRtaW4iLCJQaW4iOjEyMzQsIkV4cGlyZXMiOiIyMDE5LTA3LTMxVDIzOjUyOjUzLjAzMTg1OTErMDU6MzAiLCJVc2VySW5mbyI6eyJSb2xlIjoiQWRtaW4iLCJBZGRyZXNzIjoiVHJpIE5hZ2FyIn19.nHSPW2mCcrwrKOiQa9xO3A+2EYgI+u98TApVJoPv2+4='}
      headers: { 'jwt': token }
    };
    var me = this.http.get<any>(this.CheckAuthByTokenUrl, httpOptions)
    return me;
  }
  isAuthorized(): boolean {
    var token = localStorage.getItem('jwtToken');
    var expiryDate = new Date(localStorage.getItem('ExpiryDate'));
    let today = new Date();
    if (token != null && token != 'undefined') {
      //if (localStorage.getItem('role').toLowerCase() == "admin") {
        if (expiryDate >= today ) {
        localStorage.getItem('currentUser');
        localStorage.getItem('userName');
        localStorage.getItem('role');
        return true;
      }
    }
    else {
      return false;
    }

    return false;
  }
  Logout(): string {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('jwtToken');
    return "login";
  }
  LogoutSubject(): string {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('jwtToken');
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    return "login";
  }

   isAuth():Observable<any> {
    var response = this.http.get<any>(this.baseUrl+this.MyUrl, { headers: this.getHeader() })
    return response;
  }
  // getHeader() {
  //   var token = sessionStorage.getItem('jwtToken');
  //   // var token = 'jwtToken';
  //   let headers = new HttpHeaders().set("jwt", token);
  //   return headers;
  // }
  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(localStorage.getItem('jwtToken').split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.GetRefreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
