import { RecipeDetailsModel } from "./recipe-details-model";

export class RecipeModel {
    public RecipeID: number ;
	public RecipeName: string ; 
	public RecipeServingSize: number ; 
	public UserID: number ;
	public IsAvailableToPublic: boolean ;
	public Narration: string ; 
    public RecipeDetails: RecipeDetailsModel[];
	public RecipeList:RecipeModel[];
}
