export class FileModel {
    public FileID: number;
    public AccountCode: String;
    public RelativeFilePath: String;
    public FullPath: String;
    public FileName: String;
    public UserID: number;
    public IsAvailableToPublic: boolean;

    public FileAutoComplete:any;
   public Filelist:FileModel[];
}
