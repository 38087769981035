export class FoodLogPriceModel {
    constructor(){
this.Quantity=0;
this.CalculatedAmount=0;

    }
    public  ServingSize : number;
    public  ProductName : String;
    public  CalculatedAmount : number;
    public  Measurement : number;
    public  Quantity : number;
    public  MeasurementType : String;
    public  BillAmount : number;
    public  VoucherDate : Date;
    public  BillMonth : number;
    public  VoucherID : number;
}
