export class SmartBagCurrentComboModel {
        public  CurrentComboID:number;
        public  ComboMasterID:number;
        public  ComboMasterName:string;
        public  ComboCommand:string;
        public  DelayDurationInMS:number;
        public  NoOfTimes:number;
        public  UserID:number;
    
}
