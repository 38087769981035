
<div class="activity-section">
    <div class="activity-section-item" *ngIf="exerciseModel">
        <div>
            <i class="fas fa-fire"></i><br />
        </div>
        <div>
            {{exerciseModel.summary.caloriesOut}}
        </div>
    </div>
    <div class="activity-section-item" *ngIf="exerciseModel">
        <div>
            <i class="fas fa-shoe-prints"></i>
        </div>
        <div>
            {{exerciseModel.summary.steps}}
        </div>
    </div>
  
  <div class="activity-section-item" *ngFor="let item of exerciseViewModel">
    <ng-template #activitytooltipdata>
        <div class="" >
          <div> <span class="tool-tip-heading">{{item.ExerciseName}}: </span> <br /><span class="nutrition-log-status">
           Calories: {{item.Calories}} <br /> Duration: {{item.Duration}}Min</span> </div>
        </div>
      </ng-template>
      <span [ngbTooltip]="activitytooltipdata" 
      triggers="click:blur" data-html="true" placement="bottom-left">
        <div>
            <i class="{{item.FafaClassIcon}}"></i>
        </div>
        <div id="dumble-text">
            {{item.Duration}}
        </div>
    </span>
    </div>
    

</div>