import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
//import { User } from '../models/user';
import { ExpenseApiModel } from '../models/ExpenseManager/expense-api-model';
import { VendorResponse } from '../models/ExpenseManager/Vendor/vendor-response';
import { VendorApiModel } from '../models/ExpenseManager/Vendor/vendor-api-model';
import { ProductApiModel } from '../models/ExpenseManager/Product/product-api-model';
import { AccountApiModel } from '../models/ExpenseManager/Accounts/account-api-model';
import { TransactionApiModel } from '../models/ExpenseManager/Transaction/transaction-api-model';
import { ConfigLoaderService } from './config-loader.service';
const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
//Token url https://dev.fitbit.com/apps/details/22D3QS
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable({
  providedIn: 'root'
})
export class FitBitService {
  private baseUrl ="http://localhost:2718";
  private GetFitbitSleepDataUrl = '';
  private GetFitbitHeartDataUrl = '';
  private GetFitbitExerciseDataUrl = '';
  private GetFitbitFoodDataUrl = '';  
  private GetFitbitBodyDataUrl = '';
  private GetFitbitWaterLogDataUrl = ''; 
  private GetFitbitWaterGoalDataUrl = '';
  private GetFitbitBodyBMIDataUrl = '';
  private GetFitbitBodyWeightDataUrl = '';
  private GetFitbitBodyFatDataUrl = '';
  GetFitBitBearerTokenUrl = '';
  private bearerToken1YearValid='eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyMkQzUVMiLCJzdWIiOiI2UkIySjgiLCJpc3MiOiJGaXRiaXQiLCJ0eXAiOiJhY2Nlc3NfdG9rZW4iLCJzY29wZXMiOiJ3aHIgd251dCB3cHJvIHdzbGUgd3NvYyB3YWN0IHdveHkgd3RlbSB3d2VpIHdzZXQgd3JlcyB3bG9jIiwiZXhwIjoxNjYyNzk5MDg5LCJpYXQiOjE2NjI3MTI4NjN9.K_EHxz32PM1BtB0T0yZVnud5wreiL_lHuthS1vQy208';//8aug2023 Expire 
  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService)
 { 
//var location="localhost"
    this.baseUrl =this.configLoaderService.ApiBasePath;
    this.GetFitbitSleepDataUrl = 'https://api.fitbit.com/1.2/user/-/sleep/date/{0}.json'; 
    this.GetFitbitHeartDataUrl = 'https://api.fitbit.com/1/user/-/activities/heart/date/{0}/1d.json';
    this.GetFitbitExerciseDataUrl = 'https://api.fitbit.com/1/user/-/activities/date/{0}.json';
    this.GetFitbitFoodDataUrl = 'https://api.fitbit.com/1/user/-/foods/log/date/{0}.json';
    this.GetFitbitBodyDataUrl = 'https://api.fitbit.com/1/user/-/body/log/weight/date/{0}.json';    
    this.GetFitbitWaterLogDataUrl = 'https://api.fitbit.com/1/user/-/foods/log/water/date/{0}.json'; 
    this.GetFitbitWaterGoalDataUrl = 'https://api.fitbit.com/1/user/-/foods/log/water/goal.json';
    this.GetFitbitBodyBMIDataUrl="https://api.fitbit.com/1/user/-/body/bmi/date/today/1y.json";
    this.GetFitbitBodyWeightDataUrl="https://api.fitbit.com/1/user/-/body/weight/date/today/1y.json";
    this.GetFitbitBodyFatDataUrl="https://api.fitbit.com/1/user/-/body/fat/date/today/1y.json";
    //local this.GetFitBitBearerTokenUrl="https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22D3QS&redirect_uri=http%3A%2F%2Flocalhost%3A4401%2Ffitbit%2Ffitbittoken&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight%20oxygen_saturation%20respiratory_rate%20temperature&expires_in=31536000";
    this.GetFitBitBearerTokenUrl="https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=22D3QS&redirect_uri=https%3A%2F%2Fadmin.myzingonline.in%2Ffitbit%2Ffitbittoken&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight%20oxygen_saturation%20respiratory_rate%20temperature&expires_in=31536000";
  }//https://dev.fitbit.com/build/reference/web-api/body-timeseries/get-body-timeseries-by-date/

  GetFitBitBearerToken(){
    var response = this.http.get<any>(this.GetFitBitBearerTokenUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetFitbitSleepData(date: string): Observable<any> {
    var newUrl = this.GetFitbitSleepDataUrl.replace('{0}',date);
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitHeartData(date: string): Observable<any> {
    var newUrl = this.GetFitbitHeartDataUrl.replace('{0}',date);
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitExerciseData(date: string): Observable<any> {
    var newUrl = this.GetFitbitExerciseDataUrl.replace('{0}',date);
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitFoodData(date: string): Observable<any> {
    var newUrl = this.GetFitbitFoodDataUrl.replace('{0}',date);
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitBodyData(date: string): Observable<any> {
    var newUrl = this.GetFitbitBodyDataUrl.replace('{0}',date);
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitWaterLogData(date: string): Observable<any> {
    var newUrl = this.GetFitbitWaterLogDataUrl.replace('{0}',date);
    var response = this.http.get<any>(newUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitWaterGoalData(): Observable<any> {
    var response = this.http.get<any>(this.GetFitbitWaterGoalDataUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitBodyBMIData(): Observable<any> {
    var response = this.http.get<any>(this.GetFitbitBodyBMIDataUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitBodyWeightData(): Observable<any> {
    var response = this.http.get<any>(this.GetFitbitBodyWeightDataUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  GetFitbitBodyFatData(): Observable<any> {
    var response = this.http.get<any>(this.GetFitbitBodyFatDataUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    // var response = this.http.get<any>(this.GetVoucherIdsByNameUrl+searchText).pipe(
    //   tap(_ => console.log(response))
    // );
    return response;
  }
  getHeader(){
    //var token = this.bearerToken1YearValid;
    var token= localStorage.getItem('fitbitToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    });
    return headers;
  }    
}
