import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { HabitMappingModel } from 'src/app/models/HabitTracker/HabitMapping/habit-mapping-model';
import { HabitTrackerService } from 'src/app/services/habit-tracker.service';
//import { HabitTrackerService } from 'src/app/service/habit-tracker.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-habit-mapping',
  templateUrl: './habit-mapping.component.html',
  styleUrls: ['./habit-mapping.component.css']
})
export class HabitMappingComponent implements OnInit {
  public result='';
  public habitMappingModel:HabitMappingModel;

model: any;
searching = false;
searchFailed = false;

  constructor(private habitTrackerService: HabitTrackerService,private titleService:Title) { this.titleService.setTitle("Habit Mapping");}

  ngOnInit() {

    
this.habitMappingModel = new HabitMappingModel();
this.habitMappingModel.HabitMappinglist = [];

    this.habitTrackerService.Gethabitmappingallorbyid(0).subscribe(response=>
      {
        this.habitMappingModel.HabitMappinglist = response;
        console.log('habit mapping'+response);
      }
    );
  }

  // habit mapping
  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]):
      (this.habitTrackerService.GetHabitMappingByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )



 formatter = (x: { HabitName: string }) => x.HabitName;

 selectedItem(item){
  //this.clickedItem=item.item;
  console.log(item);
  this.habitMappingModel.HabitId = item.item.HabitId;
  this.habitMappingModel.HabitMappingId = item.item.HabitMappingId;
  this.habitMappingModel.HabitName = item.item.HabitName;
  this.habitMappingModel.HabitTypeId = item.item.HabitTypeId;

  this.habitMappingModel.HabitTypeName = item.item.HabitTypeName;
  this.habitMappingModel.NumberOfHours = item.item.NumberOfHours;

  this.habitMappingModel.NumberOfMinutes = item.item.NumberOfMinutes;
  this.habitMappingModel.NumberOfTimes = item.item.NumberOfTimes;
}

//Habit type
searchHabitTypeName = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]):
    (this.habitTrackerService.GetHabitTypeByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)



formatterHabitTypeName = (x: { HabitTypeName: string }) => x.HabitTypeName;

selectedItemHabitTypeName(item){
//this.clickedItem=item.item;
console.log(item);
// this.habitMappingModel.HabitId = item.item.HabitId;
// this.habitMappingModel.HabitMappingId = item.item.HabitMappingId;
// this.habitMappingModel.HabitName = item.item.HabitName;
this.habitMappingModel.HabitTypeId = item.item.HabitTypeId;
this.habitMappingModel.HabitTypeName = item.item.HabitTypeName;

// this.habitMappingModel.HabitTypeName = item.item.HabitTypeName;
// this.habitMappingModel.NumberOfHours = item.item.NumberOfHours;

// this.habitMappingModel.NumberOfMinutes = item.item.NumberOfMinutes;
// this.habitMappingModel.NumberOfTimes = item.item.NumberOfTimes;
}

//habit autocomplete
searchHabit = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]):
    (this.habitTrackerService.GetHabitByName(term).pipe(
      tap(() => this.searchFailed = false
      ),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)



formatterHabit = (x: { HabitName: string }) => x.HabitName;

selectedItemHabit(item){
//this.clickedItem=item.item;
console.log(item);
 this.habitMappingModel.HabitId = item.item.HabitId;
 this.habitMappingModel.HabitName = item.item.HabitName;

}

onSubmit() {
  var me = this.habitMappingModel;

  this.habitTrackerService.Insertorupdatehabitmapping(me).subscribe(
    response => {
      console.log(response);
      this.result = response;
      this.habitMappingModel = new HabitMappingModel();
    }
  );
}

}
