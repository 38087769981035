import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductApiModel } from '../models/ExpenseManager/Product/product-api-model';
import { ExpenseManagerService } from '../services/expense-manager.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  notifyBeforeDays = 7;
  produtApiModels: ProductApiModel[];
  isShowRenewProducts=false;
  sectionName='';
  constructor(private expenseManagerService: ExpenseManagerService,private route: ActivatedRoute) {
    this.produtApiModels = [];
    this.sectionName = this.route.snapshot.queryParamMap.get('section');
      if (this.sectionName != null && this.sectionName.length > 0) {
        if(this.sectionName == 'renew'){
          this.isShowRenewProducts=true;
        }
      }
  }

  ngOnInit() {
    this.GetRenewProducts();
  }
  productRenewClick(){
    this.isShowRenewProducts = !this.isShowRenewProducts;
  }
  redirectToProductPage(productId){
    window.location.href = '/expense/product' + '?productid=' + productId;
  }
  GetRenewProducts(){
    this.expenseManagerService.GetRenewProducts(this.notifyBeforeDays).subscribe(response => {
      this.produtApiModels = response;
      this.produtApiModels.length
    });
  }
  updateRenewProductByDays(){
    this.GetRenewProducts();
  }
}
