import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuestionAnswerModel } from 'src/app/models/Interview/QuestionAnswer/question-answer-model';
import { SubjectModel } from 'src/app/models/Interview/Subject/subject-model';
import { TopicModel } from 'src/app/models/Interview/Topic/topic-model';
import { InterviewService } from 'src/app/services/interview.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-question-bank',
  templateUrl: './question-bank.component.html',
  styleUrls: ['./question-bank.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionBankComponent implements OnInit {
  public questionAnswerList:QuestionAnswerModel [];
  public searchText='';
  public searchWithInText='';
  public subjectId=0;
  public topicId=0;
  public questionAnswerUntochedList:QuestionAnswerModel [];
  public questionAnswerTopicUntochedList:QuestionAnswerModel [];
  public subjectList: SubjectModel[];
  public topicList: TopicModel[];
  constructor(private interviewService: InterviewService,private titleService:Title) {this.titleService.setTitle("Question Bank"); }

  ngOnInit(): void {
    this.interviewService.GetAllSubject().subscribe(response => {
      this.subjectList = response;
    });
  }
  search(){
    console.log(this.searchText);
    this.interviewService.GetAllQuestionAnswerOrQuestionAnswerByText(this.searchText).subscribe(response =>{
      //this.questionAnswerUntochedList=response;
      var re = new RegExp(this.searchText,"gi");
      for(var expCodeObj of response){
        //expCodeObj.Answer = expCodeObj.Answer.replace(this.searchText, "<b>"+this.searchText+"</b>"); 
        expCodeObj.Answer = expCodeObj.Answer.replace(re, "<b>"+this.searchText+"</b>");
      }
      this.questionAnswerList = response;
      this.questionAnswerUntochedList = response;
  });
  }
  searchWithInRecord(){
    console.log(this.searchWithInText);
      //this.questionAnswerUntochedList=response;
      var re = new RegExp(this.searchWithInText,"gi");
      for(var expCodeObj of this.questionAnswerList){
        //expCodeObj.Answer = expCodeObj.Answer.replace(this.searchText, "<b>"+this.searchText+"</b>"); 
        if(expCodeObj.Answer.toLowerCase().includes(this.searchWithInText.toLowerCase()))
        {
          expCodeObj.TopicName = expCodeObj.TopicName+" - Found";
        }
        expCodeObj.Answer = expCodeObj.Answer.replace(re, "<b>"+this.searchWithInText+"</b>");
      }
      //this.questionAnswerList=[];
     //this.questionAnswerList = this.questionAnswerUntochedList;
  }

  filterTopic(subjectID) {
    this.subjectId=subjectID;
    console.log(subjectID);
    this.interviewService.GetAllTopic().subscribe(response => {
      this.topicList = response.filter(
        function (e) {
          return e.SubjectID == subjectID;
        }
      );
    });
    this.interviewService.GetAllQuestionAnswerBySubjectIDOrTopicIDOrQuestionID(subjectID,0,0).subscribe(response =>{
      this.questionAnswerList = response;
      this.questionAnswerUntochedList = response;
  });
   
  }

  filterQuestionByTopic(topicId) {
    this.topicId = topicId;
   this.interviewService.GetAllQuestionAnswerBySubjectIDOrTopicIDOrQuestionID(this.subjectId,topicId,0).subscribe(response =>{
      this.questionAnswerList = response;
      this.questionAnswerUntochedList = response;
  });

  }


}
