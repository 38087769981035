<fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-10" #Food>
    <legend class="section-legend-border sub-section-legend">Food</legend>
    <div class="row">

        <div class="col-sm-12">
            <form (ngSubmit)="onSubmit()" #loginForm="ngForm">

                <div class="form-row">
                    <div class="form-group col-md-10 col-lg-7">
                        <ng-template #rt let-r="result" let-t="term">
                            <!-- {{ r.Name}} -->
                            {{r.ProductName}}
                        </ng-template>
                        <label for="typeahead-basic">Search for a Food:</label>
                        <input id="typeahead-basic" type="text" class="form-control" name="foodModel.FoodAutoComplete"
                            [(ngModel)]="foodModel.FoodAutoComplete" [ngbTypeahead]="search"
                            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-10 col-lg-7">
                        <ng-template #rtClone let-r="result" let-t="term">
                            <!-- {{ r.Name}} -->
                            {{r.ProductName}}
                        </ng-template>
                        <label for="typeahead-basic">Search for a Food to Clone:</label>
                        <input id="typeahead-basic" type="text" class="form-control"
                            name="foodModel.FoodAutoCompleteClone" [(ngModel)]="foodModel.FoodAutoCompleteClone"
                            [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rtClone"
                            (selectItem)="selectedItemClone($event)" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2 col-lg-2">
                        <label for="disabledTextInput">Food Id</label>
                        <input type="text" [(ngModel)]="foodModel.FoodId" name="foodModel.FoodId" placeholder="id"
                            class="form-control">
                    </div>
                    <div class="form-group col-md-8 col-lg-5">
                        <label for="disabledTextInput">Food Name</label>
                        <input type="text" [(ngModel)]="foodModel.Name" name="foodModel.Name" placeholder="Food Name"
                            class="form-control">
                    </div>
                </div>
                <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #FoodImage *ngIf="foodImageModel.length>0">
                    <legend class="section-legend-border">Food Image</legend>
                    <div class="row">
                        <div class="col-sm-12">
                            <div *ngFor="let foodImageMo of foodImageModel" class="image-section">
                                <div (click)="DeleteFoodPic(foodImageMo.FoodImageId)">{{foodImageMo.Name}}</div>
                                <img [src]='foodImageMo.ImageByteData' width="200" height="200" [id]="foodImageMo.Name" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12" style="margin-top:12px">
                            <a class="btn btn-primary" (click)="DownLoadFiles()">Download File</a>
                            {{result}}
                        </div>
                    </div>
                 </fieldset>

                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #Brand>
                    <legend class="section-legend-border sub-section-legend">Brand</legend>
                    <div class="form-row">
                        <div class="form-group col-md-10 col-lg-7">
                            <ng-template #rtBrand let-r="result" let-t="term">
                                {{ r.Name}}
                            </ng-template>
                            <label for="typeahead-basic">Search for a Brand:</label>
                            <input id="typeahead-basic" type="text" class="form-control"
                                name="foodModel.BrandModel.BrandAutoComplete"
                                [(ngModel)]="foodModel.BrandModel.BrandAutoComplete" [ngbTypeahead]="searchBrand"
                                [inputFormatter]="formatterBrand" [resultTemplate]="rtBrand"
                                (selectItem)="selectedItemBrand($event)" />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-2 col-lg-2">
                            <label for="disabledTextInput">Brand Id</label>
                            <input type="text" [(ngModel)]="foodModel.BrandModel.BrandId"
                                name="foodModel.BrandModel.BrandId" placeholder="id" class="form-control">
                        </div>
                        <div class="form-group col-md-8 col-lg-5">
                            <label for="disabledTextInput">Brand Name</label>
                            <input type="text" [(ngModel)]="foodModel.BrandModel.Name" name="foodModel.BrandModel.Name"
                                placeholder="Brand Name" class="form-control">
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #Serving>
                    <legend class="section-legend-border sub-section-legend">Serving</legend>
                    <div class="form-row">
                        <div class="form-group col-md-10 col-lg-7">
                            <label for="disabledTextInput">Serving Size for foodlog</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.ServingSize"
                                name="foodModel.NutritionFactModel.ServingSize" placeholder="Serving Size"
                                class="form-control">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4 col-lg-4">
                            <label for="disabledTextInput">Serving Size (Pkt Tb)</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.ServingSizeValue"
                                name="foodModel.NutritionFactModel.ServingSizeValue" placeholder="Serving Size value"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-6 col-lg-5">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="meal-time"
                                name="foodModel.NutritionFactModel.ServingSize_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.ServingSize_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #B>
                    <legend class="section-legend-border sub-section-legend">B</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Biotin</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Biotin"
                                name="foodModel.NutritionFactModel.Biotin" placeholder="Biotin" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Biotin_MeasurementId"
                                name="foodModel.NutritionFactModel.Biotin_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Biotin_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #C>
                    <legend class="section-legend-border sub-section-legend">C</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Calories</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Calories"
                                name="foodModel.NutritionFactModel.Calories" placeholder="Calories"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Calories_MeasurementId" aria-placeholder="Measurement"
                                name="foodModel.NutritionFactModel.Calories_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Calories_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Cobalamin</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Cobalamin"
                                name="foodModel.NutritionFactModel.Cobalamin" placeholder="Cobalamin"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Cobalamin_MeasurementId"
                                name="foodModel.NutritionFactModel.Cobalamin_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Cobalamin_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Copper</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Copper"
                                name="foodModel.NutritionFactModel.Copper" placeholder="Copper" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Copper_MeasurementId"
                                name="foodModel.NutritionFactModel.Copper_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Copper_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Cholesterol</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Cholesterol"
                                name="foodModel.NutritionFactModel.Cholesterol" placeholder="Cholesterol"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Cholesterol_MeasurementId"
                                name="foodModel.NutritionFactModel.Cholesterol_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Cholesterol_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Calcium</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Calcium"
                                name="foodModel.NutritionFactModel.Calcium" placeholder="Calcium" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Calcium_MeasurementId"
                                name="foodModel.NutritionFactModel.Calcium_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Calcium_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #D>
                    <legend class="section-legend-border sub-section-legend">D</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">DietryFiber</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.DietryFiber"
                                name="foodModel.NutritionFactModel.DietryFiber" placeholder="DietryFiber"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="DietryFiber_MeasurementId"
                                name="foodModel.NutritionFactModel.DietryFiber_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.DietryFiber_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #F>
                    <legend class="section-legend-border sub-section-legend">F</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Folate</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Folate"
                                name="foodModel.NutritionFactModel.Folate" placeholder="Folate" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Folate_MeasurementId"
                                name="foodModel.NutritionFactModel.Folate_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Folate_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #I>
                    <legend class="section-legend-border sub-section-legend">I</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Iron</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Iron"
                                name="foodModel.NutritionFactModel.Iron" placeholder="Iron" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Iron_MeasurementId"
                                name="foodModel.NutritionFactModel.Iron_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Iron_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Iodin</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Iodin"
                                name="foodModel.NutritionFactModel.Iodin" placeholder="Iodin" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Iodin_MeasurementId"
                                name="foodModel.NutritionFactModel.Iodin_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Iodin_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #M>
                    <legend class="section-legend-border sub-section-legend">M</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Monounsaturated Fat</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.MonounsaturatedFat"
                                name="foodModel.NutritionFactModel.MonounsaturatedFat" placeholder="PolyunsaturaredFat"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="MonounsaturatedFat_MeasurementId"
                                name="foodModel.NutritionFactModel.MonounsaturatedFat_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.MonounsaturatedFat_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Mangnese</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Mangnese"
                                name="foodModel.NutritionFactModel.Mangnese" placeholder="Mangnese"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Magnesium_MeasurementId"
                                name="foodModel.NutritionFactModel.Magnesium_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Magnesium_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #O>
                    <legend class="section-legend-border sub-section-legend">O</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Omega3</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Omega3"
                                name="foodModel.NutritionFactModel.Omega3" placeholder="Omega3" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Omega3_MeasurementId"
                                name="foodModel.NutritionFactModel.Omega3_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Omega3_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Oxalate</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Oxalate"
                                name="foodModel.NutritionFactModel.Oxalate" placeholder="Oxalate" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Oxalate_MeasurementId"
                                name="foodModel.NutritionFactModel.Oxalate_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Oxalate_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #P>
                    <legend class="section-legend-border sub-section-legend">P</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Protien</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Protien"
                                name="foodModel.NutritionFactModel.Protien" placeholder="Protien" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Protien_MeasurementId"
                                name="foodModel.NutritionFactModel.Protien_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Protien_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Polyunsaturared Fat</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.PolyunsaturaredFat"
                                name="foodModel.NutritionFactModel.PolyunsaturaredFat" placeholder="PolyunsaturaredFat"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="PolyunsaturaredFat_MeasurementId"
                                name="foodModel.NutritionFactModel.PolyunsaturaredFat_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.PolyunsaturaredFat_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Phosphorus</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Phosphorus"
                                name="foodModel.NutritionFactModel.Phosphorus" placeholder="Phosphorus"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Phosphorus_MeasurementId"
                                name="foodModel.NutritionFactModel.Phosphorus_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Phosphorus_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Potassium</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Potassium"
                                name="foodModel.NutritionFactModel.Potassium" placeholder="Potassium"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Potassium_MeasurementId"
                                name="foodModel.NutritionFactModel.Potassium_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Potassium_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #S>
                    <legend class="section-legend-border sub-section-legend">S</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Sugar</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Sugar"
                                name="foodModel.NutritionFactModel.Sugar" placeholder="Sugar" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Sugar_MeasurementId"
                                name="foodModel.NutritionFactModel.Sugar_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Sugar_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Saturated Fat</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.SaturatedFat"
                                name="foodModel.NutritionFactModel.SaturatedFat" placeholder="SaturatedFat"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="SaturatedFat_MeasurementId"
                                name="foodModel.NutritionFactModel.SaturatedFat_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.SaturatedFat_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Sodium</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Sodium"
                                name="foodModel.NutritionFactModel.Sodium" placeholder="Sodium" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Sodium_MeasurementId"
                                name="foodModel.NutritionFactModel.Sodium_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Sodium_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Selenium</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Selenium"
                                name="foodModel.NutritionFactModel.Selenium" placeholder="Selenium"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Selenium_MeasurementId"
                                name="foodModel.NutritionFactModel.Selenium_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Selenium_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #T>
                    <legend class="section-legend-border sub-section-legend">T</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">TotalFat</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.TotalFat"
                                name="foodModel.NutritionFactModel.TotalFat" placeholder="TotalFat"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="TotalFat_MeasurementId"
                                name="foodModel.NutritionFactModel.TotalFat_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.TotalFat_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Tans Fat</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.TansFat"
                                name="foodModel.NutritionFactModel.TansFat" placeholder="TansFat" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="TansFat_MeasurementId"
                                name="foodModel.NutritionFactModel.TansFat_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.TansFat_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Total Carbohydrate</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.TotalCabohydrate"
                                name="foodModel.NutritionFactModel.TotalCabohydrate" placeholder="TotalCabohydrate"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="TotalCabohydrate_MeasurementId"
                                name="foodModel.NutritionFactModel.TotalCabohydrate_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.TotalCabohydrate_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                </fieldset>

                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #V>
                    <legend class="section-legend-border sub-section-legend">V</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminA</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminA"
                                name="foodModel.NutritionFactModel.VitaminA" placeholder="VitaminA"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminA_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminA_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminA_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminB1_Thiamin</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminB1_Thiamin"
                                name="foodModel.NutritionFactModel.VitaminB1_Thiamin" placeholder="VitaminB1_Thiamin"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminB1_Thiamin_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminB1_Thiamin_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminB1_Thiamin_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminB2_Riboflavin</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminB2_Riboflavin"
                                name="foodModel.NutritionFactModel.VitaminB2_Riboflavin"
                                placeholder="VitaminB2_Riboflavin" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminB2_Riboflavin_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminB2_Riboflavin_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminB2_Riboflavin_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminB3_Niacin</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminB3_Niacin"
                                name="foodModel.NutritionFactModel.VitaminB3_Niacin" placeholder="VitaminB3_Niacin"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminB3_Niacin_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminB3_Niacin_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminB3_Niacin_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminB5_Pantothenic</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminB5_PantothenicAcid"
                                name="foodModel.NutritionFactModel.VitaminB5_PantothenicAcid"
                                placeholder="VitaminB5_PantothenicAcid" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminB5_PantothenicAcid_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminB5_PantothenicAcid_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminB5_PantothenicAcid_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminB6</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminB6"
                                name="foodModel.NutritionFactModel.VitaminB6" placeholder="VitaminB6"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminB6_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminB6_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminB6_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminB12</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminB12"
                                name="foodModel.NutritionFactModel.VitaminB12" placeholder="VitaminB12"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminB12_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminB12_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminB12_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminC</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminC"
                                name="foodModel.NutritionFactModel.VitaminC" placeholder="VitaminC"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminC_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminC_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminC_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminD</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminD"
                                name="foodModel.NutritionFactModel.VitaminD" placeholder="VitaminD"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminD_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminD_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminD_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminE</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminE"
                                name="foodModel.NutritionFactModel.VitaminE" placeholder="VitaminE"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminE_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminE_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminE_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">VitaminK</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.VitaminK"
                                name="foodModel.NutritionFactModel.VitaminK" placeholder="VitaminK"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="VitaminK_MeasurementId"
                                name="foodModel.NutritionFactModel.VitaminK_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.VitaminK_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #W>
                    <legend class="section-legend-border sub-section-legend">W</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Water</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Water"
                                name="foodModel.NutritionFactModel.Water" placeholder="Water" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Water_MeasurementId"
                                name="foodModel.NutritionFactModel.Water_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Water_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="section-legend-border sub-section-legend  col-md-10 col-lg-7" #Z>
                    <legend class="section-legend-border sub-section-legend">Z</legend>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="disabledTextInput">Zinc</label>
                            <input type="text" [(ngModel)]="foodModel.NutritionFactModel.Zinc"
                                name="foodModel.NutritionFactModel.Zinc" placeholder="Zinc" class="form-control">
                        </div>
                        <div class="form-group col-md-8">
                            <label for="exampleFormControlSelect1">Measurement Type</label>
                            <select class="form-control" id="Zinc_MeasurementId"
                                name="foodModel.NutritionFactModel.Zinc_MeasurementId"
                                [(ngModel)]="foodModel.NutritionFactModel.Zinc_MeasurementId">
                                <option [ngValue]="undefined" disabled selected> Select Measurement </option>
                                <option *ngFor="let item of foodModel.MeasurementModel" [value]="item.MeasurementId">
                                    {{item.MeasurementType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #UploadFile >
                    <legend class="section-legend-border">Upload File</legend>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <app-food-image [foodModel]="foodModel" #appfoodimage></app-food-image>
                    </div>
                </div>
                </fieldset>
                <div class="form-row">
                    <div class="form-group col-md-4"></div>
                    <div class="form-group col-md-4 col-sm-12">
                        <button type="submit" class="btn btn-primary btn-block">Submit</button>
                    </div>
                    <div class="form-group col-md-4"></div>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="margin-top:12px">
                        {{result}}
                    </div>
                </div>

            </form>
        </div>

        <!-- <div *ngFor="let thumb of thumbnail">
        <img [src]='thumb' width="200" height="200" />
     </div> -->
    
        <!-- <img id="myimage" [src]='thumbnail' width="200" height="200" /> -->
      
    </div>
</fieldset>
<div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4 margin-top-12 margin-bottom-10">
        <button type="button" (click)="showHideFoodList();toggle(1)" class="btn btn-primary btn-block"
            [ngClass]="{ 'activeButton' : isValue == 1 }"> {{isShowFoodList === true? 'Hide' : 'Show'}} Food
            List</button>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
        <button type="button" (click)="showHideRecipeList();toggle(2)" class="btn btn-primary btn-block"
            [ngClass]="{ 'activeButton' : isValue == 2 }"> {{isShowRecipeList === true? 'Hide' : 'Show'}} Recipe
            List</button>
    </div>

</div>
<div class="row" id="recent-tran-example" *ngIf="isShowFoodList">
    <div class="col-sm-12 example-section">
        <div class="">
            <p class="section-heading-text">Food List doesn't have NutritionFacts </p>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">ProductID</th>
                        <th scope="col">ProductName</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of productModels">
                        <th scope="row">{{item.ProductID}}</th>
                        <td>{{item.ProductName}}</td>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="row" id="recent-tran-example" *ngIf="isShowRecipeList">
    <div class="col-sm-12 example-section">
        <div class="">
            <p class="section-heading-text">Recipe list </p>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">RecipeID</th>
                        <th scope="col">RecipeName</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of recipeModels">
                        <th scope="row">{{item.RecipeID}}</th>
                        <td>{{item.RecipeName}}</td>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="col-sm-12 scroll-link scroll-section">
    <div (click)="scroll(B)">B</div>
    <div (click)="scroll(C)">C</div>
    <div (click)="scroll(D)">D</div>
    <div (click)="scroll(F)">F</div>
    <div (click)="scroll(I)">I</div>
    <div (click)="scroll(M)">M</div>
    <div (click)="scroll(O)">O</div>
    <div (click)="scroll(P)">P</div>
    <div (click)="scroll(S)">S</div>
    <div (click)="scroll(T)">T</div>
    <div (click)="scroll(V)">V</div>
    <div (click)="scroll(W)">W</div>
    <div (click)="scroll(Z)">Z</div>
</div>