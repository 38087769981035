import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SubjectModel } from 'src/app/models/Interview/Subject/subject-model';
import { InterviewService } from 'src/app/services/interview.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit {
  public result = '';
  constructor(private interviewService: InterviewService,private titleService:Title) { this.titleService.setTitle("Manage Subjects"); }
  searching = false;
  searchFailed = false;
  subjectModel: SubjectModel;
  public subjectList:SubjectModel [];
  ngOnInit(): void {
    this.subjectModel = new SubjectModel();
    this.subjectModel.Subjectlist = [];
    this.subjectList=[];
    this.interviewService.GetAllSubject().subscribe(response =>{
        this.subjectList = response;
    });
  }
//Subject Section
searchSubject = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]):
    (this.interviewService.GetSubjectsByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)
formatterSubject = (x: { Name: string }) => x.Name;

selectedItemSubject(item){
  //this.clickedItem=item.item;
  console.log(item);
  this.subjectModel.SubjectID = item.item.SubjectID;
  this.subjectModel.SubjectName = item.item.SubjectName;
}
onSubmit() {
  var me = this.subjectModel;
  this.interviewService.InsertOrUpdateSubject(this.subjectModel).subscribe(response => {
    this.result = response;
    this.interviewService.GetAllSubject().subscribe(response =>{
      this.subjectList = response;
  });
  },
    err => {
      this.result = err.message;
      
    }
  );
}
}
