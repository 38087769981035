import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigLoaderService } from './config-loader.service';
import { SmartBagCurrentComboModel } from '../models/SmartBag/smart-bag-current-combo-model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SmartBagComboMasterModel } from '../models/SmartBag/smart-bag-combo-master-model';

@Injectable({
  providedIn: 'root'
})
export class SmartBagService {
  private baseUrl ="http://localhost:2718";
 
  private GetCurrentComboUrl = '';
  private GetComboMasterAllOrByIDUrl = '';
  private GetComboBynameUrl = '';
  private InsertOrUpdateComboMasterUrl = '';
  private GetCurrentComboAllOrByIDUrl = '';
  private UpdateCurrentComboUrl = '';
  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) { 
    this.baseUrl = this.configLoaderService.ApiBasePath;
     this.GetCurrentComboUrl = this.baseUrl+'/api/smartbag/getcurrentcombo';
     this.GetComboMasterAllOrByIDUrl = this.baseUrl+'/api/smartbag/getcombomasterallorbyid?comboMasterID=';
     this.GetComboBynameUrl = this.baseUrl+'/api/smartbag/getcombobyname?comboMasterName=';
     this.InsertOrUpdateComboMasterUrl = this.baseUrl+'/api/smartbag/insertorupdatecombomaster';
     this.GetCurrentComboAllOrByIDUrl = this.baseUrl+'/api/smartbag/getcurrentcomboallorbyid?currentComboID=';
     this.UpdateCurrentComboUrl = this.baseUrl+'/api/smartbag/updatecurrentcombo';
    
  }
  GetCurrentCombo(): Observable<SmartBagCurrentComboModel> {
    var response = this.http.get<any>(this.GetCurrentComboUrl,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetComboMasterAllOrByID(comboMasterID: number): Observable<SmartBagComboMasterModel[]> {
    var response = this.http.get<any>(this.GetComboMasterAllOrByIDUrl+comboMasterID,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetComboByname(comboMasterName: string): Observable<SmartBagComboMasterModel[]> {
    var response = this.http.get<any>(this.GetComboBynameUrl+comboMasterName,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  InsertOrUpdateComboMaster(model: SmartBagComboMasterModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateComboMasterUrl, model,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetCurrentComboAllOrByID(currentComboID: number): Observable<SmartBagCurrentComboModel[]> {
    var response = this.http.get<any>(this.GetCurrentComboAllOrByIDUrl+currentComboID,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  UpdateCurrentCombo(model: SmartBagCurrentComboModel): Observable<any> {
    var response = this.http.post<any>(this.UpdateCurrentComboUrl, model,{headers:this.getHeader()}).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }  
}
