<fieldset class="section-legend-border col-md-12 col-lg-12" #HabitMapping >
  <legend class="section-legend-border">Habit Mapping</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
       <!--hbitmapping section-->
      <div class="form-row">
        <div class="form-group col-md-4">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.HabitName}}
          </ng-template>
          <label for="typeahead-basic">Search for a habit name for mapping:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="habitMappingModel.HabitMappingAutoComplete" 
          [(ngModel)]="habitMappingModel.HabitMappingAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
          [resultTemplate]="rt" (selectItem)="selectedItem($event)"
          />
        </div>
      </div>
      <div class="form-row"> 
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Habit Maping Id</label>
          <input type="text" [(ngModel)]="habitMappingModel.HabitMappingId" name="habitMappingModel.HabitMappingId" placeholder="Habit Mapping Id" class="form-control">
        </div>
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Number of times</label>
          <input type="text" [(ngModel)]="habitMappingModel.NumberOfTimes" name="habitMappingModel.NumberOfTimes" placeholder="Number Of times" class="form-control">
        </div>
      </div>
      <!--Minute hour section-->
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Number of minutes</label>
          <input type="text" [(ngModel)]="habitMappingModel.NumberOfMinutes" name="habitMappingModel.NumberOfMinutes"
           placeholder="Number Of Minutes " class="form-control">
        </div>
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Number Of Hours</label>
          <input type="text" [(ngModel)]="habitMappingModel.NumberOfHours" name="habitMappingModel.NumberOfHours" 
          placeholder="Number Of Hours" class="form-control">
        </div>
      </div>
     
      <!-- Habit Section-->
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Habit >
        <legend class="section-legend-border">Habit</legend>
      <div class="form-row">
        <div class="form-group col-md-4">
          <ng-template #rtHabit let-r="result" let-t="term">
            {{ r.HabitName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Habit Name:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="habitMappingModel.HabitAutoComplete" 
          [(ngModel)]="habitMappingModel.HabitAutoComplete" [ngbTypeahead]="searchHabit" [inputFormatter]="formatterHabit"
          [resultTemplate]="rtHabit" (selectItem)="selectedItemHabit($event)"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Habit Id</label>
          <input type="text" [(ngModel)]="habitMappingModel.HabitId" name="habitMappingModel.HabitId" placeholder="Habit Id" class="form-control">
        </div>
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Habit Name</label>
          <input type="text" [(ngModel)]="habitMappingModel.HabitName" name="habitMappingModel.HabitName" placeholder="Habit Name" class="form-control">
        </div>
      </div>
     </fieldset>
     <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #HabitType >
      <legend class="section-legend-border">Habit Type</legend>
      <div class="form-row">
        <div class="form-group col-md-4">
          <ng-template #rtHabitTypeName let-r="result" let-t="term">
            {{ r.HabitTypeName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Habit Type Name:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="habitMappingModel.HabitTypeNameAutoComplete" 
          [(ngModel)]="habitMappingModel.HabitTypeNameAutoComplete" [ngbTypeahead]="searchHabitTypeName" [inputFormatter]="formatterHabitTypeName"
          [resultTemplate]="rtHabitTypeName" (selectItem)="selectedItemHabitTypeName($event)"
          />
        </div>
      </div> 
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Habit Type Id</label>
          <input type="text" [(ngModel)]="habitMappingModel.HabitTypeId" name="habitMappingModel.HabitTypeId" placeholder="HabitType Id" class="form-control">
        </div>
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Habit Type</label>
          <input type="text" [(ngModel)]="habitMappingModel.HabitTypeName" name="habitMappingModel.HabitTypeName" placeholder="Habit Type" class="form-control">
        </div>
      </div>
     </fieldset>
      <div class="form-row">
        <div class="col-md-1"></div>
        <div class="form-group col-md-2 col-sm-12">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
      </div>
    </form> 
  </div>
  <div class="col-sm-12">
    {{result}}
  </div>
  </div>  
</fieldset>
  <!-- <pre>Model: {{ habitMappingModel.HabitMappingAutoComplete | json }}</pre> -->

  <fieldset class="section-legend-border col-md-12 col-lg-12" #Habitmappinglist >
    <legend class="section-legend-border">Habit mapping list</legend>
  <div class="row" id="habit-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Hours</th>
              <th scope="col">Min</th>
              <th scope="col">Times</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of habitMappingModel.HabitMappinglist">
              <th scope="row">{{item.HabitName}}</th>
              <td>{{item.HabitTypeName}}</td>
              <td>{{item.NumberOfHours}}</td>
              <td>{{item.NumberOfMinutes}}</td>
              <td>{{item.NumberOfTimes}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
    </div>
  </fieldset>