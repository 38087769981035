
<fieldset class="section-legend-border  col-md-12 col-lg-12" #RoomTemperature>
<legend class="section-legend-border">Room Temperature</legend>
<div id="container" style="height: 400px; min-width: 310px" class="round-div"></div>
</fieldset>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #RoomHumidity>
    <legend class="section-legend-border">Room Humidity</legend>
<div id="containerHumidity" style="height: 400px; min-width: 310px" class="round-div"></div>
</fieldset>

<fieldset class="section-legend-border  col-md-12 col-lg-12" #TemperatureFulldayData>
    <legend class="section-legend-border">Temperature Full Day Data</legend>
<div id="containerTemperatureOldData" style="height: 400px; min-width: 310px" class="round-div"></div>
</fieldset>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #HumidityFulldayData>
    <legend class="section-legend-border">Humidity Full Day Data</legend>
<div id="containerHumidityOldData" style="height: 400px; min-width: 310px" class="round-div"></div>
</fieldset>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #TankSensorFulldayData>
    <legend class="section-legend-border">Tank Sensor Full Day Data</legend>
<div id="containerTankSensorOldData" style="height: 400px; min-width: 310px" class="round-div"></div>
</fieldset>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #TankSensor>
    <legend class="section-legend-border">Tank Sensor</legend>
<div id="containerTankSensor" style="height: 400px; min-width: 310px" class="round-div"></div>
</fieldset>