import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { WaterGoalModel } from 'src/app/models/FitBit/water-goal-model';
import { WaterLogModel } from 'src/app/models/FitBit/water-log-model';
import { FitBitService } from 'src/app/services/fit-bit.service';
import { SensorService } from 'src/app/services/sensor.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-water-tank',
  templateUrl: './water-tank.component.html',
  styleUrls: ['./water-tank.component.css']
})
export class WaterTankComponent implements OnInit {
  waterLogModel: WaterLogModel;
  waterGoalModel: WaterGoalModel;
  waterLogPercentageProgress: number;
  waterLogMessage: string;
  public glassHeight: number;
  public glassWidth: number;
  @Input() DateFromOutside: string;
  sensorValue:number;
  constructor(private fitbitService: FitBitService, public datepipe: DatePipe,private sensorervice: SensorService,private titleService:Title) { 
    this.titleService.setTitle("Water Tank");

  }

  ngOnInit(): void {
    this.glassHeight = 250;
    this.glassWidth = (this.glassHeight * 57) / 100;
    setInterval(() => {         
      this.sensorervice.getSensorDataByType('terrace',"distance").subscribe(sensorData=>{
       // sensorData.SensorValue.SensorValue = 8.35;
        var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
         var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(); // current time
        // //                    // var x = (new Date()).getTime(), // current time
        this.waterLogPercentageProgress = 110-(Math.round(((sensorData.SensorValue.SensorValue - 4.35) / 40) * 100)); // To show in revese for tank emulation
        this.waterLogMessage =this.datepipe.transform(dtnew, 'yyyy-MM-dd h:mm a') +" : "+ Math.round((sensorData.SensorValue.SensorValue - 4.35))+" cm";
      });
      // just testing if it is working
    }, 10000);

    
    // var currentDate = new Date();
    // // currentDate.setDate(currentDate.getDate() - 1);
    // var previousDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    // if (this.DateFromOutside && this.DateFromOutside.length > 0) {
    //   previousDate = this.DateFromOutside;
    // }
    // this.fetchData(previousDate);
  }
  fetchData(previousDate) {
    // this.glassHeight=100;
    // this.glassWidth=57;
    this.glassHeight = 250;
    this.glassWidth = (this.glassHeight * 57) / 100;
    this.fitbitService.GetFitbitWaterLogData(previousDate).subscribe(response => {
      this.waterLogModel = response;
      this.fitbitService.GetFitbitWaterGoalData().subscribe(response => {
        this.waterGoalModel = response;
        this.waterLogPercentageProgress = Math.round((this.waterLogModel.summary.water / this.waterGoalModel.goal.goal) * 100);
        this.waterLogPercentageProgress = this.sensorValue;
        this.waterLogMessage = this.waterLogModel.summary.water > 1000 ? (this.waterLogModel.summary.water / 1000) + ' Liter' : this.waterLogModel.summary.water + ' ML'
      });
    });
  }

}
