export class HabitMappingModel {
   public HabitMappingId:number;
   public HabitTypeId:number;
   public HabitId:number;
   public NumberOfTimes:number;
   public NumberOfMinutes:number;
   public NumberOfHours:number;
   public HabitName:string;
   public HabitTypeName:string;

   public HabitMappingAutoComplete:any;
   public HabitMappinglist:HabitMappingModel[];

   public HabitTypeNameAutoComplete:any;
   public HabitAutoComplete:any;

}
