<p>Water Tank </p>
<div class="water-tank-container">

    <div class="row">
        <ng-template #waterLogTooltipdata>
            <div class="">
                <div> <span class="tool-tip-heading">Water log: </span> <span class="nutrition-log-status">
                        {{waterLogModel.summary.water}}/{{waterGoalModel.goal.goal}} ML</span> </div>
            </div>
        </ng-template>
        <div>
            <div id="percent-section" [ngbTooltip]="waterLogTooltipdata" triggers="click:blur" data-html="true"
                placement="top-left">
                {{waterLogPercentageProgress}} %
            </div>
            <div id="glass-container" [ngStyle]="{'width': glassWidth+'px'}">
                <!-- <div id="glass" [ngStyle]="{'height': glassHeight+'px'}"> -->
                <!-- <div id="glass" [ngStyle]="{'height.px': glassHeight}">  -->
                <!-- <div id="glass" [style.height.%]='glassHeight'>  -->
                <div id="glass" [style.height.px]='glassHeight'>
                    <div id="water" [style.height.%]='waterLogPercentageProgress' [style.width.px]='glassWidth-13'>
                    </div>
                </div>
            </div>
            <div [ngbTooltip]="waterLogTooltipdata" triggers="click:blur" data-html="true" placement="top-left">
                {{waterLogMessage}}
            </div>
        </div>

    </div>

</div>