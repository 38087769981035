<fieldset class="section-legend-border col-md-12 col-lg-12" #Brand >
  <legend class="section-legend-border">Brand</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.Name}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Brand:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="brandModel.BrandAutoComplete" [(ngModel)]="brandModel.BrandAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Brand Id</label>
                    <input type="text" [(ngModel)]="brandModel.BrandId" name="brandModel.BrandId" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-4">
                    <label for="disabledTextInput">Brand Name</label>
                    <input type="text" [(ngModel)]="brandModel.Name" name="brandModel.Name" placeholder="Brand Name" class="form-control">
                </div>
            </div>
            <div class="form-group col-md-2">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" name="brandModel.IsFood" [(ngModel)]="brandModel.IsFood">
                <label class="form-check-label" for="exampleCheck1">Is Food </label>
              </div> 
            <div class="form-row">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-4 col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>
<div class="row" id="topic-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <p class="section-heading-text">brandList List</p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">BrandId</th>
              <th scope="col">Name</th>
              <th scope="col">IsFood</th>
           
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of brandList">
              <th scope="row">{{item.BrandId}}</th>
              <td>{{item.Name}}</td>
              <td>{{item.IsFood}}</td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
 