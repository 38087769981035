import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { SensorDataPostModel } from 'src/app/models/Sensor/sensor-data-post-model';
import { SensorService } from 'src/app/services/sensor.service';
import * as moment from "moment";
import {Title} from "@angular/platform-browser";
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
window.moment = moment;
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
@Component({
  selector: 'app-room-sensor',
  templateUrl: './room-sensor.component.html',
  styleUrls: ['./room-sensor.component.css']
})
export class RoomSensorComponent implements OnInit {

  constructor(private sensorervice1: SensorService,private titleService:Title) {this.titleService.setTitle("Room Sensor"); }
locationNameForApi='computer%20room';
locationNameTankForApi='terrace';
 async ngOnInit() {
    const this1 = this;
    let model = new SensorDataPostModel();
    model.LocationName = "computer room";
    model.SensorType = "temperature";
    model.LogDate = new Date();
    var dataPromiseTemperature = [];
    var mePromise = await this1.sensorervice1.GetSensorDataByDay(model);
    for (var i = 0; i < mePromise.SensorAllDayValue.length; i++) {
      dataPromiseTemperature.push([
        (new Date((mePromise.SensorAllDayValue[i].LastUpdated))).getTime(),
        mePromise.SensorAllDayValue[i].SensorValue
      ]);
    }
    
    model = new SensorDataPostModel();
    model.LocationName = "computer room";
    model.SensorType = "humidity";
    model.LogDate = new Date();
    var dataPromiseHumidity = [];
    var mePromise = await this1.sensorervice1.GetSensorDataByDay(model);
    for (var i = 0; i < mePromise.SensorAllDayValue.length; i++) {
      dataPromiseHumidity.push([
        (new Date((mePromise.SensorAllDayValue[i].LastUpdated))).getTime(),
        mePromise.SensorAllDayValue[i].SensorValue
      ]);
    }
    console.log(dataPromiseHumidity);
    model = new SensorDataPostModel();
    model.LocationName = "terrace";
    model.SensorType = "distance";
    model.LogDate = new Date();
    var dataPromiseDistance = [];
    var mePromise = await this1.sensorervice1.GetSensorDataByDay(model);
    for (var i = 0; i < mePromise.SensorAllDayValue.length; i++) {
      dataPromiseDistance.push([
        (new Date((mePromise.SensorAllDayValue[i].LastUpdated))).getTime(),
        mePromise.SensorAllDayValue[i].SensorValue
      ]);
    }
    console.log(dataPromiseHumidity);

    Highcharts.setOptions({
      time: {
          timezone: "Asia/Kolkata",
      }
    });
    var options:any={
      chart: {
          events: {
              load: function () {
                  // set up the updating of the chart each second
                  var series = this.series[0];
                  setInterval(function () {
                    this1.sensorervice1.getSensorDataByType("computer room","temperature").subscribe(sensorData=>{
                      var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                       var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                      // //                    // var x = (new Date()).getTime(), // current time
                        y = sensorData.SensorValue.SensorValue;
                        series.addPoint([x, y], true, true);
                    });
                      // var x = (new Date()).getTime(), // current time
                      //     y = Math.round(Math.random() * 100);
                      // series.addPoint([x, y], true, true);
                  }, 1000);
              }
          }
      },
    
      time: {
          useUTC: false
      },
    
      rangeSelector: {
          buttons: [{
              count: 1,
              type: 'minute',
              text: '1M'
          }, {
              count: 5,
              type: 'minute',
              text: '5M'
          }, {
              type: 'all',
              text: 'All'
          }],
          inputEnabled: true,
          selected: 0
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   formatter: function() {
        //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
        //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
        //   }
        // }
      },
      title: {
          text: 'Room Temperature'
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'In Degree Celcius'
        },
      exporting: {
          enabled: false
      },
      },
      series: [{
          name: 'Temperature',
          data: (
            function () {
              return dataPromiseTemperature;
            }())
          // data: (function () {
          //     // generate an array of random data
          //     var data = [],
          //         time = (new Date()).getTime(),
          //         i;
    
          //     for (i = -50; i <= 0; i += 1) {
          //         data.push([
          //             time + i * 1000,
          //             Math.round(Math.random() * 100)
          //         ]);
          //     }
          //    // data.push([time],Math.round(Math.random() * 100))
          //     return data;
          // }())
      }]
    }
    
    var meChart = Highcharts.chart('container',options );
    // Use the Time object
    console.log(
      'Current time in New York',
      //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
      //meChart.time.dateFormat('%d( %H:%M)', Date.now())
      meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())
    
    );

    //Humidity
    var options:any={
      chart: {
          events: {
              load: function () {
                  // set up the updating of the chart each second
                  var series = this.series[0];
                  setInterval(function () {
                    this1.sensorervice1.getSensorDataByType('computer room',"humidity").subscribe(sensorData=>{
                      var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                       var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                      // //                    // var x = (new Date()).getTime(), // current time
                        y = sensorData.SensorValue.SensorValue;
                        series.addPoint([x, y], true, true);
                    });
                      // var x = (new Date()).getTime(), // current time
                      //     y = Math.round(Math.random() * 100);
                      // series.addPoint([x, y], true, true);
                  }, 1000);
              }
          }
      },
    
      time: {
          useUTC: false
      },
    
      rangeSelector: {
          buttons: [{
              count: 1,
              type: 'minute',
              text: '1M'
          }, {
              count: 5,
              type: 'minute',
              text: '5M'
          }, {
              type: 'all',
              text: 'All'
          }],
          inputEnabled: true,
          selected: 0
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   formatter: function() {
        //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
        //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
        //   }
        // }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'In Percentage (%)'
        },
      exporting: {
          enabled: false
      },
      },
      title: {
          text: 'Room Humidity'
      },
    
      exporting: {
          enabled: false
      },
    
      series: [{
          name: 'Humidity',
          data: (
            function () {
              return dataPromiseHumidity;
            }())
          // data: (function () {
          //     // generate an array of random data
          //     var data = [],
          //         time = (new Date()).getTime(),
          //         i;
    
          //     for (i = -50; i <= 0; i += 1) {
          //         data.push([
          //             time + i * 1000,
          //             Math.round(Math.random() * 100)
          //         ]);
          //     }
          //    // data.push([time],Math.round(Math.random() * 100))
          //     return data;
          // }())
      }]
    }
    
    var meChart = Highcharts.chart('containerHumidity',options );
    // Use the Time object
    console.log(
      'Current time in New York',
      //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
      //meChart.time.dateFormat('%d( %H:%M)', Date.now())
      meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())
    
    );

    // Tank Sensor

var options:any={
  chart: {
    type:'column',
      events: {
          load: function () {
              // set up the updating of the chart each second
              var series = this.series[0];
              setInterval(function () {
                this1.sensorervice1.getSensorDataByType('terrace',"distance").subscribe(sensorData=>{
                  var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                   var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                  // //                    // var x = (new Date()).getTime(), // current time
                    y = sensorData.SensorValue.SensorValue;
                    series.addPoint([x, y], true, true);
                });
                  // var x = (new Date()).getTime(), // current time
                  //     y = Math.round(Math.random() * 100);
                  // series.addPoint([x, y], true, true);
              }, 1000);
          }
      }
  },

  time: {
      useUTC: false
  },

  rangeSelector: {
      buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
      }, {
          count: 5,
          type: 'minute',
          text: '5M'
      }, {
          type: 'all',
          text: 'All'
      }],
      inputEnabled: true,
      selected: 0
  },
  xAxis: {
    type: 'datetime',
    // labels: {
    //   formatter: function() {
    //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
    //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
    //   }
    // }
  },
  yAxis: {
    // tickColor: 'white',
    title: {
      style: {
        // color: 'Black'
      },
      text: 'In Percentage (%)'
    },
  exporting: {
      enabled: false
  },
  },
  title: {
      text: 'Tank Distance'
  },

  exporting: {
      enabled: false
  },

  series: [{
      name: 'Distance',
      data: (
        function () {
          return dataPromiseDistance;
        }())
      // data: (function () {
      //     // generate an array of random data
      //     var data = [],
      //         time = (new Date()).getTime(),
      //         i;

      //     for (i = -50; i <= 0; i += 1) {
      //         data.push([
      //             time + i * 1000,
      //             Math.round(Math.random() * 100)
      //         ]);
      //     }
      //    // data.push([time],Math.round(Math.random() * 100))
      //     return data;
      // }())
  }]
}

var meChart = Highcharts.chart('containerTankSensor',options );
// Use the Time object
console.log(
  'Current time in New York',
  //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
  //meChart.time.dateFormat('%d( %H:%M)', Date.now())
  meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())

);

    //Temperature old Data
    var options:any={
      chart: {
          events: {
              load: function () {
                  // set up the updating of the chart each second
                  var series = this.series[0];
                  // setInterval(function () {
                  //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
                  //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                  //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                  //     // //                    // var x = (new Date()).getTime(), // current time
                  //       y = sensorData.SensorValue.SensorValue;
                  //       series.addPoint([x, y], true, true);
                  //   });
                  //     // var x = (new Date()).getTime(), // current time
                  //     //     y = Math.round(Math.random() * 100);
                  //     // series.addPoint([x, y], true, true);
                  // }, 1000);
              }
          }
      },
    
      time: {
          useUTC: false
      },
    
      rangeSelector: {
          buttons: [{
              count: 1,
              type: 'minute',
              text: '1M'
          }, {
              count: 5,
              type: 'minute',
              text: '5M'
          }, {
              type: 'all',
              text: 'All'
          }],
          inputEnabled: true,
          selected: 0
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   formatter: function() {
        //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
        //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
        //   }
        // }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'In Degree Celcius'
        },
        exporting: {
          enabled: false
      },
      },
      title: {
          text: 'Room Temperature Whole Day'
      },
    
      exporting: {
          enabled: false
      },
    
      series: [{
          name: 'Temperature',
          data: (
            function () {
              return dataPromiseTemperature;
            }())
          // data: (function () {
          //     // generate an array of random data
          //     var data = [],
          //         time = (new Date()).getTime(),
          //         i;
    
          //     for (i = -50; i <= 0; i += 1) {
          //         data.push([
          //             time + i * 1000,
          //             Math.round(Math.random() * 100)
          //         ]);
          //     }
          //    // data.push([time],Math.round(Math.random() * 100))
          //     return data;
          // }())
      }]
    }
    
    var meChart = Highcharts.chart('containerTemperatureOldData',options );
    // Use the Time object
    console.log(
      'Current time in New York',
      //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
      //meChart.time.dateFormat('%d( %H:%M)', Date.now())
      meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())
    
    );

    //Humidity old Data
    var options:any={
      chart: {
          events: {
              load: function () {
                  // set up the updating of the chart each second
                  var series = this.series[0];
                  // setInterval(function () {
                  //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
                  //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                  //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                  //     // //                    // var x = (new Date()).getTime(), // current time
                  //       y = sensorData.SensorValue.SensorValue;
                  //       series.addPoint([x, y], true, true);
                  //   });
                  //     // var x = (new Date()).getTime(), // current time
                  //     //     y = Math.round(Math.random() * 100);
                  //     // series.addPoint([x, y], true, true);
                  // }, 1000);
              }
          }
      },
    
      time: {
          useUTC: false
      },
    
      rangeSelector: {
          buttons: [{
              count: 1,
              type: 'minute',
              text: '1M'
          }, {
              count: 5,
              type: 'minute',
              text: '5M'
          }, {
              type: 'all',
              text: 'All'
          }],
          inputEnabled: true,
          selected: 0
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   formatter: function() {
        //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
        //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
        //   }
        // }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'In Percentage (%)'
        },
      exporting: {
          enabled: false
      },
      },
      title: {
          text: 'Room Humidity Whole Day'
      },
    
      exporting: {
          enabled: false
      },
    
      series: [{
          name: 'Humidity',
          data: (
            function () {
              return dataPromiseHumidity;
            }())
          // data: (function () {
          //     // generate an array of random data
          //     var data = [],
          //         time = (new Date()).getTime(),
          //         i;
    
          //     for (i = -50; i <= 0; i += 1) {
          //         data.push([
          //             time + i * 1000,
          //             Math.round(Math.random() * 100)
          //         ]);
          //     }
          //    // data.push([time],Math.round(Math.random() * 100))
          //     return data;
          // }())
      }]
    }
    
    var meChart = Highcharts.chart('containerHumidityOldData',options );
    // Use the Time object
    console.log(
      'Current time in New York',
      //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
      //meChart.time.dateFormat('%d( %H:%M)', Date.now())
      meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())
    
    );

    //Tank Sensor old Data
    var options:any={
      chart: {
          events: {
              load: function () {
                  // set up the updating of the chart each second
                  var series = this.series[0];
                  // setInterval(function () {
                  //   this1.sensorervice1.getSensorDataByType("humidity").subscribe(sensorData=>{
                  //     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                  //      var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                  //     // //                    // var x = (new Date()).getTime(), // current time
                  //       y = sensorData.SensorValue.SensorValue;
                  //       series.addPoint([x, y], true, true);
                  //   });
                  //     // var x = (new Date()).getTime(), // current time
                  //     //     y = Math.round(Math.random() * 100);
                  //     // series.addPoint([x, y], true, true);
                  // }, 1000);
              }
          }
      },
    
      time: {
          useUTC: false
      },
    
      rangeSelector: {
          buttons: [{
              count: 1,
              type: 'minute',
              text: '1M'
          }, {
              count: 5,
              type: 'minute',
              text: '5M'
          }, {
              type: 'all',
              text: 'All'
          }],
          inputEnabled: true,
          selected: 0
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   formatter: function() {
        //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
        //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
        //   }
        // }
      },
      yAxis: {
        // tickColor: 'white',
        title: {
          style: {
            // color: 'Black'
          },
          text: 'In Percentage (%)'
        },
      exporting: {
          enabled: false
      },
      },
      title: {
          text: 'Tank Distance Whole Day'
      },
    
      exporting: {
          enabled: false
      },
    
      series: [{
          name: 'Distance',
          data: (
            function () {
              return dataPromiseDistance;
            }())
          // data: (function () {
          //     // generate an array of random data
          //     var data = [],
          //         time = (new Date()).getTime(),
          //         i;
    
          //     for (i = -50; i <= 0; i += 1) {
          //         data.push([
          //             time + i * 1000,
          //             Math.round(Math.random() * 100)
          //         ]);
          //     }
          //    // data.push([time],Math.round(Math.random() * 100))
          //     return data;
          // }())
      }]
    }
    
    var meChart = Highcharts.chart('containerTankSensorOldData',options );
    // Use the Time object
    console.log(
      'Current time in New York',
      //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
      //meChart.time.dateFormat('%d( %H:%M)', Date.now())
      meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())
    
    );
  }

}
