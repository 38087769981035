import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private httpClient: HttpClient;
  private configs: Configs;
  constructor(handler: HttpBackend) { 
    this.httpClient = new HttpClient(handler); 

  }
  get ApiUrl() {
    return this.configs.ApiEndpoint; 
  }
  get ApiBasePath() {
    return this.configs.ApiBasePath; 
  }
  get FileBasePath() {
    return this.configs.FileBasePath; 
  }
  get FileBasePathFold3() {
    return this.configs.FileBasePathFold3; 
  }
  get FileBasePathAsus6Z() {
    return this.configs.FileBasePathAsus6Z; 
  }
  get FileBasePathCommandCenter() {
    return this.configs.FileBasePathCommandCenter; 
  }
  get FileBasePathLenovoLaptop() {
    return this.configs.FileBasePathLenovoLaptop; 
  } 
  get ItemTrackerBasePath() {
    return this.configs.ItemTrackerBasePath;
  }
  get ConfigsLoadedFrom() {
    return this.configs.ConfigsLoadedFrom;
  } 
  public async loadConfigs() : Promise<any> {
    return this.httpClient.get('assets/configs.json').pipe(settings => settings)
      .toPromise()
      .then(settings => {
        this.configs = settings as Configs; 
      });
  }


}
export interface Configs {
  ApiEndpoint: string ;
  ConfigsLoadedFrom:string;
  ApiBasePath:string;
  FileBasePath:string;
  FileBasePathFold3:string;
  FileBasePathAsus6Z:string;
  FileBasePathCommandCenter:string;
  FileBasePathLenovoLaptop:string;
  ItemTrackerBasePath:string;
}

