export class ExerciseModel {
    activities: Activity[];
    goals: Goals;
    summary: Summary;
}
export interface Activity {
        activityId: number;
        activityParentId: number;
        activityParentName: string;
        calories: number;
        description: string;
        duration: number;
        hasActiveZoneMinutes: boolean;
        hasStartTime: boolean;
        isFavorite: boolean;
        lastModified: Date;
        logId: any;
        name: string;
        startDate: string;
        startTime: string;
        steps: number;
    }

    export interface Goals {
        activeMinutes: number;
        caloriesOut: number;
        distance: number;
        floors: number;
        steps: number;
    }

    export interface Distance {
        activity: string;
        distance: number;
    }

    export interface HeartRateZone {
        caloriesOut: number;
        max: number;
        min: number;
        minutes: number;
        name: string;
    }

    export interface Summary {
        activeScore: number;
        activityCalories: number;
        caloriesBMR: number;
        caloriesOut: number;
        distances: Distance[];
        elevation: number;
        fairlyActiveMinutes: number;
        floors: number;
        heartRateZones: HeartRateZone[];
        lightlyActiveMinutes: number;
        marginalCalories: number;
        restingHeartRate: number;
        sedentaryMinutes: number;
        steps: number;
        veryActiveMinutes: number;
    }
    export class ExerciseViewModel{
        ExerciseName:string;
        Duration:number;
        Calories:number;
        ExerciseDataDisplay:string;
        FafaClassIcon:string;
    } 



