import { Component, OnInit } from '@angular/core';
import { RegisterUser } from '../models/Register/register-user';
import { UserService } from '../services/user.service';
//https://www.freecodecamp.org/news/how-to-validate-angular-template-driven-forms/
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
public model:RegisterUser;
public result='';
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.model = new RegisterUser();
  }
  onSubmit() {
    var me = this.model;
    this.userService.saveUser(this.model).subscribe(
        response => {
          this.result = response;
          console.log(response);
        },
        (err)=> {this.result = err.message}
        );
  }
}
