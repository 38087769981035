














// export interface RootObject {
//     sleep: Sleep[];
//     summary: Summary2;
// }

export class SleepModel {
    sleep: Sleep[];
    summary: SummaryEnd;
}
export class Sleep {
    dateOfSleep: string;
    duration: number;
    efficiency: number;
    endTime: Date;
    infoCode: number;
    isMainSleep: boolean;
    levels: Levels;
    logId: number;
    minutesAfterWakeup: number;
    minutesAsleep: number;
    minutesAwake: number;
    minutesToFallAsleep: number;
    startTime: Date;
    timeInBed: number;
    type: string;
}
export class SummaryEnd {
    stages: Stages;
    totalMinutesAsleep: number;
    totalSleepRecords: number;
    totalTimeInBed: number;
}
export class Stages {
    deep: number;
    light: number;
    rem: number;
    wake: number;
}
export interface Levels {
    data: LevelData[];
    shortData: ShortData[];
    summary: SummarySleepCategory;
}
export class LevelData {
    dateTime: Date;
    level: string;
    seconds: number;
}
export interface ShortData {
    dateTime: Date;
    level: string;
    seconds: number;
}
export interface SummarySleepCategory {
    deep: SleepCategoryDetails;
    light: SleepCategoryDetails;
    rem: SleepCategoryDetails;
    wake: SleepCategoryDetails;
}
export class SleepCategoryDetails {
    count: number;
    minutes: number;
    thirtyDayAvgMinutes: number;
}
export class SleepViewModel{
    SleepCategory:string;
    SleepInMinutes:number;
    SleepInPercentage:number;
    SleepDataDisplay:string;
    SleepDataClass:string;
} 