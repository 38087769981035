import { Component, OnInit } from '@angular/core';
import { post } from 'jquery';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SensorDataLocationModel } from 'src/app/models/Sensor/sensor-data-location-model';
import { SensorDataSensorFeatureTypeModel } from 'src/app/models/Sensor/sensor-data-sensor-feature-type-model';
import { SensorDataSensorMappingModel } from 'src/app/models/Sensor/sensor-data-sensor-mapping-model';
import { SensorDataSensorModel } from 'src/app/models/Sensor/sensor-data-sensor-model';
import { SensorDataSubLocationModel } from 'src/app/models/Sensor/sensor-data-sub-location-model';
import { SensorService } from 'src/app/services/sensor.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-sensor-mapping',
  templateUrl: './sensor-mapping.component.html',
  styleUrls: ['./sensor-mapping.component.css']
})
export class SensorMappingComponent implements OnInit {

 
  public result = '';
sensorDataSensorMappingModel: SensorDataSensorMappingModel;
model: any;
searching = false;
searchFailed = false;
constructor(private sensorService: SensorService,private titleService:Title) {  this.titleService.setTitle("Manage Sensor Mapping");}

ngOnInit() {
  this.sensorDataSensorMappingModel = new SensorDataSensorMappingModel();
  //this.sensorDataSensorMappingModel.SensorDataSensorlist = [];
  
  this.sensorDataSensorMappingModel.Sensor = new SensorDataSensorModel();
  this.sensorDataSensorMappingModel.Sensor.SensorDataSensorlist = [];

  this.sensorDataSensorMappingModel.Sensor = new SensorDataSensorModel();
  this.sensorDataSensorMappingModel.Sensor.SensorDataSensorlist = [];

  this.sensorDataSensorMappingModel.Location = new SensorDataLocationModel();
  this.sensorDataSensorMappingModel.Location.SensorDataLocationlist = [];

  this.sensorDataSensorMappingModel.SubLocation = new SensorDataSubLocationModel();
  this.sensorDataSensorMappingModel.SubLocation.SensorDataSubLocationlist = [];

  this.sensorDataSensorMappingModel.FeatureType = new SensorDataSensorFeatureTypeModel();
  this.sensorDataSensorMappingModel.FeatureType.SensorDataSensorFeatureTypelist = [];
}
search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.sensorService.GetSensorsByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatter = (x: { Name: string }) => x.Name;

selectedItem(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataSensorMappingModel.Sensor = item.item;
  //this.sensorDataSensorMappingModel.SensorName = item.item.SensorName; 
  //this.sensorDataSensorMappingModel.SensorUniqueName = item.item.SensorUniqueName;
 
}

//Sensor Auto Complete
searchSensor = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.sensorService.GetSensorMappingByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatterSensor = (x: { Name: string }) => x.Name;

selectedItemSensor(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataSensorMappingModel = item.item as SensorDataSensorMappingModel;
 // this.sensorDataSensorMappingModel.Sensor.SensorID = item.item.SensorID;
 // this.sensorDataSensorMappingModel.Sensor.SensorName = item.item.SensorName; 
  //this.sensorDataSensorMappingModel.Sensor.SensorUniqueName = item.item.SensorUniqueName;
  //this.sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeID = 
 }

 
//Feature Type Auto Complete
searchFeatureType = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]) :
    (this.sensorService.GetSensorFeaturetypeByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)
formatterFeatureType = (x: { Name: string }) => x.Name;

selectedItemFeatureType(item) {
//this.clickedItem=item.item;
console.log(item);
this.sensorDataSensorMappingModel.FeatureType = item.item;
//this.sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeID = item.item.SensorFeatureTypeID;
//this.sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeName = item.item.SensorFeatureTypeName; 
//this.sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeUniqueName = item.item.SensorFeatureTypeUniqueName;
}

//Location  Auto Complete
searchLocation = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]) :
    (this.sensorService.GetSensorLocationByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)
formatterLocation = (x: { Name: string }) => x.Name;

selectedItemLocation(item) {
//this.clickedItem=item.item;
console.log(item);
this.sensorDataSensorMappingModel.Location.LocationID = item.item.LocationID;
this.sensorDataSensorMappingModel.Location.LocationName = item.item.LocationName; 
this.sensorDataSensorMappingModel.Location.LocationUniqueName = item.item.LocationUniqueName;
}

//Sub Location  Auto Complete
searchSubLocation = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]) :
    (this.sensorService.GetSensorSubLocationByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)
formatterSubLocation = (x: { Name: string }) => x.Name;

selectedItemSubLocation(item) {
//this.clickedItem=item.item;
console.log(item);
this.sensorDataSensorMappingModel.SubLocation.SubLocationID = item.item.SubLocationID;
this.sensorDataSensorMappingModel.SubLocation.SubLocationName = item.item.SubLocationName; 
this.sensorDataSensorMappingModel.SubLocation.SubLocationUniqueName = item.item.SubLocationUniqueName;
}

onSubmit() {
  var me = this.sensorDataSensorMappingModel;
  var postModel = new SensorDataSensorMappingModel();
  postModel.SensorMappingID = this.sensorDataSensorMappingModel.SensorMappingID;
  postModel.SensorFeatureTypeID =this.sensorDataSensorMappingModel.FeatureType.SensorFeatureTypeID;
  postModel.SubLocationID =this.sensorDataSensorMappingModel.SubLocation.SubLocationID;
  postModel.SensorID =this.sensorDataSensorMappingModel.Sensor.SensorID;
  this.sensorService.InsertOrUpdateSensorMapping(postModel).subscribe(response => {
    this.result = response;
  },
    err => {
      this.result = err.message;
    }
  );
  
}
}
