import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { HabitTrackerMasterComponent } from '../habit-tracker-master/habit-tracker-master.component';
//import { HabitTrackerService } from 'src/app/service/habit-tracker.service';
import { HabitModel } from 'src/app/models/HabitTracker/Habit/habit-model';
import { HabitTrackerService } from 'src/app/services/habit-tracker.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-habit',
  templateUrl: './habit.component.html',
  styleUrls: ['./habit.component.css']
})
export class HabitComponent implements OnInit {
  public result='';
  public habitModel:HabitModel;

model: any;
searching = false;
searchFailed = false;

  constructor(private habitTrackerService: HabitTrackerService,private titleService:Title) { this.titleService.setTitle("Habit");}

  ngOnInit() {

    
this.habitModel = new HabitModel();
this.habitModel.Habitlist = [];
this.habitModel.HabitCategorylist = [];

    this.habitTrackerService.GetHabitCategoryAllOrById(0).subscribe(response=>
      {
        console.log(response);
      }
    );
  }

  
  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]):
      (this.habitTrackerService.GetHabitByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )



 formatter = (x: { HabitName: string }) => x.HabitName;

 selectedItem(item){
  //this.clickedItem=item.item;
  console.log(item);
  this.habitModel.HabitCategoryId = item.item.HabitCategoryId;
  this.habitModel.HabitCategoryName = item.item.HabitCategoryName;
  this.habitModel.HabitId = item.item.HabitId;
  this.habitModel.HabitName = item.item.HabitName;
}

//habit category drowndown 
searchHabitcategory = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(300),
  distinctUntilChanged(),
  tap(() => this.searching = true),
  switchMap(term => term.length < 2 ? of([]):
    (this.habitTrackerService.GetHabitCategoryByName(term).pipe(
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })))
  ),
  tap(() => this.searching = false)
)



formatterCategory = (x: { HabitName: string }) => x.HabitName;

selectedItemHabitCategory(item){
//this.clickedItem=item.item;
console.log(item);
this.habitModel.HabitCategoryId = item.item.HabitCategoryId;
this.habitModel.HabitCategoryName = item.item.HabitCategoryName;
// this.habitModel.HabitId = item.item.HabitId;
// this.habitModel.HabitName = item.item.HabitName;
}


onSubmit() {
  var me = this.habitModel;
  var habitModel = new HabitModel();
  habitModel.HabitCategoryId = me.HabitCategoryId;
  habitModel.HabitCategoryName = me.HabitCategoryName;
  habitModel.HabitId = me.HabitId;
  habitModel.HabitName = me.HabitName;
  this.habitTrackerService.Insertorupdatehabit(habitModel).subscribe(
    response => {
      console.log(response);
      this.result = response;
      this.habitModel = new HabitModel();
    }
  );
}

}
