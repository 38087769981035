import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SensorDataSensorModel } from 'src/app/models/Sensor/sensor-data-sensor-model';
import { SensorService } from 'src/app/services/sensor.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.css']
})
export class SensorComponent implements OnInit { 
public result = '';
sensorDataSensorModel: SensorDataSensorModel;
model: any;
searching = false;
searchFailed = false;
constructor(private foodLoggerservice: SensorService,private titleService:Title) { this.titleService.setTitle("Manage Sensor"); }

ngOnInit() {
  this.sensorDataSensorModel = new SensorDataSensorModel();
  this.sensorDataSensorModel.SensorDataSensorlist = [];
}
search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.foodLoggerservice.GetSensorsByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatter = (x: { Name: string }) => x.Name;

selectedItem(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataSensorModel.SensorID = item.item.SensorID;
  this.sensorDataSensorModel.SensorName = item.item.SensorName; 
  this.sensorDataSensorModel.SensorUniqueName = item.item.SensorUniqueName;
  // var accountApiModel = new SensorDataSensorModel();
  // this.accountModel.AccountID = item.item.AccountID;
  // this.accountModel.AccountCode = item.item.AccountCode;
  // this.accountModel.AccountName = item.item.AccountName;

  // this.accountModel.AccountMasterID = item.item.AccountMasterID;
  // this.accountModel.AccountMasterName = item.item.AccountMasterName;
  // this.accountModel.CashBankOther = item.item.CashBankOther;
  // this.accountModel.AccountStatus = item.item.AccountStatus;
  // this.accountModel.Receipt = item.item.Receipt;
  // this.accountModel.Active = item.item.Active;
  // this.accountModel.AccountAutoID = item.item.AccountAutoID;
}
onSubmit() {
  var me = this.sensorDataSensorModel;
  this.foodLoggerservice.InsertOrUpdateSensor(this.sensorDataSensorModel).subscribe(response => {
    this.result = response;
  },
    err => {
      this.result = err.message;
    }
  );
  // var vendorApimodel = new VendorApiModel();
  // vendorApimodel.vendorAutoID = me.VendorAutoID;
  // vendorApimodel.vendorID = me.VendorID;
  // vendorApimodel.vendorName = me.VendorName;
  // this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
  //   response => {
  //     console.log(response);
  //     this.vendorModel = new VendorModel();
  //   }
  // );
}

}
