import { FoodLogProgress } from '../FoodLog/food-log-progress';

export class MealTypeModel {
    public  MealTypeId:number;
    public  Name:string; 
    public  Created:Date; 

    public MealTypeAutoComplete:any;
    public MealTypelist:MealTypeModel[];
    public FoodLogProgress:FoodLogProgress[];
}


