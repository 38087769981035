import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { RouterLinkInfoService } from 'src/app/services/router-link-info.service';
import { AMasterSmartBagComponent } from './amaster-smart-bag/amaster-smart-bag.component';
import { MasterComboComponent } from './master-combo/master-combo.component';



@NgModule({
  declarations: [MasterComboComponent,AMasterSmartBagComponent],
  imports: [
    CommonModule,AgGridModule,NgbModule,RouterModule,FormsModule
  ]
})
export class SmartBagModule {}
