import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { AInterviewMasterComponent } from './ainterview-master/ainterview-master.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SubjectComponent } from './subject/subject.component';
import { TopicComponent } from './topic/topic.component';
import { QuestionBankComponent } from './question-bank/question-bank.component';



@NgModule({
  declarations: [QuestionAnswerComponent, AInterviewMasterComponent, SubjectComponent, TopicComponent, QuestionBankComponent],
  imports: [
    CommonModule,AgGridModule,NgbModule,RouterModule,FormsModule,AngularEditorModule 
  ]
})
export class InterviewModule { }
