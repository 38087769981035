import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FitBitService } from 'src/app/services/fit-bit.service';

@Component({
  selector: 'app-fit-bit-update-token',
  templateUrl: './fit-bit-update-token.component.html',
  styleUrls: ['./fit-bit-update-token.component.css']
})
export class FitBitUpdateTokenComponent implements OnInit {
token;
  constructor(private fitBitService: FitBitService, private route: ActivatedRoute) { 
    this.token="";
    var currentUrl = window.location.href;
    if(currentUrl.toLowerCase().indexOf('#')>=0){
      this.token= (((currentUrl.split("&",3)[0]).split("#",3))[1]).replace("access_token=","");
      localStorage.setItem('fitbitToken', this.token);
    }
  }

  ngOnInit(): void {
  }
  updateToken(){
    window.location.href = this.fitBitService.GetFitBitBearerTokenUrl; 
    //this.fitBitService.GetFitBitBearerToken().subscribe(response=>{
//console.log(response);

    //});
  }

}
/*
https://dev.fitbit.com/apps/details/22D3QS
For generating url need to update app setting to test it for local
https://dev.fitbit.com/apps/oauthinteractivetutorial?clientEncodedId=22D3QS&clientSecret=e36fc9a0ff04a67ba2d61c3c986bee95&redirectUri=https://admin.myzingonline.in/fitbit/fitbittoken&applicationType=PERSONAL
*/