<fieldset class="section-legend-border col-md-12 col-lg-12" #SensorFeatureType >
    <legend class="section-legend-border">Sensor Feature Type</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.SensorFeatureTypeName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sensor FeatureType:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSensorFeatureTypeModel.SensorDataSensorFeatureTypeAutoComplete" [(ngModel)]="sensorDataSensorFeatureTypeModel.SensorDataSensorFeatureTypeAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor FeatureType Id</label>
                    <input type="text" [(ngModel)]="sensorDataSensorFeatureTypeModel.SensorFeatureTypeID" name="sensorDataSensorFeatureTypeModel.SensorFeatureTypeID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor FeatureType Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorFeatureTypeModel.SensorFeatureTypeName" name="sensorDataSensorFeatureTypeModel.SensorFeatureTypeName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor FeatureType Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorFeatureTypeModel.SensorFeatureTypeUniqueName" name="sensorDataSensorFeatureTypeModel.SensorFeatureTypeUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rtSensor let-r="result" let-t="term">
                        {{ r.SensorName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sensor:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataSensorFeatureTypeModel.Sensor.SensorDataSensorAutoComplete" [(ngModel)]="sensorDataSensorFeatureTypeModel.Sensor.SensorDataSensorAutoComplete" [ngbTypeahead]="searchSensor" [inputFormatter]="formatterSensor" [resultTemplate]="rtSensor" (selectItem)="selectedItemSensor($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Id</label>
                    <input type="text" [(ngModel)]="sensorDataSensorFeatureTypeModel.Sensor.SensorID" name="sensorDataSensorFeatureTypeModel.Sensor.SensorID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorFeatureTypeModel.Sensor.SensorName" name="sensorDataSensorFeatureTypeModel.Sensor.SensorName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataSensorFeatureTypeModel.Sensor.SensorUniqueName" name="sensorDataSensorFeatureTypeModel.Sensor.SensorUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-4 col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>