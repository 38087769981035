import { Component, OnInit } from '@angular/core';
import { RouterLinkInfoService } from 'src/app/services/router-link-info.service';

@Component({
  selector: 'app-a-sensor-data-master',
  templateUrl: './a-sensor-data-master.component.html',
  styleUrls: ['./a-sensor-data-master.component.css']
})
export class ASensorDataMasterComponent implements OnInit {

  constructor(private routerinfoservice: RouterLinkInfoService) { }

  ngOnInit(): void {
  }
  onActivate(componentRef) {
    try {
      var currentUrl = window.location.pathname;
      // var me =  componentRef.getPageName();
      // this.CData =me;
      $('#breadItem').text(this.routerinfoservice.getPageName(currentUrl));
    }
    catch (ex) {
      console.log('Not set');
    }
  }
}
