<div class="activity-section">
    <div class="activity-section-item" *ngIf="weightViewModel">
        <div>
            <i class="fas fa-weight"></i>
        </div>
        <div>
            {{weightViewModel.weight}}
        </div>
    </div>
    <div class="activity-section-item" *ngIf="weightViewModel" >
    <ng-template #bmitooltipdata>
        <div class="" >
          <div> <span class="tool-tip-heading">Body Mass Index: </span> <span class="nutrition-log-status">
            {{weightViewModel.bmi}} </span> </div>
        </div>
      </ng-template>
        <div>
            <i class="fas fa-child"></i> 
        </div>
        <div  [ngbTooltip]="bmitooltipdata" 
        triggers="click:blur" data-html="true" placement="top-left">
            {{weightViewModel.bmi|number:'1.2-2'}}
        </div>
    </div>
 
    <div class="activity-section-item" *ngIf="weightViewModel" >
        <ng-template #fattooltipdata>
            <div class="" >
              <div> <span class="tool-tip-heading">Fat percentage: </span> <span class="nutrition-log-status">
                {{weightViewModel.fat|number:'1.2-2'}}% </span> </div>
            </div>
          </ng-template>
            <div>
                <i class="fab fa-foursquare" ></i> 
            </div>
            <div [ngbTooltip]="fattooltipdata" 
            triggers="click:blur" data-html="true" placement="top-left">
                {{weightViewModel.fat|number:'1.2-2'}}%
            </div>
    </div>

</div>
<div>
   
 
  
</div>