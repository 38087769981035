import { BrandModel } from '../Brand/brand-model';
import { FoodImageModel } from '../FoodImage/food-image-model';
import { MealTypeModel } from '../MealType/meal-type-model';
import { NutritionFactModel } from '../NutritionFact/nutrition-fact-model';
import { TagModel } from '../Tag/tag-model';
import { MeasurementModel } from '../Measurement/measurement-model';

export class FoodModel {
    public FoodId: number;
    public Name: string;
    public BrandModel: BrandModel;
    public NutritionFactModel: NutritionFactModel;
    public MealTypeModel: MealTypeModel[];
    public Tag: TagModel[];
    public FoodImage: FoodImageModel;
    public CurrentQuantitySize: number;

    public MeasurementModel:MeasurementModel[];
    public Foodlist:FoodModel[];
    public FoodAutoComplete:any;
    public FoodImageModels:FoodImageModel[];

    public FoodAutoCompleteClone:any;
}
