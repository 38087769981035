import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { interval, Subscription } from 'rxjs';
import { SensorService } from '../services/sensor.service';
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
@Component({
  selector: 'app-high-chart-sample',
  templateUrl: './high-chart-sample.component.html',
  styleUrls: ['./high-chart-sample.component.css']
})
//https://www.highcharts.com/blog/post/highcharts-and-angular-7/
//https://api.highcharts.com/highcharts/time
export class HighChartSampleComponent implements OnInit {
  
  subscription: Subscription;
  constructor(private sensorervice1: SensorService) {
    
   }
// public options:any={
//   chart: {
//       events: {
//           load: function () {

//               // set up the updating of the chart each second
//               var series = this.series[0];
//               setInterval(function () {
//                 this.sensorervice1.getSensorData().subscribe(sensorData=>{
//                   var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
//                    var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
//                   // //                    // var x = (new Date()).getTime(), // current time
//                     y = sensorData.SensorValue.SensorValue;
//                     series.addPoint([x, y], true, true);
//                 });
//                   // var x = (new Date()).getTime(), // current time
//                   //     y = Math.round(Math.random() * 100);
//                   // series.addPoint([x, y], true, true);
//               }, 3000);
//           }
//       }
//   },

//   time: {
//       useUTC: false
//   },

//   rangeSelector: {
//       buttons: [{
//           count: 1,
//           type: 'minute',
//           text: '1M'
//       }, {
//           count: 5,
//           type: 'minute',
//           text: '5M'
//       }, {
//           type: 'all',
//           text: 'All'
//       }],
//       inputEnabled: true,
//       selected: 0
//   },
//   xAxis: {
//     type: 'datetime',
//     labels: {
//       formatter: function() {
//         //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
//         return Highcharts.dateFormat('%d(%H:%M)', this.value);
//       }
//     }
//   },
//   title: {
//       text: 'Live random data'
//   },

//   exporting: {
//       enabled: false
//   },

//   series: [{
//       name: 'Random data',
//       data: (function () {
//           // generate an array of random data
//           var data = [],
//               time = (new Date()).getTime(),
//               i;

//           // for (i = -999; i <= 0; i += 1) {
//           //     data.push([
//           //         time + i * 1000,
//           //         Math.round(Math.random() * 100)
//           //     ]);
//           // }
//           data.push([time],Math.round(Math.random() * 100))
//           return data;
//       }())
//   }]
// }
  ngOnInit() {
    const this1 = this;
    // // // var me = this.getData();
    // // // this.options.series[0]['data'] = me;
    // // // var myChart = Highcharts.chart('container', this.options);
   
    // // this.subscription = source.subscribe(x=>{
    // //   var series = this.options.series[0];
    // //   var you = this.sensorervice.getSensorData().subscribe(sensorData=>{
    // //     var me = this.getData();
    // //     console.log(sensorData);
    // //                     //this.meUser=user;
    // //                     var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
    // //                     var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
    // //                    // var x = (new Date()).getTime(), // current time
    // //                        y = sensorData.SensorValue.SensorValue;
    // //     //this.options.series[0]['data'] = me;
    // //     series.data.push([x,y]);
    // //     var myChart = Highcharts.chart('container', this.options);

    // //   });
    //   const source = interval(10000);
    //   this.subscription = source.subscribe(x=>{
    //     var me = this.getData();
    //     this.options.series[0]['data'] = me;
    //      Highcharts.chart('container', this.options);
    //   });
    // //   var me = this.getData();
    // //  this.options.series[0]['data'] = me;
    // //   var myChart = Highcharts.chart('container', this.options);
    
    // //});

//new things
Highcharts.setOptions({
  time: {
      timezone: "Asia/Kolkata",
  }
});
var options:any={
  chart: {
      events: {
          load: function () {
              // set up the updating of the chart each second
              var series = this.series[0];
              setInterval(function () {
                this1.sensorervice1.getSensorData().subscribe(sensorData=>{
                  var dtnew = (new Date((sensorData.SensorValue.LastUpdated)));
                   var x = (new Date((sensorData.SensorValue.LastUpdated))).getTime(), // current time
                  // //                    // var x = (new Date()).getTime(), // current time
                    y = sensorData.SensorValue.SensorValue;
                    series.addPoint([x, y], true, true);
                });
                  // var x = (new Date()).getTime(), // current time
                  //     y = Math.round(Math.random() * 100);
                  // series.addPoint([x, y], true, true);
              }, 1000);
          }
      }
  },

  time: {
      useUTC: false
  },

  rangeSelector: {
      buttons: [{
          count: 1,
          type: 'minute',
          text: '1M'
      }, {
          count: 5,
          type: 'minute',
          text: '5M'
      }, {
          type: 'all',
          text: 'All'
      }],
      inputEnabled: true,
      selected: 0
  },
  xAxis: {
    type: 'datetime',
    // labels: {
    //   formatter: function() {
    //     //return Highcharts.dateFormat('%e %b %y %H:%M:%S', this.value);
    //     return Highcharts.time.dateFormat('%d( %H:%M)', this.value);
    //   }
    // }
  },
  title: {
      text: 'Room Temperature'
  },

  exporting: {
      enabled: false
  },

  series: [{
      name: 'Temperature',
      data: (function () {
          // generate an array of random data
          var data = [],
              time = (new Date()).getTime(),
              i;

          for (i = -50; i <= 0; i += 1) {
              data.push([
                  time + i * 1000,
                  Math.round(Math.random() * 100)
              ]);
          }
         // data.push([time],Math.round(Math.random() * 100))
          return data;
      }())
  }]
}

var meChart = Highcharts.chart('container',options );
// Use the Time object
console.log(
  'Current time in New York',
  //meChart.time.dateFormat('%Y-%m-%d %H:%M:%S', Date.now())
  //meChart.time.dateFormat('%d( %H:%M)', Date.now())
  meChart.time.dateFormat('%d( %H:%M)', (new Date(('2020-07-17T20:58:16.903'))).getTime())

);
  }
  getData() {
    var me = [];
    for (var i = 1; i <= 20; i++) {
      me.push([
        //new Date('2018-01-'+i+' 18:38:31').getTime(),
       //new Date('2018-01-'+i+' '+i+':38:31').getTime(),
       //new Date(2018, 11, i, i, 33),
       new Date('2018-01-25'+' '+i+':38:31').getTime(),
      // Date.now(),
        Math.random()+(20+ Math.random())
      ]);

    }
    return me;

  }
}
