
<div class="row">
    <div class="col-sm-12">
        <!-- <form (ngSubmit)="onSubmit()" #loginForm="ngForm"> -->
            <div class="form-row">
                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                    <input type="text" [(ngModel)]="searchText" name="searchText" placeholder="search across subjects" class="form-control">
                </div>
                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                   <button type="button" (click)="search()" class="btn btn-primary btn-block">Search</button>
                </div>
            </div>
        <!-- </form> -->
    </div>
</div>  
<div class="row" *ngIf="questionAnswerList">
    <div class="col-sm-12">
        <!-- <form (ngSubmit)="onSubmit()" #loginForm="ngForm"> -->
            <div class="form-row">
                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                    <input type="text" [(ngModel)]="searchWithInText" name="searchWithInText" placeholder="search with in record" class="form-control">
                </div>
                <div class="form-group col-sm-3 col-md-3 col-lg-3">
                   <button type="button" (click)="searchWithInRecord()" class="btn btn-primary btn-block">Search With In Record</button>
                </div>
            </div>
        <!-- </form> -->
    </div>
</div>  
<div class="row" id="subject-list">
    <span  (click)="filterTopic(0)"> 
       0 | ALL
    </span>
    <span *ngFor="let item of subjectList" (click)="filterTopic(item.SubjectID)">
      {{item.SubjectID}} | {{item.SubjectName}}
    </span>
  </div>
  <div class="row" id="topic-list">
    <span  (click)="filterQuestionByTopic(0)"> 
       0 | ALL
    </span>
    <span *ngFor="let item of topicList" (click)="filterQuestionByTopic(item.TopicID)">
      {{item.TopicID}} | {{item.TopicName}}
    </span>
  </div>

<div *ngFor="let item of questionAnswerList;let i = index" class="question-main-section">
    <div class="subject-section">
        <span class="subjection-section-item">{{item.SubjectName}} | {{item.TopicName}}</span></div>
    <div class="question-section"> 
        <span class="question-section-item">{{i+1}}. {{item.Question}}</span> 
    
    </div>
<div [innerHTML]="item.Answer" class="answer-section">
</div>

</div>
