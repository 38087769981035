import { Injectable } from '@angular/core';
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute, NavigationEnd} from '@angular/router';
import{Router} from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private previousUrl: string;
  private currentUrl: string;
  // constructor(public router:Router,public state: RouterStateSnapshot,private userService: UserService) { }
  constructor(private route: ActivatedRoute,public router:Router,private userService: UserService) { }
  canActivate():boolean{
//this.currentUrl = this.router.url;
var lastUrl = localStorage.getItem('lasturl');
if (lastUrl!=null && lastUrl != 'undefined'){
  this.currentUrl = lastUrl.replace(/^.*\/\/[^\/]+/, '');
  localStorage.setItem('lasturl',null);
}
else{
  this.currentUrl = window.location.pathname;
}
//this.currentUrl = window.location.pathname;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });


    let routerStateSnapshot = this.router.routerState.snapshot;//routerStateSnapshot.url
    if(!this.userService.isAuthorized()){
      //this.router.navigate(['login']);
      // this.router.navigate(['login',{loginaction: "login", returnurl: 
      // routerStateSnapshot.url}]);
      this.router.navigate(['login',{loginaction: "login", returnurl: 
      this.currentUrl}]);
      return false;
    }
    else{
      return true;
    }
  }
}
