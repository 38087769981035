<fieldset class="section-legend-border col-md-12 col-lg-12" #ManageTopic >
  <legend class="section-legend-border">Manage Topic</legend>
<div class="row">
    <div class="col-sm-12">
<form (ngSubmit)="onSubmit()" #loginForm="ngForm">
<div class="form-row">
    <div class="form-group col-md-8">
        <ng-template #rtTopic let-r="result" let-t="term">
            {{ r.TopicSubjectAutoComplete}}
        </ng-template>
        <label for="typeahead-basic">Search for a Topic:</label>
        <input id="typeahead-basic" type="text" class="form-control"
            name="topicModel.TopicAutoComplete"
            [(ngModel)]="topicModel.TopicAutoComplete" [ngbTypeahead]="searchTopic"
            [inputFormatter]="formatterTopic" [resultTemplate]="rtTopic"
            (selectItem)="selectedItemTopic($event)" />
    </div>
</div>
<div class="form-row">
    <div class="form-group col-md-4">
        <label for="disabledTextInput">Topic Id</label>
        <input type="text" [(ngModel)]="topicModel.TopicID" name="topicModel.TopicID"
            placeholder="id" class="form-control">
    </div>
    <div class="form-group col-md-4">
        <label for="disabledTextInput">Topic Name</label>
        <input type="text" [(ngModel)]="topicModel.TopicName" name="topicModel.TopicName"
            placeholder="Topic Name" class="form-control">
    </div>
</div>
<div class="form-row">
    <div class="form-group col-md-8">
        <ng-template #rtSubject let-r="result" let-t="term">
            {{ r.SubjectName}}
        </ng-template>
        <label for="typeahead-basic">Search for a Subject:</label>
        <input id="typeahead-basic" type="text" class="form-control"
            name="topicModel.SubjectAutoComplete"
            [(ngModel)]="topicModel.SubjectAutoComplete" [ngbTypeahead]="searchSubject"
            [inputFormatter]="formatterSubject" [resultTemplate]="rtSubject"
            (selectItem)="selectedItemSubject($event)" />
    </div>
</div>
<div class="form-row">
    <div class="form-group col-md-4">
        <label for="disabledTextInput">Subject Id</label>
        <input type="text" [(ngModel)]="topicModel.SubjectID" name="topicModel.SubjectID"
            placeholder="id" class="form-control">
    </div>
    <div class="form-group col-md-4">
        <label for="disabledTextInput">Subject Name</label>
        <input type="text" [(ngModel)]="topicModel.SubjectName" name="topicModel.SubjectName"
            placeholder="Subject Name" class="form-control">
    </div>
</div>
<div class="form-row">
    <div class="form-group col-md-1"></div>
    <div class="form-group col-md-4 col-sm-12">
        <button type="submit" class="btn btn-primary btn-block">Submit</button>
    </div>
</div>

</form>
</div>
<div class="col-sm-12">
    {{result}}
  </div>
</div>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #TopicList1 >
  <legend class="section-legend-border">Topic List</legend>
<div class="row" id="topic-list">
    <span  (click)="filterTopic(0)"> 
       0 | ALL
    </span>
    <span *ngFor="let item of subjectList" (click)="filterTopic(item.SubjectID)">
      {{item.SubjectID}} | {{item.SubjectName}}
    </span>
  </div>
<div class="row" id="topic-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Topic Name</th>
              <th scope="col">Subject Name</th>
              <th scope="col">TopicID</th>
           
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of topicList">
              <th scope="row">{{item.TopicName}}</th>
              <td>{{item.SubjectName}}</td>
              <td>{{item.TopicID}}</td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
</fieldset>
