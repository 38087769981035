<!-- <p>
  loghabit works!
</p> -->
<div class="row">
  <div class="col-sm-12">
    {{resultLogger}}
  </div>
</div>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #LogHabit>
  <legend class="section-legend-border">Log Habit</legend>
<div class="form-row">

  <div class="form-group col-sm-12 col-md-6 col-lg-3">
    <label for="exampleInputPassword1">Log Date</label>
    <div class="input-group" id="calender-input">

      <input class="form-control" placeholder="yyyy-mm-dd" name="LogDateDisplay" [(ngModel)]="LogDateDisplay"
        ngbDatepicker #d="ngbDatepicker"  (ngModelChange)="onModelChange($event)">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
  </div>
  <div class="form-group col-sm-12 col-md-6 col-lg-2">
    <div id="time-picker-section">
      <ngb-timepicker name="LogTimeDisplay" [(ngModel)]="LogTimeDisplay">
      </ngb-timepicker>
    </div>
  </div>
 
</div>
<fieldset class="section-legend-border sub-section-legend col-md-6 col-lg-6" #Filter>
  <legend class="section-legend-border">Filter</legend>
  <div class="form-group col-sm-12 col-md-5 col-lg-3">
    <label for="exampleInputPassword1">Searh By</label>
    <div class="input-group">
      <input [(ngModel)]="searchTerm" (ngModelChange)="searchByHabitName()" />
    </div>
  </div>
  <div class="form-group col-lg-12 col-md-12 col-12 col-sm-12">
    <label for="exampleInputPassword1">Filter By</label>
    <div class="d-flex">

      <div class="custom-link span-menu" (click)="filterIscompleted(0)">
        0
      </div>
      <div class="custom-link span-menu" (click)="filterIscompleted(50)">
        50
      </div>
      <div class="custom-link span-menu" (click)="filterIscompleted(75)">
        75
      </div>
      <div class="custom-link span-menu" (click)="filterIscompleted(100)">
        100
      </div>
      <div class="custom-link span-menu" (click)="filterIscompleted('Re')">
        Re
      </div>
      <div class="custom-link span-menu" (click)="filterIscompleted('R')">
        R
      </div>
    </div>
  </div>
  <div class="form-group sort-section1 col-lg-12 col-md-12 col-12 col-sm-12">
    <div id="activity-type-section">
      <div class="activity-type-item">
        <span *ngFor="let type of habitTypes" [attr.data-id]="type.HabitTypeId" [id]="type.HabitTypeId"
          class='custom-link' [class]="type.Checked==true?'custom-link active':'custom-link inactive'"
          (click)="onHabitTypeClick($event)">
          {{type.HabitTypeName}}
        </span>

        <!-- <span class="custom-link" (click)="showDates($event)">
          Daily
        </span>
        <span class="custom-link" (click)="showDates($event)">
          Weekly
        </span>
        <span class="custom-link" (click)="showDates($event)">
          Monthly
        </span>
        <span class="custom-link" (click)="showDates($event)">
          Yearly
        </span> -->
      </div>
      <div id="activity-value-section" *ngIf="days.length>0">
        <div id="activity-value-section-item">

          <div class="">
            <span class="custom-link" *ngFor="let d of days"
              [class]="d.Checked==true?'custom-link active':'custom-link inactive'" (click)="onWeekClick($event)"
              [attr.data-id]="d.Value">
              {{d.DisplayText}}
            </span>
          </div>

        </div>

      </div>
    </div>

  </div>
</fieldset>
<fieldset class="section-legend-border sub-section-legend col-md-6 col-lg-6" #SortOrder>
  <legend class="section-legend-border">Sort Order</legend>
  <div class="form-row">
    <div class="form-group col-sm-12 col-md-12 col-lg-12">
      <div id="sort-by-complete">
        <label for="exampleInputPassword1">Sort By Complete </label>
        <div class="d-flex">
  
          <div class="custom-link span-menu" (click)="filterIscompleted('asc')">
            Asc
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted('dsc')">
            Dsc
          </div>
        </div>
      </div>
      <div id="sort-by-name">
        <label for="exampleInputPassword1">Sort By Name</label>
        <div class="d-flex">
  
          <div class="custom-link span-menu" (click)="filterIscompleted('nasc')">
            Asc
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted('ndsc')">
            Dsc
          </div>
        </div>
      </div>
  
    </div>
   
  </div>

  </fieldset>
</fieldset>


<div class="row d-flex  flex-wrap justify-content-center">


  <div class="form-group sort-section1 col-lg-3 col-md-4 col-6 col-sm-4">


  </div>

  <div class="form-group sort-section1 col-lg-3 col-md-3 col-6 col-sm-4">


  </div>





</div>
<div class="row d-flex  flex-wrap justify-content-center">


</div>

<div id="habit-logger">
  <div id="habit-logger-section">


    <div id="habit-data-section">
      <div id="habit-data-section-item" class="d-flex  flex-wrap justify-content-center">


        <div class="habit-log-row " *ngFor="let data of habitLogs">
          <div class="habit-item-heading">
            <div class="habit-heading-item-text" triggers="click:blur" [ngbTooltip]="todohabit">
              {{data.HabitName}}-{{data.HabitId}} <br> {{data.ToolTipToDo}}
            </div>
          </div>
          <ng-template #donSoFar>
            <div class="">
              <div> <span class="tool-tip-heading">Total Mins To do: </span>
                <span>{{ data.TotalMinsDoneSoFar}}/{{ data.TotalMinsToDo}}</span>
              </div>

            </div>
          </ng-template>
          <ng-template #todohabit>
            <div class="">
              <div>{{data.ToolTipToDo}} </div>
              <div>{{data.HabitName}} </div>
            </div>
          </ng-template>

          <div class="habit-log-col col-circle">
            <div class="circle-section" [ngbTooltip]="donSoFar" triggers="click:blur" data-html="true"
              placement="right">
              <!-- <div class="c100 p50 small"> -->
              <div [className]="'c100 small p'+data.Completed">
                <span>{{data.Completed}}%</span>
                <div class="slice">
                  <div class="bar"></div>
                  <div class="fill"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="habit-log-col habit-name-col" triggers="click:blur" [ngbTooltip]="todohabit">
              <span>{{data.HabitNameDisplay}} </span>
            </div> -->

          <div class="habit-log-col col-two-digit">H:
            <input type="number" [(ngModel)]="data.NumberOfHours" name="data.NumberOfHours" placeholder="h"
              class="habit-form-control">
          </div>
          <div class="habit-log-col col-two-digit">M:
            <input type="number" [(ngModel)]="data.NumberOfMinutes" name="data.NumberOfMinutes" placeholder="m"
              class="habit-form-control">
          </div>
          <div class="habit-log-col col-two-digit">T:
            <input type="number" [(ngModel)]="data.NumberOfTimes" name="data.NumberOfTimes" placeholder="No"
              class="habit-form-control">
          </div>
          <div class="habit-log-col col-add">
            <button class="btn" type="button" (click)="AddHabitLog($event,data)">
              <i class="fas fa-plus-circle"></i>
            </button>
            <!-- <input type="button" value="+" class="habit-form-control"> -->
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
<!-- <div style="display: block;" style="width:300px;height: 300px;">
  <canvas baseChart width="400" height="400"
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins">
  </canvas>
</div>
<div style="display: block;">
  <canvas baseChart 
    [data]="pieChartData" 
    [labels]="pieChartLabels" 
    [chartType]="pieChartType"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [legend]="pieChartLegend">
  </canvas>
</div>-->

<!-- <div class="circle-section"> 
<div class="c100 p50 small">
  <span>50%</span>
  <div class="slice">
      <div class="bar"></div>
      <div class="fill"></div>
  </div>
</div>
</div> -->
<!-- {{LogDateDisplay |json}}
{{LogTimeDisplay |json}} -->