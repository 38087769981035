import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ConfigLoaderService } from './config-loader.service';
import { NotificationPostModel } from '../models/Notification/notification-post-model';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private baseUrl = "http://admin-api.myzingonline.com";
  private getAllNotificationOrByApplicationIDUrl = '/api/notification/getallnotificationorbyapplicationid';
  //First initialize message
  private message = new BehaviorSubject('First Message');
  private _notificationBehaviourSub = new BehaviorSubject<any[]>([]);
  public sharedMessage = this.message.asObservable();
  public _notificationData = this._notificationBehaviourSub.asObservable();

  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) {
    this.baseUrl = this.configLoaderService.ApiBasePath;
    this.getAllNotificationOrByApplicationIDUrl = this.baseUrl + '/api/notification/getallnotificationorbyapplicationid';

   }
     // Getting all notification without subject
     GetAllNotificationOrByApplicationID(model: NotificationPostModel): Observable<any> {
    var response = this.http.post<any>  (this.getAllNotificationOrByApplicationIDUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
   // Getting all notification with subject
  GetAllNotificationOrByApplicationID_Sub(model: NotificationPostModel) {
   this.http.post<any>(this.getAllNotificationOrByApplicationIDUrl, model, { headers: this.getHeader() }).pipe(
    ).subscribe(notificationData=> this._notificationBehaviourSub.next(notificationData));
  }
  nextMessage(message: string) {
    this.message.next(message)
  }
  

  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  } 
}
