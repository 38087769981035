import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SubjectModel } from 'src/app/models/Interview/Subject/subject-model';
import { TopicModel } from 'src/app/models/Interview/Topic/topic-model';
import { InterviewService } from 'src/app/services/interview.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css']
})
export class TopicComponent implements OnInit {
  public result = '';
  constructor(private interviewService: InterviewService,private titleService:Title) {this.titleService.setTitle("Manage Topics"); }
  searching = false;
  searchFailed = false;
  topicModel: TopicModel;
  public topicList: TopicModel[];
  public subjectList: SubjectModel[];
  public topicUntouchedList: TopicModel[];
  ngOnInit(): void {
    this.topicModel = new TopicModel();
    this.topicModel.TopicAutoComplete = [];

    this.topicList = [];
    this.topicUntouchedList = [];
    this.interviewService.GetAllTopic().subscribe(response => {
      this.topicList = response;
      this.topicUntouchedList = response;

    });
    this.subjectList = [];
   
    this.interviewService.GetAllSubject().subscribe(response => {
      this.subjectList = response;

    });
  }
  //Subject Section
  searchSubject = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.interviewService.GetSubjectsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterSubject = (x: { Name: string }) => x.Name;

  selectedItemSubject(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.topicModel.SubjectID = item.item.SubjectID;
    this.topicModel.SubjectName = item.item.SubjectName;

  }

  //Topic Section
  searchTopic = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.interviewService.GetTopicsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )
  formatterTopic = (x: { Name: string }) => x.Name;

  selectedItemTopic(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.topicModel = item.item;
    //this.topicModel.TopicID = item.item.TopicID;
    //this.topicModel.TopicName = item.item.TopicName;
    //this.topicModel.SubjectID = item.item.SubjectID;

    // this.interviewService.GetSubjectById(this.topicModel.SubjectID).subscribe(response => {
    //   this.topicModel.SubjectName = response.SubjectName;
    // },
    //   err => {
    //     this.result = err.message;
    //   }
    // );

  }
  onSubmit() {
    var me = this.topicModel;
    var apiModel = new TopicModel();
    apiModel.TopicID = this.topicModel.TopicID;
    apiModel.TopicName = this.topicModel.TopicName;
    apiModel.SubjectID = this.topicModel.SubjectID;
    this.interviewService.InsertOrUpdateTopic(apiModel).subscribe(response => {
      this.result = response;
      this.interviewService.GetAllTopic().subscribe(response => {
        this.topicList = response;
      });
    },
      err => {
        this.result = err.message;

      }
    );
  }
  filterTopic(subjectID) {
    console.log(subjectID);
    this.topicList = this.topicUntouchedList;
    if (subjectID > 0) {
      this.topicList = this.topicList.filter(
        function (e) {
          return e.SubjectID == subjectID;
        }
      );
    }
  }

}
