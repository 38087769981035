import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoomSensorComponent } from './room-sensor/room-sensor.component';
import { AMasterSensorComponent } from './amaster-sensor/amaster-sensor.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RoomSensorComponent, AMasterSensorComponent],
  imports: [
    CommonModule,RouterModule
  ]
})
export class TemperatureModule { }
