export class ItemTrackerCurrentLocationModel {
    public ItemCurrentLocationID: number;
    public LocationID: number;
    public ItemID: number;
    public MainLocationID: number;
    public UserID: number;
    public IsAvailableToPublic: boolean;
    public Created: Date;
    public Updated: Date;


    public MainLocationUniqueName: string;
    public SubLocationUniqueName: string;
    public LocationUniqueName: string;
    public LocationName: string;
    public MainLocationName: string;
    public ProductName: string;
    public ItemTrackerCurrentLocationAutoComplete: any;
    public ItemTrackerCurrentLocationlist: ItemTrackerCurrentLocationModel[];
    public LocationNameForQrCode:string; 
    
    public ProductUniqueName:string;
    public ItemTrackerCurrentLocationProductAutoComplete: any;
}
