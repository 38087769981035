import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoghabitComponent } from './loghabit/loghabit.component';
import { HabitTrackerMasterComponent } from './habit-tracker-master/habit-tracker-master.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { ChartsModule } from 'ng2-charts';
import { HabitComponent } from './habit/habit.component';
import { HabitCategoryComponent } from './habit-category/habit-category.component';
import { HabitMappingComponent } from './habit-mapping/habit-mapping.component';
import { HabitTypeComponent } from './habit-type/habit-type.component';
import { HabitReportComponent } from './habit-report/habit-report.component';

@NgModule({
  declarations: [LoghabitComponent, HabitTrackerMasterComponent, HabitComponent,
  HabitCategoryComponent,HabitMappingComponent,HabitTypeComponent, HabitReportComponent
  ],
  imports: [
    CommonModule,RouterModule,FormsModule
    ,NgbModule
    //,ChartsModule
  ],
  
})
export class HabitTrackerModule { }
