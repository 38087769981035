<div class="row">
  <div class="col-md-3">
    <ng-template #actualsleepdata>
      <div class="">
        <div> <span class="tool-tip-heading">Actual Sleeping Time: </span> <span class="nutrition-log-status">
          {{totalSleep}} </span> </div>
      </div>
    </ng-template>
   
  <h5 [ngbTooltip]="actualsleepdata" 
  triggers="click:blur" data-html="true" placement="top-left"><i class="fas fa-cloud-moon"></i> {{totalSleep}}</h5>
  <ng-template #actualtimeinbeddata>
    <div class="">
      <div> <span class="tool-tip-heading">Actual Time In Bed: </span> <span class="nutrition-log-status">
        {{totalTimeInBed}} </span> </div>
    </div>
  </ng-template>
  <h5 [ngbTooltip]="actualtimeinbeddata" 
  triggers="click:blur" data-html="true" placement="top-left"><i class="fas fa-bed"></i>  {{totalTimeInBed}}</h5>
  <div id="sleep-time-section">
  
    <h5> <i class="fas fa-moon"></i>{{sleepStart}} - <i class="fas fa-sun"></i> {{sleepEnd}}</h5>
 </div>

  </div>
 
  <div class="col-md-6">
  
   <div *ngFor="let item of sleepViewData" class="sleep-section-log">
     <ng-template #sleepdata>
       <div class="">
         <div> <span class="tool-tip-heading">Sleep Details: </span> <span class="nutrition-log-status">
           {{item.SleepCategory}} {{item.SleepDataDisplay}} </span> </div>
       </div>
     </ng-template>
       <div class="stacked-bar-graph">
           <!-- <span  style="width:'{{item.SleepInPercentage}}%'" class="bar-1">{{item.SleepInPercentage}}%</span> -->
           <span  [style.width.%]='item.SleepInPercentage' class="bar-1" [ngClass]="item.SleepDataClass" [ngbTooltip]="sleepdata" 
           triggers="click:blur" data-html="true" placement="top-left">{{item.SleepInPercentage}}%</span> 
           <div style="display: inline-block;margin-left:6px;"> {{item.SleepCategory}} {{item.SleepDataDisplay}}
           </div>
           <!-- <span style="width:15%" class="bar-2">20%</span>
           <span style="width:30%" class="bar-3">30%</span> -->
         </div>
   </div>
  </div>
</div>


