export class AccountModel {
   public AccountID:number;
   public AccountCode:string;
   public AccountName:string;
   public AccountMasterID:number;
   public AccountMasterName:string;
   public CashBankOther:string;
   public AccountStatus:string;
   public Receipt:boolean;
   public Active:boolean;
   public AccountAutoID:number;
   public AccountAutoComplete:any;
   public AccountMasterAutoComplete:any;
}
