import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-afit-bit-master',
  templateUrl: './afit-bit-master.component.html',
  styleUrls: ['./afit-bit-master.component.css']
})
export class AFitBitMasterComponent implements OnInit {
  routerinfoservice: any;

  constructor() { }

  ngOnInit(): void {
  }
  onActivate(componentRef) {
    try {
      var currentUrl = window.location.pathname;
      // var me =  componentRef.getPageName();
      // this.CData =me;
      $('#breadItem').text(this.routerinfoservice.getPageName(currentUrl));
    }
    catch (ex) {
      console.log('Not set');
    }
  }

}
