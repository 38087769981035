import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetAllItemsForStockModel } from '../models/ItemTracker/get-all-items-for-stock-model';
import { InsertOrUpdateProductsStocksMappingModel } from '../models/ItemTracker/insert-or-update-products-stocks-mapping-model';
import { ItemTrackerCurrentLocationModel } from '../models/ItemTracker/item-tracker-current-location-model';
import { ItemTrackerLocationModel } from '../models/ItemTracker/item-tracker-location-model';
import { ItemTrackerProductsStocksMappingModel } from '../models/ItemTracker/item-tracker-products-stocks-mapping-model';
import { ConfigLoaderService } from './config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ItemTrackerService {
  private baseUrl = '';
//Stock
private InsertOrUpdateProductsStocksMappingUrl = '';
private GetProductsStocksMappingByIdUrl='';
private GetAllProductsStocksMappingOrProductsStocksMappingByIdUrl='';
private GetAllItemsForStockUrl='';
private GetProductFromStockMappingUrl='';
   //Location 
   private InsertOrUpdateLocationUrl = '';
   private GetAllLocationOrLocationByIdUrl='';
   private GetLocationByNameUrl='';
   private GetMainLocationByNameUrl='';

   //Current location
   private InsertOrUpdateCurrentLocationUrl = '';
   private GetAllCurrentLocationOrCurrentLocationByIdUrl='';
   private GetCurrentLocationByProductNameUrl='';
   private GetAllItemsByLocationUniqueNameUrl='';

   private GetItemCurrentLocationUrl='';


  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) { 
    this.baseUrl = this.configLoaderService.ApiBasePath;

    //Stock
     this.InsertOrUpdateProductsStocksMappingUrl = this.baseUrl + '/api/itemtracker/stock/insertorupdateproductsstocksmapping';
     this.GetProductsStocksMappingByIdUrl=this.baseUrl + '/api/itemtracker/stock/getproductsstocksmappingbyid?id=';
     this.GetAllProductsStocksMappingOrProductsStocksMappingByIdUrl=this.baseUrl + '/api/itemtracker/stock/getallproductsstocksmappingorproductsstocksmappingbyid';
     this.GetAllItemsForStockUrl=this.baseUrl + '/api/itemtracker/stock/getallitemsforstock';
     this.GetProductFromStockMappingUrl=this.baseUrl + '/api/itemtracker/stock/getproductfromstockmapping?productName=';


    //Location 
    this.InsertOrUpdateLocationUrl = this.baseUrl + '/api/itemtracker/location/insertorupdatelocation';
    this.GetAllLocationOrLocationByIdUrl = this.baseUrl + '/api/itemtracker/location/getalllocationorlocationbyid';
    this.GetLocationByNameUrl = this.baseUrl + '/api/itemtracker/location/getLocationbyname?locationName=';
    this.GetMainLocationByNameUrl = this.baseUrl + '/api/itemtracker/location/getmainlocationbyname?mainLocation=';

    //Current Location
    this.InsertOrUpdateCurrentLocationUrl = this.baseUrl + '/api/itemtracker/currentlocation/insertorupdatecurrentlocation';
    this.GetAllCurrentLocationOrCurrentLocationByIdUrl = this.baseUrl + '/api/itemtracker/currentlocation/getallcurrentlocationorcurrentlocationbyid';
    this.GetCurrentLocationByProductNameUrl = this.baseUrl + '/api/itemtracker/currentlocation/getcurrentlocationbyproductname?productName={0}';
    this.GetAllItemsByLocationUniqueNameUrl = this.baseUrl + '/api/itemtracker/currentlocation/getallitemsbylocationuniquename?locationUniqueName={0}';

    this.GetItemCurrentLocationUrl = this.baseUrl + '/api/itemtracker/GetItemCurrentLocation?itemName={0}';
  }

  // Stock starts
  InsertOrUpdateProductsStocksMapping(model: InsertOrUpdateProductsStocksMappingModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateProductsStocksMappingUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetProductsStocksMappingById(id: number): Observable<ItemTrackerProductsStocksMappingModel> {
    var response = this.http.get<any>(this.GetProductsStocksMappingByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllProductsStocksMappingOrProductsStocksMappingById(): Observable<ItemTrackerProductsStocksMappingModel[]> {
    var response = this.http.get<any>(this.GetAllProductsStocksMappingOrProductsStocksMappingByIdUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllItemsForStock(): Observable<GetAllItemsForStockModel[]> {
    var response = this.http.get<any>(this.GetAllItemsForStockUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetProductFromStockMapping(productName:string): Observable<ItemTrackerCurrentLocationModel[]> {
    var response = this.http.get<any>(this.GetProductFromStockMappingUrl+productName, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
// Location Starts
InsertOrUpdateLocation(model: ItemTrackerLocationModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateLocationUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllLocationOrLocationById(id: number): Observable<ItemTrackerLocationModel> {
  var response = this.http.get<any>(this.GetAllLocationOrLocationByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllLocation(): Observable<ItemTrackerLocationModel[]> {
  var response = this.http.get<any>(this.GetAllLocationOrLocationByIdUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetLocationByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetLocationByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetMainLocationByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetMainLocationByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
//Current Location 
InsertOrUpdateCurrentLocation(model: ItemTrackerCurrentLocationModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateCurrentLocationUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetCurrentLocationById(id: number): Observable<ItemTrackerCurrentLocationModel> {
  var response = this.http.get<any>(this.GetAllCurrentLocationOrCurrentLocationByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllCurrentLocation(): Observable<ItemTrackerCurrentLocationModel[]> {
  var response = this.http.get<any>(this.GetAllCurrentLocationOrCurrentLocationByIdUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetCurrentLocationByProductName(productName:string): Observable<ItemTrackerCurrentLocationModel[]> {
  var newUrl = this.GetCurrentLocationByProductNameUrl.replace('{0}',productName);
  var response = this.http.get<any>(newUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetItemCurrentLocation(productName:string): Observable<string> {
  var newUrl = this.GetItemCurrentLocationUrl.replace('{0}',productName);
  var response = this.http.get<any>(newUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllItemsByLocationUniqueName(locationUniqueName:string): Observable<ItemTrackerCurrentLocationModel[]> {
  var newUrl = this.GetAllItemsByLocationUniqueNameUrl.replace('{0}',locationUniqueName);
  var response = this.http.get<any>(newUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}


  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }
}
