
<fieldset class="section-legend-border col-md-12 col-lg-12" #ComboMaster >
    <legend class="section-legend-border">Combo Master</legend>
    <div class="row">
        <div class="col-sm-12">
            <span>Left Kick </span>
            <ol>
                <li>LKSLU - (LK- Left Kick | SLU -Side Left Upper)</li>
                <li>LKSLM - (LK- Left Kick | SLM -Side Left Middle)</li>
                <li>LKSLL - (LK- Left Kick | SLL -Side Left Lower)</li>
                
                <li>LKFFM - (LK- Left Kick | FFM -Front Front Middle)</li>
                <li>LKFFL - (LK- Left Kick | FFL -Front Front Lower)</li>

                
            </ol>
            <span>Right Kick </span>
            <ol>
                <li>RKSRU - (RK- Right Kick | SRU -Side Right Upper)</li>
                <li>RKSRM - (RK- Right Kick | SRM -Side Right Middle)</li>
                <li>RKSRL - (RK- Right Kick | SRL -Side Right Lower)</li>

                <li>RKFFM - (RK- Right Kick | FFM -Front Front Middle)</li>
                <li>RKFFL - (RK- Right Kick | FFL -Front Front Lower)</li>
            </ol>
            <span>Left Punch</span>
            <ol>
                <li>LPFFU - (LP- Left Punch | FFU -Front Front Upper)</li>
                <li>LPFFM - (LP- Left Punch | FFM -Front Front Middle)</li>
                <li>LPSLU - (LP- Left Punch | SLM -Side Left Upper)</li>
                <li>LPSLM - (LP- Left Punch | SLM -Side Left Middle)</li>
            </ol>
            <span>Right Punch</span>
            <ol>
                <li>RPSRU - (RP- Right Punch | SLM -Side Right Upper)</li>
                <li>RPSRM - (RP- Right Punch | SLM -Side Right Middle)</li>
                <li>RPFFU - (RP- Right Punch | FFU -Front Front Upper)</li>
                <li>RPFFM - (RP- Right Punch | FFM -Front Front Middle)</li>
            </ol>
        </div>
        <div class="col-sm-12">
            <span>Backward Forward</span>
            <ol>
                <li>FFFFF - (Forward | Green)</li>
                <li>BBBBB - (Backward | Red)</li>
            </ol>
        </div>
        <div class="col-sm-12">
            <span>Punch Kick Color Coding</span>
            <ol>
                <li>LK <span class="blue-dot dot"></span>- (LK- Left Kick | Blue)</li>
                <li>RK <span class="green-dot dot"></span>- (RK- Right Kick | Green)</li>
                <li>LP <span class="red-dot dot"></span>- (LP- Left Punch | Red)</li>     
                <li>RP <span class="orange-dot dot"></span>- (RP- Right Punch | Orange)</li>
            </ol>
        </div>
    </div>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #BagUI >
    <legend class="section-legend-border">Bag Simulation</legend>
    <div class="row">
        <div col="col-sm-12">
            <div>
                <input type="button" value="Clear Combo" (click)="clearCombo()" name="Clear Combo" class="btn btn-primary btn-block"/>
                <input type="button" value="Add to Current Combo" (click)="AddToInput('CurrentCombo')"  class="btn btn-primary btn-block"/>
                <input type="button" value="Add to Combo Master" (click)="AddToInput('ComboMaster')"  class="btn btn-primary btn-block"/>
            </div>
            <div>
                
            </div>
            <div>
                {{comboBagSimulationList.join(', ')}}
            </div>
          </div>
        <div class="col-sm-12">
            <div id="BagArea">
                <div id="bag-content">
                    <div id="top-bag-section" >
                        <div id="" class="kick-punch-section">
                            <div  class="kick-punch-circle left-kick-circle" (click)="addcombo('LKSLU')">LK</div>
                            <!-- <div  class="kick-punch-circle left-punch-circle"  (click)="addcombo('LPSLU')">LP</div> -->
                        </div>
                        <div id="" class="middle-kick-punch-section kick-punch-section">
                            <!-- <div  class="kick-punch-circle left-kick-circle" (click)="addcombo('LKSLU')">LK</div>
                            <div  class="kick-punch-circle right-kick-circle" (click)="addcombo('LKSLU')">RK</div> -->
                            <div  class="kick-punch-circle left-punch-circle"  (click)="addcombo('LPFFU')">LP</div>
                            <div  class="kick-punch-circle right-punch-circle"  (click)="addcombo('RPFFU')">RP</div>
                        </div>
                        <div id="" class="middle-kick-punch-section kick-punch-section">
                            <div class="kick-punch-circle right-kick-circle"  (click)="addcombo('RKSRU')">RK</div>
                            <!-- <div  class="kick-punch-circle right-punch-circle"   (click)="addcombo('LPSLU')">RP</div> -->
                        </div>
                    </div>
                

                    <div id="middle-bag-section">
                        <div class="kick-punch-section">
                            <div  class="kick-punch-circle left-kick-circle" (click)="addcombo('LKSLM')">LK</div>
                            <div  class="kick-punch-circle left-punch-circle"  (click)="addcombo('LPSLM')">LP</div>
                        </div>
                        <div class="middle-kick-punch-section kick-punch-section">
                            <div  class="kick-punch-circle left-punch-circle"  (click)="addcombo('LPFFM')">LP</div>
                            <div  class="kick-punch-circle right-punch-circle"  (click)="addcombo('RPFFM')">RP</div>
                            <div  class="kick-punch-circle left-kick-circle"  (click)="addcombo('LKFFM')">LK</div>
                            <div  class="kick-punch-circle right-kick-circle"  (click)="addcombo('RKFFM')">RK</div>
                        </div>
                        <div class="middle-kick-punch-section kick-punch-section">
                            <div  class="kick-punch-circle right-punch-circle"  (click)="addcombo('RPSRM')">RP</div>
                            <div  class="kick-punch-circle right-kick-circle"  (click)="addcombo('RKSRM')">RK</div>
                        </div>
                    </div>
                    <div id="bottom-bag-section">
                        <div class="kick-punch-section">
                            <div  class="kick-punch-circle left-kick-circle" (click)="addcombo('LKSLL')">LK</div>
                            <!-- <div  class="kick-punch-circle left-punch-circle"  (click)="addcombo('LPSLU')">LP</div> -->
                        </div>
                        <div class="middle-kick-punch-section kick-punch-section">
                            <div  class="kick-punch-circle left-kick-circle"  (click)="addcombo('LKFFL')">LK</div>
                            <div  class="kick-punch-circle right-kick-circle"  (click)="addcombo('RKFFL')">RK</div>
                        </div>
                        <div class="middle-kick-punch-section kick-punch-section">
                            <div  class="kick-punch-circle right-kick-circle"  (click)="addcombo('RKSRL')">RK</div>
                            <!-- <div  class="kick-punch-circle right-punch-circle"  (click)="addcombo('LPSLU')">RP</div> -->
                        </div>
                    </div>
                    <div id="ground-bag-section">
                        <div class="kick-punch-section">
                            <div  class="kick-punch-circle left-kick-circle" (click)="addcombo('FFFFF')">Fwd</div>
                            <!-- <div  class="kick-punch-circle left-punch-circle"  (click)="addcombo('LPSLU')">LP</div> -->
                        </div>
                        <div class="kick-punch-section ground-kick-punch-section">
                            <div  class="kick-punch-circle left-kick-circle"  (click)="addcombo('BBBBB')">Bck</div>
                        </div>
                        
                    </div>
                 
                </div>
            </div>
        </div>
    </div>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #ComboMaster >
    <legend class="section-legend-border">Combo Master</legend>
  <div class="row">
      <div class="col-sm-12">
          <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
              <div class="form-row">
                  <div class="form-group col-md-6">
                      <ng-template #rt let-r="result" let-t="term">
                          {{ r.ComboMasterName}}
                      </ng-template>
                      <label for="typeahead-basic">Search for a Combo Master:</label>
                      <input id="typeahead-basic" type="text" class="form-control" name="smartBagComboMasterModel.ComboMasterAutoComplete" [(ngModel)]="smartBagComboMasterModel.ComboMasterAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                      />
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-group col-md-2">
                      <label for="disabledTextInput">Combo MasterID</label>
                      <input type="text" [(ngModel)]="smartBagComboMasterModel.ComboMasterID" name="smartBagComboMasterModel.ComboMasterID" placeholder="id" class="form-control">
                  </div>
                  <div class="form-group col-md-4">
                      <label for="disabledTextInput">Combo Master Name</label>
                      <input type="text" [(ngModel)]="smartBagComboMasterModel.ComboMasterName" name="smartBagComboMasterModel.ComboMasterName" placeholder="Combo Master Name" class="form-control">
                  </div>
              </div>
              <div class="form-row">
            
                <div class="form-group col-md-6">
                    <label for="disabledTextInput">Combo Command</label>
                    <textarea type="text"  [(ngModel)]="smartBagComboMasterModel.ComboCommand" name="smartBagComboMasterModel.ComboCommand"
                    placeholder="Combo Command" class="form-control" rows="5"></textarea>
                </div>
            </div>
           
              <div class="form-row">
                  <div class="form-group col-md-1"></div>
                  <div class="form-group col-md-4 col-sm-12">
                      <button type="submit" class="btn btn-primary btn-block">Submit</button>
                      <input type="button" value="Add to Current Combo From CM" (click)="AddToInput('CurrentComboFromCM')"  class="btn btn-primary btn-block"/>
                  </div>
                  <div class="col-sm-12">
                    {{result}}
                  </div>
              </div>
              
          </form>
      </div>
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #CurrentCombo >
        <legend class="section-legend-border">Current Combo</legend>
      <div class="col-sm-12">
        <form (ngSubmit)="onCurrentComboSubmit()" #loginForm="ngForm">
       
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Current Combo ID</label>
                    <input type="text" [(ngModel)]="smartBagCurrentComboModel.CurrentComboID" name="smartBagCurrentComboModel.CurrentComboID" placeholder="id" class="form-control" disabled>
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Combo MasterID</label>
                    <input type="text" [(ngModel)]="smartBagCurrentComboModel.ComboMasterID" name="smartBagCurrentComboModel.ComboMasterID" placeholder="id" class="form-control">
                </div>
                
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="disabledTextInput">Combo Master Name</label>
                    <input type="text" [(ngModel)]="smartBagCurrentComboModel.ComboMasterName" name="smartBagCurrentComboModel.ComboMasterName" placeholder="Combo Master Name" class="form-control">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Delay Duration In MS</label>
                    <input type="text" [(ngModel)]="smartBagCurrentComboModel.DelayDurationInMS" name="smartBagCurrentComboModel.DelayDurationInMS" placeholder="Combo Master Name" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">No Of Times</label>
                    <input type="text" [(ngModel)]="smartBagCurrentComboModel.NoOfTimes" name="smartBagCurrentComboModel.NoOfTimes" placeholder="No Of Times" class="form-control">
                </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
                <label for="disabledTextInput">Combo Command</label>
                <textarea type="text"  [(ngModel)]="smartBagCurrentComboModel.ComboCommand" name="smartBagCurrentComboModel.ComboCommand"
                placeholder="Combo Command" class="form-control" rows="5"></textarea>

            </div>
          </div>
         
            <div class="form-row">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-4 col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block">Save Current Combo</button>
                </div>
            </div>
        </form>
    </div>

      </fieldset>
      <div class="col-sm-12">
          {{result}}
        </div>
  </div>
  </fieldset>
  <fieldset class="section-legend-border col-md-12 col-lg-12" #ComboMasterList >
    <legend class="section-legend-border">Combo Master List</legend>
  <div class="row" id="topic-example">
      <div class="col-sm-12 example-section">
        <div class="">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Combo Master ID</th>
                <th scope="col">Combo Master Name</th>
                <th scope="col">Combo Command</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of smartBagComboMasterList">
                <th scope="row" (click)="selectedComboMaster(item.ComboMasterID)">{{item.ComboMasterID}}</th>
                <td>{{item.ComboMasterName}}</td>
                <td>{{item.ComboCommand}}</td>
            </tbody>
          </table>
        </div>
      </div>
      </div>
  </fieldset>
   