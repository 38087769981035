import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FitbitBodyModel, Weight } from 'src/app/models/FitBit/fitbit-body-model';
import { FitBitService } from 'src/app/services/fit-bit.service';

@Component({
  selector: 'app-fit-bit-body',
  templateUrl: './fit-bit-body.component.html',
  styleUrls: ['./fit-bit-body.component.css']
})
export class FitBitBodyComponent implements OnInit {
bodyWeightModel:FitbitBodyModel;
weightViewModel:Weight;
  constructor(private fitbitService:FitBitService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    let observableBatch = [];
    var currentDate = new Date();
    this.weightViewModel = new Weight();
    this.weightViewModel.bmi=0;
    this.weightViewModel.fat=0;
    this.weightViewModel.weight=0;
    // currentDate.setDate(currentDate.getDate() - 1);
     var previousDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    // this.fitbitService.GetFitbitBodyData('2021-12-18').subscribe(response=>{
    // this.bodyWeightModel= response;
    // });
    this.bodyWeightModel= new FitbitBodyModel();
    this.bodyWeightModel.weight =[];
    var weightModel:Weight;
    observableBatch.push(this.fitbitService.GetFitbitBodyBMIData());
    observableBatch.push(this.fitbitService.GetFitbitBodyFatData());
    observableBatch.push(this.fitbitService.GetFitbitBodyWeightData());
    forkJoin(observableBatch).subscribe(responseList => {
      responseList.forEach(bodyModel => {
        var response = bodyModel as any;
        if(bodyModel["body-bmi"] !== undefined){
          this.weightViewModel.bmi = bodyModel["body-bmi"][bodyModel["body-bmi"].length -1].value;
         // weightModel.bmi = bodyModel["body-bmi"][bodyModel["body-bmi"].length -1].value;
        }
        else  if(bodyModel["body-fat"] !== undefined){
          this.weightViewModel.fat = bodyModel["body-fat"][bodyModel["body-fat"].length -1].value;
         // weightModel.fat = bodyModel["body-fat"][bodyModel["body-fat"].length -1].value;
        }
        else  if(bodyModel["body-weight"] !== undefined){
          this.weightViewModel.weight = bodyModel["body-weight"][bodyModel["body-weight"].length -1].value;
         // weightModel.weight = bodyModel["body-weight"][bodyModel["body-weight"].length -1].value;
        }
    });
  });
}
}

