import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WaterGoalModel } from 'src/app/models/FitBit/water-goal-model';
import { WaterLogModel } from 'src/app/models/FitBit/water-log-model';
import { FitBitService } from 'src/app/services/fit-bit.service';

@Component({
  selector: 'app-fit-bit-water',
  templateUrl: './fit-bit-water.component.html',
  styleUrls: ['./fit-bit-water.component.css']
})
export class FitBitWaterComponent implements OnInit,OnChanges   {
  waterLogModel: WaterLogModel;
  waterGoalModel: WaterGoalModel;
  waterLogPercentageProgress: number;
  waterLogMessage: string;
  public glassHeight: number;
  public glassWidth: number;
  @Input() DateFromOutside: string;
  constructor(private fitbitService: FitBitService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    var currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() - 1);
    var previousDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    if (this.DateFromOutside && this.DateFromOutside.length > 0) {
      previousDate = this.DateFromOutside;
    }
    //this.fetchData(previousDate);
  }
  fetchData(previousDate) {
    // this.glassHeight=100;
    // this.glassWidth=57;
    this.glassHeight = 50;
    this.glassWidth = (this.glassHeight * 57) / 100;
    this.fitbitService.GetFitbitWaterLogData(previousDate).subscribe(response => {
      this.waterLogModel = response;
      this.fitbitService.GetFitbitWaterGoalData().subscribe(response => {
        this.waterGoalModel = response;
        this.waterLogPercentageProgress = Math.round((this.waterLogModel.summary.water / this.waterGoalModel.goal.goal) * 100);
        this.waterLogMessage = this.waterLogModel.summary.water > 1000 ? (this.waterLogModel.summary.water / 1000) + ' Liter' : this.waterLogModel.summary.water + ' ML'
      });
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    // create header using child_id
    this.fetchData(this.DateFromOutside);
  }

}
