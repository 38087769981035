import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VitalInformationMeasurementModel } from '../models/VitalInformation/vital-information-measurement-model';
import { ConfigLoaderService } from './config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class VitalInformationService {
  private baseUrl = "http://admin-api.myzingonline.com";
  private InsertOrUpdateVitalInformationMeasurementUrl = '';
  private GetAllVitalInformationMeasurementUrl = '';
  private GetVitalInformationMeasurementByIdUrl = '';
  private GetVitalInformationMeasurementByDateUrl = '';
  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) {
    this.baseUrl = this.configLoaderService.ApiBasePath;
    this.InsertOrUpdateVitalInformationMeasurementUrl = this.baseUrl + '/api/vitalinformation/insertorupdatevitalinformationmeasurement';
    this.GetAllVitalInformationMeasurementUrl = this.baseUrl + '/api/vitalinformation/getallvitalinformationmeasurement';
    this.GetVitalInformationMeasurementByIdUrl = this.baseUrl + '/api/vitalinformation/getvitalinformationmeasurementbyid';
    this.GetVitalInformationMeasurementByDateUrl = this.baseUrl + '/api/vitalinformation/getvitalinformationmeasurementbydate';

  }
  // QuestionAnswer Starts
  InsertOrUpdateVitalInformationMeasurement(model: VitalInformationMeasurementModel): Observable<any> {
    var response = this.http.post<any>(this.InsertOrUpdateVitalInformationMeasurementUrl, model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))

    );
    return response;
  }
  GetAllVitalInformationMeasurement(): Observable<any> {
    var response = this.http.get<any>(this.GetAllVitalInformationMeasurementUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  GetAllVitalInformationMeasurementPromise(): Promise<any> {
    var response = this.http.get<any>(this.GetAllVitalInformationMeasurementUrl, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    ).toPromise();
    return response;
  }
  GetVitalInformationMeasurementById(id: number): Observable<any> {
    var response = this.http.get<any>(this.GetVitalInformationMeasurementByIdUrl + id, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  } 
  GetVitalInformationMeasurementByDate(logDate: Date): Observable<any> {
    var model = {
      Created:logDate.toDateString()
    }

    var logDateNew = moment(logDate).format("YYYY-MM-DD");
    var response = this.http.post<any[]>(this.GetVitalInformationMeasurementByDateUrl,model, { headers: this.getHeader() }).pipe(
      tap(_ => console.log(response))
    );
    return response;
  }
  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }
}
