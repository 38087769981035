import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SensorDataSensorFeatureTypeModel } from 'src/app/models/Sensor/sensor-data-sensor-feature-type-model';
import { SensorDataSensorModel } from 'src/app/models/Sensor/sensor-data-sensor-model';
import { SensorService } from 'src/app/services/sensor.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-sensor-feature-type',
  templateUrl: './sensor-feature-type.component.html',
  styleUrls: ['./sensor-feature-type.component.css']
})
export class SensorFeatureTypeComponent implements OnInit {

  public result = '';
sensorDataSensorFeatureTypeModel: SensorDataSensorFeatureTypeModel;
model: any;
searching = false;
searchFailed = false;
constructor(private sensorService: SensorService,private titleService:Title) {this.titleService.setTitle("Manage Sensor Feature Type"); }

ngOnInit() {
  this.sensorDataSensorFeatureTypeModel = new SensorDataSensorFeatureTypeModel();
  this.sensorDataSensorFeatureTypeModel.SensorDataSensorFeatureTypelist = [];

  this.sensorDataSensorFeatureTypeModel.Sensor = new SensorDataSensorModel();
  this.sensorDataSensorFeatureTypeModel.Sensor.SensorDataSensorlist = [];
}
search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.sensorService.GetSensorFeaturetypeByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatter = (x: { Name: string }) => x.Name;

selectedItem(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataSensorFeatureTypeModel = item.item;
  this.sensorDataSensorFeatureTypeModel.SensorFeatureTypeID = item.item.SensorFeatureTypeID;
  this.sensorDataSensorFeatureTypeModel.SensorFeatureTypeName = item.item.SensorFeatureTypeName; 
  this.sensorDataSensorFeatureTypeModel.SensorFeatureTypeUniqueName = item.item.SensorFeatureTypeUniqueName;
 }

//Sensor Auto Complete
searchSensor = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.sensorService.GetSensorsByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatterSensor = (x: { Name: string }) => x.Name;

selectedItemSensor(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataSensorFeatureTypeModel.Sensor.SensorID = item.item.SensorID;
  this.sensorDataSensorFeatureTypeModel.Sensor.SensorName = item.item.SensorName; 
  this.sensorDataSensorFeatureTypeModel.Sensor.SensorUniqueName = item.item.SensorUniqueName;
 }

onSubmit() {
  var me = this.sensorDataSensorFeatureTypeModel;
  this.sensorService.InsertOrUpdateSensorFeatureType(this.sensorDataSensorFeatureTypeModel).subscribe(response => {
    this.result = response;
  },
    err => {
      this.result = err.message;
    }
  );
}

}
