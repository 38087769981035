export class TransactionApiModel {
    TTransID:number;
    VoucherDate:string;
    VoucherDateDisplay:string
    VoucherType:string;
    VoucherID:number;
    AccountCode :string;
    Amount :number
    PayMode :string;
    TranType: string;
    ProductID:number;
    ProductName:string;
    Quantity:number;
    Measurement:Number;
     MeasurementId:number;
     Narration:string;
}
