import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrcode-ex',
  templateUrl: './qrcode-ex.component.html',
  styleUrls: ['./qrcode-ex.component.css']
})
export class QrcodeExComponent implements OnInit {

  public myAngularxQrCode: string = null;
  
  constructor () {
    this.myAngularxQrCode = 'ItSoluionStuff.com';
  }

  ngOnInit(): void {
  }

}
