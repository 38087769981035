import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { HabitCategoryModel } from 'src/app/models/HabitTracker/HabitCategory/habit-category-model';
import { HabitTrackerService } from 'src/app/services/habit-tracker.service';
//import { HabitTrackerService } from 'src/app/service/habit-tracker.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-habit-category',
  templateUrl: './habit-category.component.html',
  styleUrls: ['./habit-category.component.css']
})
export class HabitCategoryComponent implements OnInit {
  public result='';
  public habitCategoryModel:HabitCategoryModel;

  model: any;
  searching = false;
  searchFailed = false;
  constructor(private habitTrackerService: HabitTrackerService,private titleService:Title) {  this.titleService.setTitle("Habit Category");}

  ngOnInit() {
    this.habitCategoryModel = new HabitCategoryModel();
    this.habitCategoryModel.HabitCategorylist = [];

    // this.habitTrackerService.GetHabitCategoryAllOrById(0).subscribe(response=>
    //   {
    //     console.log(response);
    //   }
    // );
  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]):
      (this.habitTrackerService.GetHabitCategoryByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )



 formatter = (x: { HabitName: string }) => x.HabitName;

 selectedItem(item){
  //this.clickedItem=item.item;
  console.log(item);
  this.habitCategoryModel.HabitCategoryId = item.item.HabitCategoryId;
  this.habitCategoryModel.HabitCategoryName = item.item.HabitCategoryName;
  
}
onSubmit() {
  var me = this.habitCategoryModel;
  // var HabitCategoryModel = new HabitCategoryModel();
  // HabitCategoryModel.HabitCategoryId = me.HabitCategoryId;
  // HabitCategoryModel.HabitCategoryName = me.HabitCategoryName;
  
  this.habitTrackerService.Insertorupdatehabitcategory(this.habitCategoryModel).subscribe(
    response => {
      console.log(response);
      this.result = response;
      this.habitCategoryModel = new HabitCategoryModel();
    }
  );
}

}
