<fieldset class="section-legend-border  col-md-12 col-lg-12" #SensorLocation >
    <legend class="section-legend-border">Sensor Location</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <div class="form-row">
                <div class="form-group col-md-7">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.LocationName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sensor Location:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="sensorDataLocationModel.SensorDataLocationAutoComplete" [(ngModel)]="sensorDataLocationModel.SensorDataLocationAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Location id</label>
                    <input type="text" [(ngModel)]="sensorDataLocationModel.LocationID" name="sensorDataLocationModel.LocationID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-md-2">
                    <label for="disabledTextInput">Sensor Location Name</label>
                    <input type="text" [(ngModel)]="sensorDataLocationModel.LocationName" name="sensorDataLocationModel.LocationName" placeholder="Sensor Name" class="form-control">
                </div>
                <div class="form-group col-md-3">
                    <label for="disabledTextInput">Sensor Location Unique Name</label>
                    <input type="text" [(ngModel)]="sensorDataLocationModel.LocationUniqueName" name="sensorDataLocationModel.LocationUniqueName" placeholder="Sensor Unique Name" class="form-control">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-1"></div>
                <div class="form-group col-md-4 col-sm-12">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>