import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FitBitSleepTrackerComponent } from './fit-bit-sleep-tracker/fit-bit-sleep-tracker.component';
import { FitBitActivityComponent } from './fit-bit-activity/fit-bit-activity.component';
import { FitBitBodyComponent } from './fit-bit-body/fit-bit-body.component';
import { FitBitWaterComponent } from './fit-bit-water/fit-bit-water.component';
import { FitBitUpdateTokenComponent } from './fit-bit-update-token/fit-bit-update-token.component';



@NgModule({
  declarations: [FitBitSleepTrackerComponent, FitBitActivityComponent, FitBitBodyComponent, FitBitWaterComponent, FitBitUpdateTokenComponent],
  imports: [
    CommonModule,AgGridModule,NgbModule,RouterModule,FormsModule
  ],
  providers: [DatePipe],
  exports: [
    FitBitSleepTrackerComponent, FitBitActivityComponent, FitBitBodyComponent,FitBitWaterComponent,  // <--- Enable using the component in other modules
  ]
})
export class FitBitModule { }
