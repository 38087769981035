import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { SensorDataLocationModel } from 'src/app/models/Sensor/sensor-data-location-model';
import { SensorService } from 'src/app/services/sensor.service';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-sensor-location',
  templateUrl: './sensor-location.component.html',
  styleUrls: ['./sensor-location.component.css']
})
export class SensorLocationComponent implements OnInit {

  public result = '';
sensorDataLocationModel: SensorDataLocationModel;
model: any;
searching = false;
searchFailed = false;
constructor(private sensorService: SensorService,private titleService:Title) {this.titleService.setTitle("Manage Sensor Location"); }

ngOnInit() {
  this.sensorDataLocationModel = new SensorDataLocationModel();
  this.sensorDataLocationModel.SensorDataLocationlist = [];
}
search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.sensorService.GetSensorLocationByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatter = (x: { Name: string }) => x.Name;

selectedItem(item) {
  //this.clickedItem=item.item;
  console.log(item);
  this.sensorDataLocationModel.LocationID = item.item.LocationID;
  this.sensorDataLocationModel.LocationName = item.item.LocationName; 
  this.sensorDataLocationModel.LocationUniqueName = item.item.LocationUniqueName;
  // var accountApiModel = new SensorDataSensorModel();
  // this.accountModel.AccountID = item.item.AccountID;
  // this.accountModel.AccountCode = item.item.AccountCode;
  // this.accountModel.AccountName = item.item.AccountName;

  // this.accountModel.AccountMasterID = item.item.AccountMasterID;
  // this.accountModel.AccountMasterName = item.item.AccountMasterName;
  // this.accountModel.CashBankOther = item.item.CashBankOther;
  // this.accountModel.AccountStatus = item.item.AccountStatus;
  // this.accountModel.Receipt = item.item.Receipt;
  // this.accountModel.Active = item.item.Active;
  // this.accountModel.AccountAutoID = item.item.AccountAutoID;
}
onSubmit() {
  var me = this.sensorDataLocationModel;
  this.sensorService.InsertOrUpdateSensorLocation(this.sensorDataLocationModel).subscribe(response => {
    this.result = response;
  },
    err => {
      this.result = err.message;
    }
  );
  // var vendorApimodel = new VendorApiModel();
  // vendorApimodel.vendorAutoID = me.VendorAutoID;
  // vendorApimodel.vendorID = me.VendorID;
  // vendorApimodel.vendorName = me.VendorName;
  // this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
  //   response => {
  //     console.log(response);
  //     this.vendorModel = new VendorModel();
  //   }
  // );
}

}
