
<fieldset class="section-legend-border col-md-12 col-lg-12" #Vendor >
  <legend class="section-legend-border">Vendor</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">

      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.VendorName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Vendor:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="vendorModel.VendorAutoComplete" 
          [(ngModel)]="vendorModel.VendorAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
          [resultTemplate]="rt" (selectItem)="selectedItem($event)"
          />
        </div>
      </div>
      <div class="form-row"> 
        <div class="form-group col-sm-12 col-md-2 col-lg-2">
          <label for="disabledTextInput">Vendor Id</label>
          <input type="text" disabled [(ngModel)]="vendorModel.VendorID" name="vendorModel.VendorID" placeholder="id" class="form-control">
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-6">
          <label for="disabledTextInput">Vendor Name</label>
          <input type="text" [(ngModel)]="vendorModel.VendorName" name="vendorModel.VendorName" placeholder="Vendor Name" class="form-control">
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="disabledTextInput">Vendor Auto Id</label>
          <input type="text" disabled [(ngModel)]="vendorModel.VendorAutoID" name="vendorModel.VendorAutoID" placeholder="Vendor Auto ID" class="form-control">
        </div>
      </div>
   
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
      </div>
    </form> 
  </div>
  </div>  
</fieldset>
  <div class="row" id="topic-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <p class="section-heading-text">Vendor List</p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">VendorID</th>
              <th scope="col">VendorName</th>
              <th scope="col">VendorAutoID</th>
           
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of vendorModel.Vendorlist">
              <th scope="row">{{item.VendorID}}</th>
              <td>{{item.VendorName}}</td>
              <td>{{item.VendorAutoID}}</td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  