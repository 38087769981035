import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { RoomSensorComponent } from '../CustomModule/temperature/room-sensor/room-sensor.component';
import { HighChartSampleComponent } from '../high-chart-sample/high-chart-sample.component';
import { SensorWidgetComponent } from '../widgets/sensor-widget/sensor-widget.component';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.css']
})
export class DashBoardComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  // Keep track of list of generated components for removal purposes
  components = [];
  //sensorWidgetComponent = HighChartSampleComponent;
  sensorWidgetComponent = SensorWidgetComponent;
  //sensorWidgetComponent = RoomSensorComponent;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
  }

  addComponent(componentClass: Type<any>,sensorDataType:string,dataName:string,title:string,width:number,height:number,yAxisLabel:string) {
    console.log(sensorDataType+'-addcomponent');
    localStorage.setItem('dataName', dataName);
    localStorage.setItem('sensorDataType', sensorDataType);
    localStorage.setItem('title', title);
    localStorage.setItem('width', width+'');
    localStorage.setItem('height', height+'');
    localStorage.setItem('yAxisLabel', yAxisLabel);
    console.log(localStorage.getItem('sensorDataType')+'-addcomponentSession'); ;
    // Create component dynamically inside the ng-template
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const component = this.container.createComponent(componentFactory);
    //if(component.componentType.name  === 'HighChartSampleComponent')
    if(component.componentType.name  === 'SensorWidgetComponent')
    // if(component.componentType.name  === 'RoomSensorComponent')
    {
      console.log('addcomponent-'+sensorDataType);
     
       //component.instance.sensorDataType =sensorDataType;
       component.instance.dataName =dataName;
       component.instance.title =title;
       component.instance.width=width;
       component.instance.height=height;
       component.instance.yAxisLabel = yAxisLabel;
       // getting the component's HTML
      let element: HTMLElement = <HTMLElement>component.location.nativeElement;
      element.classList.add('dynamicGraph');
      // component.param1 =param;
    }

    // Push the component so that we can keep track of which components are created
    this.components.push(component);
  }
  removeComponent(componentClass: Type<any>) {
    // Find the component
    const component = this.components.find((component) => component.instance instanceof componentClass);
    const componentIndex = this.components.indexOf(component);

    if (componentIndex !== -1) {
      // Remove component from both view and array
      this.container.remove(this.container.indexOf(component));
      this.components.splice(componentIndex, 1);
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    this.container.move(this.components[event.previousIndex].hostView, event.currentIndex);
    moveItemInArray(this.components, event.previousIndex, event.currentIndex);
}
}
