import { SubjectModel } from "../Subject/subject-model";
import { TopicModel } from "../Topic/topic-model";

export class QuestionAnswerModel {
    public TopicID:number; 
    public QuestionAnswerID:number; 
    public Question:string;
    public Answer:string;
    
    public TopicName:string;
    public SubjectID:number;
    public SubjectName:number;
    public QuestionAnswerAutoComplete:any;
    public QuestionAnswerlist:QuestionAnswerModel[];

    public TopicAutoComplete:any;
    public Topiclist:TopicModel[];

    public SubjectAutoComplete:any;
    public Subjectlist:SubjectModel[];

    public  QuestionAutoComplete:string;
}
