import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QuestionAnswerModel } from '../models/Interview/QuestionAnswer/question-answer-model';
import { SubjectModel } from '../models/Interview/Subject/subject-model';
import { TopicModel } from '../models/Interview/Topic/topic-model';
import { ConfigLoaderService } from './config-loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {
  private baseUrl = '';
 

  //Questionanswer 
  private InsertOrUpdateQuestionAnswerUrl = '';
  private GetquestionanswerByIdUrl = '';
  private GetAllQuestionAnswerUrl = '';
  private GetQuestionAnswersByNameUrl = '';
  private GetAllQuestionAnswerOrQuestionAnswerByTextUrl = '';
  private GetAllQuestionAnswerBySubjectIDOrTopicIDOrQuestionIDUrl = '';

   //Subject 
   private InsertOrUpdateSubjectUrl = '';
   private GetSubjectByIdUrl = '';
   private GetAllSubjectUrl = '';
   private GetSubjectsByNameUrl = '';

   //topic 
   private InsertOrUpdateTopicUrl = '';
   private GetTopicByIdUrl = '';
   private GetAllTopicUrl = '';
   private GetTopicsByNameUrl = '';
   private GetTopicsByNameSubjectIdUrl = '';
  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) {
    this.baseUrl = this.configLoaderService.ApiBasePath;

    //question answer
    this.InsertOrUpdateQuestionAnswerUrl = this.baseUrl + '/api/interview/questionanswer/insertorupdatequestionanswer';
    this.GetquestionanswerByIdUrl = this.baseUrl + '/api/interview/questionanswer/getquestionanswerbyid?id=';
    this.GetAllQuestionAnswerUrl = this.baseUrl + '/api/interview/questionanswer/getallquestionanswer';
    this.GetQuestionAnswersByNameUrl = this.baseUrl + '/api/interview/questionanswer/getquestionanswersbyname?question=';
    this.GetAllQuestionAnswerOrQuestionAnswerByTextUrl = this.baseUrl + '/api/interview/questionanswer/getallquestionanswerorquestionanswerbytext?searchText=';
    this.GetAllQuestionAnswerBySubjectIDOrTopicIDOrQuestionIDUrl = this.baseUrl + '/api/interview/questionanswer/getallquestionanswerbysubjectidortopicidorquestionid?subjectID={0}&topicID={1}&questionAnswerID={2}';

      //subject
      this.InsertOrUpdateSubjectUrl = this.baseUrl + '/api/interview/subject/insertorupdatesubject';
      this.GetSubjectByIdUrl = this.baseUrl + '/api/interview/subject/getsubjectbyid?id=';
      this.GetAllSubjectUrl = this.baseUrl + '/api/interview/subject/getallsubject';
      this.GetSubjectsByNameUrl = this.baseUrl + '/api/interview/subject/getsubjectsbyname?subjectName=';

          //topics
          this.InsertOrUpdateTopicUrl = this.baseUrl + '/api/interview/topic/insertorupdatetopic';
          this.GetTopicByIdUrl = this.baseUrl + '/api/interview/topic/gettopicbyid?id=';
          this.GetAllTopicUrl = this.baseUrl + '/api/interview/topic/getalltopics';
          this.GetTopicsByNameUrl = this.baseUrl + '/api/interview/topic/gettopicsbyname?topicName=';
          this.GetTopicsByNameSubjectIdUrl = this.baseUrl + '/api/interview/topic/gettopicsbynamesubjectid?topicName={0}&subjectId={1}';
  }

  
// QuestionAnswer Starts
InsertOrUpdateQuestionAnswer(model: QuestionAnswerModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateQuestionAnswerUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetQuestionAnswerById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetquestionanswerByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllQuestionAnswer(): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllQuestionAnswerUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetQuestionAnswersByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetQuestionAnswersByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllQuestionAnswerOrQuestionAnswerByText(searchText:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllQuestionAnswerOrQuestionAnswerByTextUrl+searchText, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllQuestionAnswerBySubjectIDOrTopicIDOrQuestionID(subjectID:number,topicID:number,questionAnswerID:number): Observable<any[]> {
  var newUrl = this.GetAllQuestionAnswerBySubjectIDOrTopicIDOrQuestionIDUrl;
    newUrl = newUrl.replace('{0}', subjectID.toString());
    newUrl = newUrl.replace('{1}', topicID.toString());
     newUrl = newUrl.replace('{2}', questionAnswerID.toString());
  var response = this.http.get<any[]>(newUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
// Subject Starts
InsertOrUpdateSubject(model: SubjectModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateSubjectUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetSubjectById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetSubjectByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllSubject(): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllSubjectUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetSubjectsByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetSubjectsByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}

// Topics Starts
InsertOrUpdateTopic(model: TopicModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateTopicUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetTopicById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetTopicByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllTopic(): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllTopicUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetTopicsByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetTopicsByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetTopicsByNameSubjectId(name:string,subjectId:number): Observable<any[]> {
  var newUrl = this.GetTopicsByNameSubjectIdUrl;
  newUrl = newUrl.replace('{0}', name);
  newUrl = newUrl.replace('{1}',subjectId.toString());
  var response = this.http.get<any[]>(newUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }
}
