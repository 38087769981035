  <!-- Icon Cards-->
  <div class="row">
    <div class="col-xl-3 col-sm-6 mb-3">
      <div class="card text-white bg-primary o-hidden h-100">
        <div class="card-body">
          <div class="card-body-icon">
            <i class="fas fa-fw fa-comments"></i>
          </div>
          <div class="mr-5">{{produtApiModels.length}} Product(s) to renew</div>
        </div>
        <span class="card-footer text-white clearfix small z-1 view-click-hover" (click)="productRenewClick()">
          <span class="float-left" >View Details</span>
          <span class="float-right">
            <i class="fas fa-angle-right"></i>
          </span>
        </span>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 mb-3">
      <div class="card text-white bg-warning o-hidden h-100">
        <div class="card-body">
          <div class="card-body-icon">
            <i class="fas fa-fw fa-list"></i>
          </div>
          <div class="mr-5">11 New Tasks!</div>
        </div>
        <a class="card-footer text-white clearfix small z-1" href="#">
          <span class="float-left">View Details</span>
          <span class="float-right">
            <i class="fas fa-angle-right"></i>
          </span>
        </a>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 mb-3">
      <div class="card text-white bg-success o-hidden h-100">
        <div class="card-body">
          <div class="card-body-icon">
            <i class="fas fa-fw fa-shopping-cart"></i>
          </div>
          <div class="mr-5">123 New Orders!</div>
        </div>
        <a class="card-footer text-white clearfix small z-1" href="#">
          <span class="float-left">View Details</span>
          <span class="float-right">
            <i class="fas fa-angle-right"></i>
          </span>
        </a>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 mb-3">
      <div class="card text-white bg-danger o-hidden h-100">
        <div class="card-body">
          <div class="card-body-icon">
            <i class="fas fa-fw fa-life-ring"></i>
          </div>
          <div class="mr-5">13 New Tickets!</div>
        </div>
        <a class="card-footer text-white clearfix small z-1" href="#">
          <span class="float-left">View Details</span>
          <span class="float-right">
            <i class="fas fa-angle-right"></i>
          </span>
        </a>
      </div>
    </div>
  </div>
  <!-- DataTables Example -->
  <div class="card mb-3" *ngIf="isShowRenewProducts">
    <div class="card-header">
      <i class="fas fa-table"></i>
      Product To be renewed in <input type="number" id="noOfDays" [(ngModel)]="notifyBeforeDays" (change)="updateRenewProductByDays()"></div>
      <div class="row productlist-section" id="productlist">
        <div class="col-sm-12 example-section">
          <div class="">
            <p class="section-heading-text"></p>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Renewed Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of produtApiModels">
                  <th scope="row" class="view-click-hover"> <a (click)='redirectToProductPage(item.ProductAutoID)'> {{item.ProductName}} </a></th>
                  <td>{{item.Amount|number:'1.2-2'}}</td>
                  <td>{{item.RenewDate| date: 'fullDate'}}</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div>
  </div>
