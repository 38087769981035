<div class="card card-register mx-auto mt-1">
  <div class="card-header">Register an Account</div>
  <div class="card-body">
    <form (ngSubmit)="registerForm.form.valid && onSubmit()" #registerForm="ngForm"
      [appMatchPassword]="['model.UserPassword', 'model.UserConfirmPassword']" novalidate>
      <div class="form-group">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-label-group">
              <input type="text" [(ngModel)]="model.FirstName" name="model.FirstName" id="firstName"
                class="form-control" placeholder="First name" required="required" autofocus="autofocus" #name="ngModel">
              <label for="firstName">First name</label>
              <span class="text-danger" *ngIf="(name.touched || registerForm.submitted) && name.errors?.required">
                Name is required
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-label-group">
              <input type="text" [(ngModel)]="model.LastName" name="model.LastName" id="lastName" class="form-control"
                placeholder="Last name" required="required">
              <label for="lastName">Last name</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-label-group">
              <input type="text" [(ngModel)]="model.UserName" name="model.UserName" id="UserName" class="form-control"
                placeholder="UserName" required="required" #username="ngModel" appValidateUserName>
              <label for="UserName">Username</label>
              <span class="text-danger" *ngIf="(username.touched || registerForm.submitted) && username.errors?.required">
                User Name is required
              </span>
              <span class="text-danger" *ngIf="username.touched && username.errors?.userNameNotAvailable">
                User Name not available
              </span>
            </div>
          </div>
          <div class="col-md-6">
              <div class="form-label-group">
                <input type="text" [(ngModel)]="model.Email" name="model.Email" id="Email" class="form-control"
                  placeholder="Email address" required="required" #email="ngModel" appValidateEmail>
                <label for="Email">Email</label>
                <span class="text-danger" *ngIf="(email.touched || registerForm.submitted) && email.errors?.required">
                  Email is required
                </span>
                <span class="text-danger" *ngIf="email.touched && email.errors?.emailNotAvailable">
                 Email not available
                </span>
              </div>
            </div>
         
        </div>
      
      </div>
      <div class="form-group">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-label-group">
              <input type="password" [(ngModel)]="model.UserPassword" name="model.UserPassword" id="inputPassword"
                class="form-control" placeholder="Password" required="required" #password="ngModel">
              <label for="inputPassword">Password</label>

              <span class="text-danger" *ngIf="password.touched && password.errors?.invalidPassword">
                Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                letter and 1 number
              </span> 
              <span class="text-danger"
                *ngIf="(password.touched || registerForm.submitted) && password.errors?.required">
                Password is required
              </span>
              <span class="text-danger" *ngIf="password.touched && password.errors?.invalidPassword">
                Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
                letter and 1 number
              </span>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-label-group">
              <input type="password" [(ngModel)]="model.UserConfirmPassword" name="model.UserConfirmPassword"
                id="confirmPassword" class="form-control" #confirmPassword="ngModel" placeholder="Confirm password"
                required="required">
              <label for="confirmPassword">Confirm password</label>
            </div>
            <span class="text-danger" *ngIf="confirmPassword.touched && confirmPassword.errors?.passwordMismatch">
              Passwords doesnot match
            </span>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">Submit</button>
    </form>
    <div class="text-center">
      <a class="d-block small mt-3" href="login.html">Login Page</a>
      <a class="d-block small" href="forgot-password.html">Forgot Password?</a>
    </div>
  </div>
</div>
<div class="col-sm-12">
  {{result|json}}
</div>