<p>
  test-table works!
</p>
<form (ngSubmit)="onSubmit()" #loginForm="ngForm">
  <div class="form-row">
    <div class="form-group col-md-2">
      <label for="disabledTextInput">Test table ID</label>
      <input type="text" [(ngModel)]="testTable.TestId" name="testTable.TestId" placeholder="Test id" class="form-control">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-2">
      <label for="disabledTextInput">Test table Name</label>
      <input type="text" [(ngModel)]="testTable.TestName" name="testTable.TestName" placeholder="Test id" class="form-control">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-1"></div>
    <div class="form-group col-md-4 col-sm-12">
      <button type="submit" class="btn btn-primary btn-block">Submit</button>
    </div>
  </div>
</form>
<div class="form-row">
  <div class="form-group col-md-2">
    <label for="disabledTextInput">Test table ID</label>
    <input type="text" [(ngModel)]="testTable.TestId" name="testTable.TestId" placeholder="Test id" class="form-control">
  </div>
</div>
<div class="form-row">
  <div class="form-group col-md-1"></div>
  <div class="form-group col-md-4 col-sm-12">
    <button type="button" class="btn btn-primary btn-block" (click) = "GetTesttableData($event)">GetTesttableData</button>
  </div>
</div>
<div class="col-sm-12">
  {{result|json}}
</div>