import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ConfigLoaderService } from './config-loader.service';
import { MainSensorData } from '../models/Sensor/main-sensor-data';
import { SensorDataPostModel } from '../models/Sensor/sensor-data-post-model';
import { SensorDataSensorModel } from '../models/Sensor/sensor-data-sensor-model';
import { SensorDataSensorFeatureTypeModel } from '../models/Sensor/sensor-data-sensor-feature-type-model';
import { SensorDataSensorMappingModel } from '../models/Sensor/sensor-data-sensor-mapping-model';
import { SensorDataLocationModel } from '../models/Sensor/sensor-data-location-model';
import { SensorDataSubLocationModel } from '../models/Sensor/sensor-data-sub-location-model';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  
  private baseUrl = "http://admin-api.myzingonline.com";

  //Sensor 
  private InsertOrUpdateSensorUrl = '';
  private GetSensorByIdUrl = '';
  private GetAllSensorUrl = '';
  private GetSensorsByNameUrl = '';

  //Sensor Featurer Type
  private InsertOrUpdateSensorFeatureTypeUrl = '';
  private GetSensorFeatureTypeByIdUrl = '';
  private GetAllSensorFeatureTypeUrl = '';
  private GetSensorFeaturetypeByNameUrl = '';

  //Sensor Location
  private InsertOrUpdateSensorLocationUrl = '';
  private GetSensorSensorLocationByIdUrl = '';
  private GetAllSensorLocationUrl = '';
  private GetSensorLocationByNameUrl = '';

   //Sensor Sub Location
   private InsertOrUpdateSensorSubLocationUrl = '';
   private GetSensorSensorSubLocationByIdUrl = '';
   private GetAllsensorSubLocationUrl = '';
   private GetSensorSubLocationByNameUrl = '';

     //Sensor Mapping
     private InsertOrUpdateSensorMappingUrl = '';
     private GetSensorMappingByIdUrl = '';
     private GetAllSensorMappingUrl = '';
     private GetSensorMappingByNameUrl = '';
  

  private GetSensorDataUrl = "http://sensor-api.myzingonline.com/api/Sensor?locationName=Computer%20room&sensorType=temperature";
  private GetSensorDataByDayUrl = "";
  constructor(private http: HttpClient, private configLoaderService: ConfigLoaderService) {
    this.baseUrl = this.configLoaderService.ApiBasePath;
    //this.baseUrl = "http://admin-api.myzingonline.com";
    //this.baseUrl ="http://localhost:5182";

   //Sensor
   this.InsertOrUpdateSensorUrl = this.baseUrl + '/api/sensordata/sensor/insertorupdatesensor';
   this.GetSensorByIdUrl = this.baseUrl + '/api/sensordata/sensor/getsensorbyid?id=';
   this.GetAllSensorUrl = this.baseUrl + '/api/sensordata/sensor/getallsensor';
   this.GetSensorsByNameUrl = this.baseUrl + '/api/sensordata/sensor/getsensorsbyname?sensorName=';

    //Sensor Feature Type
    this.InsertOrUpdateSensorFeatureTypeUrl = this.baseUrl + '/api/sensordata/sensor/insertorupdatesensorfeaturetype';
    this.GetSensorFeatureTypeByIdUrl = this.baseUrl + '/api/sensordata/sensor/getsensorfeaturetypebyid?id=';
    this.GetAllSensorFeatureTypeUrl = this.baseUrl + '/api/sensordata/sensor/getallsensorfeaturetype';
    this.GetSensorFeaturetypeByNameUrl = this.baseUrl + '/api/sensordata/sensor/getsensorfeaturetypebyname?sensorFeatureType=';

    //Sensor Location
    this.InsertOrUpdateSensorLocationUrl = this.baseUrl + '/api/sensordata/sensor/insertorupdatesensorlocation';
    this.GetSensorSensorLocationByIdUrl = this.baseUrl + '/api/sensordata/sensor/getsensorlocationbyid?id=';
    this.GetAllSensorLocationUrl = this.baseUrl + '/api/sensordata/sensor/getallsensorlocation';
    this.GetSensorLocationByNameUrl = this.baseUrl + '/api/sensordata/sensor/getsensorlocationbyname?sensorLocation=';
    
    //Sensor Sub Location
    this.InsertOrUpdateSensorSubLocationUrl = this.baseUrl + '/api/sensordata/sensor/insertorupdatesensorsublocation';
    this.GetSensorSensorSubLocationByIdUrl = this.baseUrl + '/api/sensordata/sensor/getallsensorsublocation?id=';
    this.GetAllsensorSubLocationUrl = this.baseUrl + '/api/sensordata/sensor/getallsensorsublocation';
    this.GetSensorSubLocationByNameUrl = this.baseUrl + '/api/sensordata/sensor/getsensorsublocationbyname?sensorSubLocation=';

    //Sensor mapping
    this.InsertOrUpdateSensorMappingUrl = this.baseUrl + '/api/sensordata/sensor/insertorupdatesensormapping';
    this.GetSensorMappingByIdUrl = this.baseUrl + '/api/sensordata/sensor/getsensormappingbyid?id=';
    this.GetAllSensorMappingUrl = this.baseUrl + '/api/sensordata/sensor/getallSensorMapping';
    this.GetSensorMappingByNameUrl = this.baseUrl + '/api/sensordata/sensor/getsensormappingbyname?name=';
   
   //logger
    this.GetSensorDataUrl = this.baseUrl+"/api/sensordata/getsensordata?locationName={0}&sensorType={1}";
    this.GetSensorDataByDayUrl = this.baseUrl+"/api/sensordata/getsensordatabyday";
  }

// Sensor Starts
InsertOrUpdateSensor(model: SensorDataSensorModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateSensorUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetSensorById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetSensorByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllSensor(): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllSensorUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetSensorsByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetSensorsByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}

//Sensor Feature Type
InsertOrUpdateSensorFeatureType(model: SensorDataSensorFeatureTypeModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateSensorFeatureTypeUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetSensorFeatureTypeById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetSensorFeatureTypeByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllSensorFeatureType(): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllSensorFeatureTypeUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetSensorFeaturetypeByName(name:string): Observable<SensorDataSensorFeatureTypeModel[]> {
  var response = this.http.get<SensorDataSensorFeatureTypeModel[]>(this.GetSensorFeaturetypeByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}

//Sensor Location
InsertOrUpdateSensorLocation(model: SensorDataLocationModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateSensorLocationUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetSensorSensorLocationById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetSensorSensorLocationByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllSensorLocation(): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllSensorLocationUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetSensorLocationByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetSensorLocationByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}

//Sensor Sub Location
InsertOrUpdateSensorSubLocation(model: SensorDataSubLocationModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateSensorSubLocationUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetSensorSensorSubLocationById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetSensorSensorSubLocationByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllsensorSubLocation(): Observable<SensorDataSubLocationModel[]> {
  var response = this.http.get<SensorDataSubLocationModel[]>(this.GetAllsensorSubLocationUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetSensorSubLocationByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetSensorSubLocationByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}


//Sensor Mapping
InsertOrUpdateSensorMapping(model: SensorDataSensorMappingModel): Observable<any> {
  var response = this.http.post<any>(this.InsertOrUpdateSensorMappingUrl, model, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))

  );
  return response;
}

GetSensorMappingById(id: number): Observable<any> {
  var response = this.http.get<any>(this.GetSensorSensorSubLocationByIdUrl + id, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetAllSensorMapping(): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetAllsensorSubLocationUrl, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
GetSensorMappingByName(name:string): Observable<any[]> {
  var response = this.http.get<any[]>(this.GetSensorMappingByNameUrl+name, { headers: this.getHeader() }).pipe(
    tap(_ => console.log(response))
  );
  return response;
}
//logging
  getSensorData(): Observable<MainSensorData> {
    //var me = this.http.get<any>(this.GetSensorDataUrl);
    return this.http.get<MainSensorData>(this.GetSensorDataUrl)
    // return me;
  }
  getSensorDataByType(sensorLocation,sensorType: string): Observable<MainSensorData> {
    var newUrl = this.GetSensorDataUrl;
    //newUrl = newUrl.replace('{0}', 'computer%20room');
    newUrl = newUrl.replace('{0}', sensorLocation);
    newUrl = newUrl.replace('{1}', sensorType);
    //console.log(newUrl);
    var me = this.http.get<MainSensorData>(newUrl).pipe(
      map(response => {
        //if(sensorType !=='temp'){
          response.SensorValue.SensorValue = response.SensorValue.SensorValue;
      //  }
        return response;
      })
    );
    return me;
  }
  GetSensorDataByDay(sensorDataPostModel: SensorDataPostModel): Promise<MainSensorData> {
    var response = this.http.post<MainSensorData>(this.GetSensorDataByDayUrl,sensorDataPostModel).pipe(
       ).toPromise();
    return response;
  }

  getHeader(){
    var token = localStorage.getItem('jwtToken');
    let headers = new HttpHeaders({
    'Authorization': 'Bearer ' + token
    }).set("jwt","Bearer "+ token);
    return headers;
  }
}
