import { Component, OnInit, ViewChildren, QueryList, Input } from '@angular/core';
import { FoodImageModel } from 'src/app/models/FoodLogger/FoodImage/food-image-model';
import { FoodModel } from 'src/app/models/FoodLogger/Food/food-model';
import { FoodLoggerService } from 'src/app/services/food-logger.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-food-image',
  templateUrl: './food-image.component.html',
  styleUrls: ['./food-image.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class FoodImageComponent implements OnInit {
   @Input() foodModel:FoodModel;
public foodImageModel:FoodImageModel;
public isImageSaved=false;
@ViewChildren('cmp') components: QueryList<any>;
  constructor(private foodLoggerService: FoodLoggerService) { }

  ngOnInit() {
    //this.foodModel = new FoodModel();
    this.foodModel.FoodImageModels=[];
    //this.foodImageModel = new FoodImageModel();
    this.foodModel.FoodImageModels.push({ FoodImageId: 0, Name: '', Path: '' });

  }
  addFile(): void {
    this.foodModel.FoodImageModels.push({ FoodImageId: 0, Name: '', Path: '' });
    // this.food.UploadFiles.push("Filename");
  }
  onSubmit() {
    var me = this.foodModel;
    this.components.forEach(element =>
      console.log(element.nativeElement.elements.Name)
      //input.append("file[]", element.nativeElement.elements.state.files)
    );

    //adding files to form data
    var arrCompnonet = this.components.toArray();
    //arrCompnonet[0].nativeElement.elements.Name.files[0].name
    var uploadedFiles = [];
    for (var i = 0; i < arrCompnonet.length; i++) {
      uploadedFiles.push(arrCompnonet[i].nativeElement.elements.Name);
    }
    let input = new FormData();
    for (var i = 0; i < uploadedFiles.length; i++) {
      input.append("file[" + i + "]", uploadedFiles[i].files[0], uploadedFiles[i].files[0]['name']);
    }

    this.foodLoggerService.SaveFoodFiles(input,this.foodModel.FoodId).subscribe(
      data => {
      var me = data;
        console.log(me);
        this.isImageSaved =true;
      },
      error=>{
        console.log(console.error);
        this.isImageSaved =false;
     }
    );
  }
}
